import React, { useState, useRef } from "react";
import { InformationSection } from "..";
import { HHeading, SubmitButton } from "../../../../components";
import { Input, Row, Col, notification, Form } from "antd";
import { AuthLinks } from "../AuthTemplate/components";
import { TwoFAUser } from "../../Login";
import { handleFetch } from "../../../../util";
import { API_ROUTE, FETCH } from "../../../../constants";
import loginStyles from "../../Login/login.module.scss";

export const TwoFactorInput = ({
  TwoFAUser,
  isLoading = false,
  handleVerify,
  isFirstTime = false,
}: TwoFactorInputProps) => {
  const [code, setCode] = useState<number[]>([0, 0, 0, 0, 0, 0]);
  const inputRef = useRef<any[]>([0, 1, 2, 3, 4, 5]);

  const verify2FA = async () => {
    if (TwoFAUser?.userId && TwoFAUser.phoneNumber)
      await handleVerify(
        code.join(""),
        TwoFAUser.phoneNumber,
        TwoFAUser.userId,
      );
  };

  const handleResend2FA = async () => {
    if (TwoFAUser?.phoneNumber)
      await handleFetch<TwoFAUser, HealcoUserIdentity>({
        route: API_ROUTE.auth.twoFactorVerify,
        method: FETCH.post,
        body: {
          ...TwoFAUser,
        },
        onSuccess: () =>
          notification.success({
            message: "2FA",
            description: "New code sent to your registered phone number.",
          }),
      });
  };

  return (
    <InformationSection>
      <HHeading
        size={5}
        text={`${
          isFirstTime
            ? ""
            : "Your account is protected with two-factor authentication."
        } We've sent you a text message. Please enter the code below.`}
      />
      <Form layout="vertical" onFinish={verify2FA} colon={false}>
        <Form.Item
          name="code"
          label="Two-Factor Code"
          className={loginStyles.verifyLabel}
        >
          <Input.Group size="large">
            <Row className={loginStyles.verifyContainer}>
              {code.map((c, i) => (
                <Col span={3} key={i} className={loginStyles.verifyColumn}>
                  <Input
                    inputMode="numeric"
                    pattern="[0-9]*"
                    type="text"
                    placeholder={c.toString()}
                    ref={(el) => (inputRef.current[i] = el)}
                    maxLength={1}
                    onChange={(e) => {
                      const newCode = code;
                      const isInteger = /^[0-9]+$/;
                      if (isInteger.test(e.target.value)) {
                        newCode[i] = parseInt(e.currentTarget.value);
                        setCode(newCode);
                        if (i + 1 < code.length)
                          inputRef.current[i + 1].focus();
                        else verify2FA();
                      }
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Input.Group>
        </Form.Item>
        <SubmitButton text="Verify" isLoading={isLoading} />
      </Form>
      <AuthLinks>
        <p className={loginStyles.resend} onClick={handleResend2FA}>
          Resend 2FA Code
        </p>
      </AuthLinks>
    </InformationSection>
  );
};

interface TwoFactorInputProps {
  TwoFAUser?: TwoFAUser;
  handleVerify: (
    code: string,
    phoneNumber: string,
    userId: string,
  ) => Promise<void>;
  isLoading?: boolean;
  isFirstTime?: boolean;
}
