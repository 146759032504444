import { Button, ButtonProps, Tooltip, TooltipProps } from "antd";
import React from "react";

/*
 * Icon Button
 - - - - - - - -
 Renders a button that's just an icon with a tooltip when hovering
 @param title: tooltip title
 @param icon: button icon
 @param tooltipProps: other optional tooltip props
 @param buttonProps: other optional button props
 */
export const IconButton = ({
  title,
  icon,
  tooltipProps,
  buttonProps,
}: IconButtonProps) => {
  return (
    <Tooltip title={title} {...tooltipProps}>
      <Button icon={icon} {...buttonProps} />
    </Tooltip>
  );
};

interface IconButtonProps {
  title: string;
  icon: React.ReactNode;
  tooltipProps?: TooltipProps;
  buttonProps?: ButtonProps;
}
