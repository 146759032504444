import { stripePromise } from "..";

/*
 * Redirect To Membership Checkout
 - - - - - - - - - - -
 */
export const redirectoToCheckout = async (args: { sessionId: string }) => {
  const stripe = await stripePromise;
  if (!stripe) {
    return { type: "api_connection_error", message: "Failed to load Stripe" };
  }
  return stripe.redirectToCheckout({ sessionId: args.sessionId });
};
