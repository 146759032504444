import React, { useState } from "react";
import ImgCrop from "antd-img-crop";
import { Upload, message, Image } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { getFetchResponse, handleFetch } from "../../util";
import { API_ROUTE, FETCH } from "../../constants";
import styles from "./fileUploader.module.scss";

export const FileUploader = ({
  folderLocation,
  files,
  setFiles,
  loading,
  setLoading,
  crop = false,
  shape = "rect",
  rotate = true,
  grid = true,
  isSinglePhoto = false,
}: FileUploaderProps) => {
  const [uploadedFiles, setUploadedFiles] = useState<FileSchema[]>([]);

  const handleUpload = async (file: RcFile) => {
    setLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const { success, result } = await getFetchResponse<any>(
        fetch(`/api${API_ROUTE.upload.fileUploader}/${folderLocation}`, {
          method: FETCH.post,
          body: formData,
        }),
      );
      if (success) {
        setUploadedFiles(isSinglePhoto ? [result] : [...uploadedFiles, result]);
        setFiles(isSinglePhoto ? [result] : [...files, result]);
      }
    }
    setLoading(false);
  };

  const handleDelete = async (fileName: string, index: number) => {
    await handleFetch({
      route: `${API_ROUTE.upload.fileUploader}/${folderLocation}/${fileName}`,
      method: FETCH.delete,
      onSuccess: () => {
        const removeUploaded = [...uploadedFiles];
        removeUploaded.splice(index, 1);
        const removeFiles = [...files];
        removeFiles.splice(index, 1);
        setUploadedFiles(removeUploaded);
        setFiles(removeFiles);
        message.success("File Deleted.");
      },
    });
  };

  const Uploader = () => {
    return (
      <Upload
        className={styles.uploadContainer}
        accept=".jpg, .jpeg, .png, .pdf"
        action={() => {
          return "";
        }}
        name="Uploader"
        customRequest={() => {}}
        showUploadList={false}
        beforeUpload={(file) => {
          const rejectedFileTypes =
            file.type !== "image/png" &&
            file.type !== "image/jpeg" &&
            file.type !== "image/jpg" &&
            file.type !== "application/pdf";
          if (rejectedFileTypes)
            message.error(`${file.name} is not a png/jpeg file`);

          return rejectedFileTypes ? Upload.LIST_IGNORE : handleUpload(file);
        }}
        listType="picture-card"
      >
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      </Upload>
    );
  };

  return (
    <>
      {crop ? (
        <ImgCrop shape={shape} rotate={rotate} grid={grid}>
          <Upload
            className={styles.uploadContainer}
            accept=".jpg, .jpeg, .png"
            action={() => {
              return "";
            }}
            name="Uploader"
            customRequest={() => {}}
            showUploadList={false}
            beforeUpload={(file) => {
              const rejectedFileTypes =
                file.type !== "image/png" &&
                file.type !== "image/jpeg" &&
                file.type !== "image/jpg";
              if (rejectedFileTypes)
                message.error(`${file.name} is not a png/jpeg file`);

              return rejectedFileTypes
                ? Upload.LIST_IGNORE
                : handleUpload(file);
            }}
            listType="picture-card"
          >
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </ImgCrop>
      ) : (
        <Uploader />
      )}
      {uploadedFiles.length > 0 && (
        <>
          <p>Uploaded Files</p>
          <div className={styles.fileContainer}>
            {uploadedFiles.map((file, i) => (
              <div key={file.id}>
                <Image
                  className={styles.image}
                  height={132}
                  width={132}
                  src={`/api/no-auth/download-file/${file.fileName}/${folderLocation}`}
                />
                <DeleteOutlined
                  className={styles.delete}
                  onClick={() => handleDelete(file.fileName, i)}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

type filePaths =
  | "properties"
  | "logos"
  | "feedback"
  | "leases"
  | "patientVerification"
  | "patientQuestionnaires"
  | "patientPrescriptions";

interface FileUploaderProps {
  folderLocation: filePaths;
  files: FileSchema[];
  setFiles: React.Dispatch<React.SetStateAction<FileSchema[]>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  crop?: boolean;
  shape?: "rect" | "round";
  rotate?: boolean;
  grid?: boolean;
  isSinglePhoto?: boolean;
}
