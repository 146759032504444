import React from "react";
import Icon from "@ant-design/icons";
/*
 * HealCo HIconSVG
 - - - - - - - - -
 Creates an ant-design custom icon from an svg imported as a ReactComponent

 example:  import { ReactComponent as iconCaringPatients } from "../../assets/icons/icon-caring-patients.svg";

 @param customIcon - SVG to be provided for icon
 @param onClick - optional onClick
 @param style - optional style object for inline styling
 @param className - optional className
 @param rotate - optional rotation value to alter svg direction
*/

export const HIconSVG = ({
  customIcon,
  onClick,
  style,
  className,
  rotate,
}: HIconSVGProps) => {
  return (
    <>
      <Icon
        component={customIcon}
        onClick={onClick}
        style={style}
        className={className}
        rotate={rotate}
      />
    </>
  );
};
interface HIconSVGProps {
  customIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  style?: { [key: string]: string };
  className?: string;
  rotate?: number;
}
