import React from "react";
import styles from "./authLinks.module.scss";

/*
 * Auth Links
 - - - - - - - - -
 Container of links that can change depending on the Auth page
 */
export const AuthLinks = ({ children }: AuthContainer) => {
  return <div className={styles.authLinks}>{children}</div>;
};

interface AuthContainer {
  children: React.ReactNode;
}
