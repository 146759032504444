import { message } from "antd";
import React, { useEffect, useState } from "react";
import { combineStyles, getFetchResponse } from "../../../../../util";
import { Link } from "react-router-dom";
import { PATH } from "../../../../../constants";
import { PageTitle, LoadingPage } from "../../../../../components/";
import { PropertyCard } from "../PropertyCard/index";
import navIconProperties from "../../../../../assets/icon-property.svg";
import queryString from "query-string";
import sharedStyles from "../../../../../styles/global.module.scss";
import styles from "../properties.module.scss";
import { useToggle } from "../../../../../hooks";

const css = combineStyles(styles, sharedStyles);

const GuestProperties = ({ organizationId }: GuestPropertiesPage) => {
  const [currentPage, setCurrentPage] = useState<
    HealcoResponsePage<HealcoPropertyUser>
  >();
  const [isLoading, toggleLoading] = useToggle();
  const params = queryString.parse(window.location.search);

  // * Fetch Guest Properties
  const fetchPropertiesPage = async () => {
    toggleLoading();
    const queryingParam = {
      organizationId: params.organizationId,
      search: params.search,
      state: params.state,
      specialty: params.specialty,
    };
    try {
      const pageResponse = (await getFetchResponse(
        fetch(`/api/properties/guest?${queryString.stringify(queryingParam)}`),
      )) as HealcoResponsePage<HealcoPropertyUser>;
      setCurrentPage(pageResponse);
    } catch (err) {
      message.error(
        err.message ||
          `Failed to fetch properties, please wait a bit and try again or contact support`,
      );
    }
    toggleLoading();
  };
  // eslint-disable-next-line
  useEffect(() => void fetchPropertiesPage(), [window.location.search]);

  if (isLoading) return <LoadingPage />;
  return (
    <>
      <PageTitle
        title="Property Matches"
        subtitle="Here are your HealCo-curated properties! You can view the specific
        listing by clicking on the property cards."
        content={""}
      />
      <div className={css.cardGrid}>
        {currentPage && currentPage?.results?.length > 0 ? (
          currentPage.results?.map((property) => {
            return (
              <Link
                key={property.id}
                to={`${PATH.provider.properties}/${property.id}`}
              >
                <PropertyCard
                  organizationId={organizationId}
                  showStatus={true}
                  property={property}
                />
              </Link>
            );
          })
        ) : (
          <div className={css.emptyState}>
            <img
              src={navIconProperties}
              className={css.emptyStateImage}
              alt="real estate sign"
            />
            <div>
              Sit tight! Our HealCo experts are in the process of gathering
              available spaces that meet your needs. In the meantime make sure
              you can look for properties yourself with our{" "}
              <Link to={`${PATH.provider.properties}?tab=property-search`}>
                <b>Search Map</b>
              </Link>
              . Find your perfect match.
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GuestProperties;

interface GuestPropertiesPage {
  organizationId: string;
}
