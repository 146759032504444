/*
 * UI
 - - - - - - - - -
 Any constants to do strictly with the UI (breakpoints, etc)
 */

export const UI: UIConstant = {
  antd: {
    sm: "576",
    md: "768",
    lg: "992",
    xl: "1200",
    xxl: "1600",
  },
  datetimeFormat: {
    date: "MM/DD/YY",
    fullDate: "MMM/DD/YYYY",
    time: "h:mm A",
    datetime: "ddd MM/DD h:mm A",
  },

  scrollToIds: {
    faq: {
      whatIs: "#what-is",
      whoUses: "#who-uses",
      howDoIListMyProperties: "#how-do-i-list-my-properties",
      whatDoesTheServiceInclude: "#what-does-the-service-include",
      howMuchDoesHealcoCost: "#how-much-does-healco-cost",
      howDoesPaymentWork: "#how-does-payment-work",
      howDoIGetStarted: "#how-do-i-get-started",
      whatDoIUseFor: "#what-do-i-use-for",
      doIPayToUse: "#do-i-pay-to-use",
      howDoesPaymentWorkLessee: "#how-does-payment-work-lessee",
      howDoIGetStartedLessee: "#how-do-get-started-lessee",
    },
    privacyPolicy: {
      infoCollect: "#info-collect",
      infoShared: "#info-shared",
      googleMap: "#google-map",
      infoStored: "#info-stored",
      infoSafe: "#info-safe",
      infoMinor: "#info-minor",
      privacy: "#privacy",
      dnt: "#dnt",
      california: "#california",
      updates: "#updates",
      contact: "#contact",
    },
    whatWeTreat: {
      acne: "#acne",
      erectileDysfunction: "#erectile-dysfunction",
      femaleLibido: "#female-libido",
      prep: "#prep",
    },
  },
  support: { email: "mailto:support@healco.us", phone: "551-212-8844" },
};

interface UIConstant {
  antd: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
  };
  datetimeFormat: {
    date: string;
    fullDate: string;
    time: string;
    datetime: string;
  };
  scrollToIds: {
    faq: {
      whatIs: string;
      whoUses: string;
      howDoIListMyProperties: string;
      whatDoesTheServiceInclude: string;
      howMuchDoesHealcoCost: string;
      howDoesPaymentWork: string;
      howDoIGetStarted: string;
      whatDoIUseFor: string;
      doIPayToUse: string;
      howDoesPaymentWorkLessee: string;
      howDoIGetStartedLessee: string;
    };
    privacyPolicy: {
      infoCollect: string;
      infoShared: string;
      googleMap: string;
      infoStored: string;
      infoSafe: string;
      infoMinor: string;
      privacy: string;
      dnt: string;
      california: string;
      updates: string;
      contact: string;
    };
    whatWeTreat: {
      acne: string;
      erectileDysfunction: string;
      femaleLibido: string;
      prep: string;
    };
  };
  support: { email: string; phone: string };
}
