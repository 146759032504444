import { API_ROUTE, FETCH } from "../../constants";
import { handleFetch } from "../fetch";

/*
 * Get Ref Tables
 - - - - - - - - -
 Returns objects with all data from one or more Reference tables
 @param tables - array of ref table names to return
 */
export const getRefTables = async (tables: string[]) => {
  const { result } = await handleFetch<HealcoRefTablesBody, HealcoRefTables>({
    route: API_ROUTE.misc.getRefTables,
    method: FETCH.post,
    body: { tables },
  });

  return result;
};
