import React from "react";
import styles from "./informationSection.module.scss";
import cx from "classnames";

/*
 * Information Section
 - - - - - - - - - - - -
 Section with a header and content
 */
export const InformationSection = ({
  children,
  title,
  isError,
  centered = true,
}: InformationSectionProps) => {
  return (
    <div
      className={cx(styles.informationSection, {
        [styles.isError]: isError,
        [styles.centered]: centered,
      })}
    >
      <h2>{title}</h2>
      {children}
    </div>
  );
};

interface InformationSectionProps {
  children: React.ReactNode;
  title?: string;
  isError?: boolean;
  centered?: boolean;
}
