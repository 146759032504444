import { Form, Input } from "antd";
import React from "react";
import { antdFormValidator } from "../../../util";

/*
 * Confirm Password Form
 - - - - - - - - - - - - -
 Antd wrapper for password input, which includes a Confirm Password input,
 and a Password Strength checker.

 */

// TODO1: Improve and implement weighted password checker + UI component for password strength
export const checkForStrongPassword = (password = "") => {
  return password.length > 8;
};

export const ConfirmPasswordForm = () => {
  return (
    <>
      <Form.Item label="Password">
        <Form.Item
          name="password"
          validateTrigger={["onBlur"]}
          rules={[
            {
              required: true,
              validator: (_, value) =>
                antdFormValidator<string>(value, (value) =>
                  checkForStrongPassword(value),
                ),
              message: "Your password must be greater than 8 characters.",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={["Password"]}
          rules={[
            ({ getFieldValue }) => ({
              validator: (_, value) =>
                antdFormValidator<string>(
                  value,
                  (value) => !value || getFieldValue("password") === value,
                ),
              message: "Your passwords do not match.",
            }),
            { required: true },
          ]}
        >
          <Input.Password placeholder="Confirm password" />
        </Form.Item>
      </Form.Item>
    </>
  );
};
