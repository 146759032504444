import { Col, Form, Input, Row } from "antd";
import React from "react";
import { useFormNamePath } from "../../../../hooks";
import { antdFormValidator, isOnlyNumbers } from "../../../../util";
import { HAutoComplete } from "../HAutoComplete";

const FORM_NAME = "address";

/*
 * HealCo Address Form
 - - - - - - - - - -
 Form component for address input
 TODO2: Integrate with Google Location API + expand functionality
 */
export const HAddressForm = ({
  usState,
  name,
  label = "Address",
  required,
  showLines = true,
  showCity = true,
  showUsState = true,
  showZipcode = true,
}: HAddressForm) => {
  const itemName = useFormNamePath(FORM_NAME, name);

  return (
    <Form.Item label={label}>
      {showLines && (
        <>
          <Row align="middle">
            <Col span={24}>
              <Form.Item
                name={itemName.concat(["addressLineOne"])}
                rules={[{ required }]}
              >
                <Input placeholder="Address Line 1" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name={itemName.concat(["addressLineTwo"])}>
                <Input placeholder="Address Line 2" />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Row gutter={8} align="top">
        {showCity && (
          <Col xs={14} sm={8}>
            <Form.Item name={itemName.concat(["city"])} rules={[{ required }]}>
              <Input placeholder="City" />
            </Form.Item>
          </Col>
        )}
        {showUsState && (
          <Col xs={4} sm={4}>
            <HAutoComplete
              refValues={usState}
              name={itemName.concat(["usState"])}
              placeholder="State"
              useIds
              required={required}
            />
          </Col>
        )}
        {showZipcode && (
          <Col xs={6} sm={6} style={{ height: "100%" }}>
            <Form.Item
              name={itemName.concat(["zipcode"])}
              validateTrigger={["onBlur"]}
              rules={[
                {
                  required,
                  validator: (_, value) =>
                    antdFormValidator<string>(
                      value,
                      (v) => isOnlyNumbers(v) && v.length === 5,
                    ),
                  message: "Please enter a valid 5-digit zipcode.",
                },
              ]}
            >
              <Input
                placeholder="Zipcode"
                inputMode="numeric"
                pattern="[0-9]*"
                type="text"
                maxLength={5}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form.Item>
  );
};

interface HAddressForm {
  usState?: HealcoReference[];
  name?: string | string[];
  label?: string;
  required?: boolean;
  showLines?: boolean;
  showCity?: boolean;
  showUsState?: boolean;
  showZipcode?: boolean;
}
