import React from "react";
import { dateSort, alphabeticalSort } from "../../../../../util/sort";
import moment from "moment";
import { UI, PATH } from "../../../../../constants";
import { openInNewTab } from "../../../../../util/openInNewTab";
import styles from "../../Providers/providers.module.scss";
import { ColumnsType } from "antd/lib/table";

const onlineStatusConf = [
  { className: styles.neverIndicator, title: "Never logged in" },
  { className: styles.offlineIndicator, title: "Offline" },
  {
    className: styles.offlineRecentIndicator,
    title: "Online in the last 24 hours",
  },
  { className: styles.onlineIndicator, title: "Online" },
];
const now = moment();

export const providers = ({
  FilterProps,
  currentPage,
  searchParam,
  specialtyFilter,
  stateFilter,
  signupFilter,
}: {
  FilterProps: (
    filterField: string,
    filteredData: HealcoProviderList[] | undefined,
  ) => {
    filterDropdown: () => JSX.Element;
    filterIcon: (filtered: boolean) => JSX.Element;
  };
  currentPage?: HealcoProviderList[];
  searchParam?: { [key: string]: string };
  specialtyFilter:
    | Array<{
        text: string;
        value: string;
      }>
    | undefined;
  stateFilter:
    | Array<{
        text: string;
        value: string;
      }>
    | undefined;
  signupFilter:
    | Array<{
        text: string;
        value: string;
      }>
    | undefined;
}): ColumnsType<HealcoProviderList> => [
  {
    key: "name",
    title: "Host Name",
    sorter: (a, b) => alphabeticalSort(a.hostName, b.hostName),
    render: (item: HealcoProviderList) => {
      const lastOnlineMoment = moment.utc(item.lastLogin);
      const onlineStatus = item.lastLogin
        ? lastOnlineMoment.add(2, "minutes") > now
          ? 3
          : lastOnlineMoment.add(24, "hours") > now
          ? 2
          : 1
        : 0;
      const conf = onlineStatusConf[onlineStatus];
      return (
        <>
          <span title={conf.title} className={conf.className}>
            ⬤
          </span>
          &nbsp;
          <span>{item.hostName}</span>
        </>
      );
    },
    ...FilterProps(
      "name",
      currentPage?.filter(
        (provider) =>
          searchParam?.["name"] &&
          (provider?.hostName?.toLowerCase()?.includes(searchParam?.["name"]) ||
            provider?.organizationName
              ?.toLowerCase()
              ?.includes(searchParam?.["name"].toLowerCase())),
      ),
    ),
  },
  {
    key: "email",
    title: "Email",
    sorter: (a, b) => alphabeticalSort(a.email, b.email),
    render: (item: HealcoProviderList) => item.email,
    ...FilterProps(
      "email",
      currentPage?.filter(
        (provider) =>
          searchParam?.["email"] &&
          provider?.email
            ?.toLowerCase()
            ?.includes(searchParam?.["email"].toLowerCase()),
      ),
    ),
  },
  {
    key: "phone",
    title: "Phone",
    render: (item: HealcoPatientList) => item.phone,
    ...FilterProps(
      "phone",
      currentPage?.filter(
        (provider) =>
          searchParam?.["phone"] &&
          provider?.phone?.includes(searchParam?.["phone"]),
      ),
    ),
  },
  {
    key: "address",
    title: "Address",
    sorter: false,
    render: (item: HealcoProviderList) => item.address,
    filters: stateFilter,
    filterMultiple: false,
    onFilter: (value, record) =>
      record?.usState?.indexOf(value as string) === 0,
  },
  {
    key: "specialty",
    title: "Specialty",
    render: (item: HealcoProviderList) => item.specialty.join(" | "),
    filters: specialtyFilter,
    filterMultiple: true,
    onFilter: (value, record) => record?.specialty?.includes(value as string),
  },
  {
    key: "signupReason",
    title: "Signup Reason",
    render: (item: HealcoProviderList) => item.signupReason?.join(" | "),
    filters: signupFilter,
    onFilter: (value, record) =>
      record?.signupReason?.includes(value as string) || false,
  },
  {
    key: "propertyCount",
    title: "Property Count",
    sorter: true,
    render: (item: HealcoProviderList) => item.propertyCount || 0,
  },
  {
    key: "signup",
    title: "Signup Date",
    sorter: (a, b) => dateSort(a.createdOn, b.createdOn),
    render: (item: HealcoProviderList) =>
      moment(item.createdOn).format(UI.datetimeFormat.date),
  },
  {
    key: "login",
    title: "Last Active",
    sorter: (a, b) => dateSort(a.lastLogin || "0", b.createdOn || "0"),
    render: (item: HealcoProviderList) =>
      moment(item.lastLogin).isValid()
        ? moment(item.lastLogin).format(UI.datetimeFormat.date)
        : "never",
  },
  {
    key: "action",
    title: "Action",
    render: (item: HealcoProviderList) => (
      <span
        className={styles.link}
        onClick={() =>
          openInNewTab(
            `${PATH.admin.account}/?organizationId=${item.organization_id}&tab=profile&userId=${item.key}`,
          )
        }
      >
        View Provider
      </span>
    ),
  },
];
