import {
  AppstoreAddOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { Button, message, notification } from "antd";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import { PATH } from "../../../../../constants";
import { PropertyCard } from "../PropertyCard/index";
import { getFetchResponse } from "../../../../../util";
import propertyStyles from "../properties.module.scss";
import styles from "./hostProperties.module.scss";

const OrganizeProperty = ({
  currentPage,
  setMode,
  organizationId,
}: OrganizeProperty) => {
  // const limit = currentPage?.results[0]?.activeListingLimit || 0;
  const [prioritizeProperty, setPrioritizeProperty] = useState<string[]>([]);

  const toggleAddPriority = (propertyId: string) => {
    const currentProperties = [...prioritizeProperty];
    const index = prioritizeProperty.indexOf(`'${propertyId}'`);
    if (index < 0) {
      // if (prioritizeProperty.length === limit)
      //   message.error(
      //     "Reached max amount of allowed properties. De-select a property or increase your membership plan.",
      //   );
      // else
      currentProperties.push(`'${propertyId}'`);
    } else currentProperties.splice(index, 1);
    setPrioritizeProperty(currentProperties);
  };

  const handleUpdatePriority = async () => {
    try {
      const resp = await getFetchResponse<HealcoResponse<any>>(
        fetch(`/api/properties/update-priority/${organizationId}`, {
          method: "PUT",
          body: JSON.stringify({ prioritizeProperty }),
          headers: { "Content-Type": "application/json" },
        }),
      );
      if (resp.success) {
        notification.success({
          message: "Property",
          description: "Your listings have been updated!",
        });
        setMode("");
      }
    } catch (err) {
      message.error(err.message || "Failed to update your listings.");
    }
  };

  return (
    <>
      <div className={styles.organizeHeader}>
        <h2>Choose my Properties</h2>
        <p>
          You may choose which property you would like to be actively listed and
          visible to providers who are seeking space. You may edit which
          properties are listed at any time. View your unlisted properties in{" "}
          <Link
            to={`${PATH.provider.properties}`}
            onClick={() => setMode("draft")}
          >
            My Drafts/Unlisted Properties
          </Link>
        </p>
      </div>
      <div className={propertyStyles.cardGrid}>
        {currentPage &&
          currentPage.results.map((property) => {
            return (
              <div
                key={property.id}
                onClick={() => toggleAddPriority(property.id)}
              >
                {prioritizeProperty.includes(`'${property.id}'`) && (
                  <h4
                    className={`${propertyStyles.paymentConfirmation} ${propertyStyles.paid}`}
                  >
                    <SafetyCertificateOutlined /> Selected
                  </h4>
                )}
                <PropertyCard
                  organizationId={organizationId}
                  property={property}
                />
              </div>
            );
          })}
      </div>
      {prioritizeProperty.length > 0 && (
        <div className={styles.confirmContainer}>
          <Button
            type="primary"
            shape="round"
            size="large"
            icon={<AppstoreAddOutlined />}
            onClick={handleUpdatePriority}
          >
            Save Listings
          </Button>
        </div>
      )}
    </>
  );
};

export default OrganizeProperty;

interface OrganizeProperty {
  currentPage?: HealcoResponsePage<HealcoPropertyUser>;
  setMode: React.Dispatch<React.SetStateAction<string>>;
  organizationId: string;
}
