import React from "react";
import { Button } from "antd";
import { UpCircleOutlined } from "@ant-design/icons";
import { scrollToTop } from "../../../../../../util";
import styles from "./scrollUp.module.scss";

/*
 * Scroll to top of page
 - - - - - - - - - -
 Displays an up arrow icon in fixed position on bottom right of screen
 Should be implemented on Landing Template as scrollUp boolean
 */

//  TODO Convert to Ant-Design BackTop

/* <BackTop>
  <UpCircleTwoTone twoToneColor={variables.primary} />
  </BackTop> */

export const ScrollUp = () => {
  return (
    <Button
      className={styles.scrollUp}
      type="link"
      shape="circle"
      icon={<UpCircleOutlined />}
      onClick={() => scrollToTop()}
    />
  );
};
