import React, { useEffect, useState, useMemo } from "react";
import { Row, Col, Button, Tag } from "antd";
import { handleFetch, updateQs } from "../../../../../../../../util";
import { API_ROUTE, FETCH, UI } from "../../../../../../../../constants";
import { openInNewTab } from "../../../../../../../../util/openInNewTab";
import styles from "./patientSubscription.module.scss";
import patientDashboardStyles from "../../patientDashboard.module.scss";
import { PrescriptionSubscriptionProps } from "../..";
import { PrescriptionTagColors } from "./prescriptionTagColors";
import moment from "moment";

const PatientSubscription = ({
  prescriptionSubscription,
}: PrescriptionSubscriptionProps) => {
  const [nextBillingDue, setNextBillingDue] = useState<number>();
  const plans = Object.values(prescriptionSubscription || {}).map((script) => {
    return {
      label: `${script[0].medication.name} - ${
        script[0].medication.dosage
      }${script[0].medication.daySupply &&
        `/${script[0].medication.daySupply}`}`,
      category: script[0].treatmentCategory.id,
      id: script[0].medication.id,
      isActive: Boolean(script[0].isActive),
      hasPaid: script[0].hasPaid,
      acceptedOn: script[0].acceptedOn,
    };
  });

  const activePlans = useMemo(() => plans.filter((plan) => plan.isActive), [
    plans,
  ]);
  const inActivePlans = useMemo(
    () =>
      plans.filter(
        (plan) => !plan.isActive && plan.hasPaid && !plan.acceptedOn,
      ),
    [plans],
  );
  const incompletePlans = useMemo(() => plans.filter((plan) => !plan.hasPaid), [
    plans,
  ]);

  const getSubscriptionInfo = async () => {
    await handleFetch<void, number>({
      route: API_ROUTE.stripe.subscriptionInfo,
      method: FETCH.get,
      onSuccess: (res) => setNextBillingDue(res),
    });
  };

  useEffect(() => void getSubscriptionInfo(), []);

  const handleCustomerPortal = async () => {
    await handleFetch<void, string>({
      route: API_ROUTE.stripe.customerPortal,
      method: FETCH.get,
      onSuccess: (res) => openInNewTab(res),
    });
  };

  return (
    <Row
      justify="space-around"
      gutter={[0, 16]}
      className={patientDashboardStyles.row}
    >
      <Col xs={24} lg={15} className={patientDashboardStyles.col}>
        <div className={styles.activePlansContainer}>
          <b>Active Plans:</b>
          <p>
            {activePlans.length > 0 ? (
              activePlans.map((plan) => (
                <Tag
                  key={plan.id}
                  onClick={() => updateQs("tab", plan.category)}
                  color={
                    PrescriptionTagColors[plan.category as MedicationTypes]
                  }
                >
                  {plan.label}
                </Tag>
              ))
            ) : (
              <span>No active plans</span>
            )}
          </p>
          {inActivePlans.length > 0 && (
            <>
              <b>Waiting Approval:</b>
              <p>
                {inActivePlans.map((plan) => (
                  <Tag
                    key={plan.id}
                    onClick={() => updateQs("tab", plan.category)}
                    color={
                      PrescriptionTagColors[plan.category as MedicationTypes]
                    }
                  >
                    {plan.label}
                  </Tag>
                ))}
              </p>
            </>
          )}
          {incompletePlans.length > 0 && (
            <>
              <b>Incomplete:</b>
              <p>
                {incompletePlans.map((plan) => (
                  <Tag
                    key={plan.id}
                    onClick={() => updateQs("tab", plan.category)}
                    color={
                      PrescriptionTagColors[plan.category as MedicationTypes]
                    }
                  >
                    {plan.label}
                  </Tag>
                ))}
              </p>
            </>
          )}
        </div>
      </Col>
      <Col xs={24} lg={7} className={patientDashboardStyles.col}>
        {nextBillingDue ? (
          <>
            <p className={styles.paymentInfo}>Next Payment</p>
            <p>
              {moment.unix(nextBillingDue).format(UI.datetimeFormat.fullDate)}
            </p>
            <p>
              due in{" "}
              <b>{moment.unix(nextBillingDue).diff(moment(), "days")} day(s)</b>
            </p>
          </>
        ) : (
          <p>No active subscriptions</p>
        )}
        <Row style={{ marginTop: "1rem" }}>
          <div>
            <Button
              onClick={() => handleCustomerPortal()}
              className={styles.subscriptionBtn}
            >
              Billing Settings
            </Button>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export { PatientSubscription };
