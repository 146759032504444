import React, { useState, useEffect, useMemo } from "react";
import {
  fetchSearchResults,
  getFetchResponse,
  history,
} from "../../../../../../util";
import { useClientInformation } from "../../../../../../hooks/useClientInformation";
import { message, Popover, Image } from "antd";
import { PATH } from "../../../../../../constants";
import { LoadingPage } from "../../../../../../components";
import { PropertyCard } from "../../../Properties/PropertyCard";
import { InfoCircleOutlined } from "@ant-design/icons";
import queryString from "query-string";
import { Link } from "react-router-dom";
import HouseSearch from "../../../../../../assets/house-search.svg";
import propertyStyles from "../../../Properties/properties.module.scss";
import styles from "./nearbyAndRecent.module.scss";
import { useToggle } from "../../../../../../hooks";

export const NearbyAndRecent = ({ organizationId }: NearbyAndRecentProps) => {
  const clientInformation = useClientInformation();
  const [currentPage, setCurrentPage] = useState<
    HealcoResponsePage<HealcoPropertyUser>
  >();
  const [nearbyProperties, setNearbyProperties] = useState<
    HealcoPropertyUser[]
  >();
  const [isLoading, toggleLoading] = useToggle();
  const params = queryString.parse(window.location.search);

  // * Fetch Guest Properties
  const fetchPropertiesPage = async () => {
    toggleLoading();
    const queryingParam = {
      organizationId: params.organizationId,
      search: params.search,
      state: params.state,
      specialty: params.specialty,
    };
    try {
      const pageResponse = (await getFetchResponse(
        fetch(`/api/properties/guest?${queryString.stringify(queryingParam)}`),
      )) as HealcoResponsePage<HealcoPropertyUser>;
      setCurrentPage(pageResponse);
    } catch (err) {
      message.error(
        err.message ||
          `Failed to fetch properties, please wait a bit and try again or contact support`,
      );
    }

    toggleLoading();
  };
  // eslint-disable-next-line
  useEffect(() => void fetchPropertiesPage(), []);

  // * Fetch Nearest 4 locations that are not in properties or owned by user organization by first grabbing
  // * Lat/Lng information from IP Address then searching based on that.
  useEffect(() => {
    const searchNearby = async (pageResponse: any) => {
      const geometry = clientInformation?.geometry;
      if (geometry && !isNaN(geometry.x) && !isNaN(geometry.y)) {
        const search = await fetchSearchResults(geometry.x, geometry.y, "", 5);
        if (search) {
          if (pageResponse) {
            const results = search.result.results.filter(
              (property: any) =>
                !pageResponse.results.some((comparedProperty: any) => {
                  return property.id === comparedProperty.id;
                }) && property.organization_id !== organizationId,
            );
            setNearbyProperties(results.splice(0, 4));
          } else {
            setNearbyProperties(search?.result.splice(0, 4));
          }
        }
      }
    };
    if (currentPage) searchNearby(currentPage);
  }, [currentPage, organizationId, clientInformation]);

  // For Nearby Properties, Property must be in property_selection table to
  // be viewable to the lessee. This function adds it to that then redirect
  // to property page.
  const handleSendInterestMessage = async (propertyId: string) => {
    try {
      await getFetchResponse<HealcoResponse<null>>(
        fetch(`/api/propertyMatch/${propertyId}`, {
          method: "POST",
          body: JSON.stringify({
            organizationId,
          }),
        }),
      );
    } catch (e) {
      message.error(e.message);
    } finally {
      history.push(`${PATH.provider.properties}/${propertyId}`);
    }
  };

  const hasPropertyMatches = useMemo(
    () => currentPage?.results && currentPage?.results?.length > 0,
    [currentPage],
  );

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <h2>
        Your Most Recent Matches{" "}
        {hasPropertyMatches && (
          <Link to={`${PATH.provider.properties}?tab=guest`}>
            - View all matches
          </Link>
        )}
      </h2>
      {hasPropertyMatches ? (
        <div className={propertyStyles.cardGrid}>
          {currentPage?.results.slice(0, 4).map((property) => (
            <Link
              key={property.id}
              to={`${PATH.provider.properties}/${property.id}`}
            >
              <PropertyCard
                organizationId={organizationId}
                showStatus={true}
                property={property}
              />
            </Link>
          ))}
        </div>
      ) : (
        <div className={styles.houseSearch}>
          <p>You have no matches at this time.</p>
          <Image
            src={HouseSearch}
            alt="House Search"
            preview={false}
            width={150}
          />
          <p>
            In the meantime make sure you can look for properties yourself with
            our{" "}
            <Link to={`${PATH.provider.properties}?tab=property-search`}>
              Property Search Map
            </Link>
            .
          </p>
          <p> Find your perfect match.</p>
        </div>
      )}

      {nearbyProperties && nearbyProperties?.length > 0 && (
        <>
          <h2>
            Recommended Nearby Properties{" "}
            <Popover
              content={
                <p>
                  Based on properties that are within your current location's
                  ~5mile radius.
                </p>
              }
            >
              <InfoCircleOutlined
                style={{ color: "gray", cursor: "pointer" }}
              />
            </Popover>
          </h2>
          <div className={propertyStyles.cardGrid}>
            {nearbyProperties.map((property, i) => {
              return (
                !property.matched && (
                  <div
                    key={i}
                    onClick={() => {
                      handleSendInterestMessage(property.id.toString());
                    }}
                  >
                    <PropertyCard
                      organizationId={organizationId}
                      property={property}
                    />
                  </div>
                )
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

interface NearbyAndRecentProps {
  organizationId: string;
}
