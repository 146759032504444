/*
 * Get Initials
 - - - - - - - - -
 Given and first and last name, returns the initials
 @param fullName - the name
 */
export const getInitials = (fullName: string) => {
  const names = fullName.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
