import React from "react";
import Loadable from "react-loadable";
import { LoadingPage } from "../LoadingPage";

export const Loading = (props: Loadable.LoadingComponentProps) => {
  if (props.error) {
    const prevLoadError = localStorage?.getItem("loadError");
    const shouldReload =
      prevLoadError &&
      // if the last error was less than this number of seconds we don't reload,
      // so not to create an infinite loop of page reloading
      Number(prevLoadError) < Date.now() - 1000 * 30;
    if (prevLoadError === null || shouldReload) {
      localStorage?.setItem("loadError", Date.now().toString());
      console.error(props.error);
      console.error(
        "This usually means a chunk could not be found, because we have a new version of the app published.",
      );
      window.location.reload();
      return null;
    }
    // in case of prevLoadError == undefined, we don't have access to localStorage,
    // so let the user deal with the error.
    return <LoadingPage isError />;
  } else if (props.timedOut) {
    // Loader has taken too long
    return <LoadingPage isTimedOut />;
  } else if (props.pastDelay) {
    // Loader has wait past the artificial delay
    return <LoadingPage />;
  } else {
    // Loader has just started
    return null;
  }
};
