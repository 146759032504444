import cx from "classnames";
import moment from "moment";
import React from "react";
import { LoadingPage } from "../../../../../components";
import styles from "./profile.module.scss";
import { UI } from "../../../../../constants";

const ShowRefInfo = (refArray: HealcoReference[]) => {
  return refArray.map((lang, i) => (
    <span key={lang.id}>
      {lang.name}
      {i < refArray.length - 1 && " | "}
    </span>
  ));
};

const ShowInfo = ({ profile, loading }: ShowInfo) => {
  if (loading) return <LoadingPage />;
  return (
    <>
      <div className={cx(styles.flexInfo, styles.infoContainer)}>
        <div>
          <p>First Name</p>
          <p>{profile.firstName}</p>
        </div>
        <div>
          <p>Last Name</p>
          <p>{profile.lastName}</p>
        </div>
        {/* // TODO0 ADD BACK IN AFTER EDITINFO HAS THIS*/}
        {/* <div>
          <p>Date of Birth</p>
          <p>{profile.dateOfBirth ? profile.dateOfBirth : "-"}</p>
        </div> */}
        <div>
          <p>Created On</p>
          <p>{moment(profile.createdOn).format(UI.datetimeFormat.date)}</p>
        </div>
      </div>
      {/* <div className={cx(styles.flexInfo, styles.infoContainer)}>
        {profile.gender && (
          <div>
            <p>Gender</p>
            <p>{profile.gender}</p>
          </div>
        )}
        {profile.race && (
          <div>
            <p>Race</p>
            <p>{profile.race}</p>
          </div>
        )}
        {profile.faith && (
          <div>
            <p>Faith</p>
            <p>{profile.faith}</p>
          </div>
        )}
      </div> */}
      <div className={styles.infoContainer}>
        <div>
          <p className={styles.label}>Bio</p>
          <p>{profile.bio ? profile.bio : "-"}</p>
        </div>
      </div>
      <div className={styles.infoContainer}>
        <p className={styles.infoLabel}>Provider Details</p>
        <div className={styles.flexInfo}>
          <div>
            <p className={styles.label}>NPI</p>
            <p>{profile?.provider?.npi ? profile.provider.npi : "-"}</p>
          </div>
          <div>
            <p className={styles.label}>Years Practiced</p>
            <p>
              {profile?.provider?.yearsPracticed
                ? profile.provider.yearsPracticed
                : "-"}
            </p>
          </div>
        </div>
        {/* <div>
          <p className={styles.label}>Education</p>
          {profile?.provider?.education &&
          profile?.provider?.education?.length > 0 ? (
            profile.provider.education.map((edu, i) => (
              <p key={i}>
                {edu.degree} {edu.yearGraduated} - {edu.institute}
              </p>
            ))
          ) : (
            <p>-</p>
          )}
        </div> */}
        <div>
          <p className={styles.label}>Insurances</p>
          <p>
            {profile?.provider?.insurance &&
            profile?.provider?.insurance?.length > 0
              ? ShowRefInfo(profile.provider.insurance)
              : "-"}
          </p>
        </div>
        <div>
          <p className={styles.label}>Licensed States</p>
          <p>
            {profile?.provider?.licensedState &&
            profile?.provider?.licensedState?.length > 0
              ? ShowRefInfo(profile.provider.licensedState)
              : "-"}
          </p>
        </div>
        <div>
          <p className={styles.label}>Specialties</p>
          <p>
            {profile?.provider?.specialty &&
            profile?.provider?.specialty?.length > 0
              ? ShowRefInfo(profile.provider.specialty)
              : "-"}
          </p>
        </div>
        <div>
          <p className={styles.label}>Languages</p>
          <p>
            {profile?.language && profile?.language?.length > 0
              ? ShowRefInfo(profile?.language)
              : "-"}
          </p>
        </div>
      </div>
    </>
  );
};

export default ShowInfo;

interface ShowInfo {
  profile: HealcoProfile;
  loading: boolean;
}
