import { VersionHistory } from "./types";
import { PATH } from "../../../../../constants";

const versionWrapper = ({
  id,
  category,
  content,
  releaseMonth,
  releaseYear,
  link,
  linkText = "Check out",
  allowedRoles,
}: VersionHistory) => {
  return {
    id,
    category,
    content,
    releaseMonth,
    releaseYear,
    link,
    linkText,
    allowedRoles,
  };
};

export const changeLog2021 = {
  ver1_5: versionWrapper({
    id: "ver1_5",
    category: "Patient",
    content: "HealCoRx has launched!",
    releaseMonth: "May",
    releaseYear: 2021,
    link: PATH.landing.home,
    linkText:
      "Your personal healthcare marketplace is here! Get access to affordable therapeutics and complimentary mental health care.",
  }),

  ver1_4: versionWrapper({
    id: "ver1_4",
    category: "General",
    content:
      "We've updated our home page! Take a look through our site for engaging content to learn how HealCo can help you.",
    releaseMonth: "May",
    releaseYear: 2021,
  }),

  ver1_3: versionWrapper({
    id: "ver1_3",
    category: "General",
    content:
      "Turn on 2FA (Two-Factor Authentication) for extra security on your account. When enabled, you will be texted a code to make sure that you're you.",
    releaseMonth: "May",
    releaseYear: 2021,
    link: PATH.provider.account,
    linkText: "Turn on 2FA",
  }),

  ver1_2: versionWrapper({
    id: "ver1_2",
    category: "Provider",
    content:
      "Why are you joining HealCo? Tell us more about your goals on signup, so that we can tailor your experience uniquely to you.",
    releaseMonth: "April",
    releaseYear: 2021,
  }),

  ver1_1: versionWrapper({
    id: "ver1_1",
    category: "Provider",
    content:
      "Choose to receive notifications about nearby providers via text or email.",
    releaseMonth: "April",
    releaseYear: 2021,
    link: PATH.provider.account,
    linkText: "Stay connected",
    allowedRoles: ["provider"],
  }),

  ver1_0: versionWrapper({
    id: "ver1_0",
    category: "Provider",
    content:
      "Profile pictures are out! Upload yours now to start making connections with other providers.",
    releaseMonth: "April",
    releaseYear: 2021,
    link: PATH.provider.account,
    linkText: "View profile",
    allowedRoles: ["provider"],
  }),
};
