import { Layout } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withRouter } from "react-router-dom";
import { LoadingPage } from "../../components";
// import FaqBot from "./Twilio/FaqBot";
import { PATH, REF_ID } from "../../constants";
import {
  history,
  IdentityContext,
  isHealcoRole,
  triggerIdentityRefresh,
  hasProviderRole,
} from "../../util";
import styles from "./dashboard.module.scss";
import { ProviderNav, AdminNav, PatientNav } from "./components/SideNav";
import AdminRoutes from "./Admin";
import ProviderRoutes from "./Provider";
import PatientRoutes from "./Patient";
import { DeepSwitch } from "./util";
import { logoutEPHIAudit } from "../../util/audit";

export const rxPages = [
  PATH.admin.patient,
  PATH.admin.prescription,
  PATH.patient.dashboard,
];

const Dashboard = () => {
  const identity = useContext(IdentityContext);
  const userIsAdmin = isHealcoRole(identity, "healcoAdmin");
  const userIsRx =
    isHealcoRole(identity, "patient") ||
    hasProviderRole(identity, REF_ID.providerRole.pharmacist) ||
    hasProviderRole(identity, REF_ID.providerRole.healcoPro);
  const [logAttempt, setLogAttempt] = useState<number>(0);

  useEffect(() => {
    if (identity?.success === false || !identity?.success) {
      const logTimer = setTimeout(() => {
        triggerIdentityRefresh();
        // TODO1: minor issue causes memory leak
        setLogAttempt(logAttempt + 1);
      }, 1000);
      if (logAttempt > 2) {
        clearTimeout(logTimer);
        history.push({
          pathname: PATH.auth.login,
          state: { link: history.location.pathname },
        });
      }
    }
  }, [identity, logAttempt]);

  useEffect(() => {
    if (userIsAdmin || userIsRx)
      history.listen((location) => {
        const prevPage = (location.state as { prevPage: string })?.prevPage;
        if (rxPages.includes(prevPage)) logoutEPHIAudit();
      });
  }, [userIsAdmin, userIsRx]);

  if (identity.success === undefined) return <LoadingPage />;
  return (
    <Layout>
      <Helmet>
        <title>HealCo | Dashboard</title>
      </Helmet>
      {userIsAdmin ? <AdminNav /> : userIsRx ? <PatientNav /> : <ProviderNav />}
      {/* {!userIsAdmin && <FaqBot identity={identity} />} */}
      <Layout className={styles.mainPanel}>
        {/* {identity?.dataCheckupFlag && <DataCheckupModal />} */}
        <DeepSwitch>
          {userIsAdmin && <AdminRoutes />}
          {userIsRx ? <PatientRoutes /> : <ProviderRoutes />}
        </DeepSwitch>
      </Layout>
    </Layout>
  );
};

export default withRouter(Dashboard);
