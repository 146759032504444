import React from "react";
import { makeFullAddress } from "../../../../../util";
import { dateSort } from "../../../../../util/sort";
import moment from "moment";
import { UI, PATH } from "../../../../../constants";
import { openInNewTab } from "../../../../../util/openInNewTab";
import providerStyles from "../../Providers/providers.module.scss";
import { ColumnsType } from "antd/lib/table";

export const properties = ({
  FilterProps,
  currentPage,
  searchParam,
  specialtyFilter,
}: {
  FilterProps: (
    filterField: string,
    filteredData: HealcoProperty[] | undefined,
  ) => {
    filterDropdown: () => JSX.Element;
    filterIcon: (filtered: boolean) => JSX.Element;
  };
  currentPage?: HealcoProperty[];
  searchParam?: { [key: string]: string };
  specialtyFilter:
    | Array<{
        text: string;
        value: string;
      }>
    | undefined;
}): ColumnsType<HealcoProperty> => [
  {
    key: "id",
    title: "Property Id",
    fixed: "left",
    sorter: false,
    render: (item: HealcoProperty) => item.id,
    ...FilterProps(
      "id",
      currentPage?.filter(
        (property) =>
          searchParam?.["id"] &&
          property?.id
            ?.toLowerCase()
            ?.includes(searchParam?.["id"].toLowerCase()),
      ),
    ),
  },
  {
    key: "title",
    title: "Title",
    fixed: "left",
    sorter: (a, b) => a.title.length - b.title.length,
    render: (item: HealcoProperty) => item.title,
    ...FilterProps(
      "title",
      currentPage?.filter(
        (property) =>
          searchParam?.["title"] &&
          property?.title
            ?.toLowerCase()
            ?.includes(searchParam?.["title"].toLowerCase()),
      ),
    ),
  },
  {
    key: "hostOrganization",
    title: "Host | Organization",
    fixed: "left",
    sorter: (a, b) => a.title.length - b.title.length,
    render: (item: HealcoProperty) => (
      <span>
        {item.hostName} | {item.organizationName}
      </span>
    ),
  },
  {
    key: "address",
    title: "Address",
    fixed: "left",
    sorter: false,
    render: (item: HealcoProperty) => {
      const address = {
        addressLineOne: item.addressInfo.addressLineOne,
        addressLineTwo: item.addressInfo.addressLineTwo,
        city: item.addressInfo.city,
        usState: item.addressInfo.usState,
        zipcode: item.addressInfo.zipcode,
      };
      return <span>{makeFullAddress(address)}</span>;
    },
    ...FilterProps(
      "addressInfo",
      currentPage?.filter((property) => {
        const {
          addressLineOne,
          addressLineTwo,
          city,
          usState,
          zipcode,
        } = property?.addressInfo;
        if (searchParam?.["addressInfo"])
          return (
            addressLineOne
              ?.toLowerCase()
              ?.includes(searchParam.addressInfo.toLowerCase()) ||
            addressLineTwo
              ?.toLowerCase()
              ?.includes(searchParam.addressInfo.toLowerCase()) ||
            city
              ?.toLowerCase()
              ?.includes(searchParam.addressInfo.toLowerCase()) ||
            usState
              ?.toLowerCase()
              ?.includes(searchParam.addressInfo.toLowerCase()) ||
            zipcode?.toLowerCase()?.includes(searchParam.addressInfo)
          );
      }),
    ),
  },
  {
    key: "propertyStatus",
    title: "Property Status",
    fixed: "left",
    sorter: false,
    render: (item: HealcoProperty) => item.propertyStatus,
  },
  {
    key: "supportedSpecialty",
    title: "Supported Specialty",
    fixed: "left",
    render: (item: HealcoProperty) =>
      item.supportedProviderSpecialty.join(" | "),
    filters: specialtyFilter,
    filterMultiple: true,
    onFilter: (value, record) =>
      record?.supportedProviderSpecialty?.includes(value as string),
  },
  {
    key: "unsupportedSpecialty",
    title: "Unsupported Specialty",
    fixed: "left",
    render: (item: HealcoProperty) =>
      item.unsupportedProviderSpecialty.join("|"),
    filters: specialtyFilter,
    filterMultiple: true,
    onFilter: (value, record) =>
      record?.unsupportedProviderSpecialty?.includes(value as string),
  },
  {
    key: "createdOn",
    title: "Created On",
    fixed: "left",
    sorter: (a, b) => dateSort(a.createdOn, b.createdOn),
    render: (item: HealcoProperty) =>
      moment(item.createdOn).format(UI.datetimeFormat.date),
  },
  {
    key: "action",
    title: "Action",
    render: (record: HealcoProperty) => (
      <span
        className={providerStyles.link}
        onClick={() => openInNewTab(`${PATH.admin.properties}/${record.id}`)}
      >
        View Property
      </span>
    ),
  },
];
