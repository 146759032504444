import { Col, Form, Input, Row, Select } from "antd";
import { RefSelectProps } from "antd/lib/select";
import React, { useCallback, useRef } from "react";
import { REF_TABLE } from "../../constants";
import { useHReference } from "../../hooks";

export function buildAddressLines(addressInfo: HealcoAddress) {
  if (!addressInfo) return [];

  const city = addressInfo.city ? addressInfo.city + "," : "";
  const state = addressInfo.usState || "";
  const zip = addressInfo.zipcode || "";
  const addressLine2 = [city, state, zip].join(" ");
  if (!addressInfo.addressLineOne) return [addressLine2];

  const suiteNo = addressInfo.addressLineTwo || "";
  const addressLine1 = `${addressInfo.addressLineOne} ${suiteNo}`;
  return [addressLine1, addressLine2];
}

export const AddressForm = ({
  addressInfo = {} as HealcoAddress,
  setAddressInfo,
}: AddressForm) => {
  const { [REF_TABLE.usState]: usStates } = useHReference([REF_TABLE.usState]);

  const updateAddressInfo = useCallback(
    (update) => setAddressInfo(Object.assign({}, addressInfo, update)),
    [setAddressInfo, addressInfo],
  );

  const selectDomEl = useRef<RefSelectProps>(null);

  return (
    <Form layout="vertical">
      <Row gutter={18}>
        <Col xs={24} md={16}>
          <Form.Item
            label="Street Address"
            rules={[{ required: true, message: "Please input your address" }]}
          >
            <Input
              size="large"
              type="text"
              name="addressLineOne"
              onChange={(e) =>
                updateAddressInfo({ addressLineOne: e.target.value })
              }
              value={addressInfo.addressLineOne || ""}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label="Suite or Apt #">
            <Input
              size="large"
              type="text"
              name="addressLineTwo"
              onChange={(e) =>
                updateAddressInfo({ addressLineTwo: e.target.value })
              }
              value={addressInfo.addressLineTwo || ""}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="City"
            rules={[{ required: true, message: "Please input your city" }]}
          >
            <Input
              size="large"
              type="text"
              name="city"
              onChange={(e) => updateAddressInfo({ city: e.target.value })}
              value={addressInfo.city || ""}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="State"
            rules={[{ required: true, message: "Please input your state" }]}
          >
            <Select
              id="usState"
              onChange={(usState) => updateAddressInfo({ usState })}
              onFocus={() => {
                if ((selectDomEl.current as any)?.click) {
                  (selectDomEl.current as any).click();
                }
              }}
              ref={selectDomEl}
              size="large"
              value={addressInfo.usState}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option?.children.toUpperCase().indexOf(input?.toUpperCase()) !==
                -1
              }
            >
              {usStates?.map((ref) => (
                <Select.Option key={ref.id} value={ref.id}>
                  {ref.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Zipcode"
            rules={[{ required: true, message: "Please input your zipcode" }]}
          >
            <Input
              size="large"
              type="text"
              name="zipcode"
              onChange={(e) => updateAddressInfo({ zipcode: e.target.value })}
              value={addressInfo.zipcode || ""}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

interface AddressForm {
  addressInfo: HealcoAddress;
  setAddressInfo: (payload: any) => void;
}
