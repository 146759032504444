import React from "react";
import { Typography } from "antd";
import styles from "./hStrong.module.scss";

/*
 * HStrong
 - - - - -
 Can be used anywhere, provides consistent styling between span elements.

  @param children: any content to be rendered in a <strong> tag
 */

const { Text } = Typography;

export const HStrong = ({
  children,
  underline = false,
  italic = false,
}: HStrongProps) => {
  if (underline) {
    return (
      <Text className={styles.hStrong} underline>
        {children}
      </Text>
    );
  } else if (italic) {
    return <i className={styles.hStrong}>{children}</i>;
  } else {
    return (
      <Text className={styles.hStrong} strong>
        {children}
      </Text>
    );
  }
};

interface HStrongProps {
  children: React.ReactNode;
  underline?: boolean;
  italic?: boolean;
}
