import React from "react";
import { Form, Input } from "antd";
import { HAddressForm } from "../General";

const FORM_NAME = "practice";

/*
 * Organization Form
 - - - - - - - - - - -
 Form to update a user's Practice / Organization
 TODO2: Make HOC to wrap all custom forms to allow inclusion / exclusion of fields by name
 @param full: if true, shows all organization information
 */
export const OrganizationForm = ({ usState, full }: OrganizationFormProps) => {
  return (
    <>
      <Form.Item
        name={[FORM_NAME, "name"]}
        label="Business name"
        tooltip="If you practice under a name other than your full name."
      >
        <Input />
      </Form.Item>
      {full && (
        <Form.Item
          name={[FORM_NAME, "email"]}
          label="Business email"
          tooltip="You can add a secondary email here, or keep it as your login email."
        >
          <Input type="Email" />
        </Form.Item>
      )}
      {full && (
        <Form.Item
          name={[FORM_NAME, "phone"]}
          label="Business phone"
          tooltip="You can add a secondary phone number here, or keep it as your primary number."
        >
          <Input type="number" />
        </Form.Item>
      )}
      {full && (
        <HAddressForm
          name={FORM_NAME}
          label="Business address"
          usState={usState}
        />
      )}
      {full && (
        <Form.Item name={[FORM_NAME, "website"]} label="Website">
          <Input type="number" />
        </Form.Item>
      )}
      {full && (
        <Form.Item name={[FORM_NAME, "fax"]} label="Fax" tooltip="">
          <Input type="number" />
        </Form.Item>
      )}
      {full && (
        <Form.Item name={[FORM_NAME, "ein"]} label="EIN">
          <Input type="number" />
        </Form.Item>
      )}
    </>
  );
};

interface OrganizationFormProps {
  full?: boolean;
  usState?: HealcoReference[];
}
