import React from "react";
import { HContent } from "../../types";
import { Space } from "antd";
import styles from "./hSpace.module.scss";
import cx from "classnames";

/*
 * HSpace
 - - - - -
 Mainly used on Pillar pages, provides consistent spacing between elements.

  @param children: any content to be spaced apart
  @param textStyle: optional color, background-color and <a> theming
  @param align: orientation on page
  @param direction: horizontal or vertical orientation of elements
  @param size: the amount of spacing between elements
 */

export const HSpace = ({
  children,
  textStyle,
  align,
  direction = "vertical",
  size = "large",
  addPadding,
  width,
}: HSpaceProps) => (
  <Space
    align={align}
    direction={direction}
    size={size}
    style={{ width: `${width}%` }}
    className={cx(styles.hSpace, {
      [styles.whitePrimary]: textStyle === "whitePrimary",
      [styles.whiteSecondary]: textStyle === "whiteSecondary",
      [styles.whiteTertiary]: textStyle === "whiteTertiary",
      [styles.addPadding]: addPadding,
    })}
  >
    {children}
  </Space>
);

interface HSpaceProps {
  children: React.ReactNode;
  align?: HContent.TSpaceAlign;
  direction?: HContent.TSpaceDirection;
  size?: HContent.TSpaceSize;
  addPadding?: boolean;
  width?: number;
  textStyle?: HContent.TTextStyle;
}
