import { DatePicker, Form } from "antd";
import moment from "moment";
import React from "react";
import { makeRangeArray } from "../../../../util";

/*
 * Work Week Date Time Picker
 - - - - - - - - - - - - - - -
 Ant Design DateTime Picker that only
 allows times within the workweek (Eastern Time),
 and only allows picking up to one month in advance.
 */
export const WorkWeekDateTimePicker = ({
  name,
  label,
  required,
}: WorkWeekDateTimePickerProps) => {
  return (
    <Form.Item name={name} label={label} rules={[{ required }]}>
      <DatePicker
        showTime={{
          showNow: false,
          use12Hours: true,
          minuteStep: 15,
          hideDisabledOptions: true,
        }}
        size="large"
        format="MM-DD-YYYY HH:mm A"
        disabledDate={(day) =>
          [0, 6].includes(moment(day).day()) ||
          moment().add(-1, "days") >= day ||
          moment().add(1, "month") <= day
        }
        disabledTime={() => {
          return {
            disabledHours: () =>
              makeRangeArray(8).concat(makeRangeArray(24, 17)),
          };
        }}
      />
    </Form.Item>
  );
};

interface WorkWeekDateTimePickerProps {
  name: string;
  label: string;
  required: boolean;
}
