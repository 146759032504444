import { AutoComplete, Checkbox, Col, Row, Statistic } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { useCallback, useEffect, useState } from "react";
import { REF_TABLE } from "../../../../../constants";
import { useHReference } from "../../../../../hooks";
import styles from "./lesseeMap.module.scss";

const StatisticsData = ({ results, setMapGeometry }: StatisticsDataProps) => {
  const [selectedState, setSelectedState] = useState<string>("NJ");
  const [withinState, setWithinState] = useState<boolean>(false);
  const [citiesInArea, setCitiesInArea] = useState<any[]>([]);

  const { [REF_TABLE.usState]: usState } = useHReference([REF_TABLE.usState]);

  useEffect(() => {
    const locations = Object.keys(results);
    const data = [] as any;
    if (withinState) {
      locations.forEach((location) => {
        if (results[`${location}`].state === selectedState)
          data.push(results[`${location}`]);
      });
    } else {
      locations.forEach((location) => data.push(results[`${location}`]));
    }
    setCitiesInArea(data);
  }, [results, withinState, selectedState]);

  const handleChangeState = useCallback(
    (value: string) => {
      const index = usState?.filter((s) => s.name === value);
      if (index && index.length > 0) {
        setSelectedState(index[0]?.id);
        // TODO0 Add back in feature, no geometry data on refTables
        //   setMapGeometry({
        //     lat: index[0]?.geometry?.x,
        //     lng: index[0]?.geometry?.y,
        //   });
      }
    },
    [usState],
  );

  return (
    <Row gutter={16} className={styles.statisticsContainer}>
      <Col span={24} className={styles.stateSelection}>
        <AutoComplete
          style={{ width: "100%" }}
          placeholder="State"
          size="large"
          onChange={handleChangeState as (value: SelectValue) => void}
          filterOption={true}
          defaultValue="New Jersey"
        >
          {usState?.map((ref) => (
            <AutoComplete.Option key={ref.id} value={ref.id || ""}>
              {ref.name}
            </AutoComplete.Option>
          ))}
        </AutoComplete>
        <Checkbox onChange={(value) => setWithinState(value.target.checked)}>
          Only Within Selected State
        </Checkbox>
      </Col>
      <Col span={24}>
        <h4>Interests Cities</h4>
      </Col>
      {citiesInArea.length > 0 ? (
        citiesInArea.map((location: any, i: number) => {
          return (
            <Col
              key={i}
              span={12}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setMapGeometry(location.geometry);
              }}
            >
              <div title="Pan on Map">
                <Statistic
                  title={`${location.location}, ${location.state}`}
                  value={location.amount}
                />
              </div>{" "}
            </Col>
          );
        })
      ) : (
        <Col span={24}>
          <h5>This state does not have any lessees interested in the area.</h5>
        </Col>
      )}
    </Row>
  );
};

export default StatisticsData;

interface LocationProp {
  [key: string]: {
    location: string;
    amount: number;
    state: string;
    geometry: HealcoGeometry;
  };
}

interface StatisticsDataProps {
  results: LocationProp;
  setMapGeometry: Function;
}
