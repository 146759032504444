import React from "react";
import { notification } from "antd";
import { triggerIdentityRefresh } from "..";
import { PATH } from "../../constants";
import { history } from "../getHistory";

/*
 * Handle Sign out
 - - - - - - - - - -
  Signs current user out of session
 */
export const handleSignout = (path?: string) => {
  try {
    fetch("/api/logout").then(() => {
      triggerIdentityRefresh({} as HealcoUserIdentity);
      history.push(path ? path : PATH.auth.login);
    });
  } catch (e) {
    notification.error({
      message: "Error Signing Out",
      description: (
        <div>
          <p>There was a server error signing you out.</p>
          <p>Error message is: {e.message}</p>
          <p>
            If the problem persists, you may sign out manually by clearing your
            browser cookies.
          </p>
        </div>
      ),
    });
  }
};
