import { Button } from "antd";
import React, { useEffect, useReducer } from "react";
import { getProfile } from "..";
import { LoadingPage } from "../../../../../components";
import {
  isHealcoRole,
  objectMetaReducer,
  updateQsList,
} from "../../../../../util";
import styles from "../Profile/profile.module.scss";
import EditOrganization from "./EditOrganization";
import ShowOrganization from "./ShowOrganization";
import { useToggle } from "../../../../../hooks";

const Organization = ({ profile, identity }: OrganizationProps) => {
  const [isLoading, toggleLoading] = useToggle();
  const [isEdit, toggleEdit] = useToggle();
  const userIsAdmin = isHealcoRole(identity, "healcoAdmin");
  const allowedUser =
    profile?.organization?.id === identity?.organization?.id || userIsAdmin;
  const [updOrganization, setUpdOrganization] = useReducer<
    TObjectReducer<HealcoOrganizationTemp | undefined>
  >(objectMetaReducer, undefined);

  const getOrganization = async () => {
    toggleLoading();
    try {
      const { result } = await getProfile();
      if (result) {
        if (!updOrganization) {
          setUpdOrganization({
            type: "init",
            payload: { ...result.organization },
          });
        } else {
          setUpdOrganization({
            type: "update",
            payload: { ...result.organization },
          });
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      toggleLoading();
    }
  };

  useEffect(() => {
    updateQsList(["isEdit"], [isEdit ? "true" : null]);
  }, [isEdit]);

  useEffect(() => {
    getOrganization();
    // eslint-disable-next-line
  }, []);

  if (!profile || isLoading) return <LoadingPage />;
  return (
    <div className={styles.accountContainer}>
      {isEdit && updOrganization && allowedUser ? (
        <EditOrganization
          organization={updOrganization}
          getOrganization={getOrganization}
          dispatchOrgInfo={setUpdOrganization}
          setIsEdit={toggleEdit}
        />
      ) : (
        <div>
          {allowedUser && (
            <div className={styles.editBtn}>
              <Button size="large" type="primary" onClick={toggleEdit}>
                Edit Practice
              </Button>
            </div>
          )}
          <ShowOrganization
            identity={identity}
            profile={profile}
            organization={updOrganization}
            loading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default Organization;

interface OrganizationProps {
  profile: HealcoProfile;
  identity: HealcoUserIdentity;
}

export interface HealcoOrganizationTemp {
  id: string;
  name: string;
  email: string;
  phone: string;
  address: HealcoAddress;
  fax?: string;
  website?: string;
  ein?: string;
  createdOn: string;
}
