import React, { useEffect, useState } from "react";
import { PatientSubscription, MedicationStatus } from "./components";
import { handleFetch } from "../../../../../../util";
import { API_ROUTE, FETCH } from "../../../../../../constants";
import { HCard } from "../../../../../../components";
import variables from "../../../../../../styles/variables.module.scss";
import { Row } from "antd";
import styles from "./patientDashboard.module.scss";

const PatientDashboard = () => {
  const [prescriptionSubscription, setPrescriptionSubscription] = useState<
    HealcoPrescriptionSubscription
  >();
  const fetchSubscriptionInfo = () => {
    handleFetch<void, HealcoPrescriptionSubscription>({
      route: API_ROUTE.prescription.subscription,
      method: FETCH.get,
      onSuccess: (res) => setPrescriptionSubscription(res),
    });
  };

  useEffect(() => void fetchSubscriptionInfo(), []);

  return (
    <>
      <PatientSubscription
        prescriptionSubscription={prescriptionSubscription}
      />
      <MedicationStatus prescriptionSubscription={prescriptionSubscription} />

      <Row className={styles.row}>
        <HCard backgroundColor={variables.primary} centered>
          <p>
            <b>
              If you are experiencing adverse side effects or are in an
              emergency situation, please dial 911.
            </b>
          </p>
        </HCard>
      </Row>
    </>
  );
};

export { PatientDashboard };

export interface PrescriptionSubscriptionProps {
  prescriptionSubscription?: HealcoPrescriptionSubscription;
}
