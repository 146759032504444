import React from "react";
import { Input, Form, Checkbox, Row } from "antd";
import {
  SubmitButton,
  FileUploader,
  HHeading,
} from "../../../../../../components";
import Button from "antd/es/button";
import styles from "../../PrescriptionModal/PrescriptionInfoModal/prescriptionsInfoModal.module.scss";

const ApproveWindow = ({
  handleApprove,
  prescriptionUpload,
  setPrescriptionUpload,
  loading,
  handleBack,
  setLoading,
  externalPrescription,
  setExternalPrescription,
}: ApproveWindowProps) => {
  const alertWindow = () => {
    const resp = window.confirm(
      "Are you sure you want to exit? You have not accepted or rejected this prescription yet.",
    );
    if (resp === true) handleBack();
  };
  return (
    <Form layout="vertical" onFinish={handleApprove}>
      <HHeading text="Prescription Submission" size={5} noMargin addMarginTop />
      <Form.Item label={<b>Note:</b>} name="providerNote">
        <Input.TextArea
          placeholder="Any special instructions or information you want to tell the patient."
          autoSize={{ minRows: 2, maxRows: 6 }}
          showCount
          maxLength={255}
        />
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={
            prescriptionUpload.length !== 0 ? false : externalPrescription
          }
          disabled={prescriptionUpload.length !== 0}
          onClick={() =>
            setExternalPrescription(
              (externalPrescription) => !externalPrescription,
            )
          }
        >
          <b>I have already sent in this prescription externally</b>
        </Checkbox>
      </Form.Item>
      {!externalPrescription && (
        <Form.Item label={<b>Upload Prescription:</b>}>
          <FileUploader
            folderLocation="patientPrescriptions"
            files={prescriptionUpload}
            setFiles={setPrescriptionUpload}
            loading={loading}
            setLoading={setLoading}
          />
        </Form.Item>
      )}
      <Row justify="space-between" align="middle" gutter={[0, 25]}>
        <Button type="primary" size="large" onClick={() => alertWindow()}>
          Back
        </Button>
        <SubmitButton
          className={styles.approve}
          isLoading={loading}
          disabled={prescriptionUpload.length === 0 && !externalPrescription}
        />
      </Row>
    </Form>
  );
};

export { ApproveWindow };

interface ApproveWindowProps {
  handleApprove: (values: any) => Promise<void>;
  prescriptionUpload: FileSchema[];
  setPrescriptionUpload: React.Dispatch<React.SetStateAction<FileSchema[]>>;
  loading: boolean;
  handleBack: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  externalPrescription: boolean;
  setExternalPrescription: React.Dispatch<React.SetStateAction<boolean>>;
}
