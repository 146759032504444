import { Button, Spin, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  getFetchResponse,
  isHealcoRole,
  updateQsList,
} from "../../../../../util";
import { Link } from "react-router-dom";
import OrganizeProperty from "./OrganizeProperty";
import { PATH } from "../../../../../constants";
import { PageTitle } from "../../../../../components";
import { PropertyCard } from "../PropertyCard/index";
import queryString from "query-string";
import styles from "../properties.module.scss";
import { useToggle } from "../../../../../hooks";

const HostProperties = ({ organizationId, identity }: HostPropertiesPage) => {
  const [currentPage, setCurrentPage] = useState<
    HealcoResponsePage<HealcoPropertyUser>
  >();
  const [isLoading, toggleLoading] = useToggle();
  const [mode, setMode] = useState<string>("");
  const params = queryString.parse(window.location.search);
  // const [showModal, setShowModal] = useState<boolean>(false);
  // const limit = currentPage?.results[0]?.activeListingLimit;
  const filterOrganizationId =
    organizationId || (params.organizationId as string);
  const filterUserId = params.userId;
  const userIsAdmin = identity ? isHealcoRole(identity, "healcoAdmin") : false;
  const newPropertyLink =
    PATH.provider.newProperty +
    (userIsAdmin
      ? `?organizationId=${filterOrganizationId}&userId=${filterUserId}`
      : "");

  // disabling warning because react-router will actually re-render the component in case of qs changing
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void fetchPropertiesPage(), [window.location.search]);
  // useEffect(() => {
  //   if (history.location.state) {
  //     const { modal } = history.location.state as { modal: string };
  //     if (modal === "new_property") setShowModal(true);
  //   }
  // }, []);

  useEffect(() => {
    updateQsList(["mode"], [mode]);
  }, [mode]);

  // Fetch Properties Lessor
  const fetchPropertiesPage = async () => {
    toggleLoading();
    const queryingParam = {
      organizationId: params.organizationId,
      search: params.search,
      state: params.state,
      specialty: params.specialty,
      mode: params.mode,
    };

    try {
      const pageResponse = (await getFetchResponse(
        fetch(`/api/properties?${queryString.stringify(queryingParam)}`),
      )) as HealcoResponsePage<HealcoPropertyUser>;
      setCurrentPage(pageResponse);
    } catch (err) {
      message.error(
        err.message ||
          `Failed to fetch properties, please wait a bit and try again or contact support`,
      );
    }
    toggleLoading();
  };

  return (
    <>
      <div>
        <PageTitle
          title="Your Property Listings"
          subtitle="These are your properties you have listed with us!"
          content={
            <p>
              You can list your properties with the{" "}
              <Link to={newPropertyLink}>New Property</Link> button or view your
              specific listing by clicking on the property cards below.
            </p>
          }
        />
        <div>
          {mode === "" && (
            <Button type="primary" style={{ margin: "0.5rem" }}>
              <Link to={newPropertyLink}>New Property</Link>
            </Button>
          )}

          <Button
            type="primary"
            style={{ margin: "0.5rem" }}
            onClick={() => (mode === "" ? setMode("draft") : setMode(""))}
          >
            {mode === "" ? "My Drafts/Unlisted" : "Back to properties"}
          </Button>

          {mode === "" && (
            <Tooltip title="You can choose which properties are listed and shown to providers.">
              <Button
                style={{ margin: "0.5rem" }}
                onClick={() => setMode("organize")}
              >
                Change my listed properties
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
      {isLoading ? (
        <Spin className="show" />
      ) : mode === "organize" ? (
        <OrganizeProperty
          currentPage={currentPage}
          setMode={setMode}
          organizationId={filterOrganizationId}
        />
      ) : (
        <div className={styles.cardGrid}>
          {currentPage &&
            currentPage.results.map((property) => {
              return (
                <div key={property.id}>
                  <Link to={`${PATH.provider.properties}/${property.id}`}>
                    <PropertyCard
                      organizationId={filterOrganizationId}
                      property={property}
                      showStatus={true}
                    />
                  </Link>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default HostProperties;

interface HostPropertiesPage {
  organizationId: string;
  identity?: HealcoUserIdentity;
}
