import React from "react";
import { Route, Switch } from "react-router-dom";
import LoadableChunks from "../../../components/Loadable";
import { PATH } from "../../../constants";
import UserDashboard from "./UserDashboard";

const ProviderRoutes = () => (
  <Switch>
    <Route exact path={PATH.provider.dashboard} component={UserDashboard} />
    <Route
      path={PATH.provider.properties}
      exact
      component={LoadableChunks.provider.Properties}
    />
    <Route
      exact
      path={`${PATH.provider.properties}/:id([^&]*)`}
      component={LoadableChunks.provider.Property}
    />
    <Route
      exact
      path={PATH.provider.newProperty}
      component={LoadableChunks.provider.NewProperty}
    />
    <Route
      exact
      path={`${PATH.provider.editProperty}/:id([^&]*)`}
      component={LoadableChunks.provider.PropertyEditor}
    />
    <Route
      exact
      path={PATH.provider.connections}
      component={LoadableChunks.provider.Application}
    />
    <Route
      exact
      path={PATH.provider.account}
      component={LoadableChunks.provider.Account}
    />
    <Route
      exact
      path={PATH.provider.propertyChat}
      component={LoadableChunks.provider.PropertyChat}
    />

    <Route component={LoadableChunks.landing.PageNotFound} />
  </Switch>
);

export default ProviderRoutes;
