import { Col, Row, Button } from "antd";
import React from "react";
import { HHeading } from "..";
import styles from "./medicationPrice.module.scss";
import { HLink } from "../HLink";
import { PATH } from "../../constants";
import MedicationOptions from "./MedicationOptions";
import MedLabel from "./MedLabel";
// import Icon from "@ant-design/icons";

// import { ReactComponent as iconFireworks } from "../../assets/icons/icon-fireworks.svg";
// import { ReactComponent as iconRocket } from "../../assets/icons/icon-rocket.svg";
// import { ReactComponent as iconFace } from "../../assets/icons/icon-face.svg";
// import { ReactComponent as iconMedical } from "../../assets/icons/icon-medical.svg";
// import { REF_ID } from "../../constants";

/*
 * Medication Price
 - - - - - - - - - - - -
 Given a treatment category and medications, renders a table
 to check which medication the user wants
 */
export const MedicationPrice = ({
  treatmentCategory,
  medications,
  chosenMedicationId,
  onChoose,
  medPreference,
  setMedPreference,
}: MedicationPriceProps) => {
  return (
    <div
      className={styles.medicationPrice}
      style={{ padding: onChoose ? ".5rem" : "1rem" }}
    >
      <Row align="middle" justify="center">
        {/* <Col>
          {treatmentCategory.id === REF_ID.treatmentCategory.acne ? (
            <Icon component={iconFace} style={{ fontSize: "rem" }} />
          ) : treatmentCategory.id === REF_ID.treatmentCategory.edys ? (
            <Icon component={iconRocket} style={{ fontSize: "2rem" }} />
          ) : treatmentCategory.id === REF_ID.treatmentCategory.sccm ? (
            <Icon component={iconFireworks} style={{ fontSize: "2rem" }} />
          ) : treatmentCategory.id === REF_ID.treatmentCategory.prep ? (
            <Icon component={iconMedical} style={{ fontSize: "2rem" }} />
          ) : (
            <></>
          )}
        </Col> */}
        <Col>
          <HHeading text={`${treatmentCategory.name}`} color="white" />
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }} justify="center">
        <HLink newTab to={PATH.landing.whatWeTreat}>
          Learn more...
        </HLink>
      </Row>
      {/* //TODO3 ADD DIFFERENCES BETWEEN MEDICATION - IE Generic vs Brand /etc */}
      <Row align="middle" gutter={12}>
        {medPreference && medPreference !== "all_options" && (
          <MedLabel medications={[{ name: medPreference }]} index={0} />
        )}
        {medications?.map((med, i) => (
          <React.Fragment key={med.id}>
            {medPreference && medPreference !== "all_options" ? (
              medPreference === med.name.split(" ")[0] && (
                <MedicationOptions
                  med={med}
                  chosenMedicationId={chosenMedicationId}
                  onChoose={onChoose}
                  treatmentCategory={treatmentCategory}
                />
              )
            ) : (
              <>
                {i === 0 && <MedLabel medications={medications} index={i} />}
                <MedicationOptions
                  med={med}
                  chosenMedicationId={chosenMedicationId}
                  onChoose={onChoose}
                  treatmentCategory={treatmentCategory}
                />
                {medications[i + 1] &&
                  med.name.split(" ")[0] !==
                    medications[i + 1].name.split(" ")[0] && (
                    <MedLabel medications={medications} index={i + 1} />
                  )}
              </>
            )}
          </React.Fragment>
        ))}
      </Row>
      <Row justify="center">
        {setMedPreference && (
          <Button onClick={() => setMedPreference("")}>
            Choose a different medication
          </Button>
        )}
      </Row>
    </div>
  );
};

export interface MedicationPriceProps {
  treatmentCategory: HealcoReference;
  medications: HealcoMedication[];
  chosenMedicationId?: string;
  onChoose?: ({ id, priceId }: { id: string; priceId: string }) => void;
  medPreference?: string;
  setMedPreference?: React.Dispatch<React.SetStateAction<string>>;
  shouldShowThreeMonth?: boolean;
}
