import { Form, Input, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { useMemo } from "react";
import { InputList } from "..";
import { REF_OTHER } from "../../../../constants";

const { Option } = Select;

/*
 * HealCo Reference Select
 - - - - - - - - - - - - -
 Antd wrapper for Select, which populates with data from an array of HealCo references,
 and conditionally shows an extra Input field if "Other" is selected. Also allows the option
 for multiple inputs.
 @param refValues: array of HealCo References
 @param name: form item names ('Other' is appended on other fields)
 @param label: form section's label
 @param placeholder: main input's placeholder
 @param multiple: true if the main input should be multi-select
 @param multipleOther: true if multiple Other options may be added
 @param useIds: uses Reference IDs as values instead of names
 @param required: main field required or not
 @param noOther: while not strictly required, this will prevent the need to specify .input when grabbing the field's value (used for ref tables without Other values)
 @param tooltip: string that appears when user hovers over the info tooltip
 @param onChange: onChange Event Handler
  @param allowFilter: enables user typed filtering only if multiple is also true
 */
export const HReferenceSelect = ({
  refValues,
  name,
  label,
  placeholder,
  multiple,
  multipleOther,
  useIds,
  required = false,
  noOther,
  tooltip,
  onChange,
  allowFilter,
}: HReferenceSelectProps) => {
  // combines the values into form value object
  const fieldNames = useMemo(
    () =>
      Array.isArray(name)
        ? { input: name.concat("input"), other: name.concat("other") }
        : { input: [name, "input"], other: [name, "other"] },
    [name],
  );

  // Conditionally allows the user to add one, or multiple other values
  const otherField = multipleOther ? (
    <InputList name={noOther ? name : fieldNames.other} />
  ) : (
    <Form.Item name={noOther ? name : fieldNames.other}>
      <Input placeholder="Please specify" />
    </Form.Item>
  );

  return (
    <>
      {/* Main input field */}
      <Form.Item
        name={noOther ? name : fieldNames.input}
        label={label}
        labelCol={{ span: 24 }}
        rules={[{ required }]}
        tooltip={tooltip}
      >
        <Select
          mode={multiple ? "multiple" : undefined}
          onChange={onChange ? (v) => onChange(v) : undefined}
          placeholder={
            placeholder
              ? placeholder
              : multiple
              ? "Choose all that apply"
              : "Choose one"
          }
          filterOption={
            allowFilter
              ? (searchValue, option) =>
                  option?.value
                    .toUpperCase()
                    .indexOf(searchValue.toUpperCase()) !== -1 ||
                  option?.children
                    .toUpperCase()
                    .indexOf(searchValue.toUpperCase()) !== -1
              : undefined
          }
        >
          {refValues?.map((ref) => (
            <Option key={ref.id} value={ref.id}>
              {useIds ? ref.id : ref.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* Conditionally create other field */}
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          return prevValues !== currentValues;
        }}
      >
        {/* Checks if the Other field(s) should be visible */}
        {({ getFieldValue }) => {
          const value = getFieldValue(fieldNames.input);

          let isVisible;
          if (value instanceof Array) {
            isVisible = value.includes(REF_OTHER);
          } else {
            isVisible = value === REF_OTHER;
          }

          return isVisible ? otherField : null;
        }}
      </Form.Item>
    </>
  );
};

interface HReferenceSelectProps {
  refValues?: HealcoReference[];
  name: string | string[];
  label?: string;
  placeholder?: string;
  multiple?: boolean;
  multipleOther?: boolean;
  useIds?: boolean;
  required?: boolean;
  noOther?: boolean;
  tooltip?: string;
  onChange?: (v: SelectValue) => void;
  allowFilter?: boolean;
}
