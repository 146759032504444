import React from "react";
import { Modal, message, notification } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { handleFetch } from "../../../../../util";
import { FETCH, API_ROUTE } from "../../../../../constants";
import { TwoFactorInput } from "../../../../Auth/components";

const validateNumberModalContent = function(
  number: string,
  userId: string,
  callback: () => void,
) {
  const handleVerify = async (
    code: string,
    phoneNumber: string,
    userId: string,
  ) => {
    await handleFetch<VerifyCode, void>({
      route: API_ROUTE.account.checkVerifyPhone,
      method: FETCH.post,
      body: { code, phoneNumber, userId },
      onSuccess: async () => {
        notification.success({
          message: "Verified",
          description: "Thank you for verifying your phone number.",
        });
        callback();
        Modal.destroyAll();
      },
    });
  };

  return {
    icon: <PhoneOutlined />,
    title: "Validate Phone Number",
    content: (
      <>
        <p>
          You have not yet validated your phone number. This is required to
          receive text messages alerts from us on depending on your alert
          preferences or promotional material.
        </p>
        <p>
          Please click <b>SEND</b> below to receive a validation code to{" "}
          <a href={`tel:${number}`}>{number}</a> and verify your phone number.
        </p>
        <p>
          Phone number not correct? Update your phone number before enabling
          2FA.
        </p>
      </>
    ),
    cancelText: "Close",
    okText: "Send",
    onOk: async () => {
      try {
        await handleFetch<{ phoneNumber: string }, void>({
          route: API_ROUTE.account.sendVerifyPhone,
          method: FETCH.post,
          body: { phoneNumber: number },
          onSuccess: () => {},
        });
        Modal.info({
          icon: <MailOutlined />,
          title: "New Validation Message Sent",
          content: (
            <TwoFactorInput
              TwoFAUser={{ userId, phoneNumber: number }}
              handleVerify={handleVerify}
              isFirstTime={true}
            />
          ),
          okButtonProps: {
            disabled: true,
            style: { display: "none" },
          },
        });
      } catch (e) {
        message.error(`Error verifying phone number: ${e.message}`);
      }
    },
  };
};

export default validateNumberModalContent;

interface VerifyCode {
  code: string;
  phoneNumber: string;
  userId: string;
}
