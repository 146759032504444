import React from "react";
import { RequiredCheckbox } from "..";
import { HLink } from "../..";
import { PATH } from "../../../constants";

/*
 * Agree To Terms Checkbox
 - - - - - - - - - - - - - -
 Used often throughout the signup user experiences for legal purposes.
 @param showConsentToTelehealth: show extra legal page to view
 */
export const AgreeToTermsCheckbox = ({
  showConsentToTelehealth,
}: AgreeToTermsCheckboxProps) => {
  return (
    <RequiredCheckbox name={showConsentToTelehealth ? "telehealth" : undefined}>
      I have read and agree to the{" "}
      {!showConsentToTelehealth ? (
        <>
          <HLink to={PATH.landing.termsAndConditions} newTab>
            Terms & Conditions
          </HLink>{" "}
          and{" "}
          <HLink to={PATH.landing.privacyPolicy} newTab>
            Privacy Policy
          </HLink>
        </>
      ) : (
        <>
          <HLink to={PATH.landing.consentToTelehealth} newTab>
            Consent to Telehealth
          </HLink>{" "}
          and{" "}
          <HLink to={PATH.landing.hipaaAuthorization} newTab>
            HIPAA Authorization
          </HLink>
        </>
      )}
    </RequiredCheckbox>
  );
};

interface AgreeToTermsCheckboxProps {
  showConsentToTelehealth?: boolean;
}
