/*
 * Cleanup Files Info For API
 - - - - -

 */
export function cleanupFilesInfoForApi(filesInfo?: {
  files: HealcoFileUpload[];
}) {
  return {
    files: filesInfo?.files
      ? filesInfo.files.map((f) => ({
          id: f.id || "",
          display_order: f.display_order || 0,
        }))
      : [],
  };
}
