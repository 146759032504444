import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MediumOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { Col, Image, Row } from "antd";
import React from "react";
import { HLink } from "../../components";
import { PATH } from "../../constants";
import { Sitemap } from "../../types";
import { mapSitemapToComponent, scrollToTop } from "../../util";
import styles from "./hfooter.module.scss";
import { healcoBeanGreen } from "../../constants/cdn";

/*
 * HealCo Footer
 - - - - - - - - -
  Footer that appears on all landing / auth pages
 */

export const HFooter = () => {
  /* Sitemap Component Creator */

  const sitemapComponentCreator: Sitemap.ComponentCreator = () => {
    const MainMenu: Sitemap.ContainerComponent = ({ children, category }) => {
      const { header } = category;

      return (
        <Col xs={12} lg={4} className={styles.landingFooterSitemapSection}>
          <h3 className={styles.landingFooterSitemapHeader}>{header}</h3>
          {children}
        </Col>
      );
    };

    const SubMenu: Sitemap.SubContainerComponent = ({
      sectionHeader,
      text,
      link,
      params,
    }) => {
      return (
        <>
          {sectionHeader && (
            <h4 className={styles.landingFooterSitemapSubHeader}>
              {sectionHeader.link ? (
                <HLink to={sectionHeader.link}>{sectionHeader.text}</HLink>
              ) : (
                sectionHeader.text
              )}
            </h4>
          )}
          <div className={styles.landingFooterSitemapLink}>
            <HLink to={link} params={params} key={text}>
              {text}
            </HLink>
          </div>
        </>
      );
    };

    return [MainMenu, SubMenu];
  };

  return (
    <footer className={styles.landingFooter}>
      <Row className={styles.topRow}>
        <Col xs={24} md={6} className={styles.logoStarted}>
          <Image
            className={styles.healcoLogo}
            src={healcoBeanGreen}
            alt="HealCo"
            onClick={() => scrollToTop()}
            preview={false}
            height={170}
            width={200}
          />
          {/* TODO1: Make into Button with On Click */}
          <div className={styles.getStarted}>
            <HLink to={PATH.landing.home}>GET STARTED</HLink>
          </div>
        </Col>
        <Col xs={24} md={18}>
          <Row
            className={styles.landingFooterSitemapSection}
            justify="space-between"
          >
            {/* Site Map */}
            {mapSitemapToComponent({ sitemapComponentCreator })}
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <Col md={18}>
          <Row justify="space-between" className={styles.middleRow}>
            <Col>
              {" "}
              <HLink to={PATH.landing.termsAndConditions}>
                Terms & Conditions
              </HLink>{" "}
            </Col>
            <Col>
              <HLink to={PATH.landing.privacyPolicy}>Privacy Policy</HLink>
            </Col>
            <Col>
              <HLink to={PATH.landing.consentToTelehealth}>
                Consent to Telehealth
              </HLink>
            </Col>
            <Col>
              <HLink to={PATH.landing.hipaaAuthorization}>
                HIPAA Authorization
              </HLink>
            </Col>
          </Row>

          {/* Copyright and Social */}
          <Row className={styles.bottomRow}>
            <Col xs={24} md={12} className={styles.copyright}>
              <p>Copyright © 2021 HealCo All Rights Reserved.</p>
            </Col>
            <Col xs={24} md={12} className={styles.socialLinks}>
              <HLink newTab to="https://www.HLinkedin.com/company/healco/">
                <LinkedinOutlined />
              </HLink>
              <HLink
                newTab
                to="https://www.facebook.com/groups/2824956500855824/"
              >
                <FacebookOutlined />
              </HLink>
              <HLink newTab to="https://medium.com/@HealCo">
                <MediumOutlined />
              </HLink>
              <HLink newTab to="https://twitter.com/WeAreHealCo">
                <TwitterOutlined />
              </HLink>
              <HLink newTab to="https://www.instagram.com/wearehealco/">
                <InstagramOutlined />
              </HLink>
              <HLink
                newTab
                to="https://www.youtube.com/channel/UCIBSAbOOdC5MDjRrSm19agw"
              >
                <YoutubeOutlined />
              </HLink>
            </Col>
          </Row>
        </Col>
      </Row>
    </footer>
  );
};
