import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  MessageOutlined,
  PaperClipOutlined,
  PlaySquareOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Badge, Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NavIconHome } from "../../../../../assets/icon-home.svg";
import { ReactComponent as NavIconProperties } from "../../../../../assets/icon-property.svg";
import { PATH } from "../../../../../constants";
import { NavWrapper } from "../NavWrapper";

/*
 * Provider Nav
 - - - - - - - - - - - -
 Shows only the provider side navigation options.
 */
export const ProviderNav = () => {
  const propertyAlertCount = 0;

  return (
    <NavWrapper>
      <Menu.Item key={PATH.provider.dashboard}>
        <Link to={PATH.provider.dashboard}>
          <NavIconHome />
          <span>Dashboard</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={PATH.provider.properties}>
        <Link to={PATH.provider.properties}>
          <Badge count={propertyAlertCount}>
            <NavIconProperties />
          </Badge>
          <span>Properties</span>
        </Link>
      </Menu.Item>
      <Menu.SubMenu
        key={PATH.provider.connections}
        title="Connections"
        icon={<PaperClipOutlined />}
      >
        <Menu.Item key="pending" icon={<PlaySquareOutlined />}>
          <Link to={PATH.provider.connections}>Pending Connections</Link>
        </Menu.Item>
        <Menu.Item key="approved" icon={<CheckSquareOutlined />}>
          <Link to={`${PATH.provider.connections}?viewCondition=approved`}>
            Approved Connections
          </Link>
        </Menu.Item>
        <Menu.Item key="rejected" icon={<CloseSquareOutlined />}>
          <Link to={`${PATH.provider.connections}?viewCondition=rejected`}>
            Rejected Connections
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key={PATH.provider.propertyChat}>
        <Link to={PATH.provider.propertyChat}>
          <MessageOutlined />
          <span>Chat</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={PATH.provider.account}>
        <Link to={PATH.provider.account}>
          <UserOutlined />
          <span>Account</span>
        </Link>
      </Menu.Item>
    </NavWrapper>
  );
};
