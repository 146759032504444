import React from "react";
import { Switch, Modal, Button, notification } from "antd";
import { HHeading, HText } from "../../../../../components";
import validateNumberModalContent from "../Notification/VerifyModal";
import { handleFetch } from "../../../../../util";
import { API_ROUTE, FETCH } from "../../../../../constants";

const TwoFactorAuth = ({ profile, updateProfile }: TwoFactorAuthProps) => {
  const { isPhoneVerified, twoFactorEnabled } = profile;

  const handleTwoFactorEnabled = async (twoFactorEnabled: boolean) => {
    await handleFetch({
      route: API_ROUTE.account.twoFactorSetup,
      method: FETCH.put,
      body: {
        userId: profile.id,
        twoFactorEnabled,
      },
      onSuccess: () => {
        notification.success({
          message: "2FA Preference",
          description: "Saved",
        });
      },
      ignoreError: true,
    });
  };

  return (
    <>
      <HHeading size={4} text="Enable Two Factor Authentication" />
      <HText>
        Two-factor authentication (2FA) refers to a security method used to help
        protect your account from unauthorized access by requiring an extra
        verification of their identity.
      </HText>
      {profile && profile.phone && !isPhoneVerified ? (
        <Button
          danger
          type="primary"
          onClick={() =>
            Modal.confirm(
              validateNumberModalContent(
                profile.phone as string,
                profile.id,
                updateProfile,
              ),
            )
          }
        >
          You must first verify your phone number to enable 2FA
        </Button>
      ) : (
        <Switch
          defaultChecked={twoFactorEnabled}
          checkedChildren={"Enabled"}
          unCheckedChildren={"Disabled"}
          onChange={handleTwoFactorEnabled}
        />
      )}
    </>
  );
};

export default TwoFactorAuth;

interface TwoFactorAuthProps {
  profile: HealcoProfile;
  updateProfile: () => void;
}
