import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from "antd";
import React, { useReducer, useEffect } from "react";
import { LoadingPage } from "../../../../../components";
import { REF_TABLE } from "../../../../../constants";
import { useHReference, useToggle } from "../../../../../hooks";
import {
  actionCreators,
  getFetchResponse,
  objectMetaReducer,
} from "../../../../../util";
import styles from "./profile.module.scss";

export const InputField = ({
  label,
  value,
  onChange,
  isRequired = false,
}: InputField) => {
  return (
    <Form.Item label={label}>
      <Input
        size="large"
        required={isRequired}
        value={value}
        onChange={onChange}
      />
    </Form.Item>
  );
};

const EditInfo = ({ profile, dispatchEntityInfo, toggleEdit }: EditInfo) => {
  const {
    [REF_TABLE.gender]: gender,
    [REF_TABLE.insurance]: insurance,
    [REF_TABLE.providerSpecialty]: providerSpecialty,
    [REF_TABLE.usState]: usState,
    [REF_TABLE.language]: language,
    [REF_TABLE.faith]: faith,
  } = useHReference([
    REF_TABLE.gender,
    REF_TABLE.insurance,
    REF_TABLE.providerSpecialty,
    REF_TABLE.usState,
    REF_TABLE.language,
    REF_TABLE.faith,
    REF_TABLE.race,
  ]);

  const [isLoading] = useToggle();
  const [provider, dispatchProviderInfo] = useReducer<
    TObjectReducer<HealcoProvider | undefined>
  >(objectMetaReducer, profile?.provider);

  const genderOtherCondition = "7";
  const faithOtherCondition = "OTHR";

  const checkIfRef = (
    value: string | number | undefined,
    refTable: HealcoReference[] | undefined,
    ref: string,
    refOther: string,
    other: string,
  ) => {
    if (!value) return;
    const foundValues = refTable?.filter(
      (refT) => `${refT.id}` === value || `${refT.name}` === value,
    );
    if (foundValues && foundValues.length < 1)
      dispatchEntityInfo(
        actionCreators.metaUpdateObject({
          [ref]: other as string,
          [refOther]: value as string,
        }),
      );
  };

  useEffect(() => {
    if (!isLoading) {
      checkIfRef(
        profile?.gender,
        gender,
        "gender",
        "genderOther",
        genderOtherCondition,
      );
      checkIfRef(
        profile?.faith,
        faith,
        "faith",
        "faithOther",
        faithOtherCondition,
      );
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const handleSaveProfile = async () => {
    try {
      if (profile) {
        const data = profile;
        // * Delete data not updating
        delete data.createdOn;
        delete data.organization;
        delete data.billing;
        delete data.isEmailVerified;
        delete data.isPhoneVerified;
        delete data.success;

        const { success } = await getFetchResponse<any>(
          fetch(`/api/profile`, {
            method: "PUT",
            body: JSON.stringify({ ...data, provider }),
            headers: { "Content-Type": "application/json" },
          }),
        );
        if (success) toggleEdit();
      }
    } catch (err) {
      message.error("Unable to update profile. Please try again later.");
    }
  };

  if (!profile || !provider || isLoading) return <LoadingPage />;
  return (
    <Form layout="vertical" onFinish={handleSaveProfile}>
      <div className={styles.editContainer}>
        <Row gutter={8}>
          <Col span={24}>
            <InputField
              label="First Name"
              isRequired={true}
              value={profile.firstName}
              onChange={(e) =>
                dispatchEntityInfo(
                  actionCreators.metaUpdateObject({
                    firstName: e.target.value,
                  }),
                )
              }
            />
            <InputField
              label="Last Name"
              isRequired={true}
              value={profile.lastName}
              onChange={(e) =>
                dispatchEntityInfo(
                  actionCreators.metaUpdateObject({
                    lastName: e.target.value,
                  }),
                )
              }
            />
          </Col>
          {/* // TODO1 ADD BACK IN USING SIGN UP FORM INPUT*/}
          {/* <Col span={12}>
            <InputField
              label="Date of Birth"
              value={profile.dateOfBirth}
              onChange={(e) =>
                dispatchEntityInfo(
                  actionCreators.metaUpdateObject({
                    dateOfBirth: e.target.value,
                  }),
                )
              }
            />
          </Col> */}
        </Row>
      </div>
      {/* <div className={styles.editContainer}>
        <Row gutter={8}>
          <Col xs={24} md={12}>
            <Form.Item label="Gender">
              <Select
                size="large"
                value={profile.gender}
                onChange={(g) =>
                  dispatchEntityInfo(
                    actionCreators.metaUpdateObject({
                      gender: g as string,
                    }),
                  )
                }
              >
                {gender?.map((reason) => (
                  <Select.Option key={reason.id} value={reason.id.toString()}>
                    {reason.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            {profile.gender === genderOtherCondition && (
              <InputField
                label="Gender Other"
                value={profile.genderOther || (profile.gender as string)}
                onChange={(e) =>
                  dispatchEntityInfo(
                    actionCreators.metaUpdateObject({
                      genderOther: e.target.value,
                    }),
                  )
                }
              />
            )}
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Race">
              <Select
                size="large"
                value={profile.race}
                onChange={(rc) =>
                  dispatchEntityInfo(
                    actionCreators.metaUpdateObject({
                      race: rc as string,
                    }),
                  )
                }
              >
                {race?.map((rc) => (
                  <Select.Option key={rc.id} value={rc.id.toString()}>
                    {rc.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Faith">
              <Select
                size="large"
                value={profile.faith}
                onChange={(religion) =>
                  dispatchEntityInfo(
                    actionCreators.metaUpdateObject({
                      faith: religion as string,
                    }),
                  )
                }
              >
                {faith?.map((religion) => (
                  <Select.Option
                    key={religion.id}
                    value={religion.id.toString()}
                  >
                    {religion.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            {" "}
            {profile.faith === faithOtherCondition && (
              <InputField
                label="Faith Other"
                value={profile.faithOther}
                onChange={(e) =>
                  dispatchEntityInfo(
                    actionCreators.metaUpdateObject({
                      faithOther: e.target.value,
                    }),
                  )
                }
              />
            )}
          </Col>
        </Row>
      </div> */}
      <div className={styles.editContainer}>
        <Form.Item
          label="Bio"
          tooltip="Tell providers about yourself and your practice!"
        >
          <Input.TextArea
            allowClear
            showCount
            maxLength={1000}
            autoSize={{ minRows: 3 }}
            value={profile.bio}
            onChange={(e) =>
              dispatchEntityInfo(
                actionCreators.metaUpdateObject({
                  bio: e.target.value,
                }),
              )
            }
          />
        </Form.Item>
      </div>
      <div className={styles.editContainer}>
        <p className={styles.infoLabel}>Provider Details</p>
        <InputField
          label="NPI"
          value={provider?.npi || ""}
          onChange={(e) =>
            dispatchProviderInfo(
              actionCreators.metaUpdateObject({
                npi: e.target.value,
              }),
            )
          }
        />
        <Form.Item label="Years Practiced">
          <InputNumber
            size="large"
            min={0}
            value={provider?.yearsPracticed || 0}
            onChange={(e) => {
              dispatchProviderInfo(
                actionCreators.metaUpdateObject({
                  yearsPracticed: e,
                }),
              );
            }}
          />
        </Form.Item>
        {/* <div>
        // TODO1 Education Settings
          <p className={styles.label}>Education</p>
          {profile?.education?.length > 0 ? (
            profile.education.map((edu) => (
              <p>
                {edu.degree} {edu.yearGraduated} - {edu.institute}
              </p>
            ))
          ) : (
            <p>-</p>
          )}
        </div> */}
        <Form.Item label="Insurances" className={styles.editLabel}>
          <Select
            size="large"
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select all that apply"
            filterOption={(input, option: any) =>
              option?.children.toUpperCase().indexOf(input?.toUpperCase()) !==
              -1
            }
            value={provider.insurance?.map(
              (href) => href.id || ((href as unknown) as string),
            )}
            onChange={(insurance) => {
              dispatchProviderInfo(
                actionCreators.metaUpdateObject({
                  insurance: JSON.parse(JSON.stringify(insurance)),
                }),
              );
            }}
          >
            {insurance?.map((s) => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Licensed States" className={styles.editLabel}>
          <Select
            size="large"
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select all that apply"
            value={provider.licensedState?.map(
              (states) => states.id || ((states as unknown) as string),
            )}
            filterOption={(input, option: any) =>
              option?.children.toUpperCase().indexOf(input?.toUpperCase()) !==
              -1
            }
            onChange={(licensedState) => {
              dispatchProviderInfo(
                actionCreators.metaUpdateObject({
                  licensedState: JSON.parse(JSON.stringify(licensedState)),
                }),
              );
            }}
          >
            {usState?.map((s) => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Specialty" className={styles.editLabel}>
          <Select
            size="large"
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select all that apply"
            filterOption={(input, option: any) =>
              option?.children.toUpperCase().indexOf(input?.toUpperCase()) !==
              -1
            }
            value={provider.specialty.map(
              (spec) => spec.id || ((spec as unknown) as string),
            )}
            onChange={(specialty) => {
              dispatchProviderInfo(
                actionCreators.metaUpdateObject({
                  specialty: JSON.parse(JSON.stringify(specialty)),
                }),
              );
            }}
          >
            {providerSpecialty?.map((s) => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Languages" className={styles.editLabel}>
          <Select
            size="large"
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select all that apply"
            defaultValue={profile.language?.map((lang) => lang.id) || ["ENGL"]}
            value={profile.language?.map(
              (lang) => lang.id || ((lang as unknown) as string),
            )}
            onChange={(language) => {
              dispatchEntityInfo(
                actionCreators.metaUpdateObject({
                  language: JSON.parse(JSON.stringify(language)),
                }),
              );
            }}
            filterOption={(input, option: any) =>
              option?.children.toUpperCase().indexOf(input?.toUpperCase()) !==
              -1
            }
          >
            {language?.map((s) => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className={styles.actionBtns}>
        <Button
          size="large"
          onClick={toggleEdit}
          icon={<CloseCircleOutlined />}
        />
        <Button htmlType="submit" size="large" type="primary">
          Save Settings
        </Button>
      </div>
    </Form>
  );
};

export default EditInfo;

interface EditInfo {
  profile?: HealcoProfileTemp;
  dispatchEntityInfo: React.Dispatch<IReducerObjectAction<HealcoProfileTemp>>;
  toggleEdit: () => void;
}

interface InputField {
  label: string;
  value: string | undefined;
  onChange: (e: any) => void;
  isRequired?: boolean;
}
