import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { Sitemap } from "../../../types";
import { mapSitemapToComponent } from "../../../util";
import styles from "./mobileMenu.module.scss";
import variables from "../../../styles/variables.module.scss";
import { HLink, HCollapseLink } from "../../../components";
import { PATH } from "../../../constants";
import { hBeanGreen } from "../../../constants/cdn";

/*
 * Mobile Menu
 - - - - - - - -
 Landing menu that appears on Mobile
 */

const MobileMenu = ({
  isHamburgerActive,
  toggleHamburgerActive,
}: MobileMenuProps) => {
  /* Sitemap Component Creator */
  const sitemapComponentCreator: Sitemap.ComponentCreator = () => {
    const MainMenu: Sitemap.ContainerComponent = ({ children, category }) => {
      const { header } = category;

      return (
        <div className={styles.mobileMenuSectionContainer}>
          <h2 className={styles.mobileMenuSectionHeader}>{header}</h2>
          <div className={styles.mobileMenuList}>{children}</div>
        </div>
      );
    };

    const SubMenu: Sitemap.SubContainerComponent = ({
      sectionHeader,
      text,
      link,
      hash,
      params,
      expandItems,
    }) => {
      return (
        <>
          {sectionHeader && (
            <h4 className={styles.mobileMenuListHeader}>
              {sectionHeader.link ? (
                <HLink to={sectionHeader.link} hash={hash}>
                  {sectionHeader.text}{" "}
                </HLink>
              ) : (
                sectionHeader.text
              )}
            </h4>
          )}
          <div className={styles.mobileMenuListLink}>
            {expandItems ? (
              <HCollapseLink text={text} expandItems={expandItems} />
            ) : (
              <HLink to={link} key={text} params={params} hash={hash}>
                {text}
              </HLink>
            )}
          </div>
        </>
      );
    };

    return [MainMenu, SubMenu];
  };

  return (
    <Drawer
      className={styles.mobileMenu}
      visible={isHamburgerActive}
      onClose={() => toggleHamburgerActive()}
      placement="left"
      width="80%"
      title={
        <HLink to={PATH.landing.home}>
          <img
            src={hBeanGreen}
            width={125}
            height={125}
            style={{ padding: "5px" }}
            alt="HealCo"
          />
        </HLink>
      }
      closeIcon={
        <CloseOutlined
          className={styles.icon}
          style={{ color: variables.primary, fontSize: "20px" }}
        />
      }
      zIndex={parseInt(variables.top3)}
      style={{ position: "absolute" }}
    >
      {mapSitemapToComponent({ sitemapComponentCreator })}
    </Drawer>
  );
};

interface MobileMenuProps {
  isHamburgerActive: boolean;
  toggleHamburgerActive: () => void;
}

export default MobileMenu;
