import React from "react";
import { HCard } from "../../../../../../components";
import styles from "./authContainer.module.scss";

/*
 * Auth Container
 - - - - - - - - -
 Main container where all Auth forms / messages are added
 */
export const AuthContainer = ({ children, title }: AuthContainer) => {
  return (
    <HCard className={styles.authContainer}>
      {title && <h1>{title}</h1>}
      {children}
    </HCard>
  );
};

interface AuthContainer {
  children: React.ReactNode;
  title?: string;
}
