import Loadable from "react-loadable";
import { Loading } from "./Loading";

const loadableConfig = {
  loading: Loading,
  delay: 100,
};

// # Auth Loadable
const Login = Loadable({
  loader: () => import("../../pages/Auth/Login/"),
  ...loadableConfig,
});

const ForgotPassword = Loadable({
  loader: () => import("../../pages/Auth/ForgotPassword"),
  ...loadableConfig,
});

const ResetPassword = Loadable({
  loader: () => import("../../pages/Auth/ResetPassword"),
  ...loadableConfig,
});

const VerifyEmail = Loadable({
  loader: () => import("../../pages/Auth/VerifyEmail"),
  ...loadableConfig,
});

const TherapyNavigation = Loadable({
  loader: () => import("../../pages/Auth/TherapyNavigation"),
  ...loadableConfig,
});

// # LANDING PAGES
const Home = Loadable({
  loader: () => import("../../pages/Landing/Home"),
  ...loadableConfig,
});

const ProviderHome = Loadable({
  loader: () => import("../../pages/Landing/Pillar/ProviderHome"),
  ...loadableConfig,
});

const ExtraProviderHome = Loadable({
  loader: () => import("../../pages/Landing/Pillar/ExtraProviderHome"),
  ...loadableConfig,
});

const PatientHome = Loadable({
  loader: () => import("../../pages/Landing/Pillar/PatientHome"),
  ...loadableConfig,
});

const OnlinePharmacyHome = Loadable({
  loader: () => import("../../pages/Landing/Pillar/OnlinePharmacyHome"),
  ...loadableConfig,
});

const PartnerHome = Loadable({
  loader: () => import("../../pages/Landing/Pillar/PartnerHome"),
  ...loadableConfig,
});

const Questionnaire = Loadable({
  loader: () => import("../../pages/Auth/Questionnaire"),
  ...loadableConfig,
});

const OfferVirtualCare = Loadable({
  loader: () =>
    import("../../pages/Landing/Pillar/CoordinateCare/OfferVirtualCare"),
  ...loadableConfig,
});

const PhysicianOrganizations = Loadable({
  loader: () =>
    import("../../pages/Landing/Pillar/CoordinateCare/PhysicianOrganizations"),
  ...loadableConfig,
});

const FAQ = Loadable({
  loader: () => import("../../pages/Landing/Pillar/FAQ"),
  ...loadableConfig,
});

const Glossary = Loadable({
  loader: () => import("../../pages/Landing/Pillar/Glossary"),
  ...loadableConfig,
});

const Pricing = Loadable({
  loader: () => import("../../pages/Landing/Pillar/Pricing"),
  ...loadableConfig,
});

const About = Loadable({
  loader: () => import("../../pages/Landing/Pillar/About"),
  ...loadableConfig,
});

const Contact = Loadable({
  loader: () => import("../../pages/Landing/Pillar/Contact"),
  ...loadableConfig,
});

const PrivacyPolicy = Loadable({
  loader: () => import("../../pages/Landing/Legal/PrivacyPolicy"),
  ...loadableConfig,
});
const HipaaAuthorization = Loadable({
  loader: () => import("../../pages/Landing/Legal/HipaaAuthorization"),
  ...loadableConfig,
});

const TermsAndConditions = Loadable({
  loader: () => import("../../pages/Landing/Legal/TermsAndConditions"),
  ...loadableConfig,
});

const GetMedicinalCannabisLicense = Loadable({
  loader: () =>
    import("../../pages/Landing/Pillar/GetMedicinalCannabisLicense"),
  ...loadableConfig,
});

const EmployeeHealthBenefitPlans = Loadable({
  loader: () => import("../../pages/Landing/Pillar/EmployeeHealthBenefitPlans"),
  ...loadableConfig,
});

const WrvuCalculator = Loadable({
  loader: () => import("../../pages/Landing/Pillar/WrvuCalculator"),
  ...loadableConfig,
});

const ProviderSignup = Loadable({
  loader: () => import("../../pages/Auth/Signup/ProviderSignup"),
  ...loadableConfig,
});

const PatientSignup = Loadable({
  loader: () => import("../../pages/Auth/Signup/PatientSignup"),
  ...loadableConfig,
});

const MedicalOfficeBrokers = Loadable({
  loader: () => import("../../pages/Landing/Pillar/MedicalOfficeBrokers"),
  ...loadableConfig,
});

const ProviderMemberBenefits = Loadable({
  loader: () => import("../../pages/Landing/Pillar/ProviderMemberBenefits"),
  ...loadableConfig,
});

const ProviderFindListSpace = Loadable({
  loader: () => import("../../pages/Landing/Pillar/ProviderFindListSpace"),
  ...loadableConfig,
});

const TalkTherapy = Loadable({
  loader: () => import("../../pages/Landing/Pillar/TalkTherapy"),
  ...loadableConfig,
});

const ConsentToTelehealth = Loadable({
  loader: () => import("../../pages/Landing/Legal/ConsentToTelehealth"),
  ...loadableConfig,
});

const GetYourMedicalSpaceRented = Loadable({
  loader: () => import("../../pages/Landing/Pillar/GetYourMedicalSpaceRented"),
  ...loadableConfig,
});

const ProviderResources = Loadable({
  loader: () => import("../../pages/Landing/Pillar/ProviderResources"),
  ...loadableConfig,
});

const HelpCenter = Loadable({
  loader: () => import("../../pages/Landing/Pillar/HelpCenter"),
  ...loadableConfig,
});

const WhatWeTreat = Loadable({
  loader: () => import("../../pages/Landing/Pillar/WhatWeTreat"),
  ...loadableConfig,
});

const Pharmacies = Loadable({
  loader: () => import("../../pages/Landing/Pillar/Pharmacies"),
  ...loadableConfig,
});

const ErectileDysfunction = Loadable({
  loader: () => import("../../pages/Landing/Pillar/ErectileDysfunction"),
  ...loadableConfig,
});

const FemaleLibido = Loadable({
  loader: () => import("../../pages/Landing/Pillar/FemaleLibido"),
  ...loadableConfig,
});

const Prep = Loadable({
  loader: () => import("../../pages/Landing/Pillar/Prep"),
  ...loadableConfig,
});

const Acne = Loadable({
  loader: () => import("../../pages/Landing/Pillar/Acne"),
  ...loadableConfig,
});

const PageNotFound = Loadable({
  loader: () => import("../../pages/Landing/Pillar/404"),
  ...loadableConfig,
});

// # DASHBOARD
const ProviderDashboard = Loadable({
  loader: () => import("../../pages/Dashboard"),
  ...loadableConfig,
});
const Property = Loadable({
  loader: () => import("../../pages/Dashboard/Provider/Properties/Property"),
  ...loadableConfig,
});
const Properties = Loadable({
  loader: () => import("../../pages/Dashboard/Provider/Properties"),
  ...loadableConfig,
});
const Account = Loadable({
  loader: () => import("../../pages/Dashboard/Provider/Account"),
  ...loadableConfig,
});
const PropertyEditor = Loadable({
  loader: () =>
    import(
      "../../pages/Dashboard/Provider/Properties/PropertyCreator/PropertyEditor"
    ),
  ...loadableConfig,
});
const NewProperty = Loadable({
  loader: () =>
    import("../../pages/Dashboard/Provider/Properties/PropertyCreator"),
  ...loadableConfig,
});
const Map = Loadable({
  loader: () => import("../../pages/Dashboard/Provider/Map/index"),
  ...loadableConfig,
});
const PropertyChat = Loadable({
  loader: () =>
    import("../../pages/Dashboard/Provider/Properties/PropertyChat/index"),
  ...loadableConfig,
});
const Application = Loadable({
  loader: () => import("../../pages/Dashboard/Provider/Application/index"),
  ...loadableConfig,
});

// # Admin Dashboard Loadable
const AdminDashboard = Loadable({
  loader: () => import("../../pages/Dashboard/Admin/AdminDashboard"),
  ...loadableConfig,
});

const AdminProviders = Loadable({
  loader: () => import("../../pages/Dashboard/Admin/Providers"),
  ...loadableConfig,
});

const AdminProperties = Loadable({
  loader: () => import("../../pages/Dashboard/Admin/AdminProperties"),
  ...loadableConfig,
});

const ProviderProfile = Loadable({
  loader: () => import("../../pages/Dashboard/Provider/Account"),
  ...loadableConfig,
});

const AdminPatients = Loadable({
  loader: () => import("../../pages/Dashboard/Admin/Patients"),
  ...loadableConfig,
});

const AdminPrescriptions = Loadable({
  loader: () => import("../../pages/Dashboard/Admin/Prescription"),
  ...loadableConfig,
});

export * from "./LoadingPage";

export default {
  auth: {
    Login,
    ForgotPassword,
    ResetPassword,
    VerifyEmail,
    TherapyNavigation,
  },
  landing: {
    Home,
    PatientHome,
    OnlinePharmacyHome,
    ProviderHome,
    ExtraProviderHome,
    PartnerHome,
    Questionnaire,
    OfferVirtualCare,
    PhysicianOrganizations,
    ProviderSignup,
    PatientSignup,
    FAQ,
    Glossary,
    Pricing,
    PrivacyPolicy,
    HipaaAuthorization,
    TermsAndConditions,
    ConsentToTelehealth,
    WrvuCalculator,
    About,
    HelpCenter,
    Contact,
    GetMedicinalCannabisLicense,
    EmployeeHealthBenefitPlans,
    MedicalOfficeBrokers,
    ProviderMemberBenefits,
    ProviderFindListSpace,
    TalkTherapy,
    GetYourMedicalSpaceRented,
    ProviderResources,
    WhatWeTreat,
    Pharmacies,
    ErectileDysfunction,
    FemaleLibido,
    Prep,
    Acne,
    PageNotFound,
  },
  provider: {
    ProviderDashboard,
    Property,
    Properties,
    PropertyEditor,
    NewProperty,
    Map,
    PropertyChat,
    Application,
    Account,
  },
  admin: {
    AdminDashboard,
    AdminProviders,
    AdminProperties,
    ProviderProfile,
    AdminPatients,
    AdminPrescriptions,
  },
};
