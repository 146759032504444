import queryString from "query-string";
import { history } from "../../";

/*
 * Update Qs
 - - - - - - - - - -
 Removes
 */
export const updateQs = (param: string, value?: string | number) => {
  const newQs = queryString.parse(window.location.search);
  if (value) {
    newQs[param] = value.toString();
  } else {
    delete newQs[param];
  }
  history.replace({
    pathname: window.location.pathname,
    search: queryString.stringify(newQs),
  });
};
