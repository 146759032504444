/*
 * CDN
 Content Delivery Network - AWS CloudFront Url for our assets
 */
export const cdnUrl = "https://cdn.healco.us";

// HealCo Logos
export const hGreen = `${cdnUrl}/healco-logos/h-green.svg`;
export const hDark = `${cdnUrl}/healco-logos/h-dark.svg`;
export const hBeanGreen = `${cdnUrl}/healco-logos/h-bean-green.svg`;
export const hBeanDark = `${cdnUrl}/healco-logos/h-bean-dark.svg`;
export const healcoBeanGreen = `${cdnUrl}/healco-logos/healco-bean-green.svg`;
export const healcoBeanDark = `${cdnUrl}/healco-logos/healco-bean-dark.svg`;

// HealCo RX Logos
export const healcorxBeanGreen = `${cdnUrl}/healco-logos/healco-rx-bean-green.svg`;
