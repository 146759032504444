import { useEffect } from "react";
import { scrollToTop } from "../../util";
import { useLocation } from "react-router-dom";

/*
 * Use Scroll To Position
 - - - - - - - - - - -
 Instantly brings the user to the top of a page upon render or to id location if present
*/

export const useScrollToPosition = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView();
      }
    } else {
      scrollToTop(false);
    }
  }, [location]);
};
