/*
 * Contains Contact Information
 - - - - - - - - - - - - - - - -
 Given some text, checks if it contains a phone number or email in a variety of common formatting
 @param text - the text to analyze
 */
export const containsContactInformation = (text: string) => {
  const websiteEmailReg = /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z()]{1,3}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;
  const phoneReg = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/;
  const descriptionArray = text?.split(" ");
  // Current bypass is if user enters 1 in beginning of ### ### ####
  if (descriptionArray && descriptionArray.length > 0) {
    for (let i = 0; i < descriptionArray.length; i++) {
      // Checks for ########## format
      if (descriptionArray[i].match(phoneReg))
        return "We detected a possible phone number in the description, please format with spaces if that was not the case.";
      else if (
        i + 1 < descriptionArray.length &&
        i + 2 < descriptionArray.length
      ) {
        // Checks for ### ####### format and ###-#######
        if (descriptionArray[i].concat(descriptionArray[i + 1]).match(phoneReg))
          return "We detected a possible phone number in the description, please format with spaces if that was not the case.";
        // Checks for ###### #### format and ######-####
        if (
          descriptionArray[i + 1]
            .concat(descriptionArray[i + 2])
            .match(phoneReg)
        )
          return "We detected a possible phone number in the description, please format with spaces if that was not the case.";
        // Checks for ### ### #### format and ###-###-####
        if (
          descriptionArray[i]
            .concat(descriptionArray[i + 1])
            .concat(descriptionArray[i + 2])
            .match(phoneReg)
        )
          return "We detected a possible phone number in the description, please format with spaces if that was not the case.";
      } else if (text.match(websiteEmailReg))
        return "We detected a possible email or website in the description, please format with spaces if that was not the case.";
      else return "";
    }
  }
};
