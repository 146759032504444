import { ReactComponent as iconFireworks } from "../../assets/icons/icon-fireworks.svg";
import { ReactComponent as iconRocket } from "../../assets/icons/icon-rocket.svg";
import { ReactComponent as iconFace } from "../../assets/icons/icon-face.svg";
import { ReactComponent as iconMedical } from "../../assets/icons/icon-medical.svg";

export const medicationIcons = {
  ACNE: iconFace,
  EDYS: iconRocket,
  PREP: iconMedical,
  SCCM: iconFireworks,
};
