/*
 * Make Boolean Array
 - - - - - - - - - -
 Returns an array holding all boolean values of a specific length
 */
export const makeBooleanArray = (
  length: number,
  initValue = false,
): boolean[] => {
  return new Array(length).fill(initValue);
  //TODO3 review this rewrite:
  // Not supported on IE, (support for IE ends 8/17/2021)
  // return [...Array(length).keys()].map((_) => initValue);
};
