import { Button } from "antd";
import cx from "classnames";
import React from "react";
import { HHeading, HLink } from "../../../../../../components";
import styles from "./pillarHeadings.module.scss";

export const PillarHeading = ({
  title,
  pillarLink,
  pillarBackgroundImage,
}: PillarHeadingProps) => {
  return (
    <div
      className={cx(styles.pillarHeading, {
        [styles.backgroundImage]: pillarBackgroundImage,
        [styles.overlay]: !pillarBackgroundImage,
      })}
      style={{ backgroundImage: `url(${pillarBackgroundImage})` }}
    >
      <HHeading size={2} white centered text={title} gagalin />
      {pillarLink && (
        <Button size="large" className={styles.pillarLink}>
          <HLink to={pillarLink.link} params={pillarLink.params}>
            {pillarLink.text}
          </HLink>
        </Button>
      )}
    </div>
  );
};

interface PillarHeadingProps {
  title: string;
  pillarLink?: {
    text: string;
    link: string;
    params?: {
      [param: string]:
        | string
        | string[]
        | { [param: string]: string | string[] };
    };
  };
  pillarBackgroundImage?: string;
}
