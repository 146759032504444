import React from "react";
import checkIcon from "../../../assets/icon-check.svg";
import styles from "./healcocheckbox.module.scss";

export const HealcoCheckbox = ({
  checked,
  checkedChanged,
  disabled,
  className = "",
  caption,
}: CheckBoxProps) => {
  return (
    <label className={`${styles.container} ${className}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => checkedChanged(e.target.checked)}
        disabled={disabled}
      />
      <span className={styles.checkmark}>
        <img src={checkIcon} alt="" />
      </span>
      {caption && (
        <span
          className={`${styles.caption} ${
            disabled ? styles.disabledCaption : ""
          }`}
        >
          {caption}
        </span>
      )}
    </label>
  );
};

interface CheckBoxProps {
  checked: boolean;
  checkedChanged: (value: boolean) => void;
  disabled?: boolean;
  className?: string;
  caption?: React.ReactNode;
}
