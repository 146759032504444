import React, { useState } from "react";
import { Col, Typography, ColProps, Image } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import styles from "./expandableTableData.module.scss";
import cx from "classnames";
/*
 * Expandable Table Data
 - - - - - - - -
 Renders a antd column with typography text data for Table Rows
 @param columnName: column name
 @param columnValue: column value
 @param extraColumn: takes in an array of object with optional label and value to display in the same column
 @param columnProps: optional column props for formatting
 @param textProps: other optional typography text props
 */
export const ExpandableTableData = ({
  columnName,
  columnValue,
  extraColumn,
  columnProps = { span: 4 },
  textProps,
  isSensitiveData = false,
}: ExpandableTableDataProps) => {
  const [showData, setShowData] = useState<boolean>(!isSensitiveData);
  return (
    <Col {...columnProps} className={styles.column}>
      <Typography.Text strong>{columnName}</Typography.Text>
      <Typography.Text {...textProps}>{columnValue}</Typography.Text>
      {extraColumn?.map((data, i) => (
        <React.Fragment key={i}>
          {data.label && <Typography.Text strong>{data.label}</Typography.Text>}
          {data.value && (
            <Typography.Text
              {...(isSensitiveData && !showData ? {} : textProps)}
            >
              {isSensitiveData && !showData ? (
                <span onClick={() => setShowData(!showData)}>
                  {data.value[0]}
                  <span className={styles.block}></span>
                  {data.value[data.value.length - 1]}
                </span>
              ) : (
                data.value
              )}
            </Typography.Text>
          )}
        </React.Fragment>
      ))}
    </Col>
  );
};

interface ExpandableTableDataProps {
  columnName: string;
  columnValue?: string;
  extraColumn?: Array<{ value?: string; label?: string }>;
  columnProps?: ColProps;
  textProps?: TextProps;
  isSensitiveData?: boolean;
}

/*
 * Expandable Table Image
 - - - - - - - -
 Renders a antd column with Image data for Table Rows
 @param imageRef: string of specific file or array of HealcoFile
 @param columnProps: other optional column text props
 @param imageType: what image folder
 */
export const ExpandableTableImage = ({
  imageRef,
  columnProps = { span: 4 },
  imageType,
}: ExpandableTableImageProps) => {
  return (
    <Col {...columnProps}>
      {Array.isArray(imageRef) ? (
        <Image.PreviewGroup>
          {imageRef.map((img, i) => (
            <Image
              key={i}
              className={cx(styles.photo, {
                [styles.hidden]: i > 0,
              })}
              src={`/api/no-auth/download-file/${img.fileName}/${imageType}`}
            />
          ))}
        </Image.PreviewGroup>
      ) : (
        imageRef && (
          <Image
            className={cx(styles.photo)}
            src={`/api/no-auth/download-file/${imageRef}/${imageType}`}
          />
        )
      )}
    </Col>
  );
};

interface ExpandableTableImageProps {
  imageRef: string | HealcoFile[];
  columnProps?: ColProps;
  imageType: "properties" | "logos";
}
