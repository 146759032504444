import { Col, Row } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { PageHeader, HText } from "../../../../components/";
import Changelog from "../../components/Changelog";
import {
  IdentityContext,
  isHealcoRole,
  hasProviderRole,
  makePhoneNumber,
  makeFullName,
} from "../../../../util";
import { REF_ID, LOCAL_STORAGE } from "../../../../constants";
import SecurityWrapper from "../../components/SecurityWrapper";
import {
  accessEPHIAudit,
  continueEPHIAudit,
  logoutEPHIAudit,
} from "../../../../util/audit";
import { WelcomeModal } from "../../components/WelcomeModal";
import { PatientDashboard, HProPharmDashboard } from "./components";

const Dashboard = () => {
  const identity = useContext(IdentityContext);
  const userIsAdmin = isHealcoRole(identity, "healcoAdmin");
  const userIsPatient = isHealcoRole(identity, "patient");
  const userIsPharmacist = hasProviderRole(
    identity,
    REF_ID.providerRole.pharmacist,
  );
  const userIsHPro = hasProviderRole(identity, REF_ID.providerRole.healcoPro);
  const requiredPassword = false;
  const [isSecurityChecked, setIsSecurityChecked] = useState<boolean>(
    !requiredPassword,
  );

  useEffect(() => {
    accessEPHIAudit();
  }, []);

  // Logs audit when user closes tab and was accessing ePHI
  useEffect(() => {
    if (isSecurityChecked)
      window.addEventListener("beforeunload", logoutEPHIAudit);
    return () => {
      if (isSecurityChecked)
        window.removeEventListener("beforeunload", logoutEPHIAudit);
    };
  }, [isSecurityChecked]);

  return (
    <SecurityWrapper
      setIsSecurityChecked={setIsSecurityChecked}
      requiredPassword={requiredPassword}
      successCallback={accessEPHIAudit}
      modalCallback={continueEPHIAudit}
      exitCallback={logoutEPHIAudit}
    >
      {localStorage.getItem(LOCAL_STORAGE.signupReason) && <WelcomeModal />}
      <Changelog />
      <PageHeader label="Dashboard" />

      <Row>
        <Col span={20}>
          <HText noMargin>
            {makeFullName(identity.firstName, identity.lastName)}
          </HText>
          <HText noMargin>{identity.email}</HText>
          <HText>{makePhoneNumber(identity.phone)}</HText>
        </Col>
      </Row>

      {userIsPatient ? (
        <PatientDashboard />
      ) : (
        <HProPharmDashboard
          isSecurityChecked={isSecurityChecked}
          userIsAdmin={userIsAdmin}
          userIsPatient={userIsPatient}
          userIsHPro={userIsHPro}
          userIsPharmacist={userIsPharmacist}
        />
      )}
    </SecurityWrapper>
  );
};

export default Dashboard;
