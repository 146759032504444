import { handleFetch } from "../..";
import { API_ROUTE, FETCH } from "../../../constants";

export const getMedicationPricing = async (med: string) => {
  const { result } = await handleFetch<void, HealcoMedicationPricing>({
    route: `${API_ROUTE.pricing.medications}?treatmentCategory=${med}`,
    method: FETCH.post,
  });
  return result;
};
