/*
 * Fetch Methods
 - - - - - - - - - - - - - -
 This object contains all commonly used Fetch Methods.
 */
export const FETCH: FetchConstant = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  patch: "PATCH",
};

interface FetchConstant {
  get: TFetchMethod;
  post: TFetchMethod;
  put: TFetchMethod;
  delete: TFetchMethod;
  patch: TFetchMethod;
}
