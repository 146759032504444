/*
 * Is Only Letters
 - - - - - - - - -
 Returns if a string is only letters.
 */
export const isOnlyLetters = (str: string) => {
  const lettersRegexp = /^[A-Za-z]+$/;

  return lettersRegexp.test(str);
};
