import { UI } from "../../../constants";

/*
 * Is Mobile
 - - - - - - -
 Returns whether or not the user is on a mobile device screen
 */
export const isMobile = () => {
  return window.innerWidth <= parseInt(UI.antd.lg);
};
