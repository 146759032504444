import { Col, Row } from "antd";
import { RowProps } from "antd/lib/row";
import React from "react";

/*
 * Split Section
 - - - - - - - - -
 A component that takes in two sets of children and renders
 them responsively in Antd Rows/Cols
 TODO: Expand to more functionality, like ordering, specifying the breakpoint, etc
 @param left: left children (will be on top on mobile)
 @param right: right children (will be on bottom on mobile)
 */
export const SplitSection = ({
  left,
  right,
  ...rowProps
}: SplitSectionProps) => {
  return (
    <Row {...rowProps}>
      <Col xs={24} lg={12}>
        {left}
      </Col>
      <Col xs={24} lg={12}>
        {right}
      </Col>
    </Row>
  );
};

interface SplitSectionProps {
  left: JSX.Element;
  right: JSX.Element;
  rowProps?: RowProps;
}
