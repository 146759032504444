import React from "react";

// Checks identity every 10 minutes (ms, s, min)
const CHECK_IDENTITY_INTERVAL = 1000 * 60 * 10;

let savedCheckIdentity: ((identity?: any) => void) | undefined;

let intervalHandle: NodeJS.Timeout | undefined;

/*
 * Create Identity Context
 */
export const IdentityContext = React.createContext({} as HealcoUserIdentity);

/*
 * Set Check Identity
 */
export const setCheckFunction = (checkIdentity: () => void) => {
  if (intervalHandle) clearInterval(intervalHandle);
  savedCheckIdentity = checkIdentity;
  intervalHandle = setInterval(savedCheckIdentity, CHECK_IDENTITY_INTERVAL);
  checkIdentity();
};

/*
 * Unset Check Identity
 */
export const unsetCheckFunction = (checkIdentity: () => void) => {
  if (savedCheckIdentity === checkIdentity) {
    savedCheckIdentity = undefined;
    if (intervalHandle) clearInterval(intervalHandle);
    intervalHandle = undefined;
  }
};

/*
 * Trigger Identity Refresh
 */
export const triggerIdentityRefresh = (identity?: HealcoUserIdentity) => {
  if (savedCheckIdentity) {
    savedCheckIdentity(identity);
    if (intervalHandle) clearInterval(intervalHandle);
    intervalHandle = setInterval(savedCheckIdentity, CHECK_IDENTITY_INTERVAL);
  }
};
