import React, { useEffect, useState } from "react";
import styles from "./lesseeMap.module.scss";

export default ({
  showAll,
  setShowAll,
  rangeLimits,
  filterDates,
}: DateRangeProps) => {
  const [sliderValues, setSliderValues] = useState<[number, number]>([
    rangeLimits[0],
    rangeLimits[1],
  ]);
  // const [dateValues, setDateValues] = useState<[Moment | null, Moment | null]>([
  //   moment(rangeLimits[0]),
  //   moment(rangeLimits[1]),
  // ]);

  useEffect(() => {
    // setDateValues([moment(rangeLimits[0]), moment(rangeLimits[1])]);
    setSliderValues([rangeLimits[0], rangeLimits[1]]);
  }, [rangeLimits]);

  // const updateDate = (value: [number, number]) => {
  //   if (typeof value === "object")
  //     setDateValues([moment(value[0]), moment(value[1])]);
  // };

  return (
    <div id={styles.dateRangeContainer}>
      <h4
        className={styles.sliderButtons}
        onClick={() => {
          if (showAll) {
            setShowAll(false);
            filterDates(sliderValues);
          } else setShowAll(true);
        }}
      >
        {showAll ? "Filter Lessees" : "Show All Lessees"}
      </h4>

      {/* {!showAll && rangeLimits[0] !== 0 && (
        <>
          <h4
            className={styles.sliderButtons}
            onClick={() => {
              resetRange();
            }}
          >
            Reset
          </h4>
          <h5>Date Range</h5>
          <RangePicker
            value={dateValues}
            id={styles.rangePicker}
            onChange={([start, end]) => {
              setDateValues([start, end]);
            }}
            onCalendarChange={(dates) => {
              if (dates && dates[0] && dates[1]) {
                setSliderValues([
                  parseInt(moment(dates[0]).format("x")),
                  parseInt(moment(dates[1]).format("x")),
                ]);
                filterDates([
                  parseInt(moment(dates[0]).format("x")),
                  parseInt(moment(dates[1]).format("x")),
                ]);
              }
            }}
          />
          <Slider
            className={styles.timelapseSlider}
            range
            min={rangeLimits[0]}
            max={rangeLimits[1]}
            marks={{
              [rangeLimits[0]]: moment(rangeLimits[0]).format("MMM DD YYYY"),
              [rangeLimits[1]]: moment(rangeLimits[1]).format("MMM DD YYYY"),
            }}
            defaultValue={rangeLimits}
            value={sliderValues}
            onAfterChange={(value) => {
              filterDates(value);
              updateDate(value);
            }}
            onChange={(value) => {
              setSliderValues(value);
            }}
            tipFormatter={(value) => <>{moment(value).format("MMM DD YYYY")}</>}
          />
        </>
      )} */}
    </div>
  );
};

interface DateRangeProps {
  showAll: boolean;
  setShowAll: Function;
  rangeLimits: [number, number];
  filterDates: Function;
  resetRange: Function;
}
