/*
 * Scroll To ID
 - - - - - - - - - -
 Scrolls to an element with a given selector ID.
 @param id - the id of the element to be scrolled to
 */
export const scrollToId = (id: string) => {
  if (document.getElementById(id))
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
};
