/*
 * Config
 - - - - - -
 Contains non-sensitive config data that is okay to make public.
 All sensitive information should be on the root config that is
 only accessible from the backend.
 */
const config: Config = {
  "healco.us": {
    stripe: {
      clientId: "ca_EZXTKeDxNS0yXg1TDYMBC5YlGQfZrAb2",
    },
    gaTrackingId: "UA-134478471-1",
  },
  dev: {
    stripe: {
      clientId: "ca_EZXTLqd3tdQIm0noogJBF8hw2Ec8Bqvv",
    },
    gaTrackingId: "UA-146859251-1",
  },
};

export default config[window.location.host] || config.dev;

interface Config {
  [env: string]: {
    stripe: {
      clientId: string;
    };
    gaTrackingId: string;
  };
}
