import { AutoComplete, Col, Form, FormItemProps, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { MONTH, YEAR } from "../../../../constants";
import {
  antdFormValidator,
  antdNumberFilterOption,
  countTrueValues,
  makeRangeArray,
  makeArrayIfNot,
} from "../../../../util";

const { Option } = AutoComplete;

const FORM_NAME = "date";

/*
 * Date Form
 - - - - - - - - - -
 Form component for date input
 TODO1: Figure out banning certain input on Autocomplete
 TODO1: Use FormItemProps instead of name, label, required...
 @param form: the form section's label
 @param label: the form section's label
 @param label: the form section's label
 @param label: the form section's label
 */
export const DateForm = ({
  name,
  label,
  required,
  hasDay = true,
  hasMonth = true,
  hasYear = true,
  yearPlaceholder = "Year",
}: DateForm) => {
  // sets the correct number of days for a given month
  const [month, setMonth] = useState(0);
  const [numDays, setNumDays] = useState(31);
  const [year, setYear] = useState<number>(2021);

  // used for field width styling
  const numFieldRatio = useMemo(
    () => countTrueValues([hasDay, hasMonth, hasYear]) / 3,
    [hasDay, hasMonth, hasYear],
  );

  const dayArray = useMemo(() => makeRangeArray(numDays, 1), [numDays]);
  useEffect(() => {
    // Takes leap year into account
    if (!(year % 4) && month === 1) {
      setNumDays(29);
    } else {
      setNumDays(MONTH[month].days);
    }
  }, [month, year]);

  const itemName = name ? makeArrayIfNot(name) : [FORM_NAME];

  return (
    <Form.Item label={label}>
      <Row gutter={8}>
        {hasMonth && (
          <Col xs={8 / numFieldRatio} sm={6 / numFieldRatio}>
            <Form.Item
              name={itemName.concat(["month"])}
              rules={[
                {
                  required,
                  validator: (_, value) =>
                    antdFormValidator(value, (value) =>
                      MONTH.map((m) => m.month).includes(value),
                    ),
                },
              ]}
            >
              <AutoComplete
                placeholder="Month"
                filterOption
                onSelect={(v) =>
                  setMonth(MONTH.findIndex((m) => m.month === v))
                }
              >
                {MONTH.map((m, i) => (
                  <Option key={i} value={m.month}>
                    {m.month}
                  </Option>
                ))}
              </AutoComplete>
            </Form.Item>
          </Col>
        )}
        {hasDay && (
          <Col xs={8 / numFieldRatio} sm={6 / numFieldRatio}>
            <Form.Item
              name={itemName.concat(["day"])}
              rules={[
                {
                  required,
                  validator: (_, value) =>
                    antdFormValidator(value, (value) =>
                      dayArray.includes(parseInt(value)),
                    ),
                },
              ]}
            >
              <AutoComplete
                filterOption={antdNumberFilterOption}
                placeholder="Day"
              >
                {dayArray.map((d, i) => (
                  <Option key={i} value={d}>
                    {d}
                  </Option>
                ))}
              </AutoComplete>
            </Form.Item>
          </Col>
        )}
        {hasYear && (
          <Col xs={8 / numFieldRatio} sm={12 / numFieldRatio}>
            <Form.Item
              name={itemName.concat(["year"])}
              rules={[
                {
                  required,
                  validator: (_, value) =>
                    antdFormValidator(value, (value) =>
                      YEAR.includes(parseInt(value)),
                    ),
                },
              ]}
            >
              <AutoComplete
                placeholder={yearPlaceholder}
                filterOption={antdNumberFilterOption}
                onSelect={(v) => setYear(parseInt(v))}
              >
                {YEAR.map((y, i) => (
                  <Option key={i} value={y}>
                    {y}
                  </Option>
                ))}
              </AutoComplete>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form.Item>
  );
};

interface DateForm {
  name?: string | string[];
  label?: string;
  required?: boolean;
  formItemProps?: FormItemProps;
  hasDay?: boolean;
  hasMonth?: boolean;
  hasYear?: boolean;
  yearPlaceholder?: string;
}
