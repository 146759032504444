import React from "react";
import styles from "./medLabel.module.scss";
import { Col } from "antd";
import { HText } from "../..";

const MedLabel = ({
  medications,
  index,
}: {
  medications: Array<{ name: string }>;
  index: number;
}) => {
  return (
    <Col span={24} style={{ margin: "20px 0" }}>
      <HText textStyle="whitePrimary" noMargin centered>
        <b className={styles.medLabel}>{medications[index].name}</b>
      </HText>
    </Col>
  );
};

export default MedLabel;
