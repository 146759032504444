import { useMemo } from "react";

/*
 * Use Form Name Path
 - - - - - - - - - - -
 Smartly creates a form item name path. Used to nest form items
 @param name: the passed in name of the parent Form Item
 @param formName: the name to be appended to the form path
 @use: const itemName = useFormNamePath(FORM_NAME, name);
 @use  <Form.Item name={itemName.concat(["xxxx"])}
 */
export const useFormNamePath = (formName: string, name?: string | string[]) => {
  return useMemo(() => {
    return name
      ? Array.isArray(name)
        ? name.concat([formName])
        : [name, formName]
      : [formName];
  }, [name, formName]);
};
