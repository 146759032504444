import cx from "classnames";
import { Carousel } from "antd";
import React from "react";
import styles from "./sectionBackground.module.scss";

/*
 * Section Background
 - - - - - - - - - -
 Wrap Section Content with this component to add styles, a background image, or carouselImages array of background images that extend past the content width limit
 @param className: classname specific to the landing section's child element - usually used to set a background
 @param color: styles text color in a section with a background color or image without carouselImages array
 @param backgroundColor: styles background color in a section without carouselImages array
 @param backgroundImage: styles background image in a section without carouselImages array
 @param height: styles background height
 @param carouselImages: renders a Carousel wrapped section that displays images carouselImages array
*/
export const SectionBackground = ({
  children,
  className,
  color,
  backgroundColor,
  backgroundImage,
  height,
  carouselImages,
}: SectionBackgroundProps) => {
  return (
    <>
      {carouselImages && carouselImages.length > 0 ? (
        <Carousel
          dots={false}
          autoplay
          autoplaySpeed={8000}
          speed={3000}
          fade
          pauseOnHover={false}
        >
          {carouselImages.map((bgImg, i) => {
            return (
              <React.Fragment key={i}>
                <section
                  className={cx(
                    styles.sectionBackground,
                    styles.backgroundImage,
                    className,
                  )}
                  style={{
                    backgroundImage: `url(${bgImg})`,
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: `${height}px`,
                  }}
                >
                  {children}
                </section>
              </React.Fragment>
            );
          })}
        </Carousel>
      ) : (
        <section
          className={cx(styles.sectionBackground, className, {
            [styles.backgroundImage]: backgroundImage,
          })}
          style={{
            color,
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: `${height}px`,
            backgroundColor,
          }}
        >
          {children}
        </section>
      )}
    </>
  );
};

interface SectionBackgroundProps {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  height?: number;
  carouselImages?: string[];
}
