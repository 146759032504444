import { Col, Row, Tag } from "antd";
import { LikeFilled, SafetyCertificateOutlined } from "@ant-design/icons";

import DisplayRates from "./displayRate";
import React from "react";
import moment from "moment";
import styles from "../components/Cards/cards.module.scss";
import unavailablePhoto from "../../../../../assets/unavailablePhoto.svg";
import { REF_ID } from "../../../../../constants";

export const PropertyCard = ({
  organizationId,
  property,
  showStatus = false,
}: PropertyCard) => {
  let isNew = false;
  if (property.matchedOn) {
    const now = moment(); // * todays date
    const end = moment(property.matchedOn);
    const duration = moment.duration(now.diff(end));
    isNew = duration.asDays() > 4;
  }

  return (
    <Row
      className={`${styles.propertyCard} ${
        property.matched ? styles.disabledCard : ""
      }`}
      gutter={8}
    >
      <Col xs={10} lg={24} className={styles.imageContainer}>
        {property?.propertyPhoto?.files?.length ? (
          !property?.propertyPhoto?.files[0]?.mimeType?.startsWith("image") ? (
            <video style={{ height: "186px", backgroundColor: "black" }} muted>
              <source
                src={`/api/no-auth/download-file/${property?.propertyPhoto?.files[0].fileName}/properties`}
                type="video/mp4"
              />
            </video>
          ) : (
            <img
              onError={(ev) => {
                ev.currentTarget.src = unavailablePhoto;
              }}
              src={`/api/no-auth/download-file/${property?.propertyPhoto?.files[0].fileName}/properties`}
              alt=""
              className={styles.cardPicture}
            />
          )
        ) : (
          <div className={styles.noPicture}>No Pictures Yet</div>
        )}
        {property.organization_id === organizationId &&
          showStatus &&
          (property.propertyStatus === REF_ID.propertyStatus.listed ? (
            <h4 className={`${styles.paymentConfirmation} ${styles.paid}`}>
              <SafetyCertificateOutlined /> Completed
            </h4>
          ) : property.propertyStatus === REF_ID.propertyStatus.drafted ? (
            <h4 className={`${styles.paymentConfirmation} ${styles.draft}`}>
              <SafetyCertificateOutlined /> Draft
            </h4>
          ) : (
            <h4 className={`${styles.paymentConfirmation} ${styles.draft}`}>
              <SafetyCertificateOutlined /> Hidden
            </h4>
          ))}
      </Col>
      <Col xs={14} lg={24}>
        {/* Tags */}
        <div>
          {property.sentIntroductionOn && (
            <LikeFilled
              className={`${styles.feedbackIconTag} ${styles.floatTag} ${styles.like}`}
            />
          )}

          {/* ( // TODO2 Dislike should only be shown to admin, todo later
            <DislikeFilled
              className={`${styles.feedbackIconTag} ${styles.floatTag}`}
            />
          ) */}
          {/* // TODO1 CONDITION FOR ADMIN/HOST TO NOW SHOW TAG */}
          {!property.viewedOn &&
            showStatus &&
            organizationId !== property.organization_id && (
              <>
                {property.matchedOn && isNew ? (
                  <Tag color="grey" className={styles.floatTag}>
                    Not Viewed
                  </Tag>
                ) : (
                  <Tag color="green" className={styles.floatTag}>
                    New
                  </Tag>
                )}
              </>
            )}
        </div>
        {/* // * Property Info */}

        <div className={styles.propertyInfo}>
          <p style={{ marginBottom: "0.5rem" }}>
            <span className={styles.propertyIdTag}>#{property.id}</span>
          </p>
          <h5 className={styles.propertyTitle}>
            {property.title
              ? property.title.toLowerCase()
              : property.description.toLowerCase()}
          </h5>
          <p className={styles.addressSnippet}>
            {`${property.addressInfo.city.toLowerCase()}, ${
              property.addressInfo.usState
            } ${property.addressInfo.zipcode}`}
          </p>
          <p className={styles.roomTypes}>{property?.roomTypes?.join(" | ")}</p>
          <div>
            {property.supportedProviderSpecialty
              ?.slice(0, 5)
              ?.map((specialty: string, i: number) => (
                <Tag key={i} style={{ margin: "4px" }}>
                  {specialty}
                </Tag>
              ))}
            {property.supportedProviderSpecialty?.length > 5 && (
              <Tag style={{ margin: "4px" }}>
                +{property.supportedProviderSpecialty.length - 5} More
              </Tag>
            )}
          </div>
          <div>
            {Boolean(property.sentIntroductionOn) &&
              (property?.propertyRejection_id ? (
                <Tag color="red">Declined Application</Tag>
              ) : property?.propertyChat_id ? (
                <Tag color="green">Approved Application</Tag>
              ) : (
                <Tag color="orange">Contacting Host</Tag>
              ))}
            {property.alerts?.pendingApp && (
              <Tag className={styles.cardApplicationTag} color="green">
                New Application
              </Tag>
            )}
          </div>
          <div className={styles.rateInfo}>
            <DisplayRates property={property} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

interface PropertyCard {
  organizationId: string;
  property: HealcoPropertyUser;
  showStatus?: boolean;
}
