/*
 * Paths
 - - - - - - - - -
 URL paths throughout the HealCo app.

 */

export const PATH: PathConstant = {
  auth: {
    login: "/login",
    verifyEmail: "/verify-email",
    verifyUpdateEmail: "/verify-update-email",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password",
    newOrganizationUser: "/welcome",
    therapyNavigation: "/schedule-a-complimentary-therapy-navigation",
    questionnaire: "/get-started",
  },
  landing: {
    home: "/",
    partnerHome: "/partners",
    providerHome: "/providers",
    extraProviderHome: "/providers-extra",
    patientHome: "/patients",
    onlinePharmacyHome: "/online-pharmacy",
    offerVirtualCare: "/offer-virtual-care",
    physicianOrganizations: "/physician-organizations",
    faq: "/faq",
    glossary: "/glossary",
    pricing: "/pricing",
    blog: "/blog",
    providerSignup: "/provider/signup",
    patientSignup: "/patient/signup",
    wrvuCalculator: "/calculate-my-wrvu",
    about: "/about-us",
    contact: "/contact-us",
    termsAndConditions: "/terms-and-conditions",
    privacyPolicy: "/privacy-policy",
    hipaaAuthorization: "/hipaa-authorization",
    getMedicinalCannabisLicense: "/get-medicinal-cannabis-license",
    employeeHealthBenefitPlans: "/employee-health-benefit-plans",
    medicalOfficeBrokers: "/medical-office-brokers",
    providerMemberBenefits: "/provider-member-benefits",
    providerFindListSpace: "/provider-find-list-space",
    talkTherapy: "/talk-therapy",
    consentToTelehealth: "/consent-to-telehealth",
    getYourMedicalSpaceRented: "/get-your-medical-space-rented",
    providerResources: "/provider-resources",
    helpCenter: "/help",
    whatWeTreat: "/what-we-treat",
    pharmacies: "/pharmacies",
    acne: "/what-we-treat/acne",
    prep: "/what-we-treat/prep",
    edys: "/what-we-treat/edys",
    sccm: "/what-we-treat/sccm",
    axdp: "/what-we-treat/axdp",
    hrps: "/what-we-treat/hrps",
    migr: "/what-we-treat/migr",
  },
  admin: {
    dashboard: "/admin",
    patient: "/admin/patient",
    properties: "/admin/properties",
    provider: "/admin/provider",
    prescription: "/admin/prescription",
    calculator: "/admin/calculator",
    propertyChat: "/admin/property-chat",
    connections: "/admin/connections",
    account: "/admin/providerProfile",
    settings: "/admin/settings",
  },
  patient: {
    dashboard: "/rx",
    account: "/rx/account",
  },
  provider: {
    dashboard: "/provider",
    properties: "/provider/properties",
    referrals: "/provider/referrals",
    newProperty: "/provider/new/properties",
    editProperty: "/provider/edit/properties",
    propertyChat: "/provider/property-chat",
    connections: "/provider/connections",
    map: "/provider/map",
    redirectStripe: "/redirectStripePath",
    account: "/provider/account",
    prescription: "/provider/prescription",
  },
  misc: {
    redirectStripePath: "/api/visit-stripe",
  },
};

interface PathConstant {
  auth: {
    login: string;
    verifyEmail: string;
    verifyUpdateEmail: string;
    forgotPassword: string;
    resetPassword: string;
    newOrganizationUser: string;
    therapyNavigation: string;
    questionnaire: string;
  };
  landing: {
    home: string;
    partnerHome: string;
    providerHome: string;
    extraProviderHome: string;
    patientHome: string;
    onlinePharmacyHome: string;
    offerVirtualCare: string;
    physicianOrganizations: string;
    faq: string;
    glossary: string;
    pricing: string;
    blog: string;
    providerSignup: string;
    patientSignup: string;
    wrvuCalculator: string;
    about: string;
    contact: string;
    termsAndConditions: string;
    privacyPolicy: string;
    hipaaAuthorization: string;
    getMedicinalCannabisLicense: string;
    employeeHealthBenefitPlans: string;
    medicalOfficeBrokers: string;
    providerMemberBenefits: string;
    providerFindListSpace: string;
    talkTherapy: string;
    helpCenter: string;
    consentToTelehealth: string;
    getYourMedicalSpaceRented: string;
    providerResources: string;
    whatWeTreat: string;
    pharmacies: string;
    acne: string;
    prep: string;
    edys: string;
    sccm: string;
    axdp: string;
    hrps: string;
    migr: string;
  };
  admin: {
    dashboard: string;
    patient: string;
    properties: string;
    provider: string;
    prescription: string;
    calculator: string;
    propertyChat: string;
    connections: string;
    account: string;
    settings: string;
  };
  patient: {
    dashboard: string;
    account: string;
  };
  provider: {
    dashboard: string;
    properties: string;
    referrals: string;
    newProperty: string;
    editProperty: string;
    account: string;
    propertyChat: string;
    connections: string;
    map: string;
    redirectStripe: string;
    prescription: string;
  };
  misc: {
    redirectStripePath: string;
  };
}
