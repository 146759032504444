import { message } from "antd";
import { getFetchResponse } from "../../";

/*
 * Fetch Search Results
 - - - - - - - - - - - -
 Gets property search results
 */
export const fetchSearchResults = async (
  lat: number,
  lng: number,
  specialty: string | undefined,
  range: number,
) => {
  if (lat && lng) {
    try {
      const getSearch = await getFetchResponse<any>(
        fetch(
          `/api/properties/suggestion/geometry/?lat=${lat}&lng=${lng}&range=${range}${
            specialty ? `&specialty=${specialty}` : ""
          }`,
        ),
      );
      return { result: getSearch, lat, lng };
    } catch (err) {
      message.error(err.message || "No suggestions at this time.");
    }
  }
};
