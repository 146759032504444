import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import styles from "./helpicon.module.scss";

export const HelpIcon = ({ text }: { text: string | React.ReactNode }) => {
  return (
    <Tooltip className={styles.helpIcon} title={text}>
      <QuestionCircleOutlined />
    </Tooltip>
  );
};
