/*
 * Local Storage
 - - - - - - - - -
 Local storage keys used throughout the frontend
 */
export const LOCAL_STORAGE: LocalStorageConstant = {
  signupReason: "signupReason",
  version: "version",
};

interface LocalStorageConstant {
  signupReason: string;
  version: string;
}
