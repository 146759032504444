import {
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, message, Progress, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import React, { useCallback, useEffect, useState } from "react";
import uploadIcon from "../../assets/icon-image-upload.svg";
import { TFilesInfoTemp, TPendingFile } from "../../types";
import { getFetchResponse } from "../../util";
import styles from "./dropzone.module.scss";

/*
 * Dropzone
 - - - - - - - - - -
 Component where users can drop files to be uploaded
 */
export const Dropzone = ({
  filesInfo = { files: [] },
  setFilesInfo,
  onPendingFiles,
  note,
  folderLocation,
}: Dropzone) => {
  const [files, setFiles] = useState(!filesInfo.files ? [] : filesInfo.files);
  const [newFiles, setNewFiles] = useState<TPendingFile[]>([]);
  const [uploadingInProgress, setUploadingInProgress] = useState(false);
  const maxFileLimit = 10385760;
  const currentFile = newFiles
    .map((e) => {
      return e?.blob?.size;
    })
    .reduce((a, b) => a + b, 0);

  useEffect(() => {
    let error = uploadingInProgress
      ? "Files uploading is in progress"
      : newFiles.length
      ? "Please upload pending files or remove them"
      : "";
    if (currentFile > 10385760)
      error = "Files exceed 10MBs. Please upload in 10MB Batches.";

    if (error !== filesInfo.error) setFilesInfo?.({ files, error });
  }, [
    newFiles,
    filesInfo.error,
    files,
    uploadingInProgress,
    setFilesInfo,
    currentFile,
  ]);
  useEffect(() => setFilesInfo?.({ files }), [files, setFilesInfo]);
  useEffect(() => {
    if (onPendingFiles) {
      onPendingFiles(newFiles);
    }
  }, [newFiles, onPendingFiles]);

  const onRemove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e.button !== 0) return;
    const key = e.currentTarget.dataset.filekey;
    const displayOrder = findFile(key, files, newFiles);

    setFiles((files) =>
      files
        .filter((f) => f.fileName !== key)
        .map((f) => {
          if (f.display_order > displayOrder) {
            if (f.display_order) f.display_order--;
            else f.display_order = 0;
          }
          return f;
        }),
    );
    setNewFiles((files) =>
      files
        .filter((f) => f.blob.uid !== key)
        .map((f) => {
          if (f.displayOrder > displayOrder) {
            if (f.displayOrder) f.displayOrder--;
            else f.displayOrder = 0;
          }
          return f;
        }),
    );
  };

  const onMoveFile = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, direction: number) => {
      const key = e.currentTarget.dataset.filekey;
      const displayOrder = findFile(key, files, newFiles);
      if (direction === -1 && displayOrder === 0) return;
      if (direction === 1 && displayOrder === files.length + newFiles.length)
        return;
      const oldPos = displayOrder;
      const newPos = displayOrder + direction;

      setFiles(
        files.map((f) => {
          const nf = Object.assign({}, f);
          if (nf.display_order === oldPos) nf.display_order = newPos;
          else if (nf.display_order === newPos) nf.display_order = oldPos;
          return nf;
        }),
      );
      setNewFiles(
        newFiles.map((f) => {
          const nf = Object.assign({}, f);
          if (nf.displayOrder === oldPos) nf.displayOrder = newPos;
          else if (nf.displayOrder === newPos) nf.displayOrder = oldPos;
          return nf;
        }),
      );
    },
    [files, newFiles],
  );
  const onMoveFileUp = (e: React.MouseEvent<HTMLElement, MouseEvent>) =>
    onMoveFile(e, -1);
  const onMoveFileDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) =>
    onMoveFile(e, 1);

  const uploadSelectedFiles = useCallback(async () => {
    if (currentFile > maxFileLimit)
      return message.error(
        "File too large for upload, maximum size of files in batch is 10 MB",
      );
    setUploadingInProgress(true);
    try {
      const formData = new FormData();
      newFiles.forEach((f) => formData.append("file", f.blob));
      const { results } = await getFetchResponse<
        HealcoResponsePage<HealcoFile>
      >(
        fetch(`/api/upload-files/${folderLocation}`, {
          method: "POST",
          body: formData,
        }),
      );
      // add the uploaded files to the list of files, persisting the display_order
      setFiles((files) =>
        files.concat(
          newFiles
            .map((newFile) => ({
              newFile,
              record: results.find((r) => r.originalName === newFile.blob.name),
            }))
            .filter((f) => Boolean(f.record))
            .map((f) => {
              const newFile = f.newFile;
              const record = f.record as HealcoFile;
              const file: HealcoFile = {
                id: record.id,
                display_order: newFile.displayOrder,
                fileName: record.fileName,
                mimeType: record.mimeType,
                originalName: record.originalName,
              };
              return file;
            }),
        ),
      );
      // we should keep any file that failed to be uploaded
      setNewFiles((files) =>
        files.filter(
          (f) => !results.find((r) => r.originalName === f.blob.name),
        ),
      );
    } catch (err) {
      if (!!err.json && err.json.code === "ER_FILE_SIZE") {
        message.warning(
          "File too large for upload, maximum size of files in batch is 10 MB",
        );
      } else {
        message.error(`File uploading failed: ${err.message}`);
      }
    } finally {
      setUploadingInProgress(false);
    }
  }, [currentFile, folderLocation, newFiles]);

  useEffect(() => {
    if (!onPendingFiles && Boolean(newFiles.length)) uploadSelectedFiles();
  }, [onPendingFiles, newFiles, uploadSelectedFiles]);

  const allFiles = files
    .map((f) => ({
      id: f.id as string | "",
      displayOrder: f.display_order,
      originalName: f.originalName,
      key: f.fileName,
    }))
    .concat(
      newFiles.map((f) => ({
        id: "",
        displayOrder: f.displayOrder,
        originalName: f.blob.name,
        key: f.blob.uid,
      })),
    );
  let nextDisplayOrder = allFiles.length + 1; // displayOrder is 1-based
  return (
    <div className={styles.filesDropzone}>
      <Upload.Dragger
        action={(blob: RcFile) => {
          const file: TPendingFile = {
            displayOrder: nextDisplayOrder++, // this action can be called several times
            blob,
          };
          setNewFiles([...newFiles, file]);
          return "";
        }}
        // Specifying dummy implementation for AntD built-in uploading since we're uploading ourselves.
        customRequest={() => {}}
        name="file"
        showUploadList={false}
      >
        <div className={styles.filesDropzoneDragContainer}>
          <img src={uploadIcon} alt="" />
          <div>
            <p>Drag and drop files here</p>
            <p>or select files</p>
          </div>
        </div>
      </Upload.Dragger>

      <h4 style={{ margin: "1rem 0 0 0" }}>
        {Math.round((currentFile / maxFileLimit) * 100)}% Full
      </h4>
      <Progress
        percent={(currentFile / maxFileLimit) * 100}
        status={currentFile < maxFileLimit ? "success" : "exception"}
      />
      <h4>10 MB batch upload limit</h4>

      {Boolean(allFiles.length) && (
        <ul className={styles.fileList}>
          {allFiles
            .sort((a, b) =>
              Math.sign((a.displayOrder || 0) - (b.displayOrder || 0)),
            )
            .map((f, i) => (
              <li key={f.key} className={styles.fileListItem}>
                {f.originalName}
                <DeleteOutlined
                  className={styles.fileActionIcon}
                  data-filekey={f.key}
                  onClick={onRemove}
                />
                <CaretDownOutlined
                  className={styles.fileActionIcon}
                  data-filekey={f.key}
                  onClick={onMoveFileDown}
                  style={
                    i === allFiles.length - 1 ? { color: "#ccc" } : undefined
                  }
                />
                <CaretUpOutlined
                  className={styles.fileActionIcon}
                  data-filekey={f.key}
                  onClick={onMoveFileUp}
                  style={i === 0 ? { color: "#ccc" } : undefined}
                />
              </li>
            ))}
        </ul>
      )}
      {note && <p className={styles.note}>{note}</p>}
      {!onPendingFiles && Boolean(newFiles.length) && (
        <Button
          type="ghost"
          className={styles.uploadButton}
          loading={uploadingInProgress}
          onClick={uploadSelectedFiles}
          size="large"
        >
          Confirm Upload
        </Button>
      )}
    </div>
  );
};

/*
 Find File
 */
const findFile = (
  key: string | undefined,
  files: HealcoFile[],
  newFiles: TPendingFile[],
) => {
  if (!key) throw Error("Key is not present in element!");
  const foundOld = files.find((f) => f.fileName === key);
  if (foundOld) {
    return foundOld.display_order;
  } else {
    const foundNew = newFiles.find((f) => f.blob.uid === key);
    if (foundNew) return foundNew.displayOrder;
    else throw Error("File was not found in lists!");
  }
};

type filePaths =
  | "properties"
  | "logos"
  | "feedback"
  | "leases"
  | "patientVerification"
  | "patientQuestionnaires"
  | "patientPrescriptions";

interface Dropzone {
  filesInfo?: TFilesInfoTemp;
  setFilesInfo?: (fileInfo: TFilesInfoTemp) => void;
  onPendingFiles?: (files: TPendingFile[]) => void;
  note?: string;
  folderLocation: filePaths;
}
