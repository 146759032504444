import { Image } from "antd";
import cx from "classnames";
import React, { useContext } from "react";
import { ConditionalButton, HLink } from "..";
import { PATH, REF_ID } from "../../constants";
import { useMobile, useScrolled, useToggle } from "../../hooks";
import {
  SectionBackground,
  SectionContent,
} from "../../pages/Landing/components";
import {
  IdentityContext,
  scrollToTop,
  isHealcoRole,
  hasProviderRole,
} from "../../util";
import HeaderHamburger from "./HeaderHamburger";
import HeaderLinks from "./HeaderLinks";
import MobileMenu from "./MobileMenu";
import styles from "./mobilemenu.module.scss";
import { hBeanGreen } from "../../constants/cdn";

const HEADER_HEIGHT = 100;

const LOGIN_LINK = {
  text: "Login",
  link: PATH.auth.login,
};

/*
 * HealCo Header
 - - - - - - - - -
 Header Nav bar on all Landing / Auth pages
 @param isHome: if the header is on the homepage
 */
export const HHeader = ({
  isHome,
  doNotStick,
  isBannerClosed,
  isUnsupportedBrowser,
}: HHeaderProps) => {
  const identity = useContext(IdentityContext);
  const isScrolled = useScrolled(HEADER_HEIGHT);
  const isMobile = useMobile();
  const [isHamburgerActive, toggleHamburgerActive] = useToggle();
  const userIsAdmin = isHealcoRole(identity, "healcoAdmin");
  const userIsRx =
    isHealcoRole(identity, "patient") ||
    hasProviderRole(identity, REF_ID.providerRole.pharmacist) ||
    hasProviderRole(identity, REF_ID.providerRole.healcoPro);
  const DASHBOARD_LINK = {
    text: "Dashboard",
    link: userIsAdmin
      ? PATH.admin.dashboard
      : userIsRx
      ? PATH.patient.dashboard
      : PATH.provider.dashboard,
  };

  return (
    <>
      <MobileMenu
        isHamburgerActive={isHamburgerActive}
        toggleHamburgerActive={toggleHamburgerActive}
      />
      <SectionBackground
        className={cx(styles.hHeaderBackground, {
          [styles.isScrolled]: isScrolled,
          [styles.doNotStick]: doNotStick,
          [styles.isBannerClosed]: isBannerClosed,
          [styles.isUnsupportedBrowser]: !isUnsupportedBrowser,
        })}
      >
        <SectionContent>
          <header
            className={cx(styles.hHeader, {
              [styles.isScrolled]: isScrolled,
              [styles.doNotStick]: doNotStick,
            })}
          >
            {/* Shows a mobile menu on top */}
            {}

            {/* Conditionally shows Logo on top left  */}
            {((isScrolled && !isMobile && isHome) ||
              (!isHome && !isMobile)) && (
              <HLink to={PATH.landing.home}>
                <Image
                  src={hBeanGreen}
                  preview={false}
                  className={styles.headerImage}
                  onClick={() => scrollToTop()}
                  alt="HealCo"
                />
              </HLink>
            )}

            {/* Controls the Mobile Hamburger Menu that replaces the header links on Mobile */}
            {/* //TODO2 replace icon and remove package  */}
            {isMobile && (
              <HeaderHamburger
                isScrolled={isScrolled}
                isHamburgerActive={isHamburgerActive}
                toggleHamburgerActive={toggleHamburgerActive}
              />
            )}

            {/* Conditionally shows Logo on top middle  */}
            {isMobile && isScrolled && (
              <HLink to={PATH.landing.home}>
                <Image
                  className={styles.headerImage}
                  src={hBeanGreen}
                  alt="HealCo"
                  preview={false}
                />
              </HLink>
            )}

            {/* Conditionally shows Header Links */}
            {!isMobile && (
              <HeaderLinks isScrolled={isScrolled} doNotStick={doNotStick} />
            )}

            {/* Displays Login / Dashboard depending on if the current user is logged in or not */}
            <ConditionalButton
              isCondition={!identity?.id}
              primaryLink={LOGIN_LINK}
              secondaryLink={DASHBOARD_LINK}
              size="large"
              type="primary"
            />
          </header>
        </SectionContent>
      </SectionBackground>
    </>
  );
};

interface HHeaderProps {
  isHome?: boolean; // indicates background color and link styling
  doNotStick?: boolean;
  isBannerClosed?: boolean | null;
  isUnsupportedBrowser?: boolean;
}
