import { Layout } from "antd";
import React, { useMemo } from "react";
import Helmet from "react-helmet";
import { HBanner, HFooter, HHeader } from "../../../../components";
import { ContactSection } from "./components/ContactSection";
import { useSessionState } from "../../../../hooks/useSessionState";
import { PillarHeading, ScrollUp } from "./components";
import cx from "classnames";
import styles from "./landingTemplate.module.scss";
import { checkIsBrowserSupported } from "../../../../util";

const { Header, Content, Footer } = Layout;

const IS_BANNER_OPEN_KEY = "isBannerClosed";

/*
 * Landing Template
 - - - - - - - - - -
 Template used by all Landing pages
 @param metaTitle: Meta title
 @param isHome: true if home page (slightly different styles)
 @param pillarTitle: true if the page should have a large pillar heading
 @param pillarLink: true if the pillar heading should have a "Get Started" button
 @param scrollUp: true if the page should a Scroll Up button
 */
export const LandingTemplate = ({
  children,
  metaTitle,
  isHome,
  pillarBackgroundImage,
  pillarTitle,
  pillarLink,
  scrollUp,
  doNotStick,
  contactSection,
  contactType,
}: LandingTemplateProps) => {
  // Handles keeping banner closed throughout the session if the user chooses to close it
  const [isBannerClosed, setBannerClosed] = useSessionState<boolean>(
    IS_BANNER_OPEN_KEY,
  );
  const isUnsupportedBrowser = useMemo(() => !checkIsBrowserSupported(), []);

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <Layout className={styles.landingTemplate}>
        <Header className={cx({ [styles.doNotStick]: doNotStick })}>
          <HBanner
            onClose={() => setBannerClosed(true)}
            isBannerClosed={isBannerClosed}
            isUnsupportedBrowser={isUnsupportedBrowser}
          />
          <HHeader
            isHome={isHome}
            isBannerClosed={isBannerClosed}
            doNotStick={doNotStick}
            isUnsupportedBrowser={isUnsupportedBrowser}
          />
        </Header>
        <Content>
          {pillarTitle && (
            <PillarHeading
              title={pillarTitle}
              pillarLink={pillarLink}
              pillarBackgroundImage={pillarBackgroundImage}
            />
          )}
          {scrollUp && <ScrollUp />}
          {children}
        </Content>
        {contactSection && <ContactSection contactType={contactType} />}
        <Footer>
          <HFooter />
        </Footer>
      </Layout>
    </>
  );
};

interface LandingTemplateProps {
  children: React.ReactNode;
  metaTitle: string;
  isHome?: boolean;
  pillarBackgroundImage?: string;
  pillarTitle?: string;
  pillarLink?: {
    text: string;
    link: string;
    params?: {
      [param: string]:
        | string
        | string[]
        | { [param: string]: string | string[] };
    };
  };
  scrollUp?: boolean;
  doNotStick?: boolean;
  contactSection?: boolean;
  contactType?: string;
}
