/*
 * Action Creators
 - - - - - - - - - -
 */
export const actionCreators = {
  metaInitObject: <T>(payload: T): IReducerObjectActionInit<T> => ({
    type: "init",
    payload,
  }),

  metaUpdateObject: <T>(
    payload: Partial<T>,
  ): IReducerObjectActionUpdate<T> => ({
    type: "update",
    payload,
  }),

  metaResetObject: <T>(payload: T): IReducerObjectActionReset => ({
    type: "reset",
    payload,
  }),

  metaInitListItem: <T>(
    index: number,
    payload: T,
  ): IReducerListActionInit<T> => ({ type: "list-init", index, payload }),

  metaAddListItem: <T>(payload: T): IReducerListActionAdd<T> => ({
    type: "list-add",
    payload,
  }),

  metaUpdateListItem: <T>(
    index: number,
    payload: T,
  ): IReducerListActionUpdate<T> => ({ type: "list-update", index, payload }),

  metaRemoveListItem: (index: number): IReducerListActionRemove => ({
    type: "list-remove",
    index,
  }),
};
