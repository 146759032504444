/*
 * Antd Form Validator
 - - - - - - - - - - -
 Wrapper for Ant Design form validators to reduce verboseness
 @param value: value being validated
 @param condition: callback validation function\
 @use validator: (_, value) => antdFormValidator(value, callback(value))
 */
export const antdFormValidator = <T>(
  value: T,
  condition: (value: T) => boolean,
) => {
  return condition(value)
    ? Promise.resolve()
    : Promise.reject(new Error("This input is not valid."));
};
