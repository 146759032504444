/*
 * Get Browser Info
 - - - - - - - - - - - -
 Grabs browser info from navigator user agent and returns browser name/version
 */

export const getBrowserInfo = () => {
  const ua = navigator.userAgent;
  let tem: string[] | RegExpMatchArray | null;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    tem = ua.match(/Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Edge", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: +M[1],
  };
};

const supportedBrowsers: { [key: string]: { version: number } } = {
  Chrome: {
    version: 77,
  },
  Edge: {
    version: 80,
  },
  Safari: {
    version: 13,
  },
  Firefox: {
    version: 85,
  },
  Opera: {
    version: 64,
  },
  IE: {
    version: 9999999999999,
  },
};

/*
 * Check is Browser Supported
 - - - - - - - - - - - -
  Returns boolean of if current browser is supported.
 */

export const checkIsBrowserSupported = () => {
  const browserInfo = getBrowserInfo();
  return supportedBrowsers?.[browserInfo.name].version <= browserInfo.version;
};
