import React from "react";
import { Row, Col, Upload, message, Image } from "antd";
import ImgCrop from "antd-img-crop";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import profilePictureStyles from "./profilePicture.module.scss";
import { RcFile } from "antd/lib/upload";
import queryString from "query-string";
import {
  getFetchResponse,
  getInitials,
  handleFetch,
} from "../../../../../util";
import { HHeading } from "../../../../../components";
import MaleAvatar from "../../../../../assets/profile/male-avatar.svg";
import FemaleAvatar from "../../../../../assets/profile/female-avatar.svg";
import BuildingAvatar from "../../../../../assets/profile/building.svg";
import ProfileAvatar from "../../../../../assets/profile/profile-pic.svg";
import { FETCH } from "../../../../../constants";
import { useToggle } from "../../../../../hooks";

const AvatarOptions = [
  { id: "initials", pic: null },
  { id: "male-avatar", pic: MaleAvatar },
  { id: "female-avatar", pic: FemaleAvatar },
  { id: "building", pic: BuildingAvatar },
  { id: "profile-pic", pic: ProfileAvatar },
];

export const HealcoAvatar = (ref: string) => {
  const index = AvatarOptions.findIndex((avatar) => avatar.id === ref);
  return index > -1 ? AvatarOptions[index].pic : null;
};

const InitialsAvatar = ({ initials }: { initials: string }) => {
  return (
    <p className={profilePictureStyles.initials} data-letters={`${initials}`} />
  );
};

const ProfilePicture = ({
  profile,
  handleModal,
  updateProfile,
}: ProfilePictureProps) => {
  const [isLoading, toggleLoading] = useToggle();
  const qs = queryString.parse(window.location.search);
  const isOrganizationPic = qs.type === "organization";
  const userOrOrgPhoto = isOrganizationPic
    ? profile.organization.profilePic
    : profile.profilePic;
  const userOrOrgInitials = isOrganizationPic
    ? getInitials(`${profile.organization.name}`)
    : getInitials(`${profile.firstName} ${profile.lastName}`);

  const handlePhoto = async (file: RcFile) => {
    toggleLoading();
    try {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        const { success } = await getFetchResponse<any>(
          fetch(
            `/api/profilePicture/${profile.organization.id}/${profile.id}/${
              isOrganizationPic ? "organization" : "user"
            }`,
            {
              method: FETCH.post,
              body: formData,
            },
          ),
        );
        if (success) {
          updateProfile();
          handleModal("");
        }
      }
    } catch (err) {
      message.error("Something went wrong. ", err);
    } finally {
      toggleLoading();
    }
  };

  const setAvatar = async (id: string) => {
    const { success } = await handleFetch<{ imageId: string }>({
      route: `/profile/avatar/${profile.organization.id}/${profile.id}/${
        isOrganizationPic ? "organization" : "user"
      }`,
      method: FETCH.put,
      body: {
        imageId: id,
      },
    });
    if (success) {
      updateProfile();
      handleModal("");
    }
  };

  return (
    <Row>
      <Col span={24}>
        <HHeading size={5} centered text="Current Pic" />
        <div className={profilePictureStyles.uploadContainer}>
          {userOrOrgPhoto && userOrOrgPhoto !== "initials" ? (
            isLoading ? (
              <LoadingOutlined />
            ) : (
              <Image
                src={
                  HealcoAvatar(profile?.profilePic) ||
                  `/api/no-auth/download-file/${userOrOrgPhoto}/logos`
                }
                width={132}
                height={132}
                className={profilePictureStyles.uploadPic}
                preview={false}
              />
            )
          ) : (
            <InitialsAvatar initials={userOrOrgInitials} />
          )}
        </div>
      </Col>
      <Col>
        <HHeading
          size={5}
          centered
          text="Upload your own photo/logo or select from our collection!"
        />
        <div className={profilePictureStyles.avatarContainer}>
          {(isOrganizationPic ? [] : AvatarOptions).map((avatar, i) => (
            <div
              className={profilePictureStyles.avatar}
              id={avatar.id}
              key={i}
              onClick={() => setAvatar(avatar.id)}
            >
              {avatar.pic && avatar.pic !== "initials" ? (
                <Image
                  src={avatar.pic}
                  width={132}
                  height={132}
                  preview={false}
                />
              ) : (
                <InitialsAvatar initials={userOrOrgInitials} />
              )}
            </div>
          ))}
          <div>
            <ImgCrop shape="round" rotate grid>
              <Upload
                accept=".jpg, .jpeg, .png"
                action={() => {
                  return "";
                }}
                className={profilePictureStyles.uploadContainer}
                name="avatar"
                customRequest={() => {}}
                showUploadList={false}
                beforeUpload={(file) => {
                  if (file.type !== "image/png" && file.type !== "image/jpeg") {
                    message.error(`${file.name} is not a png/jpeg file`);
                  }
                  return file.type === "image/png" || file.type === "image/jpeg"
                    ? handlePhoto(file)
                    : Upload.LIST_IGNORE;
                }}
                listType="picture-card"
              >
                <div className={profilePictureStyles.uploadPic}>
                  {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            </ImgCrop>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ProfilePicture;

interface ProfilePictureProps {
  profile: HealcoProfile;
  handleModal: (component: string) => void;
  updateProfile: () => void;
}
