export const ZIPCODE: ZipcodeConstant = {
  nj: [
    "07001",
    "07002",
    "07003",
    "07004",
    "07005",
    "07006",
    "07007",
    "07008",
    "07009",
    "07010",
    "07011",
    "07012",
    "07013",
    "07014",
    "07015",
    "07016",
    "07017",
    "07018",
    "07019",
    "07020",
    "07021",
    "07022",
    "07023",
    "07024",
    "07026",
    "07027",
    "07028",
    "07029",
    "07030",
    "07031",
    "07032",
    "07033",
    "07034",
    "07035",
    "07036",
    "07039",
    "07040",
    "07041",
    "07042",
    "07043",
    "07044",
    "07045",
    "07046",
    "07047",
    "07050",
    "07051",
    "07052",
    "07054",
    "07055",
    "07057",
    "07058",
    "07059",
    "07060",
    "07061",
    "07062",
    "07063",
    "07064",
    "07065",
    "07066",
    "07067",
    "07068",
    "07069",
    "07070",
    "07071",
    "07072",
    "07073",
    "07074",
    "07075",
    "07076",
    "07077",
    "07078",
    "07079",
    "07080",
    "07081",
    "07082",
    "07083",
    "07086",
    "07087",
    "07088",
    "07090",
    "07091",
    "07092",
    "07093",
    "07094",
    "07095",
    "07096",
    "07097",
    "07099",
    "07101",
    "07102",
    "07103",
    "07104",
    "07105",
    "07106",
    "07107",
    "07108",
    "07109",
    "07110",
    "07111",
    "07112",
    "07114",
    "07175",
    "07184",
    "07188",
    "07189",
    "07191",
    "07192",
    "07193",
    "07195",
    "07198",
    "07199",
    "07201",
    "07202",
    "07203",
    "07204",
    "07205",
    "07206",
    "07207",
    "07208",
    "07302",
    "07303",
    "07304",
    "07305",
    "07306",
    "07307",
    "07308",
    "07310",
    "07311",
    "07395",
    "07399",
    "07401",
    "07403",
    "07405",
    "07407",
    "07410",
    "07416",
    "07417",
    "07418",
    "07419",
    "07420",
    "07421",
    "07422",
    "07423",
    "07424",
    "07428",
    "07430",
    "07432",
    "07435",
    "07436",
    "07438",
    "07439",
    "07440",
    "07442",
    "07444",
    "07446",
    "07450",
    "07451",
    "07452",
    "07456",
    "07457",
    "07458",
    "07460",
    "07461",
    "07462",
    "07463",
    "07465",
    "07470",
    "07474",
    "07480",
    "07481",
    "07495",
    "07501",
    "07502",
    "07503",
    "07504",
    "07505",
    "07506",
    "07507",
    "07508",
    "07509",
    "07510",
    "07511",
    "07512",
    "07513",
    "07514",
    "07522",
    "07524",
    "07533",
    "07538",
    "07543",
    "07544",
    "07601",
    "07602",
    "07603",
    "07604",
    "07605",
    "07606",
    "07607",
    "07608",
    "07620",
    "07621",
    "07624",
    "07626",
    "07627",
    "07628",
    "07630",
    "07631",
    "07632",
    "07640",
    "07641",
    "07642",
    "07643",
    "07644",
    "07645",
    "07646",
    "07647",
    "07648",
    "07649",
    "07650",
    "07652",
    "07653",
    "07656",
    "07657",
    "07660",
    "07661",
    "07662",
    "07663",
    "07666",
    "07670",
    "07675",
    "07676",
    "07677",
    "07699",
    "07701",
    "07702",
    "07703",
    "07704",
    "07709",
    "07710",
    "07711",
    "07712",
    "07715",
    "07716",
    "07717",
    "07718",
    "07719",
    "07720",
    "07721",
    "07722",
    "07723",
    "07724",
    "07726",
    "07727",
    "07728",
    "07730",
    "07731",
    "07732",
    "07733",
    "07734",
    "07735",
    "07737",
    "07738",
    "07739",
    "07740",
    "07746",
    "07747",
    "07748",
    "07750",
    "07751",
    "07752",
    "07753",
    "07754",
    "07755",
    "07756",
    "07757",
    "07758",
    "07760",
    "07762",
    "07763",
    "07764",
    "07765",
    "07799",
    "07801",
    "07802",
    "07803",
    "07806",
    "07820",
    "07821",
    "07822",
    "07823",
    "07825",
    "07826",
    "07827",
    "07828",
    "07829",
    "07830",
    "07831",
    "07832",
    "07833",
    "07834",
    "07836",
    "07837",
    "07838",
    "07839",
    "07840",
    "07842",
    "07843",
    "07844",
    "07845",
    "07846",
    "07847",
    "07848",
    "07849",
    "07850",
    "07851",
    "07852",
    "07853",
    "07855",
    "07856",
    "07857",
    "07860",
    "07863",
    "07865",
    "07866",
    "07869",
    "07870",
    "07871",
    "07874",
    "07875",
    "07876",
    "07877",
    "07878",
    "07879",
    "07880",
    "07881",
    "07882",
    "07885",
    "07890",
    "07901",
    "07902",
    "07920",
    "07921",
    "07922",
    "07924",
    "07926",
    "07927",
    "07928",
    "07930",
    "07931",
    "07932",
    "07933",
    "07934",
    "07935",
    "07936",
    "07938",
    "07939",
    "07940",
    "07945",
    "07946",
    "07950",
    "07960",
    "07961",
    "07962",
    "07963",
    "07970",
    "07974",
    "07976",
    "07977",
    "07978",
    "07979",
    "07980",
    "07981",
    "07999",
    "08001",
    "08002",
    "08003",
    "08004",
    "08005",
    "08006",
    "08007",
    "08008",
    "08009",
    "08010",
    "08011",
    "08012",
    "08014",
    "08015",
    "08016",
    "08018",
    "08019",
    "08020",
    "08021",
    "08022",
    "08023",
    "08025",
    "08026",
    "08027",
    "08028",
    "08029",
    "08030",
    "08031",
    "08032",
    "08033",
    "08034",
    "08035",
    "08036",
    "08037",
    "08038",
    "08039",
    "08041",
    "08042",
    "08043",
    "08045",
    "08046",
    "08048",
    "08049",
    "08050",
    "08051",
    "08052",
    "08053",
    "08054",
    "08055",
    "08056",
    "08057",
    "08059",
    "08060",
    "08061",
    "08062",
    "08063",
    "08064",
    "08065",
    "08066",
    "08067",
    "08068",
    "08069",
    "08070",
    "08071",
    "08072",
    "08073",
    "08074",
    "08075",
    "08076",
    "08077",
    "08078",
    "08079",
    "08080",
    "08081",
    "08083",
    "08084",
    "08085",
    "08086",
    "08087",
    "08088",
    "08089",
    "08090",
    "08091",
    "08092",
    "08093",
    "08094",
    "08095",
    "08096",
    "08097",
    "08098",
    "08099",
    "08101",
    "08102",
    "08103",
    "08104",
    "08105",
    "08106",
    "08107",
    "08108",
    "08109",
    "08110",
    "08201",
    "08202",
    "08203",
    "08204",
    "08205",
    "08210",
    "08212",
    "08213",
    "08214",
    "08215",
    "08217",
    "08218",
    "08219",
    "08220",
    "08221",
    "08223",
    "08224",
    "08225",
    "08226",
    "08230",
    "08231",
    "08232",
    "08234",
    "08240",
    "08241",
    "08242",
    "08243",
    "08244",
    "08245",
    "08246",
    "08247",
    "08248",
    "08250",
    "08251",
    "08252",
    "08260",
    "08270",
    "08302",
    "08310",
    "08311",
    "08312",
    "08313",
    "08314",
    "08315",
    "08316",
    "08317",
    "08318",
    "08319",
    "08320",
    "08321",
    "08322",
    "08323",
    "08324",
    "08326",
    "08327",
    "08328",
    "08329",
    "08330",
    "08332",
    "08340",
    "08341",
    "08342",
    "08343",
    "08344",
    "08345",
    "08346",
    "08347",
    "08348",
    "08349",
    "08350",
    "08352",
    "08353",
    "08360",
    "08361",
    "08362",
    "08401",
    "08402",
    "08403",
    "08404",
    "08405",
    "08406",
    "08501",
    "08502",
    "08504",
    "08505",
    "08510",
    "08511",
    "08512",
    "08514",
    "08515",
    "08518",
    "08520",
    "08525",
    "08526",
    "08527",
    "08528",
    "08530",
    "08533",
    "08534",
    "08535",
    "08536",
    "08540",
    "08541",
    "08542",
    "08543",
    "08544",
    "08550",
    "08551",
    "08553",
    "08554",
    "08555",
    "08556",
    "08557",
    "08558",
    "08559",
    "08560",
    "08561",
    "08562",
    "08601",
    "08602",
    "08603",
    "08604",
    "08605",
    "08606",
    "08607",
    "08608",
    "08609",
    "08610",
    "08611",
    "08618",
    "08619",
    "08620",
    "08625",
    "08628",
    "08629",
    "08638",
    "08640",
    "08641",
    "08644",
    "08645",
    "08646",
    "08647",
    "08648",
    "08650",
    "08666",
    "08690",
    "08691",
    "08695",
    "08701",
    "08720",
    "08721",
    "08722",
    "08723",
    "08724",
    "08730",
    "08731",
    "08732",
    "08733",
    "08734",
    "08735",
    "08736",
    "08738",
    "08739",
    "08740",
    "08741",
    "08742",
    "08750",
    "08751",
    "08752",
    "08753",
    "08754",
    "08755",
    "08756",
    "08757",
    "08758",
    "08759",
    "08801",
    "08802",
    "08803",
    "08804",
    "08805",
    "08807",
    "08808",
    "08809",
    "08810",
    "08812",
    "08816",
    "08817",
    "08818",
    "08820",
    "08821",
    "08822",
    "08823",
    "08824",
    "08825",
    "08826",
    "08827",
    "08828",
    "08829",
    "08830",
    "08831",
    "08832",
    "08833",
    "08834",
    "08835",
    "08836",
    "08837",
    "08840",
    "08844",
    "08846",
    "08848",
    "08850",
    "08852",
    "08853",
    "08854",
    "08855",
    "08857",
    "08858",
    "08859",
    "08861",
    "08862",
    "08863",
    "08865",
    "08867",
    "08868",
    "08869",
    "08870",
    "08871",
    "08872",
    "08873",
    "08875",
    "08876",
    "08879",
    "08880",
    "08882",
    "08884",
    "08885",
    "08886",
    "08887",
    "08888",
    "08889",
    "08890",
    "08899",
    "08901",
    "08902",
    "08903",
    "08904",
    "08906",
    "08933",
    "08989",
  ],
  ny: [
    "00501",
    "00544",
    "06390",
    "10001",
    "10002",
    "10003",
    "10004",
    "10005",
    "10006",
    "10007",
    "10008",
    "10009",
    "10010",
    "10011",
    "10012",
    "10013",
    "10014",
    "10015",
    "10016",
    "10017",
    "10018",
    "10019",
    "10020",
    "10021",
    "10022",
    "10023",
    "10024",
    "10025",
    "10026",
    "10027",
    "10028",
    "10029",
    "10030",
    "10031",
    "10032",
    "10033",
    "10034",
    "10035",
    "10036",
    "10037",
    "10038",
    "10039",
    "10040",
    "10041",
    "10043",
    "10044",
    "10045",
    "10046",
    "10047",
    "10048",
    "10055",
    "10060",
    "10069",
    "10072",
    "10079",
    "10080",
    "10081",
    "10082",
    "10087",
    "10090",
    "10094",
    "10095",
    "10096",
    "10098",
    "10099",
    "10101",
    "10102",
    "10103",
    "10104",
    "10105",
    "10106",
    "10107",
    "10108",
    "10109",
    "10110",
    "10111",
    "10112",
    "10113",
    "10114",
    "10115",
    "10116",
    "10117",
    "10118",
    "10119",
    "10120",
    "10121",
    "10122",
    "10123",
    "10124",
    "10125",
    "10126",
    "10128",
    "10129",
    "10130",
    "10131",
    "10132",
    "10133",
    "10138",
    "10149",
    "10150",
    "10151",
    "10152",
    "10153",
    "10154",
    "10155",
    "10156",
    "10157",
    "10158",
    "10159",
    "10160",
    "10161",
    "10162",
    "10163",
    "10164",
    "10165",
    "10166",
    "10167",
    "10168",
    "10169",
    "10170",
    "10171",
    "10172",
    "10173",
    "10174",
    "10175",
    "10176",
    "10177",
    "10178",
    "10179",
    "10184",
    "10185",
    "10196",
    "10197",
    "10199",
    "10203",
    "10211",
    "10212",
    "10213",
    "10242",
    "10249",
    "10256",
    "10257",
    "10258",
    "10259",
    "10260",
    "10261",
    "10265",
    "10268",
    "10269",
    "10270",
    "10271",
    "10272",
    "10273",
    "10274",
    "10275",
    "10276",
    "10277",
    "10278",
    "10279",
    "10280",
    "10281",
    "10282",
    "10285",
    "10286",
    "10292",
    "10301",
    "10302",
    "10303",
    "10304",
    "10305",
    "10306",
    "10307",
    "10308",
    "10309",
    "10310",
    "10311",
    "10312",
    "10313",
    "10314",
    "10451",
    "10452",
    "10453",
    "10454",
    "10455",
    "10456",
    "10457",
    "10458",
    "10459",
    "10460",
    "10461",
    "10462",
    "10463",
    "10464",
    "10465",
    "10466",
    "10467",
    "10468",
    "10469",
    "10470",
    "10471",
    "10472",
    "10473",
    "10474",
    "10475",
    "10499",
    "10501",
    "10502",
    "10503",
    "10504",
    "10505",
    "10506",
    "10507",
    "10509",
    "10510",
    "10511",
    "10512",
    "10514",
    "10516",
    "10517",
    "10518",
    "10519",
    "10520",
    "10521",
    "10522",
    "10523",
    "10524",
    "10526",
    "10527",
    "10528",
    "10530",
    "10532",
    "10533",
    "10535",
    "10536",
    "10537",
    "10538",
    "10540",
    "10541",
    "10542",
    "10543",
    "10545",
    "10546",
    "10547",
    "10548",
    "10549",
    "10550",
    "10551",
    "10552",
    "10553",
    "10557",
    "10558",
    "10560",
    "10562",
    "10566",
    "10567",
    "10570",
    "10571",
    "10572",
    "10573",
    "10576",
    "10577",
    "10578",
    "10579",
    "10580",
    "10583",
    "10587",
    "10588",
    "10589",
    "10590",
    "10591",
    "10594",
    "10595",
    "10596",
    "10597",
    "10598",
    "10601",
    "10602",
    "10603",
    "10604",
    "10605",
    "10606",
    "10607",
    "10610",
    "10701",
    "10702",
    "10703",
    "10704",
    "10705",
    "10706",
    "10707",
    "10708",
    "10709",
    "10710",
    "10801",
    "10802",
    "10803",
    "10804",
    "10805",
    "10901",
    "10910",
    "10911",
    "10912",
    "10913",
    "10914",
    "10915",
    "10916",
    "10917",
    "10918",
    "10919",
    "10920",
    "10921",
    "10922",
    "10923",
    "10924",
    "10925",
    "10926",
    "10927",
    "10928",
    "10930",
    "10931",
    "10932",
    "10933",
    "10940",
    "10941",
    "10943",
    "10949",
    "10950",
    "10952",
    "10953",
    "10954",
    "10956",
    "10958",
    "10959",
    "10960",
    "10962",
    "10963",
    "10964",
    "10965",
    "10968",
    "10969",
    "10970",
    "10973",
    "10974",
    "10975",
    "10976",
    "10977",
    "10979",
    "10980",
    "10981",
    "10982",
    "10983",
    "10984",
    "10985",
    "10986",
    "10987",
    "10988",
    "10989",
    "10990",
    "10992",
    "10993",
    "10994",
    "10996",
    "10997",
    "10998",
    "11001",
    "11002",
    "11003",
    "11004",
    "11005",
    "11010",
    "11020",
    "11021",
    "11022",
    "11023",
    "11024",
    "11025",
    "11026",
    "11027",
    "11030",
    "11040",
    "11041",
    "11042",
    "11043",
    "11044",
    "11050",
    "11051",
    "11052",
    "11053",
    "11054",
    "11055",
    "11096",
    "11099",
    "11101",
    "11102",
    "11103",
    "11104",
    "11105",
    "11106",
    "11109",
    "11120",
    "11201",
    "11202",
    "11203",
    "11204",
    "11205",
    "11206",
    "11207",
    "11208",
    "11209",
    "11210",
    "11211",
    "11212",
    "11213",
    "11214",
    "11215",
    "11216",
    "11217",
    "11218",
    "11219",
    "11220",
    "11221",
    "11222",
    "11223",
    "11224",
    "11225",
    "11226",
    "11228",
    "11229",
    "11230",
    "11231",
    "11232",
    "11233",
    "11234",
    "11235",
    "11236",
    "11237",
    "11238",
    "11239",
    "11240",
    "11241",
    "11242",
    "11243",
    "11244",
    "11245",
    "11247",
    "11248",
    "11249",
    "11251",
    "11252",
    "11254",
    "11255",
    "11256",
    "11351",
    "11352",
    "11354",
    "11355",
    "11356",
    "11357",
    "11358",
    "11359",
    "11360",
    "11361",
    "11362",
    "11363",
    "11364",
    "11365",
    "11366",
    "11367",
    "11368",
    "11369",
    "11370",
    "11371",
    "11372",
    "11373",
    "11374",
    "11375",
    "11377",
    "11378",
    "11379",
    "11380",
    "11381",
    "11385",
    "11386",
    "11390",
    "11405",
    "11411",
    "11412",
    "11413",
    "11414",
    "11415",
    "11416",
    "11417",
    "11418",
    "11419",
    "11420",
    "11421",
    "11422",
    "11423",
    "11424",
    "11425",
    "11426",
    "11427",
    "11428",
    "11429",
    "11430",
    "11431",
    "11432",
    "11433",
    "11434",
    "11435",
    "11436",
    "11439",
    "11451",
    "11499",
    "11501",
    "11507",
    "11509",
    "11510",
    "11514",
    "11516",
    "11518",
    "11520",
    "11530",
    "11531",
    "11535",
    "11536",
    "11542",
    "11545",
    "11547",
    "11548",
    "11549",
    "11550",
    "11551",
    "11552",
    "11553",
    "11554",
    "11555",
    "11556",
    "11557",
    "11558",
    "11559",
    "11560",
    "11561",
    "11563",
    "11565",
    "11566",
    "11568",
    "11569",
    "11570",
    "11571",
    "11572",
    "11575",
    "11576",
    "11577",
    "11579",
    "11580",
    "11581",
    "11582",
    "11590",
    "11592",
    "11594",
    "11595",
    "11596",
    "11597",
    "11598",
    "11599",
    "11690",
    "11691",
    "11692",
    "11693",
    "11694",
    "11695",
    "11697",
    "11701",
    "11702",
    "11703",
    "11704",
    "11705",
    "11706",
    "11707",
    "11708",
    "11709",
    "11710",
    "11713",
    "11714",
    "11715",
    "11716",
    "11717",
    "11718",
    "11719",
    "11720",
    "11721",
    "11722",
    "11724",
    "11725",
    "11726",
    "11727",
    "11729",
    "11730",
    "11731",
    "11732",
    "11733",
    "11735",
    "11736",
    "11737",
    "11738",
    "11739",
    "11740",
    "11741",
    "11742",
    "11743",
    "11746",
    "11747",
    "11749",
    "11750",
    "11751",
    "11752",
    "11753",
    "11754",
    "11755",
    "11756",
    "11757",
    "11758",
    "11760",
    "11762",
    "11763",
    "11764",
    "11765",
    "11766",
    "11767",
    "11768",
    "11769",
    "11770",
    "11771",
    "11772",
    "11773",
    "11774",
    "11775",
    "11776",
    "11777",
    "11778",
    "11779",
    "11780",
    "11782",
    "11783",
    "11784",
    "11786",
    "11787",
    "11788",
    "11789",
    "11790",
    "11791",
    "11792",
    "11793",
    "11794",
    "11795",
    "11796",
    "11797",
    "11798",
    "11801",
    "11802",
    "11803",
    "11804",
    "11815",
    "11819",
    "11853",
    "11854",
    "11855",
    "11901",
    "11930",
    "11931",
    "11932",
    "11933",
    "11934",
    "11935",
    "11937",
    "11939",
    "11940",
    "11941",
    "11942",
    "11944",
    "11946",
    "11947",
    "11948",
    "11949",
    "11950",
    "11951",
    "11952",
    "11953",
    "11954",
    "11955",
    "11956",
    "11957",
    "11958",
    "11959",
    "11960",
    "11961",
    "11962",
    "11963",
    "11964",
    "11965",
    "11967",
    "11968",
    "11969",
    "11970",
    "11971",
    "11972",
    "11973",
    "11975",
    "11976",
    "11977",
    "11978",
    "11980",
    "12007",
    "12008",
    "12009",
    "12010",
    "12015",
    "12016",
    "12017",
    "12018",
    "12019",
    "12020",
    "12022",
    "12023",
    "12024",
    "12025",
    "12027",
    "12028",
    "12029",
    "12031",
    "12032",
    "12033",
    "12035",
    "12036",
    "12037",
    "12040",
    "12041",
    "12042",
    "12043",
    "12045",
    "12046",
    "12047",
    "12050",
    "12051",
    "12052",
    "12053",
    "12054",
    "12055",
    "12056",
    "12057",
    "12058",
    "12059",
    "12060",
    "12061",
    "12062",
    "12063",
    "12064",
    "12065",
    "12066",
    "12067",
    "12068",
    "12069",
    "12070",
    "12071",
    "12072",
    "12073",
    "12074",
    "12075",
    "12076",
    "12077",
    "12078",
    "12082",
    "12083",
    "12084",
    "12085",
    "12086",
    "12087",
    "12089",
    "12090",
    "12092",
    "12093",
    "12094",
    "12095",
    "12106",
    "12107",
    "12108",
    "12110",
    "12115",
    "12116",
    "12117",
    "12118",
    "12120",
    "12121",
    "12122",
    "12123",
    "12124",
    "12125",
    "12128",
    "12130",
    "12131",
    "12132",
    "12133",
    "12134",
    "12136",
    "12137",
    "12138",
    "12139",
    "12140",
    "12141",
    "12143",
    "12144",
    "12147",
    "12148",
    "12149",
    "12150",
    "12151",
    "12153",
    "12154",
    "12155",
    "12156",
    "12157",
    "12158",
    "12159",
    "12160",
    "12161",
    "12164",
    "12165",
    "12166",
    "12167",
    "12168",
    "12169",
    "12170",
    "12172",
    "12173",
    "12174",
    "12175",
    "12176",
    "12177",
    "12180",
    "12181",
    "12182",
    "12183",
    "12184",
    "12185",
    "12186",
    "12187",
    "12188",
    "12189",
    "12190",
    "12192",
    "12193",
    "12194",
    "12195",
    "12196",
    "12197",
    "12198",
    "12201",
    "12202",
    "12203",
    "12204",
    "12205",
    "12206",
    "12207",
    "12208",
    "12209",
    "12210",
    "12211",
    "12212",
    "12214",
    "12220",
    "12222",
    "12223",
    "12224",
    "12225",
    "12226",
    "12227",
    "12228",
    "12229",
    "12230",
    "12231",
    "12232",
    "12233",
    "12234",
    "12235",
    "12236",
    "12237",
    "12238",
    "12239",
    "12240",
    "12241",
    "12242",
    "12243",
    "12244",
    "12245",
    "12246",
    "12247",
    "12248",
    "12249",
    "12250",
    "12252",
    "12255",
    "12256",
    "12257",
    "12260",
    "12261",
    "12288",
    "12301",
    "12302",
    "12303",
    "12304",
    "12305",
    "12306",
    "12307",
    "12308",
    "12309",
    "12325",
    "12345",
    "12401",
    "12402",
    "12404",
    "12405",
    "12406",
    "12407",
    "12409",
    "12410",
    "12411",
    "12412",
    "12413",
    "12414",
    "12416",
    "12417",
    "12418",
    "12419",
    "12420",
    "12421",
    "12422",
    "12423",
    "12424",
    "12427",
    "12428",
    "12429",
    "12430",
    "12431",
    "12432",
    "12433",
    "12434",
    "12435",
    "12436",
    "12438",
    "12439",
    "12440",
    "12441",
    "12442",
    "12443",
    "12444",
    "12446",
    "12448",
    "12449",
    "12450",
    "12451",
    "12452",
    "12453",
    "12454",
    "12455",
    "12456",
    "12457",
    "12458",
    "12459",
    "12460",
    "12461",
    "12463",
    "12464",
    "12465",
    "12466",
    "12468",
    "12469",
    "12470",
    "12471",
    "12472",
    "12473",
    "12474",
    "12475",
    "12477",
    "12480",
    "12481",
    "12482",
    "12483",
    "12484",
    "12485",
    "12486",
    "12487",
    "12489",
    "12490",
    "12491",
    "12492",
    "12493",
    "12494",
    "12495",
    "12496",
    "12498",
    "12501",
    "12502",
    "12503",
    "12504",
    "12506",
    "12507",
    "12508",
    "12510",
    "12511",
    "12512",
    "12513",
    "12514",
    "12515",
    "12516",
    "12517",
    "12518",
    "12520",
    "12521",
    "12522",
    "12523",
    "12524",
    "12525",
    "12526",
    "12527",
    "12528",
    "12529",
    "12530",
    "12531",
    "12533",
    "12534",
    "12537",
    "12538",
    "12540",
    "12541",
    "12542",
    "12543",
    "12544",
    "12545",
    "12546",
    "12547",
    "12548",
    "12549",
    "12550",
    "12551",
    "12552",
    "12553",
    "12555",
    "12561",
    "12563",
    "12564",
    "12565",
    "12566",
    "12567",
    "12568",
    "12569",
    "12570",
    "12571",
    "12572",
    "12574",
    "12575",
    "12577",
    "12578",
    "12580",
    "12581",
    "12582",
    "12583",
    "12584",
    "12585",
    "12586",
    "12588",
    "12589",
    "12590",
    "12592",
    "12593",
    "12594",
    "12601",
    "12602",
    "12603",
    "12604",
    "12701",
    "12719",
    "12720",
    "12721",
    "12722",
    "12723",
    "12724",
    "12725",
    "12726",
    "12727",
    "12729",
    "12732",
    "12733",
    "12734",
    "12736",
    "12737",
    "12738",
    "12740",
    "12741",
    "12742",
    "12743",
    "12745",
    "12746",
    "12747",
    "12748",
    "12749",
    "12750",
    "12751",
    "12752",
    "12754",
    "12758",
    "12759",
    "12760",
    "12762",
    "12763",
    "12764",
    "12765",
    "12766",
    "12767",
    "12768",
    "12769",
    "12770",
    "12771",
    "12775",
    "12776",
    "12777",
    "12778",
    "12779",
    "12780",
    "12781",
    "12783",
    "12784",
    "12785",
    "12786",
    "12787",
    "12788",
    "12789",
    "12790",
    "12791",
    "12792",
    "12801",
    "12803",
    "12804",
    "12808",
    "12809",
    "12810",
    "12811",
    "12812",
    "12814",
    "12815",
    "12816",
    "12817",
    "12819",
    "12820",
    "12821",
    "12822",
    "12823",
    "12824",
    "12827",
    "12828",
    "12831",
    "12832",
    "12833",
    "12834",
    "12835",
    "12836",
    "12837",
    "12838",
    "12839",
    "12841",
    "12842",
    "12843",
    "12844",
    "12845",
    "12846",
    "12847",
    "12848",
    "12849",
    "12850",
    "12851",
    "12852",
    "12853",
    "12854",
    "12855",
    "12856",
    "12857",
    "12858",
    "12859",
    "12860",
    "12861",
    "12862",
    "12863",
    "12864",
    "12865",
    "12866",
    "12870",
    "12871",
    "12872",
    "12873",
    "12874",
    "12878",
    "12879",
    "12883",
    "12884",
    "12885",
    "12886",
    "12887",
    "12901",
    "12903",
    "12910",
    "12911",
    "12912",
    "12913",
    "12914",
    "12915",
    "12916",
    "12917",
    "12918",
    "12919",
    "12920",
    "12921",
    "12922",
    "12923",
    "12924",
    "12926",
    "12927",
    "12928",
    "12929",
    "12930",
    "12932",
    "12933",
    "12934",
    "12935",
    "12936",
    "12937",
    "12939",
    "12941",
    "12942",
    "12943",
    "12944",
    "12945",
    "12946",
    "12949",
    "12950",
    "12952",
    "12953",
    "12955",
    "12956",
    "12957",
    "12958",
    "12959",
    "12960",
    "12961",
    "12962",
    "12964",
    "12965",
    "12966",
    "12967",
    "12969",
    "12970",
    "12972",
    "12973",
    "12974",
    "12975",
    "12976",
    "12977",
    "12978",
    "12979",
    "12980",
    "12981",
    "12983",
    "12985",
    "12986",
    "12987",
    "12989",
    "12992",
    "12993",
    "12995",
    "12996",
    "12997",
    "12998",
    "13020",
    "13021",
    "13022",
    "13024",
    "13026",
    "13027",
    "13028",
    "13029",
    "13030",
    "13031",
    "13032",
    "13033",
    "13034",
    "13035",
    "13036",
    "13037",
    "13039",
    "13040",
    "13041",
    "13042",
    "13043",
    "13044",
    "13045",
    "13051",
    "13052",
    "13053",
    "13054",
    "13056",
    "13057",
    "13060",
    "13061",
    "13062",
    "13063",
    "13064",
    "13065",
    "13066",
    "13068",
    "13069",
    "13071",
    "13072",
    "13073",
    "13074",
    "13076",
    "13077",
    "13078",
    "13080",
    "13081",
    "13082",
    "13083",
    "13084",
    "13087",
    "13088",
    "13089",
    "13090",
    "13092",
    "13093",
    "13101",
    "13102",
    "13103",
    "13104",
    "13107",
    "13108",
    "13110",
    "13111",
    "13112",
    "13113",
    "13114",
    "13115",
    "13116",
    "13117",
    "13118",
    "13119",
    "13120",
    "13121",
    "13122",
    "13123",
    "13124",
    "13126",
    "13131",
    "13132",
    "13134",
    "13135",
    "13136",
    "13137",
    "13138",
    "13139",
    "13140",
    "13141",
    "13142",
    "13143",
    "13144",
    "13145",
    "13146",
    "13147",
    "13148",
    "13152",
    "13153",
    "13154",
    "13155",
    "13156",
    "13157",
    "13158",
    "13159",
    "13160",
    "13162",
    "13163",
    "13164",
    "13165",
    "13166",
    "13167",
    "13201",
    "13202",
    "13203",
    "13204",
    "13205",
    "13206",
    "13207",
    "13208",
    "13209",
    "13210",
    "13211",
    "13212",
    "13214",
    "13215",
    "13217",
    "13218",
    "13219",
    "13220",
    "13221",
    "13224",
    "13225",
    "13235",
    "13244",
    "13250",
    "13251",
    "13252",
    "13261",
    "13290",
    "13301",
    "13302",
    "13303",
    "13304",
    "13305",
    "13308",
    "13309",
    "13310",
    "13312",
    "13313",
    "13314",
    "13315",
    "13316",
    "13317",
    "13318",
    "13319",
    "13320",
    "13321",
    "13322",
    "13323",
    "13324",
    "13325",
    "13326",
    "13327",
    "13328",
    "13329",
    "13331",
    "13332",
    "13333",
    "13334",
    "13335",
    "13337",
    "13338",
    "13339",
    "13340",
    "13341",
    "13342",
    "13343",
    "13345",
    "13346",
    "13348",
    "13350",
    "13352",
    "13353",
    "13354",
    "13355",
    "13357",
    "13360",
    "13361",
    "13362",
    "13363",
    "13364",
    "13365",
    "13367",
    "13368",
    "13401",
    "13402",
    "13403",
    "13404",
    "13406",
    "13407",
    "13408",
    "13409",
    "13410",
    "13411",
    "13413",
    "13415",
    "13416",
    "13417",
    "13418",
    "13420",
    "13421",
    "13424",
    "13425",
    "13426",
    "13428",
    "13431",
    "13433",
    "13435",
    "13436",
    "13437",
    "13438",
    "13439",
    "13440",
    "13441",
    "13442",
    "13449",
    "13450",
    "13452",
    "13454",
    "13455",
    "13456",
    "13457",
    "13459",
    "13460",
    "13461",
    "13464",
    "13465",
    "13468",
    "13469",
    "13470",
    "13471",
    "13472",
    "13473",
    "13475",
    "13476",
    "13477",
    "13478",
    "13479",
    "13480",
    "13482",
    "13483",
    "13484",
    "13485",
    "13486",
    "13488",
    "13489",
    "13490",
    "13491",
    "13492",
    "13493",
    "13494",
    "13495",
    "13501",
    "13502",
    "13503",
    "13504",
    "13505",
    "13599",
    "13601",
    "13602",
    "13603",
    "13605",
    "13606",
    "13607",
    "13608",
    "13611",
    "13612",
    "13613",
    "13614",
    "13615",
    "13616",
    "13617",
    "13618",
    "13619",
    "13620",
    "13621",
    "13622",
    "13623",
    "13624",
    "13625",
    "13626",
    "13627",
    "13628",
    "13630",
    "13631",
    "13632",
    "13633",
    "13634",
    "13635",
    "13636",
    "13637",
    "13638",
    "13639",
    "13640",
    "13641",
    "13642",
    "13643",
    "13645",
    "13646",
    "13647",
    "13648",
    "13649",
    "13650",
    "13651",
    "13652",
    "13654",
    "13655",
    "13656",
    "13657",
    "13658",
    "13659",
    "13660",
    "13661",
    "13662",
    "13664",
    "13665",
    "13666",
    "13667",
    "13668",
    "13669",
    "13670",
    "13671",
    "13672",
    "13673",
    "13674",
    "13675",
    "13676",
    "13677",
    "13678",
    "13679",
    "13680",
    "13681",
    "13682",
    "13683",
    "13684",
    "13685",
    "13687",
    "13690",
    "13691",
    "13692",
    "13693",
    "13694",
    "13695",
    "13696",
    "13697",
    "13699",
    "13730",
    "13731",
    "13732",
    "13733",
    "13734",
    "13736",
    "13737",
    "13738",
    "13739",
    "13740",
    "13743",
    "13744",
    "13745",
    "13746",
    "13747",
    "13748",
    "13749",
    "13750",
    "13751",
    "13752",
    "13753",
    "13754",
    "13755",
    "13756",
    "13757",
    "13758",
    "13760",
    "13761",
    "13762",
    "13763",
    "13774",
    "13775",
    "13776",
    "13777",
    "13778",
    "13780",
    "13782",
    "13783",
    "13784",
    "13786",
    "13787",
    "13788",
    "13790",
    "13794",
    "13795",
    "13796",
    "13797",
    "13801",
    "13802",
    "13803",
    "13804",
    "13806",
    "13807",
    "13808",
    "13809",
    "13810",
    "13811",
    "13812",
    "13813",
    "13814",
    "13815",
    "13820",
    "13825",
    "13826",
    "13827",
    "13830",
    "13832",
    "13833",
    "13834",
    "13835",
    "13837",
    "13838",
    "13839",
    "13840",
    "13841",
    "13842",
    "13843",
    "13844",
    "13845",
    "13846",
    "13847",
    "13848",
    "13849",
    "13850",
    "13851",
    "13856",
    "13859",
    "13860",
    "13861",
    "13862",
    "13863",
    "13864",
    "13865",
    "13901",
    "13902",
    "13903",
    "13904",
    "13905",
    "14001",
    "14004",
    "14005",
    "14006",
    "14008",
    "14009",
    "14010",
    "14011",
    "14012",
    "14013",
    "14020",
    "14021",
    "14024",
    "14025",
    "14026",
    "14027",
    "14028",
    "14029",
    "14030",
    "14031",
    "14032",
    "14033",
    "14034",
    "14035",
    "14036",
    "14037",
    "14038",
    "14039",
    "14040",
    "14041",
    "14042",
    "14043",
    "14047",
    "14048",
    "14051",
    "14052",
    "14054",
    "14055",
    "14056",
    "14057",
    "14058",
    "14059",
    "14060",
    "14061",
    "14062",
    "14063",
    "14065",
    "14066",
    "14067",
    "14068",
    "14069",
    "14070",
    "14072",
    "14075",
    "14080",
    "14081",
    "14082",
    "14083",
    "14085",
    "14086",
    "14091",
    "14092",
    "14094",
    "14095",
    "14098",
    "14101",
    "14102",
    "14103",
    "14105",
    "14107",
    "14108",
    "14109",
    "14110",
    "14111",
    "14112",
    "14113",
    "14120",
    "14125",
    "14126",
    "14127",
    "14129",
    "14130",
    "14131",
    "14132",
    "14133",
    "14134",
    "14135",
    "14136",
    "14138",
    "14139",
    "14140",
    "14141",
    "14143",
    "14144",
    "14145",
    "14150",
    "14151",
    "14166",
    "14167",
    "14168",
    "14169",
    "14170",
    "14171",
    "14172",
    "14173",
    "14174",
    "14201",
    "14202",
    "14203",
    "14204",
    "14205",
    "14206",
    "14207",
    "14208",
    "14209",
    "14210",
    "14211",
    "14212",
    "14213",
    "14214",
    "14215",
    "14216",
    "14217",
    "14218",
    "14219",
    "14220",
    "14221",
    "14222",
    "14223",
    "14224",
    "14225",
    "14226",
    "14227",
    "14228",
    "14231",
    "14233",
    "14240",
    "14241",
    "14260",
    "14261",
    "14263",
    "14264",
    "14265",
    "14267",
    "14269",
    "14270",
    "14272",
    "14273",
    "14276",
    "14280",
    "14301",
    "14302",
    "14303",
    "14304",
    "14305",
    "14410",
    "14411",
    "14413",
    "14414",
    "14415",
    "14416",
    "14418",
    "14420",
    "14422",
    "14423",
    "14424",
    "14425",
    "14427",
    "14428",
    "14429",
    "14430",
    "14432",
    "14433",
    "14435",
    "14437",
    "14441",
    "14443",
    "14445",
    "14449",
    "14450",
    "14452",
    "14453",
    "14454",
    "14456",
    "14461",
    "14462",
    "14463",
    "14464",
    "14466",
    "14467",
    "14468",
    "14469",
    "14470",
    "14471",
    "14472",
    "14475",
    "14476",
    "14477",
    "14478",
    "14479",
    "14480",
    "14481",
    "14482",
    "14485",
    "14486",
    "14487",
    "14488",
    "14489",
    "14502",
    "14504",
    "14505",
    "14506",
    "14507",
    "14508",
    "14510",
    "14511",
    "14512",
    "14513",
    "14514",
    "14515",
    "14516",
    "14517",
    "14518",
    "14519",
    "14520",
    "14521",
    "14522",
    "14525",
    "14526",
    "14527",
    "14529",
    "14530",
    "14532",
    "14533",
    "14534",
    "14536",
    "14537",
    "14538",
    "14539",
    "14541",
    "14542",
    "14543",
    "14544",
    "14545",
    "14546",
    "14547",
    "14548",
    "14549",
    "14550",
    "14551",
    "14555",
    "14556",
    "14557",
    "14558",
    "14559",
    "14560",
    "14561",
    "14563",
    "14564",
    "14568",
    "14569",
    "14571",
    "14572",
    "14580",
    "14585",
    "14586",
    "14588",
    "14589",
    "14590",
    "14591",
    "14592",
    "14602",
    "14603",
    "14604",
    "14605",
    "14606",
    "14607",
    "14608",
    "14609",
    "14610",
    "14611",
    "14612",
    "14613",
    "14614",
    "14615",
    "14616",
    "14617",
    "14618",
    "14619",
    "14620",
    "14621",
    "14622",
    "14623",
    "14624",
    "14625",
    "14626",
    "14627",
    "14638",
    "14639",
    "14642",
    "14643",
    "14644",
    "14645",
    "14646",
    "14647",
    "14649",
    "14650",
    "14651",
    "14652",
    "14653",
    "14664",
    "14673",
    "14683",
    "14692",
    "14694",
    "14701",
    "14702",
    "14706",
    "14707",
    "14708",
    "14709",
    "14710",
    "14711",
    "14712",
    "14714",
    "14715",
    "14716",
    "14717",
    "14718",
    "14719",
    "14720",
    "14721",
    "14722",
    "14723",
    "14724",
    "14726",
    "14727",
    "14728",
    "14729",
    "14730",
    "14731",
    "14732",
    "14733",
    "14735",
    "14736",
    "14737",
    "14738",
    "14739",
    "14740",
    "14741",
    "14742",
    "14743",
    "14744",
    "14745",
    "14747",
    "14748",
    "14750",
    "14751",
    "14752",
    "14753",
    "14754",
    "14755",
    "14756",
    "14757",
    "14758",
    "14760",
    "14766",
    "14767",
    "14769",
    "14770",
    "14772",
    "14774",
    "14775",
    "14777",
    "14778",
    "14779",
    "14781",
    "14782",
    "14783",
    "14784",
    "14785",
    "14786",
    "14787",
    "14788",
    "14801",
    "14802",
    "14803",
    "14804",
    "14805",
    "14806",
    "14807",
    "14808",
    "14809",
    "14810",
    "14812",
    "14813",
    "14814",
    "14815",
    "14816",
    "14817",
    "14818",
    "14819",
    "14820",
    "14821",
    "14822",
    "14823",
    "14824",
    "14825",
    "14826",
    "14827",
    "14830",
    "14831",
    "14836",
    "14837",
    "14838",
    "14839",
    "14840",
    "14841",
    "14842",
    "14843",
    "14845",
    "14846",
    "14847",
    "14850",
    "14851",
    "14852",
    "14853",
    "14854",
    "14855",
    "14856",
    "14857",
    "14858",
    "14859",
    "14860",
    "14861",
    "14863",
    "14864",
    "14865",
    "14867",
    "14869",
    "14870",
    "14871",
    "14872",
    "14873",
    "14874",
    "14876",
    "14877",
    "14878",
    "14879",
    "14880",
    "14881",
    "14882",
    "14883",
    "14884",
    "14885",
    "14886",
    "14887",
    "14889",
    "14891",
    "14892",
    "14893",
    "14894",
    "14895",
    "14897",
    "14898",
    "14901",
    "14902",
    "14903",
    "14904",
    "14905",
    "14925",
  ],
};

interface ZipcodeConstant {
  nj: string[];
  ny: string[];
}
