import { Form, FormItemProps, Radio } from "antd";
import React, { useEffect, useMemo, useState } from "react";

const DEFAULT_CHOICES = ["Yes", "No"];
const DEFAULT_TARGET_CHOICE = ["Yes"];
const DEFAULT_REVERSE_TARGET_CHOICE = ["No"];

/*
 * Radio Conditional Form
 - - - - - - - - - -
 Given primaryComponent Form Items, conditionally shows them if a certain radio button is picked.
 More complex version of "YesNoRadioButton"
 TODO1: Extend to conditionally show variable number of choices depending on the radio choice
 TODO1: Use YesNoRadioButton instead DEFAULT_CHOICES
 TODO1: Typesafe with Generic for 'choices'
 @param primaryComponent: Child to display on target choice
 @param secondaryComponent: Optional child to display when not on target choice
 @param name: Form item name
 @param label: The label applied to the checkbox
 @param choices: Array of choices to display. Defaults to "Yes / No"
 @param targetChoice: The choice(s) that will make the primaryComponent appear. Defaults to "Yes". Always include with choices
 @param hideOnCheck: If true, conditionally hides primaryComponent rather than shows it
 @param reverseTargetChoice: If true, makes the target choice exclusive, not inclusive. Commonly used to make the default "No" the target choice
 */
export const RadioConditionalForm = ({
  primaryComponent,
  secondaryComponent,
  choices,
  targetChoices,
  hideOnCheck,
  reverseTargetChoice,
  defaultToTarget,
  required,
  formItemProps,
}: RadioConditionalForm) => {
  // not using useToggle here due to complexity surrounding multiple target choices
  const [isOnTargetChoice, setIsOnTargetChoice] = useState(defaultToTarget);

  // checks that both choices and targetChoices are included if one is
  useEffect(() => {
    if ((choices && !targetChoices) || (!choices && targetChoices)) {
      throw Error(
        "Internal error: you must include both params 'choices' and 'targetChoices'",
      );
    }
  }, [choices, targetChoices]);

  // sets the choices and target choices (will never change, so useMemo is optimal)
  const radioChoices = useMemo(() => (choices ? choices : DEFAULT_CHOICES), [
    choices,
  ]);
  const radioTargetChoices = useMemo(
    () =>
      targetChoices
        ? targetChoices
        : reverseTargetChoice
        ? DEFAULT_REVERSE_TARGET_CHOICE
        : DEFAULT_TARGET_CHOICE,
    [targetChoices, reverseTargetChoice],
  );

  // sets if the primaryComponent will show each render
  const showChildren = hideOnCheck ? !isOnTargetChoice : isOnTargetChoice;

  return (
    <>
      <Form.Item rules={[{ required }]} {...formItemProps}>
        <Radio.Group
          defaultValue={defaultToTarget ? DEFAULT_TARGET_CHOICE[0] : null}
          onChange={(e) =>
            setIsOnTargetChoice(radioTargetChoices.includes(e.target.value))
          }
        >
          {radioChoices.map((choice) => (
            <Radio key={choice} value={choice}>
              {choice}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      {showChildren
        ? primaryComponent
        : secondaryComponent && secondaryComponent}
    </>
  );
};

interface RadioConditionalForm {
  primaryComponent: React.ReactNode;
  secondaryComponent?: React.ReactNode;
  choices?: string[];
  targetChoices?: string[];
  hideOnCheck?: boolean;
  reverseTargetChoice?: boolean;
  defaultToTarget?: boolean;
  formItemProps?: FormItemProps;
  required?: boolean;
}
