/*
 * CHAR Length
 - - - - - - - - - - - - - -
 This object contains all type length used throughout the codebase for MySQL.
 */
export const CHAR_LENGTH: CharLengthConstant = {
  entityId: 17,
  key: 64,
};

interface CharLengthConstant {
  entityId: number;
  key: number;
}
