import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  MessageOutlined,
  PaperClipOutlined,
  PlaySquareOutlined,
  TeamOutlined,
  UserDeleteOutlined,
  SettingOutlined,
  MedicineBoxOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NavIconHome } from "../../../../../assets/icon-home.svg";
import { ReactComponent as NavIconProperties } from "../../../../../assets/icon-property.svg";
import { PATH } from "../../../../../constants";
import { NavWrapper } from "../NavWrapper";
import { history } from "../../../../../util";
import { rxPages } from "../../..";

/*
 * Admin Nav
 - - - - - - - - - - - -
 Shows only the admin side navigation options.
 */
export const AdminNav = () => {
  const prevPage = { prevPage: history.location.pathname };
  const isSecurePath = rxPages.includes(history.location.pathname);

  return (
    <NavWrapper userIsRx={true} isSecurePath={isSecurePath}>
      <Menu.Item key={PATH.admin.dashboard}>
        <Link
          to={{
            pathname: PATH.admin.dashboard,
            state: prevPage,
          }}
        >
          <NavIconHome />
          <span>Dashboard</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={PATH.admin.patient}>
        <Link
          to={{
            pathname: PATH.admin.patient,
            state: prevPage,
          }}
        >
          <UserDeleteOutlined />
          <span>Patients</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={PATH.admin.provider}>
        <Link
          to={{
            pathname: PATH.admin.provider,
            state: prevPage,
          }}
        >
          <TeamOutlined />
          <span>Provider</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={PATH.admin.properties}>
        <Link
          to={{
            pathname: PATH.admin.properties,
            state: prevPage,
          }}
        >
          <NavIconProperties />
          <span>Properties</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={PATH.admin.prescription}>
        <Link
          to={{
            pathname: PATH.admin.prescription,
            state: prevPage,
          }}
        >
          <MedicineBoxOutlined />
          <span>Prescriptions</span>
        </Link>
      </Menu.Item>
      <Menu.SubMenu
        key={PATH.admin.connections}
        title="Connections"
        icon={<PaperClipOutlined />}
      >
        <Menu.Item key="pending" icon={<PlaySquareOutlined />}>
          <Link
            to={{
              pathname: PATH.admin.connections,
              state: history.location.pathname,
            }}
          >
            Pending Connections
          </Link>
        </Menu.Item>
        <Menu.Item key="approved" icon={<CheckSquareOutlined />}>
          <Link
            to={{
              pathname: `${PATH.admin.connections}?viewCondition=approved`,
              state: history.location.pathname,
            }}
          >
            Approved Connections
          </Link>
        </Menu.Item>
        <Menu.Item key="rejected" icon={<CloseSquareOutlined />}>
          <Link
            to={{
              pathname: `${PATH.admin.connections}?viewCondition=rejected`,
              state: history.location.pathname,
            }}
          >
            Rejected Connections
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key={PATH.admin.propertyChat}>
        <Link
          to={{
            pathname: PATH.admin.propertyChat,
            state: history.location.pathname,
          }}
        >
          <MessageOutlined />
          <span>Chat</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={PATH.admin.settings}>
        <Link
          to={{
            pathname: PATH.admin.settings,
            state: history.location.pathname,
          }}
        >
          <SettingOutlined />
          <span>Settings</span>
        </Link>
      </Menu.Item>
    </NavWrapper>
  );
};
