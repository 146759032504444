import { Layout, Menu } from "antd";
import React, { useState, ReactNode } from "react";
import { ReactComponent as Logout } from "../../../../../assets/logout.svg";
import { handleSignout, history } from "../../../../../util";
import styles from "../sidenav.module.scss";
import { logoutEPHIAudit } from "../../../../../util/audit";
import { hBeanGreen } from "../../../../../constants/cdn";
import { HLink } from "../../../../../components";
import { PATH } from "../../../../../constants";
import { useMobile } from "../../../../../hooks";

/*
 * Nav Wrapper
 - - - - - - - - -
 Wrapper for Nav for basic layout format
 */

export const NavWrapper = ({
  children,
  userIsRx,
  isSecurePath,
}: NavWrapperProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useMobile();

  return (
    <>
      {!collapsed && isMobile && (
        <div className={styles.backdrop} onClick={() => setCollapsed(true)} />
      )}
      <Layout.Sider
        id={styles.siderNav}
        className={styles.sideDrawer}
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={collapsed}
        onClick={() => {
          if (isMobile) setCollapsed((c) => !c);
        }}
        onBreakpoint={(broken) => setCollapsed(broken)}
      >
        <HLink to={PATH.landing.home}>
          <div className={styles.navLogo}>
            <img src={hBeanGreen} alt="HealCo" />
          </div>
        </HLink>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 124px)",
          }}
        >
          <Menu
            mode="vertical"
            className={styles.navMenu}
            defaultSelectedKeys={[history.location.pathname]}
          >
            {children}
          </Menu>
          <div
            id={styles.navLogout}
            onClick={() => {
              if (userIsRx && isSecurePath) logoutEPHIAudit();
              handleSignout();
            }}
          >
            <Logout />
            <span>Sign Out</span>
          </div>
        </div>
      </Layout.Sider>
    </>
  );
};

interface NavWrapperProps {
  children: ReactNode;
  userIsRx?: boolean;
  isSecurePath?: boolean;
}
