import { ERROR, HTTP_STATUS } from "../../../constants";
import { handleHealcoError } from "../../handleHealcoError";

/*
 * Handle Fetch
 - - - - - - - -
 Fetch wrapper that automatically handles errors and
 returns success/failure and any relevant data. You can either
 deal with the success+result / failure on the calling code,
 or use success/failure callbacks.

 @types: T: body payload type
 @types: S: return payload type
 @param route: route from API_ROUTE constant
 @param method: route method
 @param body: body parameters to route of type T
 @param onSuccess: optional callback to handle resulting data
 @param onFailure: optional callback to handle failure
 @use const { success, result } = await handleFetch<{ id: string }, HealcoIdentity >(API_ROUTE.foo, FETCH.get, body)
 @use await handleFetch<{ id: string }, HealcoIdentity >(API_ROUTE.foo, FETCH.get, { id: "xxxx" }, (v: HealcoIdentity) => setIdentity(v))
 */
export const handleFetch = async <T = void, S = void>({
  route,
  method,
  body,
  onSuccess,
  onFailure,
  ignoreError,
}: HandleFetch<T, S>): Promise<HealcoResponse<S>> => {
  try {
    const res = await fetch(`/api${route}`, {
      method,
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    });

    if (res.status === HTTP_STATUS.serverError.gatewayTimeout) {
      handleHealcoError(ERROR.misc.gatewayTimeout, true);
    }

    const data: HealcoResponse<S> = await res.json();

    // handles Success or Failure
    if (data.success) {
      if (onSuccess) onSuccess(data.result);
    } else {
      if (!ignoreError) {
        handleHealcoError(data.error, false);
        if (onFailure) onFailure(data.error);
      }
    }

    return data;
  } catch (err) {
    console.error(err);
    return { success: false, result: {} as S };
  }
};

interface HandleFetch<T, S> {
  route: string;
  method: TFetchMethod;
  body?: T;
  onSuccess?: (result: S) => void;
  onFailure?: (e?: HealcoError) => void;
  ignoreError?: boolean;
}
