/*
 * Make Full Name
 - - - - - - - - - -
 Given a first name and last name, returns the full name
 @param firstName: first name
 @param lastName: last name
*/
export const makeFullName = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName}`;
};
