import React from "react";
import { Button } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { HContent } from "../../types";
import cx from "classnames";
import styles from "./hButton.module.scss";

/*
 * HButton
 - - - - -
 Button that offers consistent HealCo styling options and a video option.
 Used mainly on Pillar pages, but can used anywhere in the application.

  @param text: text content on HButton
  @param video: if true, video styling implemented
  @param icon: optional icon to render
  @param buttonStyle: optional change from "primary" button styling
 */

export const HButton = ({
  text = "Watch",
  video = false,
  buttonStyle = "primary",
  icon = <PlayCircleOutlined />,
}: HButtonProps) => {
  if (video) {
    return (
      <Button shape="round" icon={icon} size="large">
        {text}
      </Button>
    );
  } else {
    return (
      <Button
        className={cx(styles.hButton, {
          [styles.primary]: buttonStyle === "primary",
          [styles.secondary]: buttonStyle === "secondary",
          [styles.light]: buttonStyle === "light",
        })}
      >
        {text}
      </Button>
    );
  }
};

interface HButtonProps {
  text?: string;
  video?: boolean;
  buttonStyle?: HContent.TButtonStyle;
  icon?: JSX.Element;
}
