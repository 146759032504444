import React, { useState } from "react";
import { Modal, Button, message, Image } from "antd";
import { handleFetch } from "../../../../../../util";
import { API_ROUTE, FETCH } from "../../../../../../constants";
import { ApproveWindow, RejectWindow } from "../../ProviderResponse";
import { HHeading, HText } from "../../../../../../components";
import styles from "./prescriptionsInfoModal.module.scss";
import { useToggle } from "../../../../../../hooks";

const PrescriptionInfoModal = ({
  mode,
  getUpdate,
  isModalVisible,
  setIsModalVisible,
  prescription,
  userIsHPro,
  userIsPharmacist,
}: PrescriptionInfoModalProps) => {
  const [externalPrescription, setExternalPrescription] = useState<boolean>(
    false,
  );
  const [isLoading, toggleLoading] = useToggle();
  const [providerResponse, setProviderResponse] = useState<
    ProviderResponseTypes
  >();
  const [prescriptionUpload, setPrescriptionUpload] = useState<FileSchema[]>(
    [],
  );

  const handleApprove = async (values: any) => {
    toggleLoading();
    if (!prescription) message.error("Error loading prescription");
    else {
      await handleFetch({
        route: API_ROUTE.prescription.approve,
        method: FETCH.post,
        body: {
          ...values,
          patientQuestionnaire_id: prescription.id,
          prescriptionUpload: prescriptionUpload || [],
          rxMedication_id: prescription.medication.id,
        },
        onSuccess: () => {
          handleReset();
          getUpdate();
        },
      });
    }
    toggleLoading();
  };

  const handleReject = async (values: any) => {
    toggleLoading();
    if (!prescription) message.error("Error loading prescription");
    else
      await handleFetch({
        route: API_ROUTE.prescription.reject,
        method: FETCH.post,
        body: {
          ...values,
          patientQuestionnaire_id: prescription.id,
        },
        onSuccess: () => {
          setTimeout(() => {
            getUpdate();
            handleReset();
          }, 500);
        },
      });

    toggleLoading();
  };

  const handleBack = () => {
    setProviderResponse(null);
    setPrescriptionUpload([]);
  };

  const handleReset = () => {
    setIsModalVisible(false);
    setProviderResponse(null);
    setPrescriptionUpload([]);
    setExternalPrescription(false);
  };

  if (!prescription) return <></>;
  return (
    <>
      <Modal
        visible={isModalVisible}
        footer={
          userIsHPro
            ? [
                mode === "pending"
                  ? [
                      providerResponse === "approve" && (
                        <div
                          className={styles.prescriptionSubmission}
                          key="prescriptionSubmission"
                        >
                          <ApproveWindow
                            key="approveWindow"
                            handleApprove={handleApprove}
                            prescriptionUpload={prescriptionUpload}
                            setPrescriptionUpload={setPrescriptionUpload}
                            loading={isLoading}
                            setLoading={toggleLoading}
                            externalPrescription={externalPrescription}
                            setExternalPrescription={setExternalPrescription}
                            handleBack={handleBack}
                          />
                        </div>
                      ),
                      providerResponse === "reject" && (
                        <RejectWindow
                          key="rejectWindow"
                          handleReject={handleReject}
                          loading={isLoading}
                          setLoading={toggleLoading}
                          handleBack={handleBack}
                        />
                      ),
                      !providerResponse && (
                        <React.Fragment key="approveReject">
                          <Button
                            className={styles.approve}
                            key="approve"
                            onClick={() => setProviderResponse("approve")}
                          >
                            Approve
                          </Button>
                          <Button
                            className={styles.reject}
                            key="reject"
                            onClick={() => setProviderResponse("reject")}
                          >
                            Reject
                          </Button>
                        </React.Fragment>
                      ),
                    ]
                  : [],
              ]
            : []
        }
        onCancel={() => handleReset()}
        title={prescription.treatmentCategory.name}
      >
        {!userIsPharmacist && (
          <>
            <HHeading text="Patient Verification" size={5} noMargin />
            <HText>
              {prescription.name && (
                <>
                  <b>Name:</b>
                  <span>{prescription?.name}</span>
                </>
              )}
            </HText>
            <HHeading text="Questionnaire" size={5} noMargin addMarginTop />
            {prescription.patientResponse.map((res, i) => (
              <div key={res.question.id}>
                <p className={styles.question}>
                  {i + 1}: {res.question.text}
                </p>
                {res.answer.map((ans, j) =>
                  res.question.type.id === "UPLD" ? (
                    <Image
                      key={j}
                      width={132}
                      src={`/api/no-auth/download-file/${ans.expandedAnswer}/patientQuestionnaires`}
                    />
                  ) : (
                    <p key={j}>
                      {ans.text} {ans.expandedAnswer}
                    </p>
                  ),
                )}
              </div>
            ))}
          </>
        )}
        <HHeading text="Medication Requested" size={5} noMargin addMarginTop />
        <HText>
          <b>Name:</b>
          <span>{prescription.medication.name}</span>
          <b>Dose (mg):</b>
          <span>{prescription.medication.dosage}</span>
          <b>Quantity:</b>
          <span>{prescription.medication.quantity}</span>
          <b>Number of Refills:</b>
          <span>12 months</span>
          {prescription.medication.daySupply && (
            <>
              <b>Day Supply:</b>
              <span>{prescription.medication.daySupply}</span>
            </>
          )}
        </HText>
      </Modal>
    </>
  );
};

export { PrescriptionInfoModal };

type ProviderResponseTypes = "approve" | "reject" | null;

interface PrescriptionInfoModalProps {
  mode: string;
  getUpdate: () => void;
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  prescription?:
    | HealcoPrescription
    | (PrescriptionSubscription & { name?: string });
  userIsHPro: boolean;
  userIsPharmacist: boolean;
}
