import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";

/*
 * Lottie Player
 - - - - - - - - -
 Wrapper for Lottie Player
 */
export const LottiePlayer = ({
  src,
  height,
  width,
  loop,
  keepLastFrame = true,
}: LottiePlayerProps) => {
  return (
    <Player
      autoplay
      loop={loop}
      src={src}
      style={{ height: `${height}px`, width: `${width}px` }}
      keepLastFrame={keepLastFrame}
    />
  );
};

interface LottiePlayerProps {
  src: any;
  height?: number;
  width?: number;
  loop?: boolean;
  keepLastFrame?: boolean;
}
