import React from "react";
import { formatMoney } from "../../../../../util";

const DisplayRates = ({ property }: { property: HealcoPropertyUser }) => {
  const rooms = property.roomInfo;
  const hasRoomRate = rooms?.items?.[0]?.rate;
  // * Needs to be ternary because of how it is used. Would return 0 if false.
  const fullPropertyPriceNode =
    property.rate && property.ratePeriod ? (
      <p style={{ margin: "0" }}>
        Full Office Rate: {formatMoney(property.rate)} per {property.ratePeriod}
      </p>
    ) : (
      ""
    );

  return (
    <>
      {hasRoomRate ? (
        <>
          {rooms?.items?.map((room: HealcoRoom, index: number) => {
            return room.rate && room.ratePeriod ? (
              <p key={index} style={{ margin: "0" }}>
                Room {index + 1} rate: {formatMoney(room.rate)} per&nbsp;
                {room.ratePeriod}
              </p>
            ) : (
              ""
            );
          })}
          {fullPropertyPriceNode}
        </>
      ) : (
        fullPropertyPriceNode
      )}
    </>
  );
};
export default DisplayRates;
