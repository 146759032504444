/*
 * Find HRef
 - - - - - - - - - - - -
 Given an array of HealCo references, returns the name or ID of the one corresponding
 to the passed in the known field
 @param refIdentifier: id or name to be checked
 @param refTable: array of HealCo References
 @param findById: if false, find by name instead, and returns the id
 */
export const findHRef = (
  refIdentifier: string,
  refTable?: HealcoReference[],
  findById = true,
) => {
  return findById
    ? refTable?.find((ref) => ref.id === refIdentifier)?.name || ""
    : refTable?.find((ref) => ref.name === refIdentifier)?.id || "";
};
