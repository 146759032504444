import { useMediaLayout } from "use-media";
import variables from "../../styles/variables.module.scss";

/*
 * Use Is Mobile
 - - - - - - - - -
 Returns true if content is past breakpoint

 @use const isLg = useMobile()
 @use const isMd = useMobile(UI.antd.md)
 */
export const useMobile = (maxWidth = variables.antdLg) =>
  useMediaLayout({ maxWidth });
