/*
 * Make Phone Number
 - - - - - - - - - -
 Given a phone number, format to be legible
 @param phone: phone number
*/
export const makePhoneNumber = (phone?: string) => {
  if (!phone) return "";
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
};
