import React, { useState, useEffect } from "react";
import { Input, Form, Row, Col, Button } from "antd";
import { InformationSection } from "../../../Auth/components";
import { HHeading, SubmitButton } from "../../../../components";
import Modal from "antd/lib/modal/Modal";
import { API_ROUTE, FETCH } from "../../../../constants";
import { handleFetch, handleSignout } from "../../../../util";

const SecurityWrapper = ({
  children,
  setIsSecurityChecked,
  lockoutTime = 480,
  requiredPassword,
  successCallback,
  modalCallback,
  exitCallback,
}: SecurityWrapperProps) => {
  const [checkedSecurity, setCheckedSecurity] = useState<boolean>(
    !requiredPassword,
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(lockoutTime);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (timer > 0 && checkedSecurity) {
        setTimer(timer - 1);
      }
    }, 1000);
    const resetTimeout = () => {
      setTimer(lockoutTime);
    };
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    for (const i in events) {
      window.addEventListener(events[i], resetTimeout);
    }
    return () => {
      clearInterval(myInterval);
      for (const i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  });

  const verifyUser = async (values: { password: string }) => {
    setLoading(true);
    if (values.password)
      await handleFetch<{ password: string }, HealcoUserIdentity>({
        route: API_ROUTE.auth.securityCheck,
        method: FETCH.post,
        body: values,
        onSuccess: () => {
          setIsSecurityChecked(true);
          setCheckedSecurity(true);

          if (successCallback) successCallback();
        },
      });

    setLoading(false);
  };

  useEffect(() => {
    if (timer === 30) setIsModalVisible(true);
    else if (timer === 0) {
      if (requiredPassword) {
        setIsModalVisible(false);
        setCheckedSecurity(false);
      } else {
        if (!document.hidden) handleSignout();
        else setTimer(lockoutTime);
      }
      if (exitCallback) exitCallback();
    }
  }, [timer, requiredPassword, lockoutTime, exitCallback]);

  return (
    <>
      <Modal
        centered
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
        visible={isModalVisible}
        closable={false}
        footer={[
          <Button
            key="here"
            onClick={() => {
              setIsModalVisible(false);
              if (modalCallback) modalCallback();
            }}
          >
            Still here
          </Button>,
        ]}
      >
        <p>Are you still there?</p>
        <p>You will be timed out in 30 seconds.</p>
      </Modal>
      {checkedSecurity ? (
        children
      ) : (
        <Row>
          <Col xs={24} lg={10}>
            <InformationSection>
              <HHeading
                size={5}
                text="Please re-verify your identity by entering your password."
              />
              <Form layout="vertical" onFinish={verifyUser} colon={false}>
                <Form.Item name="password" label="Password">
                  <Input type="password" />
                </Form.Item>
                <SubmitButton text="Verify" isLoading={isLoading} />
              </Form>
            </InformationSection>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SecurityWrapper;

interface SecurityWrapperProps {
  children: React.ReactNode;
  setIsSecurityChecked: React.Dispatch<React.SetStateAction<boolean>>;
  lockoutTime?: number;
  requiredPassword: boolean;
  successCallback?: () => void;
  modalCallback?: () => void;
  exitCallback?: () => void;
}
