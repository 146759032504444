import React, { useState } from "react";
import { HealcoTabs, HIconSVG } from "../../../../../../../../components";
import { Tabs, Row, Col, Button, List } from "antd";
import { REF_ID, UI, PATH } from "../../../../../../../../constants";
import patientDashboardStyles from "../../patientDashboard.module.scss";
import queryString from "query-string";
import { PrescriptionSubscriptionProps } from "../..";
import moment from "moment";
import { SignupChoice } from "../../../../../../../Landing/Home/Welcome/Signup/SignupChoice";
import { SIGNUP } from "../../../../../../../Landing/Home/Welcome/Signup/constants";
import { medicationIcons } from "../../../../../../../../components/StartQuestionnaire/medicationIcons";
import { PrescriptionInfoModal } from "../../../../../Prescriptions/PrescriptionModal";
import { useToggle } from "../../../../../../../../hooks";
import styles from "./medicationStatus.module.scss";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { history } from "../../../../../../../../util";

const MedicationStatus = ({
  prescriptionSubscription,
}: PrescriptionSubscriptionProps) => {
  const qs = queryString.parse(window.location.search);
  const [isModalVisible, toggleModal] = useToggle();
  const [medIndex, setMedIndex] = useState(0);
  const selectedMed = qs.tab as MedicationTypes;

  const MedicationChoice = ({ text = "Get started" }: { text?: string }) => {
    return (
      <Row justify="center" className={styles.medicationChoices}>
        {selectedMed && (
          <SignupChoice
            choice={SIGNUP?.[selectedMed?.toLowerCase()]}
            text={text}
            icon={<HIconSVG customIcon={medicationIcons[selectedMed]} />}
          />
        )}
      </Row>
    );
  };

  return (
    <>
      <PrescriptionInfoModal
        mode={qs.tab as string}
        getUpdate={() => {}}
        isModalVisible={isModalVisible}
        setIsModalVisible={toggleModal}
        prescription={prescriptionSubscription?.[selectedMed]?.[medIndex]}
        userIsHPro={false}
        userIsPharmacist={false}
      />
      <Row
        justify="space-around"
        style={{ marginTop: "2.5rem" }}
        className={patientDashboardStyles.row}
      >
        <Col xs={24} lg={15}>
          <HealcoTabs defaultTab={REF_ID.treatmentCategory.edys.id}>
            <Tabs.TabPane
              tab={REF_ID.treatmentCategory.edys.name}
              key={REF_ID.treatmentCategory.edys.id}
            />
            <Tabs.TabPane
              tab={REF_ID.treatmentCategory.sccm.name}
              key={REF_ID.treatmentCategory.sccm.id}
            />
            <Tabs.TabPane
              tab={REF_ID.treatmentCategory.prep.name}
              key={REF_ID.treatmentCategory.prep.id}
            />
            <Tabs.TabPane
              tab={REF_ID.treatmentCategory.acne.name}
              key={REF_ID.treatmentCategory.acne.id}
            />
          </HealcoTabs>
        </Col>
        <Col span={7}>{/* SPACER */}</Col>
      </Row>
      <Row justify="space-around" className={patientDashboardStyles.row}>
        <Col xs={24} lg={15} className={patientDashboardStyles.col}>
          {prescriptionSubscription?.[selectedMed] ? (
            prescriptionSubscription?.[selectedMed]?.map((subscription, i) => {
              const isActive = subscription.isActive;
              const isApproved = Boolean(subscription.acceptedOn);
              const isRejected = Boolean(subscription.rejectedOn);
              const isUnpaid = !subscription.hasPaid;
              return (
                <div key={subscription.id} className={styles.subscriptionRow}>
                  {((isApproved && !isActive) || isRejected) && i === 0 && (
                    <MedicationChoice text="Start a new subscription" />
                  )}
                  <p>
                    <b>Status</b>:{" "}
                    {isActive
                      ? "Active"
                      : isApproved
                      ? "Completed"
                      : isRejected
                      ? "Rejected"
                      : isUnpaid
                      ? "Incomplete"
                      : "Waiting approval"}
                  </p>
                  {!isUnpaid && (
                    <p>
                      <b>Chosen medication</b>: {subscription.medication.name} -{" "}
                      {subscription.medication.dosage}
                      {subscription.medication.daySupply &&
                        `/${subscription.medication.daySupply}`}
                    </p>
                  )}
                  <div>
                    <Button
                      onClick={() => {
                        setMedIndex(i);
                        toggleModal();
                      }}
                    >
                      View my consultation
                    </Button>
                  </div>
                  {isApproved && (
                    <>
                      <p>
                        <b>Approved On</b>:{" "}
                        {subscription.acceptedOn &&
                          moment(subscription.acceptedOn).format(
                            UI.datetimeFormat.date,
                          )}
                      </p>
                      <p>
                        <b>Provider Note</b>: {subscription.providerNote}
                      </p>
                      <div>
                        <b>History</b>
                        <List
                          dataSource={subscription?.orderHistory || []}
                          pagination={{
                            pageSize: 3,
                            position: "bottom",
                          }}
                          renderItem={(item) => (
                            <List.Item key={item.id}>
                              <List.Item.Meta
                                title={`ID: ${item.orderNumber} -${" "}
                  ${
                    item.shippedOn
                      ? `Shipped On: ${moment
                          .unix(item.shippedOn)
                          .format(UI.datetimeFormat.date)}`
                      : "Waiting shipment"
                  }`}
                                description={
                                  <>
                                    {item.approvedOn &&
                                      `Approved: ${moment(
                                        item.approvedOn,
                                      ).format(UI.datetimeFormat.date)}`}
                                    {" - "}
                                    {item.trackingNumber &&
                                      `Tracking: ${item.trackingNumber}`}
                                  </>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      </div>
                    </>
                  )}
                  {isRejected && (
                    <>
                      <p>
                        <b>Rejected On</b>:{" "}
                        {subscription.rejectedOn &&
                          moment(subscription.rejectedOn).format(
                            UI.datetimeFormat.date,
                          )}
                      </p>
                      <p>
                        <b>Rejection Reason</b>:{" "}
                        {subscription.rejectionReason.other ||
                          subscription.rejectionReason.name}
                      </p>
                    </>
                  )}
                  {isUnpaid && (
                    <Button
                      onClick={() =>
                        history.push(
                          `${PATH.auth.questionnaire}?patientQuestionnaireId=${subscription.id}`,
                        )
                      }
                      icon={<ShoppingCartOutlined />}
                      style={{ marginTop: "1rem" }}
                    >
                      Complete Checkout
                    </Button>
                  )}
                </div>
              );
            })
          ) : (
            <MedicationChoice />
          )}
        </Col>
        <Col span={7}>{/* SPACER */}</Col>
      </Row>
    </>
  );
};

export { MedicationStatus };
