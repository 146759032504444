import { SendOutlined } from "@ant-design/icons";
import React from "react";
import { HHeading, HLink, HText } from "../../../../../../components";
import { PATH } from "../../../../../../constants";
import { SectionBackground, SectionContent } from "../../..";
import styles from "./contactSection.module.scss";
import variables from "../../../../../../styles/variables.module.scss";

/*
 * ContactSection
 - - - - -
  Component reused on the Landing Template that links to Contact-Us page
  @param  Accepts optional contact type string to be provided from a REF_ID, will populate selected option on the contact us form on render
 */

export const ContactSection = ({ contactType }: { contactType?: string }) => {
  return (
    <SectionBackground className={styles.contactSection}>
      <SectionContent className={styles.contactSectionContent}>
        <HText centered>
          <HHeading size={3} white text="Have more questions?" centered />
          <SendOutlined rotate={-20} />

          <HLink
            to={PATH.landing.contact}
            params={
              contactType
                ? {
                    userType: { input: contactType },
                  }
                : undefined
            }
          >
            <HHeading
              size={4}
              color={variables.secondary}
              text="Get in touch"
              noMargin
            />
          </HLink>
        </HText>
      </SectionContent>
    </SectionBackground>
  );
};
