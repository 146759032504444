import React, { useState } from "react";
import {
  Typography,
  Modal,
  message,
  notification,
  List,
  Button,
  Popconfirm,
} from "antd";
import { FileSearchOutlined } from "@ant-design/icons";
import { handleFetch } from "../../../../../util";
import { API_ROUTE, FETCH, UI } from "../../../../../constants";
import moment from "moment";
import variables from "../../../../../styles/variables.module.scss";

const notApplicable = "N/A";
const shippedNoTracking = "Shipped (no tracking available)";

const TrackingNumber = ({
  orderHistory,
  getUpdate,
  canEdit,
}: TrackingNumberProps) => {
  const [tracking, setTracking] = useState<string>(
    orderHistory.trackingNumber ||
      (orderHistory.shippedOn && shippedNoTracking) ||
      notApplicable,
  );

  const handlePost = async (value: string, type: TrackingType) => {
    await handleFetch<TrackingBody, void>({
      route: API_ROUTE.prescription.tracking,
      method: FETCH.post,
      body: { orderHistory_id: orderHistory.id, trackingNumber: value, type },
      onSuccess: () => {
        notification.success({
          message: "Tracking Number",
          description: "Successfully updated tracking.",
        });
        getUpdate();
      },
    });
  };

  const onChange = (value: string) => {
    if (!value) message.info("Please enter tracking info or mark as shipped.");
    if (!orderHistory.trackingNumber && value) {
      setTracking(value);
      handlePost(value, "POST");
    } else if (value)
      Modal.confirm({
        icon: <FileSearchOutlined />,
        title: "Tracking",
        content: (
          <>
            <p>
              You are updating an existing tracking number. Are you sure you
              want to do this?
            </p>
            <p>
              This will send another confirmation email to the patient of their
              new tracking.
            </p>
          </>
        ),
        cancelText: "Cancel",
        okText: "Send",
        onOk: async () => {
          try {
            handlePost(value, "PUT");
            setTracking(value);
          } catch (e) {
            message.error(`Error updating tracking: ${e.message}`);
          }
        },
      });
  };
  return (
    <>
      <List.Item key={orderHistory.id}>
        <List.Item.Meta
          title={`ID: ${orderHistory.orderNumber} ${
            orderHistory.shippedOn
              ? `- Shipped: ${moment
                  .unix(orderHistory.shippedOn)
                  .format(UI.datetimeFormat.date)}`
              : ""
          }`}
          description={
            <>
              <div>
                <Typography.Text strong>Tracking Number</Typography.Text>
              </div>
              <div>
                <Typography.Text
                  copyable
                  editable={
                    canEdit && {
                      onStart: () =>
                        !orderHistory.trackingNumber && setTracking(""),
                      onChange,
                    }
                  }
                >
                  {tracking}
                </Typography.Text>
                {!orderHistory.shippedOn && (
                  <Popconfirm
                    title="Confirm medication marked as shipped."
                    onConfirm={() => handlePost("", "POST")}
                    okButtonProps={{
                      style: {
                        backgroundColor: variables.primary,
                        borderColor: variables.primary,
                      },
                    }}
                    cancelButtonProps={{ danger: true, type: "primary" }}
                    okText="Confirm"
                  >
                    <Button type="primary" style={{ marginLeft: "1rem" }}>
                      Mark as Shipped
                    </Button>
                  </Popconfirm>
                )}
              </div>
            </>
          }
        />
      </List.Item>
    </>
  );
};

export default TrackingNumber;

interface TrackingNumberProps {
  orderHistory: HealcoOrderHistory;
  getUpdate: () => void;
  canEdit: boolean;
}

type TrackingType = "POST" | "PUT";

interface TrackingBody {
  orderHistory_id: string;
  trackingNumber: string;
  type: TrackingType;
}
