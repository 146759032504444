import { HTTP_STATUS } from "../../api";
import { ERROR_CODE } from "../errorCodes";

const CONTACT_MESSAGE =
  "Please try again later, or contact HealCo Support if this problem persists.";

/*
 * Error
 - - - - - - - - - - - - - -
 This object contains all custom HealCo error codes objects.
 */
export const ERROR: ErrorConstant = {
  auth: {
    unverifiedEmail: {
      status: HTTP_STATUS.clientError.unauthorized,
      code: ERROR_CODE.auth.unverifiedEmail,
      message: `Please verify your email before logging in for security purposes. We've sent a verification link to your email. If you're unable to find it, please check your spam, or contact HealCo Support.`,
      isInformation: true,
    },
    incorrectPassword: {
      status: HTTP_STATUS.clientError.unauthorized,
      code: ERROR_CODE.auth.incorrectPassword,
      message: `We don't recognize the email and password combination you provided. Please try again.`,
    },
    deactivatedAccount: {
      status: HTTP_STATUS.clientError.unauthorized,
      code: ERROR_CODE.auth.deactivatedAccount,
      message: `Your account is currently deactivated. Please contact HealCo support to request reactivation.`,
    },
    enforceAdmin: {
      status: HTTP_STATUS.clientError.forbidden,
      code: ERROR_CODE.auth.enforceAdmin,
      message: `This page is protected. Only HealCo Administrators may access it. If you believe this to be a mistake, please contact HealCo Support.`,
    },
    unableToSendEmailVerificationEmail: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.auth.unableToSendEmailVerificationEmail,
      message: `We are unable to send your email verification link at this time. ${CONTACT_MESSAGE}`,
    },
    unableToSendResetPasswordEmail: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.auth.unableToSendResetPasswordEmail,
      message: `We are unable to send your password reset link at this time. ${CONTACT_MESSAGE}`,
    },
    unableToResetPassword: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.auth.unableToResetPassword,
      message: `We are unable to reset your password at this time. ${CONTACT_MESSAGE}`,
    },
    unableToUpdateLastLogin: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.auth.unableToUpdateLastLogin,
      message: `Unable to update last login.`,
      doNotPass: true,
    },
    unableToLogIn: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.auth.unableToLogIn,
      message: `We are unable to log you in at this time. ${CONTACT_MESSAGE}`,
    },
    unableToVerifyEmail: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.auth.unableToLogIn,
      message: `We are unable to verify your email at this time. ${CONTACT_MESSAGE}`,
    },
    invalidResetPasswordLink: {
      status: HTTP_STATUS.clientError.badRequest,
      code: ERROR_CODE.auth.invalidResetPasswordLink,
      message: `Your reset link seems to be invalid. Please click the link in your email, or request another password reset.`,
    },
    resetPasswordLinkExpired: {
      status: HTTP_STATUS.clientError.notFound,
      code: ERROR_CODE.auth.resetPasswordLinkExpired,
      message: `Your reset link seems to have expired. Please request another password reset link to be sent to you.`,
    },
    emailVerificationLinkExpired: {
      status: HTTP_STATUS.clientError.notFound,
      code: ERROR_CODE.auth.emailVerificationLinkExpired,
      message: `Your verification link seems to have expired.`,
    },
    failedToVerifyTwoFactorAuth: {
      status: HTTP_STATUS.clientError.unauthorized,
      code: ERROR_CODE.auth.failedToVerifyTwoFactorAuth,
      message: `Failed to authorize Two-Factor Authorization. Please try again or request a new code.`,
    },
    failedToVerifySecurityAuth: {
      status: HTTP_STATUS.clientError.unauthorized,
      code: ERROR_CODE.auth.failedToVerifySecurityAuth,
      message: `Failed to authorize user. Please try again.`,
    },
  },
  account: {
    unableToCreateAccount: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.account.unableToCreateAccount,
      message: `We are unable to create a new HealCo account at this time. ${CONTACT_MESSAGE}`,
    },
    unableToUpdateProvider: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.account.unableToUpdateProvider,
      message: `We are unable to update your information right now. ${CONTACT_MESSAGE}`,
    },
    duplicateEmail: {
      status: HTTP_STATUS.clientError.unauthorized,
      code: ERROR_CODE.account.duplicateEmail,
      message: `There is already an account associated with the email you provided. (Do you already have an account?)`,
      isInformation: true,
    },
  },
  questionnaire: {
    unableToGetQuestionnaire: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.questionnaire.unableToGetQuestionnaire,
      message: `We are unable to retrieve some necessary data for this page. ${CONTACT_MESSAGE}`,
    },
    unableToSubmitQuestionnaire: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.questionnaire.unableToSubmitQuestionnaire,
      message: `We are unable to submit your data at this time. ${CONTACT_MESSAGE}`,
    },
  },
  prescription: {
    unableToGetPrescription: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.prescription.unableToGetPrescription,
      message: `We are unable to retrieve some necessary data for this page. ${CONTACT_MESSAGE}`,
    },
    unableToPostPrescription: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.prescription.unableToPostPrescription,
      message: `We are unable to post your prescription at this time. ${CONTACT_MESSAGE}`,
    },
  },
  sendgrid: {
    unableToCreateContact: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.sendgrid.unableToCreateContact,
      message: `Unable to create SendGrid contact.`,
      doNotPass: true,
    },
    unableToCreateContactMailingList: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.sendgrid.unableToCreateContactMailingList,
      message: `We are unable to add you to our mailing list at this time. ${CONTACT_MESSAGE}`,
    },
    unableToSendEmail: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.sendgrid.unableToSendEmail,
      message: `Unable to send SendGrid Email`,
      doNotPass: true,
    },
    invalidTransactionalId: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.sendgrid.invalidTransactionalId,
      message: `There is an error in a SendGrid Transactional Template ID format.`,
      doNotPass: true,
    },
  },
  twilio: {
    failedToSendText: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.twilio.unableToSendText,
      message: `Failed to send text message.`,
    },
  },
  stripe: {
    unableToCreateCustomer: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.stripe.unableToCreateCustomer,
      message: `Unable to create Stripe customer`,
      doNotPass: true,
    },
    unableToFindCustomer: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.stripe.unableToFindCustomer,
      message: `Unable to find Stripe customer`,
      doNotPass: true,
    },
    unableToCheckout: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.stripe.unableToCheckout,
      message: `We are unable to checkout your cart right now. ${CONTACT_MESSAGE}`,
    },
    unableToFindSession: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.stripe.unableToFindSession,
      message: `We are unable to verify your checkout right now. ${CONTACT_MESSAGE}`,
    },
  },
  db: {
    transactionFailed: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.db.transactionFailed,
      message: `Unable to complete SQL transaction.`,
      doNotPass: true,
    },
    auditEntryFailed: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.db.auditEntryFailed,
      message: `Unable to add to Audit table.`,
      doNotPass: true,
    },
    getReferencesFailed: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.db.getReferencesFailed,
      message: `We are unable to retrieve some necessary data for this page. ${CONTACT_MESSAGE}`,
    },
    insertIntoFailed: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.db.insertIntoFailed,
      message: `Unable to insert into table.`,
      doNotPass: true,
    },
  },
  careCoordination: {
    therapyNavigationIntake: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.careCoordination.unableToScheduleNavigation,
      message: `We are unable to schedule a navigation call right now. ${CONTACT_MESSAGE}`,
    },
  },
  misc: {
    gatewayTimeout: {
      status: HTTP_STATUS.serverError.gatewayTimeout,
      code: ERROR_CODE.misc.gatewayTimeout,
      message: `We are unable to connect to the HealCo servers at the moment. ${CONTACT_MESSAGE}`,
    },
    unableToAddFeedback: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.misc.unableToAddFeedback,
      message: `We are unable to accept feedback at this time. ${CONTACT_MESSAGE}`,
    },
    unableToGetFeedback: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.misc.unableToGetFeedback,
      message: `We are unable to get user feedback at this time. ${CONTACT_MESSAGE}`,
    },
    contactUsFailed: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.misc.contactUsFailed,
      message: `We are unable to receive inquiries at this time. ${CONTACT_MESSAGE}`,
    },
    wrvuCalculatorFailed: {
      status: HTTP_STATUS.serverError.internal,
      code: ERROR_CODE.misc.wrvuCalculatorFailed,
      message: `We are unable to send your results at this time. ${CONTACT_MESSAGE}`,
    },
  },
};

interface ErrorConstant {
  auth: {
    unverifiedEmail: HealcoError;
    incorrectPassword: HealcoError;
    deactivatedAccount: HealcoError;
    enforceAdmin: HealcoError;
    unableToSendEmailVerificationEmail: HealcoError;
    unableToSendResetPasswordEmail: HealcoError;
    unableToResetPassword: HealcoError;
    unableToLogIn: HealcoError;
    unableToVerifyEmail: HealcoError;
    invalidResetPasswordLink: HealcoError;
    resetPasswordLinkExpired: HealcoError;
    unableToUpdateLastLogin: HealcoError;
    emailVerificationLinkExpired: HealcoError;
    failedToVerifyTwoFactorAuth: HealcoError;
    failedToVerifySecurityAuth: HealcoError;
  };
  account: {
    unableToCreateAccount: HealcoError;
    unableToUpdateProvider: HealcoError;
    duplicateEmail: HealcoError;
  };
  questionnaire: {
    unableToGetQuestionnaire: HealcoError;
    unableToSubmitQuestionnaire: HealcoError;
  };
  prescription: {
    unableToGetPrescription: HealcoError;
    unableToPostPrescription: HealcoError;
  };
  sendgrid: {
    unableToCreateContact: HealcoError;
    unableToCreateContactMailingList: HealcoError;
    unableToSendEmail: HealcoError;
    invalidTransactionalId: HealcoError;
  };
  twilio: {
    failedToSendText: HealcoError;
  };
  stripe: {
    unableToCreateCustomer: HealcoError;
    unableToFindCustomer: HealcoError;
    unableToCheckout: HealcoError;
    unableToFindSession: HealcoError;
  };
  db: {
    transactionFailed: HealcoError;
    auditEntryFailed: HealcoError;
    getReferencesFailed: HealcoError;
    insertIntoFailed: HealcoError;
  };
  careCoordination: {
    therapyNavigationIntake: HealcoError;
  };
  misc: {
    gatewayTimeout: HealcoError;
    unableToAddFeedback: HealcoError;
    unableToGetFeedback: HealcoError;
    contactUsFailed: HealcoError;
    wrvuCalculatorFailed: HealcoError;
  };
}
