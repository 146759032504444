import { Col, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { HHeading } from "../../HHeading";

/*
 *Contact User Form
 - - - - - - - - - -
 Form input for a user's provider name (optional), first name (optional), last name (optional), and content to be used in <ContactUser/> in order to send a HealCo branded email.

 */
export const ContactUserForm = () => {
  return (
    <>
      <HHeading size={4} text="Contact User" />
      <Row gutter={8}>
        <Col span={24}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input required />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="email"
            label={"Email"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="email" required />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="subject"
            label={"Subject"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input required />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="content"
            label="Content"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea required />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
