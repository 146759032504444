/*
 * Routes
 - - - - - - - - - - - - - -
 This object contains all API routes for the HealCo backend.
 */
export const API_ROUTE: RouteConstant = {
  admin: {
    patient: "/admin/patient",
    provider: "/admin/provider",
    stats: "/admin/stats",
    contactUser: "/admin/contact-user",
  },
  account: {
    signupProvider: "/no-auth/signup/provider",
    signupPatient: "/no-auth/signup/patient",
    updateProvider: "/provider/",
    notification: "/notification",
    sendVerifyPhone: "/twilio/send-verification",
    checkVerifyPhone: "/twilio/check-verification",
    twoFactorSetup: "/two-factor",
    changeEmail: "/changeEmail",
    changePassword: "/changePassword",
    changePhone: "/changePhone",
  },
  audit: {
    accessEphi: "/audit/epi/accessEphi",
    continueEphi: "/audit/epi/continueEphi",
    logoutEphi: "/audit/epi/logoutEphi",
  },
  careCoordination: {
    therapyNavigationIntake: "/no-auth/therapy-navigation",
  },
  prescription: {
    list: "/prescription",
    approve: "/prescription/approve",
    reject: "/prescription/reject",
    tracking: "/prescription/tracking",
    viewAddress: "/prescription/address",
    getMedicationFaqs: "/no-auth/medication-faq",
    subscription: "/prescription/prescription",
  },
  property: {
    introduction: "/propertyIntroduction/",
  },
  pricing: {
    medications: "/no-auth/pricing/medications",
  },
  questionnaire: {
    getQuestionnaire: "/no-auth/questionnaire",
    submitQuestionnaire: "/no-auth/questionnaire/submit",
    confirmQuestionnaire: "/no-auth/questionnaire/confirm",
  },
  auth: {
    getIdentity: "/no-auth/identity",
    forgotPassword: "/no-auth/password/forgot",
    login: "/no-auth/login",
    logout: "/logout",
    resetPassword: "/no-auth/password/reset",
    sendEmailVerification: "/no-auth/email/verify/:user_id",
    verifyEmailUser: "/no-auth/email/verify/user",
    verifyUpdateEmail: "/verify-update-email",
    twoFactorVerify: "/no-auth/two-factor/verify",
    twoFactorVerifyCheck: "/no-auth/two-factor/verify-check",
    securityCheck: "/security",
  },
  misc: {
    addToMailingListConfirm: "/no-auth/mailing-list/confirm",
    getRefTables: "/no-auth/refTables",
    contactUs: "/no-auth/contact",
    wrvuCalculator: "/no-auth/wrvu-calculator",
    requestLocation: "/request-location",
    addToMailingList: "/no-auth/mailing-list",
  },
  stripe: {
    redirect: "/visit-stripe-payouts",
    medicationCheckout: "/stripe/checkout/medication",
    viewPaymentHistory: "/stripe/payment/history",
    customerPortal: "/stripe/customer-portal",
    subscriptionInfo: "/stripe/subscription-info",
    webhook: {
      reorderMedication: "/no-auth/stripe/reorder",
      upcomingInvoice: "/no-auth/stripe/upcoming-invoice",
      cancelSubscription: "/no-auth/stripe/cancel-subscription",
    },
  },
  upload: {
    fileUploader: "/no-auth/fileUploader",
  },
};

interface RouteConstant {
  admin: {
    patient: string;
    provider: string;
    stats: string;
    contactUser: string;
  };
  account: {
    signupProvider: string;
    signupPatient: string;
    updateProvider: string;
    notification: string;
    sendVerifyPhone: string;
    checkVerifyPhone: string;
    twoFactorSetup: string;
    changeEmail: string;
    changePassword: string;
    changePhone: string;
  };
  audit: {
    accessEphi: string;
    continueEphi: string;
    logoutEphi: string;
  };
  auth: {
    getIdentity: string;
    forgotPassword: string;
    login: string;
    logout: string;
    resetPassword: string;
    sendEmailVerification: string;
    verifyEmailUser: string;
    verifyUpdateEmail: string;
    twoFactorVerify: string;
    twoFactorVerifyCheck: string;
    securityCheck: string;
  };
  careCoordination: {
    therapyNavigationIntake: string;
  };
  prescription: {
    list: string;
    approve: string;
    reject: string;
    tracking: string;
    viewAddress: string;
    getMedicationFaqs: string;
    subscription: string;
  };
  property: {
    introduction: string;
  };
  pricing: {
    medications: string;
  };
  questionnaire: {
    getQuestionnaire: string;
    submitQuestionnaire: string;
    confirmQuestionnaire: string;
  };
  misc: {
    addToMailingListConfirm: string;
    getRefTables: string;
    contactUs: string;
    wrvuCalculator: string;
    requestLocation: string;
    addToMailingList: string;
  };
  stripe: {
    redirect: string;
    medicationCheckout: string;
    viewPaymentHistory: string;
    customerPortal: string;
    subscriptionInfo: string;
    webhook: {
      reorderMedication: string;
      upcomingInvoice: string;
      cancelSubscription: string;
    };
  };
  upload: {
    fileUploader: string;
  };
}
