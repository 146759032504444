import React from "react";
import { LottiePlayer } from "../..";
import loading from "../../../assets/animations/loading.json";
import { HLink } from "../../HLink";
import styles from "./loadingPage.module.scss";

/*
 * Loading Page
 - - - - - - - - -
 Loading page that appears when an actual page is loading.
 */
export const LoadingPage = ({ isTimedOut, isError }: LoadingPageProps) => (
  <div className={styles.loadingPage}>
    <div className={styles.loadingAnimation} style={{}}>
      <LottiePlayer
        src={loading}
        height={200}
        width={200}
        loop={!isTimedOut || !isError}
      />
      {isTimedOut ? (
        <div>This page is taking too long to load. Please try again later.</div>
      ) : isError ? (
        <div>There was an error loading. Please refresh and try again.</div>
      ) : (
        <>
          <div>Loading...</div>
          <div className={styles.attribution}>
            <HLink to="https://lottiefiles.com/43039-medicine">
              Md Maruf @LottieFiles
            </HLink>
          </div>
        </>
      )}
    </div>
  </div>
);

interface LoadingPageProps {
  isTimedOut?: boolean;
  isError?: boolean;
}
