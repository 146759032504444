import GoogleMapReact from "google-map-react";
import React, { useEffect } from "react";

export default ({
  showAll,
  userGeometry,
  mapGeometry,
  results,
  filteredResults,
  interactive,
}: HeatMapProps) => {
  const data = results?.map((e) => {
    return { lat: e.geometry.x, lng: e.geometry.y, weight: e.weight };
  });

  const filteredData = filteredResults?.map((e) => {
    return { lat: e.geometry.x, lng: e.geometry.y };
  });

  const heatMapData = {
    positions: showAll ? data : filteredData,
    options: {
      radius: 30,
      opacity: 0.6,
    },
  };

  useEffect(() => {
    heatMapData.positions = results?.map((e) => {
      return { lat: e.geometry.x, lng: e.geometry.y, weight: e.weight };
    });
  }, [heatMapData, results]);

  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyAJboZPwCqOpUIe5OswI08uPewq_R_SuxY",
        }}
        defaultZoom={5}
        heatmapLibrary={heatMapData?.positions ? true : false}
        heatmap={heatMapData?.positions ? heatMapData : undefined}
        defaultCenter={{ lat: userGeometry.x, lng: userGeometry.y }}
        center={{ lat: mapGeometry.x, lng: mapGeometry.y }}
        options={{
          disableDefaultUI: !interactive,
          scrollwheel: interactive,
          maxZoom: interactive ? 12 : 5,
        }}
      />
    </>
  );
};

interface HeatMapProps {
  showAll: boolean;
  userGeometry: HealcoGeometry;
  mapGeometry: HealcoGeometry;
  results: Array<{ geometry: HealcoGeometry; weight: number }>;
  filteredResults: Array<{
    geometry: HealcoGeometry;
  }>;
  interactive: boolean;
}
