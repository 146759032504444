import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import React from "react";
import { HLink } from "..";

/*
 * Conditional Button
 - - - - - - - - - - -
 Dynamically changes a Button and its link depending on a passed in condition
 TODO1: Extend to handle variable conditions / links (through use of comparison callback?)
 @param condition:
 @param primary: primary link / text to display
 @param secondary: secondary link / text to display
 */
export const ConditionalButton = ({
  isCondition,
  primaryLink,
  secondaryLink,
  ...props
}: ConditionalButton) => {
  return (
    <>
      {isCondition ? (
        <Button {...props}>
          <HLink to={primaryLink.link}>{primaryLink.text}</HLink>
        </Button>
      ) : (
        <Button {...props}>
          <HLink to={secondaryLink.link}>{secondaryLink.text}</HLink>
        </Button>
      )}
    </>
  );
};

interface ConditionalButton extends ButtonProps {
  isCondition: boolean;
  primaryLink: ConditionalButtonLink;
  secondaryLink: ConditionalButtonLink;
}

interface ConditionalButtonLink {
  text: string;
  link: string;
}
