import { Image, Layout } from "antd";
import React from "react";
import Helmet from "react-helmet";
import { HFooter, HHeader } from "../../../../components";
import { SectionContent } from "../../../Landing/components";
import { AuthContainer, AuthLinks } from "./components";
import styles from "./authTemplate.module.scss";
import { healcoBeanDark } from "../../../../constants/cdn";

const { Content, Header, Footer } = Layout;

/*
 * Auth Template
 - - - - - - - - - -
 Template used by all Auth pages
 @param title: Meta title
 */
export const AuthTemplate = ({
  children,
  metaTitle,
  title,
  links,
  noContainer,
}: AuthTemplate) => {
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <Layout className={styles.authTemplate}>
        <Header>
          <HHeader doNotStick />
        </Header>
        <Content>
          {noContainer ? (
            <SectionContent>{children}</SectionContent>
          ) : (
            <>
              <Image src={healcoBeanDark} alt="HealCo" preview={false} />
              <AuthContainer title={title}>{children}</AuthContainer>
            </>
          )}
          {links && <AuthLinks>{links}</AuthLinks>}
        </Content>
        <Footer>
          <HFooter />
        </Footer>
      </Layout>
    </>
  );
};

interface AuthTemplate {
  children: React.ReactNode;
  metaTitle: string;
  title?: string;
  links?: React.ReactNode;
  noContainer?: boolean;
}
