import React, { useState, useCallback, useEffect } from "react";
import {
  PrescriptionInfoModal,
  PrescriptionFileModal,
} from "../PrescriptionModal";
import { Table, Row, Col, Button, Form, Input, Typography, List } from "antd";
import { ExpandableTableData } from "../../../Admin/shared";
import TrackingNumber from "../TrackingNumber";
import { alphabeticalSort, dateSort } from "../../../../../util/sort";
import moment from "moment";
import { UI, REF_TABLE, API_ROUTE, FETCH } from "../../../../../constants";
import {
  acceptedPrescriptionColumns,
  rejectedPrescriptionColumns,
  incompletePrescriptionColumns,
} from "../../../Admin/shared/columns";
import { handleFetch, isMobile, makeFullAddress } from "../../../../../util";
import { useHReference, useToggle } from "../../../../../hooks";
import { SearchOutlined } from "@ant-design/icons";
import queryString from "query-string";
import momentTz from "moment-timezone";
import expandableTableDataStyles from "../../../Admin/shared/ExpandableTableData/expandableTableData.module.scss";
import { HelpIcon } from "../../../../../components";
import styles from "./prescriptionTable.module.scss";

const PrescriptionTable = ({
  userIsAdmin = false,
  userIsPatient,
  userIsHPro,
  userIsPharmacist,
  isSecurityChecked = true,
  trackingDataCondition = false,
  rejectionDataCondition = false,
}: PrescriptionTableProps) => {
  const [fetchFailed, toggleFetch] = useToggle();
  const [isLoading, toggleLoading] = useToggle();
  const [currentPage, setCurrentPage] = useState<HealcoPrescription[]>([]);
  const [searchParam, setSearchParam] = useState<{ [key: string]: string }>();
  const [filterPage, setFilterPage] = useState<HealcoPrescription[]>([]);
  const [currPrescription, setCurrPrescription] = useState<
    HealcoPrescription
  >();
  const [isModalVisible, toggleModal] = useToggle();
  const [addressDictionary, setAddressDictionary] = useState<{
    [key: string]: PrescriptionAddress;
  }>();
  const qs = queryString.parse(window.location.search);

  const { [REF_TABLE.treatmentCategory]: treatmentCategory } = useHReference([
    REF_TABLE.usState,
    REF_TABLE.treatmentCategory,
  ]);

  const treatmentCategoryFilter = treatmentCategory?.map((treatment) => {
    return { text: treatment.name, value: treatment.id };
  });

  const getPrescriptions = useCallback(
    async (params) => {
      toggleLoading();
      if (isSecurityChecked)
        await handleFetch<void, HealcoPrescription[]>({
          route: `${API_ROUTE.prescription.list}?${queryString.stringify(
            params,
          )}`,
          method: FETCH.get,
          onSuccess: (result) => setCurrentPage(result),
          onFailure: () => toggleFetch(),
        });
      toggleLoading();
    },
    [isSecurityChecked, toggleFetch, toggleLoading],
  );

  const getUpdate = () => {
    getPrescriptions(qs);
  };

  // disabling warning because react-router will actually re-render the component in case of querystring changing
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void getUpdate(), [getPrescriptions, window.location.search]);

  const getPrescriptionAddress = async (
    prescription_id: string,
    session_id: string,
  ) => {
    await handleFetch<void, PrescriptionAddress>({
      route: `${API_ROUTE.prescription.viewAddress}?sessionId=${session_id}`,
      method: FETCH.get,
      onSuccess: (result) =>
        setAddressDictionary({
          ...addressDictionary,
          [prescription_id]: result,
        }),
    });
  };

  const FilterProps = (
    filterField: string,
    filteredData: HealcoPrescription[] | undefined,
  ) => {
    return {
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <Form>
            <Input
              placeholder="Search"
              value={searchParam?.[filterField]}
              onChange={(e) => {
                const currValue = e.target.value;
                setSearchParam({ ...searchParam, [filterField]: currValue });
              }}
            />
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                setFilterPage(filteredData ? filteredData : []);
              }}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button
              onClick={() => setFilterPage([])}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Form>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    };
  };

  return (
    <>
      <PrescriptionInfoModal
        mode={qs.tab as string}
        getUpdate={getUpdate}
        isModalVisible={isModalVisible}
        setIsModalVisible={toggleModal}
        prescription={currPrescription}
        userIsHPro={userIsHPro || userIsAdmin}
        userIsPharmacist={userIsPharmacist}
      />
      <Button onClick={getUpdate}>Refresh List</Button>
      <div style={{ marginBottom: "4rem" }}>
        <Table
          rowClassName={(record, index) =>
            index % 2 ? styles.evenRow : styles.oddRow
          }
          loading={isLoading}
          scroll={{ y: 1000, x: 800 }}
          pagination={{ position: ["topCenter", "bottomCenter"] }}
          dataSource={filterPage?.length > 0 ? filterPage : currentPage || []}
          expandable={
            !userIsPatient
              ? {
                  defaultExpandAllRows: true,
                  expandRowByClick: true,
                  expandedRowRender: (record) => (
                    <Row key={record.id} gutter={[8, 8]}>
                      <ExpandableTableData
                        columnProps={{ xs: 24, lg: 6 }}
                        columnName="Prescription Id"
                        isSensitiveData={userIsAdmin ? true : false}
                        extraColumn={[
                          { value: record.name, label: "Patient Name" },
                          ...[userIsAdmin ? { value: record.email } : {}],
                          ...[
                            record.dateOfBirth
                              ? {
                                  value: momentTz
                                    .tz(record.dateOfBirth, "America/New_York")
                                    .format(UI.datetimeFormat.date),
                                  label: "Date of birth",
                                }
                              : {},
                          ],
                          ...[
                            record.gender?.name
                              ? {
                                  value: record.gender?.name || " - ",
                                  label: "Gender",
                                }
                              : {},
                          ],
                          ...[
                            record.phone
                              ? {
                                  value: record.phone,
                                  label: "Phone",
                                }
                              : {},
                          ],
                        ]}
                        columnValue={record.id}
                        textProps={{ copyable: true }}
                      />

                      {userIsAdmin &&
                        (qs.tab === "approved" ||
                          qs.tab === "rejected" ||
                          qs.tab === "completed") && (
                          <ExpandableTableData
                            columnProps={{ xs: 24, lg: 6 }}
                            columnName="Provider"
                            extraColumn={[{ value: record.providerUser.id }]}
                            columnValue={record.providerUser.name}
                            textProps={{ copyable: true }}
                          />
                        )}

                      <Col
                        xs={24}
                        lg={6}
                        style={
                          isMobile() ? { borderBottom: "1px solid black" } : {}
                        }
                      >
                        <Button
                          onClick={() => {
                            setCurrPrescription(record);
                            toggleModal();
                          }}
                        >
                          {userIsPharmacist
                            ? "View Request"
                            : "View Questionnaire"}
                        </Button>
                        {trackingDataCondition && (
                          <>
                            {record.uploadedFiles ? (
                              <PrescriptionFileModal
                                uploadedFiles={record.uploadedFiles}
                              />
                            ) : (
                              <div className={expandableTableDataStyles.column}>
                                <Typography.Text strong>
                                  Prescription
                                </Typography.Text>
                                <Typography.Text type="danger">
                                  Prescription sent externally
                                  <HelpIcon text="Our provider has either called or sent in this prescription outside of HealCo. Please contact us if you think this is a mistake." />
                                </Typography.Text>
                              </div>
                            )}
                          </>
                        )}
                      </Col>
                      {(userIsAdmin || userIsPharmacist) &&
                        (qs.tab === "approved" || qs.tab === "completed") && (
                          <Col xs={24} lg={12}>
                            <div>
                              <Typography.Text strong>
                                Shipping Address
                              </Typography.Text>
                            </div>
                            <div>
                              <Typography.Text copyable>
                                {addressDictionary?.[record.id] &&
                                  makeFullAddress({
                                    addressLineOne:
                                      addressDictionary?.[record.id].line1,
                                    addressLineTwo:
                                      addressDictionary?.[record.id].line2,
                                    city: addressDictionary?.[record.id].city,
                                    usState:
                                      addressDictionary?.[record.id].state,
                                    zipcode:
                                      addressDictionary?.[record.id]
                                        .postal_code,
                                  })}
                              </Typography.Text>
                            </div>
                            <b>History</b>
                            <List
                              split={false}
                              className={styles.listBorderBottom}
                              dataSource={record.orderHistory}
                              pagination={{
                                pageSize: 3,
                                position: "bottom",
                              }}
                              renderItem={(item) => (
                                <TrackingNumber
                                  orderHistory={item}
                                  getUpdate={getUpdate}
                                  canEdit={
                                    (userIsAdmin || userIsPharmacist) &&
                                    qs.tab === "approved"
                                  }
                                />
                              )}
                            />
                          </Col>
                        )}

                      {rejectionDataCondition && (
                        <ExpandableTableData
                          columnProps={{ xs: 24, lg: 6 }}
                          columnName="Rejection Reason"
                          columnValue={
                            record.rejectionReason.other?.[0] ||
                            record.rejectionReason.name
                          }
                        />
                      )}
                    </Row>
                  ),
                }
              : undefined
          }
          columns={[
            {
              key: "id",
              title: "Id",
              sorter: (a, b) => alphabeticalSort(a.key, b.key),
              render: (item: HealcoPrescription) => <span>{item.key}</span>,
              ...FilterProps(
                "key",
                currentPage?.filter(
                  (prescription) =>
                    searchParam?.["key"] &&
                    prescription?.key
                      ?.toLowerCase()
                      ?.includes(searchParam?.["key"]),
                ),
              ),
            },
            ...(userIsPatient || userIsAdmin
              ? [
                  {
                    key: "pid",
                    title: "Prescription Id",
                    render: (item: HealcoPrescription) => (
                      <span>{item.id}</span>
                    ),
                    ...FilterProps(
                      "id",
                      currentPage?.filter(
                        (prescription) =>
                          searchParam?.["id"] &&
                          prescription?.id
                            ?.toLowerCase()
                            ?.includes(searchParam?.["id"]),
                      ),
                    ),
                  },
                ]
              : []),
            ...(userIsAdmin
              ? []
              : [
                  {
                    key: "name",
                    title: "Patient Name",
                    sorter: (a: HealcoPrescription, b: HealcoPrescription) =>
                      alphabeticalSort(a.name, b.name),
                    render: (item: HealcoPrescription) => (
                      <span>{item.name}</span>
                    ),
                    ...FilterProps(
                      "name",
                      currentPage?.filter(
                        (prescription) =>
                          searchParam?.["name"] &&
                          prescription?.name
                            ?.toLowerCase()
                            ?.includes(searchParam?.["name"]),
                      ),
                    ),
                  },
                ]),
            {
              key: "treatmentCategory",
              title: "Treatment Category",
              sorter: (a, b) =>
                alphabeticalSort(
                  a.treatmentCategory.name,
                  b.treatmentCategory.name,
                ),
              render: (item: HealcoPrescription) => item.treatmentCategory.name,
              filters: treatmentCategoryFilter,
              filterMultiple: false,
              onFilter: (value, record) =>
                record?.treatmentCategory?.name.indexOf(value as string) === 0,
            },
            {
              key: "createdOn",
              title: "Form Completed On",
              sorter: (a, b) => dateSort(a.createdOn, b.createdOn),
              render: (item: HealcoPrescription) =>
                moment(item.createdOn).format(UI.datetimeFormat.date),
            },
            ...((userIsPatient || userIsAdmin || userIsPharmacist) &&
            qs.tab === "approved"
              ? acceptedPrescriptionColumns
              : []),
            ...(qs.tab === "rejected" ? rejectedPrescriptionColumns : []),
            ...((userIsPatient || userIsAdmin) && qs.tab === "incomplete"
              ? incompletePrescriptionColumns({
                  toggleModal,
                  setCurrPrescription,
                })
              : []),
          ]}
          onExpand={(isActive, item: HealcoPrescription) => {
            if (
              isActive &&
              qs.tab === "approved" &&
              (userIsAdmin || userIsPharmacist) &&
              !addressDictionary?.[item.id]
            )
              getPrescriptionAddress(item.id, item.sessionId);
          }}
          locale={{
            emptyText: fetchFailed ? (
              <Button onClick={() => getPrescriptions(qs)}>Fetch again</Button>
            ) : (
              "No results to display"
            ),
          }}
        />
      </div>
    </>
  );
};

export default PrescriptionTable;

interface PrescriptionTableProps {
  userIsAdmin?: boolean;
  userIsPatient: boolean;
  userIsHPro: boolean;
  userIsPharmacist: boolean;
  isSecurityChecked?: boolean;
  trackingDataCondition?: boolean;
  rejectionDataCondition?: boolean;
}
