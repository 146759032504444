import { useState } from "react";
import { SessionStorage } from "../../util";

/*
 * Use Session State
 - - - - - - - - - - -
 Works exactly the same way as useState, but the state will persist
 throughout the entire session.
 @param key: session storage key
 */
export const useSessionState = <T>(
  key: string,
): [T | null, (newValue: T) => void] => {
  const [state, setState] = useState<T | null>(SessionStorage.getItem(key));

  const setSessionValue = (newValue: T) => {
    setState(newValue);
    SessionStorage.setItem<T>(key, newValue);
  };

  return [state, setSessionValue];
};
