import { CheckCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, message, notification, Radio, Row } from "antd";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HHeading, HLink, LoadingPage } from "../../../../../components";
import {
  getFetchResponse,
  history,
  redirectoToCheckout,
  triggerIdentityRefresh,
  isHealcoRole,
  updateQs,
} from "../../../../../util";
import styles from "./membership.module.scss";
import { PATH } from "../../../../../constants";
import { hGreen, hDark } from "../../../../../constants/cdn";
import { useToggle } from "../../../../../hooks";

const hostMemberships = [
  {
    planType: "Membership",
    price: 60,
    duration: 1,
  },
];
const features = [
  "Access to our virtual network of specialists",
  "Personalized marketing kit",
  "Unlimited introductions with interested providers",
];

const guestFeatures = [
  "Search through our full selection of medical spaces",
  "Built-in chat features with property owners and providers",
  "Send free introductions to providers whose space you're interested in",
];

const Membership = ({
  profile,
  identity,
}: {
  profile: HealcoProfile;
  identity: HealcoUserIdentity;
}) => {
  const [isLoading, toggleLoading] = useToggle();
  //* TODO3 use better or create isHost / isGuest profile.
  const userIsHost = profile?.billing?.healcoPlan;
  // const userIsGuest = profile?.provider?.specialty;
  const userIsAdmin = isHealcoRole(identity, "healcoAdmin");
  const qs = queryString.parse(window.location.search);
  const location = useLocation();
  const [plan, setPlan] = useState<HostPlan>(hostMemberships[0]);
  const showStripeMembership =
    profile?.billing?.healcoPlan === "standard" ||
    profile?.billing?.healcoPlan === "canceled" ||
    profile?.billing?.stripeSubscription;

  useEffect(() => {
    const checkoutSession = async () => {
      if (qs.session_id) {
        try {
          const resp = await getFetchResponse<HealcoResponse<any>>(
            fetch("/api/stripe/checkout-session", {
              method: "POST",
              body: JSON.stringify({
                sessionId: qs.session_id,
                organizationId: qs.organizationId,
              }),
              headers: { "Content-Type": "application/json" },
            }),
          );
          if (resp.success) {
            updateQs("session_id");
            notification.success({
              message: "HealCo Plan",
              description: "Thank you signing up with HealCo!",
            });
            if (!userIsAdmin) {
              history.push(`${location.pathname}?tab=billing`);
              triggerIdentityRefresh();
            }
          }
        } catch (e) {
          message.error("Something went wrong. Please try again later");
        }
      }
    };
    checkoutSession();
  }, [qs.session_id, location.pathname, userIsAdmin, qs.organizationId]);

  const signupMembership = async () => {
    if (userIsAdmin && !qs.organizationId)
      message.error("Missing Organization Id");

    try {
      const resp = await getFetchResponse<HealcoResponse<any>>(
        fetch("/api/stripe/membership", {
          method: "POST",
          body: JSON.stringify({
            userId: userIsAdmin ? qs.userId : profile.id,
            organizationId: userIsAdmin
              ? qs.organizationId
              : profile.organization.id,
          }),
          headers: { "Content-Type": "application/json" },
        }),
      );
      redirectoToCheckout({ sessionId: resp.result });
    } catch (e) {
      message.error(
        <>
          <p>Error setting up membership: {e.message}</p>
          <p>Please try again later or contact admin.</p>
        </>,
      );
    }
  };

  const customerPortal = async () => {
    toggleLoading();
    try {
      const resp = await getFetchResponse<HealcoResponse<any>>(
        fetch(`/api/stripe/customer-portal/${profile.organization.id}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }),
      );
      window.open(resp.result);
    } catch (e) {
      message.error(`Error retrieving customer info: ${e.message}`);
    } finally {
      toggleLoading();
    }
  };

  if (isLoading) return <LoadingPage />;
  return (
    <div style={{ marginBottom: "1rem" }}>
      <HHeading size={2} text="HealCo Membership" centered />
      <Row className={styles.memberContainer} gutter={[8, 16]}>
        {userIsHost && (
          <>
            <Col xs={24} sm={12}>
              {showStripeMembership ? (
                <div className={styles.membershipCard}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p className={styles.status}>
                      Membership Status: {profile.billing.healcoPlan}
                    </p>
                    <img
                      className={styles.membershipIcon}
                      src={hGreen}
                      alt="HealCo-Icon"
                    />
                    <ul>
                      {features.map((ft, i) => (
                        <li key={i}>
                          <CheckCircleOutlined /> {ft}
                        </li>
                      ))}
                      <li>
                        <QuestionCircleOutlined /> For more property listings
                        please contact us for a special rate!{" "}
                        <HLink newTab to="mailto:support@healco.us">
                          support@healco.us
                        </HLink>
                      </li>
                    </ul>
                  </div>
                  <Button
                    style={{ margin: "auto" }}
                    className={styles.selectBtn}
                    shape="round"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => customerPortal()}
                  >
                    View/Update Plan
                  </Button>
                </div>
              ) : (
                <div className={styles.membershipCard}>
                  <img
                    className={styles.membershipIcon}
                    src={hGreen}
                    alt="HealCo-Icon"
                  />
                  <HHeading size={4} centered text="Listing your space?" />
                  <h2>Choose your plan</h2>
                  <Radio.Group
                    onChange={(e) => setPlan(e.target.value)}
                    value={plan}
                  >
                    {hostMemberships.map((plan) => (
                      <Radio value={plan} key={plan.planType}>
                        <p>${plan.price} / month</p>
                      </Radio>
                    ))}
                  </Radio.Group>
                  <ul>
                    {features.map((ft, i) => (
                      <li key={i}>
                        <CheckCircleOutlined /> {ft}
                      </li>
                    ))}
                  </ul>
                  <Button
                    className={styles.selectBtn}
                    shape="round"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => signupMembership()}
                  >
                    Checkout
                  </Button>
                </div>
              )}
            </Col>
            <Col xs={24} sm={12}>
              <div className={styles.membershipCard}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    className={styles.membershipIcon}
                    src={hDark}
                    alt="HealCo-Icon"
                  />
                  <HHeading size={4} centered text="Looking for space?" />
                  <ul>
                    {guestFeatures.map((ft, i) => (
                      <li key={i}>
                        <CheckCircleOutlined /> {ft}
                      </li>
                    ))}
                  </ul>
                </div>
                <HLink to={PATH.provider.properties} search="?tab=guest">
                  <Button
                    style={{ margin: "auto" }}
                    className={styles.selectBtn}
                    shape="round"
                  >
                    Apply to properties
                  </Button>
                </HLink>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default Membership;

interface HostPlan {
  planType: string;
  price: number;
  duration: number;
}
