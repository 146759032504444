import React from "react";
import { UI, PATH } from "../../../../../constants";
import moment from "moment";
import {
  ExclamationCircleTwoTone,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { history } from "../../../../../util";
import styles from "./columnStyles.module.scss";
import variables from "../../../../../styles/variables.module.scss";
import { Col, Row } from "antd";

export const acceptedPrescriptionColumns = [
  {
    key: "acceptedOn",
    title: "Accepted On",
    render: (item: HealcoPrescription) =>
      moment(item.acceptedOn).format(UI.datetimeFormat.date),
  },
  {
    key: "waitingResponse",
    title: "Action Required",
    sorter: (a: HealcoPrescription, b: HealcoPrescription) => {
      if (a.waitingResponse && b.waitingResponse) return 1;
      if (!a.waitingResponse || !b.waitingResponse) return -1;
      else return 0;
    },
    render: (item: HealcoPrescription) =>
      item.waitingResponse && (
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <ExclamationCircleTwoTone
              twoToneColor={variables.primary}
              style={{ fontSize: "1.5rem" }}
            />
            <p>New script request</p>
          </Col>
        </Row>
      ),
  },
];

export const rejectedPrescriptionColumns = [
  {
    key: "rejectionReason",
    title: "Rejection Reason",
    sorter: false,
    render: (item: HealcoPrescription) =>
      !item.rejectionReason.other
        ? item.rejectionReason.name
        : item.rejectionReason.id,
  },
  {
    key: "rejectedOn",
    title: "Rejected On",
    sorter: false,
    render: (item: HealcoPrescription) =>
      moment(item.rejectedOn).format(UI.datetimeFormat.date),
  },
];

export const incompletePrescriptionColumns = ({
  toggleModal,
  setCurrPrescription,
}: IncompletePrescriptionColumnsProps) => [
  {
    key: "action",
    title: "Action",
    render: (item: HealcoPrescription) => (
      <div className={styles.actionContainer}>
        <p
          title="View Questionnaire"
          onClick={() => {
            setCurrPrescription(item);
            toggleModal();
          }}
        >
          <svg
            className={styles.actionIcons}
            height="24"
            viewBox="0 0 64 64"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m61 19-16-16v16z" fill="#006df0" />
            <path
              d="m45 19v-16h-13-29v54l4.15 4 4.15-4 4.15 4 4.14-4 4.14 4 4.14-4 4.13 3.981.02.019 4.14-4 4.14 4 4.14-4 4.15 4 4.13-4 4.14 4 4.14-4v-38z"
              fill="#efeadd"
            />
            <g fill="#2488ff">
              <path d="m17 27h30v2h-30z" />
              <path d="m13 33h2v2h-2z" />
              <path d="m13 27h2v2h-2z" />
              <path d="m17 33h30v2h-30z" />
              <path d="m13 39h2v2h-2z" />
              <path d="m17 39h30v2h-30z" />
            </g>
            <path
              d="m16 12a4 4 0 0 0 -4-4h-4v13h2v-5h1.382l2.723 5.447 1.79-.894-2.421-4.842a4 4 0 0 0 2.526-3.711zm-4 2h-2v-4h2a2 2 0 0 1 0 4z"
              fill="#ed1c24"
            />
            <path
              d="m24.136 8.496-2.636 4.519-2.636-4.519-1.728 1.008 3.206 5.496-3.206 5.496 1.728 1.008 2.636-4.519 2.636 4.519 1.728-1.008-3.206-5.496 3.206-5.496z"
              fill="#ed1c24"
            />
            <path d="m32 50h24v2h-24z" fill="#2488ff" />
            <path
              d="m3 30v27l4.15 4 4.15-4 4.15 4 4.14-4 4.14 4 3.67-3.545c-14.2-3.885-24.4-14.698-24.4-27.455z"
              fill="#d1cdc1"
            />
          </svg>
        </p>
        <ShoppingCartOutlined
          title="Checkout"
          className={styles.actionIcons}
          onClick={() =>
            history.push(
              `${PATH.auth.questionnaire}?patientQuestionnaireId=${item.id}`,
            )
          }
        />
      </div>
    ),
  },
];

interface IncompletePrescriptionColumnsProps {
  toggleModal: () => void;
  setCurrPrescription: React.Dispatch<
    React.SetStateAction<HealcoPrescription | undefined>
  >;
}
