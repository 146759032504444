import React from "react";
import { Col, Form, Input, Row } from "antd";
import { DateForm, InputList } from "../General";

/*
 * Education Form
 - - - - - - - - - - -
 Form to get a provider's Education
 */
export const EducationForm = () => {
  return (
    <InputList
      name="education"
      formItemProps={{ label: "What is your educational background?" }}
      customInput={
        <Input.Group>
          <Row>
            <Col xs={24} sm={8}>
              <Form.Item name={["education", "institute"]} noStyle>
                <Input placeholder="Institute / University" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item name={["education", "degree"]} noStyle>
                <Input placeholder="Degree obtained" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item name={["education", "yearGraduated"]} noStyle>
                <DateForm
                  hasDay={false}
                  hasMonth={false}
                  yearPlaceholder="Year graduated"
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>
      }
    />
  );
};
