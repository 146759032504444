import React from "react";
import { antdFormValidator } from "../../../../../../util";
import {
  checkForStrongPassword,
  ConfirmPasswordForm,
} from "../../../../../../components";
import { Input, Form } from "antd";

const PasswordBody = () => {
  return (
    <>
      <Form.Item
        label="Current Password"
        name="currentPassword"
        rules={[
          {
            required: true,
            validator: (_, value) =>
              antdFormValidator<string>(value, (value) =>
                checkForStrongPassword(value),
              ),
            message: "Your password must be greater than 8 characters.",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <ConfirmPasswordForm />
    </>
  );
};

export { PasswordBody };
