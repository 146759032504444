import React from "react";
import { Form } from "antd";
import { SubmitButton } from "../../../../../../components";
import { useForm } from "antd/lib/form/Form";

const ChangeFormBody = ({
  children,
  isLoading,
  submitText,
  handleUpdate,
}: ChangeFormBodyProps) => {
  const [form] = useForm();
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          handleUpdate(values);
          form.resetFields();
        }}
      >
        {children}
        <SubmitButton isLoading={isLoading} text={submitText} />
      </Form>
    </>
  );
};

export { ChangeFormBody };

interface ChangeFormBodyProps {
  children: React.ReactNode;
  isLoading: boolean;
  submitText: string;
  handleUpdate: (values: any) => Promise<void>;
}
