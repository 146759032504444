/*
 * Is HealCo Role
 - - - - - - - - - -
 Checks if a user is a HealCo Role
 @param identity - current session's identity
 @param role - HealCo role
 */
export const isHealcoRole = (
  identity: HealcoUserIdentity,
  role: HealcoUserRole,
): boolean => {
  return Object.hasOwnProperty.call(identity, role);
};

/*
 * Has Provider Role
 - - - - - - - - - -
 Checks if a user has a Provider Role
 @param identity - current session's identity
 @param role - provider role
 */

export const hasProviderRole = (
  identity: HealcoUserIdentity,
  role: string,
): boolean => {
  return Boolean(identity.provider?.providerRole === role);
};
