import React, { useState, useEffect } from "react";
import { handleFetch } from "../../../../../util";
import { API_ROUTE, FETCH } from "../../../../../constants";
import { Form, notification } from "antd";
import { LoadingPage, SubmitButton } from "../../../../../components";
import NotificationForms from "./NotificationForms";
import { useToggle } from "../../../../../hooks";

// TODO3 MEMORY LEAK HERE
const Notification = ({
  profile,
  handleModal,
  modalContent,
  updateProfile,
}: NotificationProps) => {
  const [isLoading, toggleLoading] = useToggle();
  const [userNotification, setUserNotification] = useState<
    HealcoPreferences[]
  >();
  const initialValues = {} as any;
  userNotification?.map((v) => {
    initialValues[v.notificationSettings.type] =
      v.notificationSettings.method || [];
  });

  const getNotification = async (organizationId: string) => {
    toggleLoading();
    await handleFetch<{ organizationId: string }, HealcoPreferences[]>({
      route: `${API_ROUTE.account.notification}/${organizationId}`,
      method: FETCH.get,
      onSuccess: (result) => setUserNotification(result),
    });
    toggleLoading();
  };

  // eslint-disable-next-line
  useEffect(() => void getNotification(profile.organization.id), [
    modalContent,
  ]);

  const savePreferences = async (values: any) => {
    toggleLoading();
    await handleFetch<{ organizationId: string }, void>({
      route: `${API_ROUTE.account.notification}/${profile.organization.id}`,
      method: FETCH.put,
      body: values,
      onSuccess: async () => {
        notification.success({
          message: "Alert Preferences",
          description: "Successfully saved.",
        });
        handleModal("");
      },
      onFailure: () => toggleLoading(),
    });
  };

  if (isLoading) return <LoadingPage />;
  return (
    <Form
      layout="vertical"
      onFinish={savePreferences}
      initialValues={initialValues}
    >
      <NotificationForms
        userNotification={userNotification}
        phone={profile.organization.phone}
        isPhoneVerified={profile.isPhoneVerified}
        userId={profile.id}
        updateProfile={updateProfile}
      />
      <SubmitButton
        htmlType="submit"
        type="primary"
        isLoading={isLoading}
        text="Save Preferences"
      />
    </Form>
  );
};

export default Notification;

interface NotificationProps {
  profile: HealcoProfile;
  modalContent: string;
  handleModal: (component: string) => void;
  updateProfile: () => void;
}
