import React from "react";
import { Collapse } from "antd";
import { HCard } from "../../components";
import { HContent } from "../../types/hContent";

const { Panel } = Collapse;

export const HCollapseCard = ({
  headingStyle,
  title,
  panelItems,
  accordion,
}: HCollapseCardProps) => {
  return (
    <HCard noMargin headingStyle={headingStyle} title={title}>
      <Collapse accordion={accordion} ghost>
        {panelItems.map((panelItem) => {
          return (
            <Panel key={panelItem.panelKey} header={panelItem.panelHeading}>
              {panelItem.panelContent}
            </Panel>
          );
        })}
      </Collapse>
    </HCard>
  );
};

type TPanelItems = {
  panelKey: number;
  panelHeading: JSX.Element;
  panelContent: JSX.Element;
};

interface HCollapseCardProps {
  headingStyle?: HContent.TStyle;
  title?: JSX.Element;
  accordion?: boolean;
  panelItems: TPanelItems[];
}
