/*
 * Ref Values
 - - - - - - - - -
 Names of all of the reference ids in the reference tables.

 @db update if ref tables are updated
 */
export const REF_OTHER = "OTHR";

export const REF_ID: ReferenceIdConstant = {
  accountStatus: {
    active: "ACTV",
    deactivated: "DEAC",
    locked: "LOCK",
  },
  ageGroup: {
    toddlersPreschoolers0To6: "TODD",
    children6To10: "CHIL",
    preteensTweens11To13: "PRET",
    adolescentsTeenagers14To19: "TEEN",
    adults: "ADLT",
    elders65: "ELDR",
  },
  auditAccountStatusEvent: {
    created: "CRTD",
    deactivated: "DEAC",
    deleted: "DLTD",
    reactivated: "REAC",
    locked: "LOCK",
    unlocked: "ULCK",
  },
  auditAuthenticationEvent: {
    failedLogIn: "LOGF",
    twoFactorFailed: "2FAF",
    twoFactorRequested: "2FAR",
    loggedIn: "LOGN",
    loggedOut: "LOGO",
    requestedPasswordReset: "REQR",
    resetPassword: "RSTP",
    securitySuccess: "SCSS",
    securityFailed: "SCSF",
    updateEmailRequested: "EMLR",
    updateEmailSuccess: "EMLS",
    updateEmailFailed: "EMLF",
    updatePasswordSuccess: "PSWS",
    updatePasswordFailed: "PSWF",
  },
  auditEphiEvent: {
    accessEphi: "ACCS",
    continueEphi: "CONT",
    logoutEphi: "LOGG",
  },
  auditLeaseEvent: {
    created: "CRTD",
    edited: "EDTD",
    terminated: "TERM",
  },
  auditPropertyEvent: {
    completed: "COMP",
    created: "CRTD",
    deleted: "DLTD",
    edited: "EDTD",
    listed: "LSTD",
    unlisted: "ULST",
  },
  auditUserEvent: {
    updatePhoneSuccess: "PHNS",
    updatePhoneFailed: "PHNF",
  },
  developmentType: {
    officeWithinAMedicalOfficeBuilding: "OWMO",
    officeInAMixedUseBuilding: "OMXB",
    officeInAMallOrStripMall: "OMSM",
    standaloneMedicalOffice: "SAMO",
  },
  downPaymentType: {
    firstMonthOnly: "FRST",
    firstMonthLastMonth: "FRLT",
    firstMonthLastMonthSecurityDeposit: "FLSD",
  },
  faith: {
    atheistAgnostic: "ATHS",
    buddhist: "BUDD",
    christian: "CHRS",
    hindu: "HNDU",
    islam: "ISLM",
    jewish: "JWSH",
    lds: "LDST",
    sikh: "SIKH",
    other: REF_OTHER,
  },
  fileBucket: {
    feedback: "FEED",
    leases: "LEAS",
    logos: "LOGO",
    properties: "PRTS",
  },
  gender: {
    genderfluid: 4,
    male: 1,
    female: 2,
    nonbinary: 3,
    femaleToMale: 5,
    maleToFemale: 6,
    other: 7,
  },
  hearAboutUs: {
    facebook: "FCBK",
    google: "GOOG",
    linkedin: "LKIN",
    reddit: "RDDT",
    wordOfMouth: "WDOM",
    other: REF_OTHER,
  },
  includedService: {
    handicapAccess: "HAND",
    highspeedInternet: "HSIT",
    janitorialServices: "JNTL",
    mailbox: "MLBX",
    onsiteParking: "PARK",
    redBag: "BIOH",
    storage: "STOR",
    utilitieswaterElectric: "UTIL",
    other: REF_OTHER,
  },
  insurance: {
    apsHealthcare: "APSH",
    aetna: "ATNA",
    allwaysHealthPartners: "AWHP",
    anthem: "ANTH",
    bmcHealthNet: "BMCH",
    beacon: "BEAC",
    behavioralHealthSystems: "BVHS",
    blueCross: "BLCR",
    blueShield: "BLSH",
    bluecrossAndBlueshield: "BCBS",
    chipa: "CHPA",
    careInTheCommunity: "CIIC",
    ceridian: "CRDN",
    cigna: "CGNA",
    claremontEap: "CEAP",
    compsych: "CMPS",
    concern: "CNCN",
    coventry: "CVTY",
    e4health: "EHLT",
    fallonHealth: "FAHT",
    firstHealth: "FHLT",
    geha: "GEHA",
    greatwestLife: "GWLF",
    guardian: "GRDN",
    hmcHealthworks: "HMCH",
    harvardPilgrim: "HVDP",
    healthNet: "HLTN",
    holmanGroup: "HMGR",
    humana: "HMNA",
    interplan: "IPLN",
    kaiseroutofnetwork: "KASR",
    mhn: "MHNA",
    mhnetBehavioralHealth: "MHBH",
    magellan: "MGLN",
    medical: "MDCL",
    medicaid: "MDCD",
    medicare: "MDCR",
    militaryOnesource: "MLOS",
    multiplan: "MTPL",
    networkHealth: "NHLT",
    optima: "OPMA",
    optum: "OPTM",
    phcs: "PHCS",
    sutter: "SUTR",
    tricare: "TRIC",
    triwest: "TRIW",
    tufts: "TFTS",
    umr: "UMRA",
    unitedhealthcare: "UHCR",
    other: REF_OTHER,
  },
  introductionRejectionReason: {
    levelOfExperience: "EXPR",
    specialty: "SPTY",
    notAvailable: "AVBY",
    timePeriod: "TIME",
    other: REF_OTHER,
  },
  language: {
    asl: "ASLE",
    arabic: "ARAB",
    armenian: "ARMN",
    bosnian: "BOSN",
    cantonese: "CANT",
    creole: "CRLE",
    croatian: "CRTN",
    dutch: "DTCH",
    farsi: "FARS",
    filipino: "FILI",
    french: "FRCH",
    german: "GERM",
    greek: "GREK",
    gujarati: "GJRT",
    hebrew: "HBRW",
    hindi: "HNDI",
    hungarian: "HNGN",
    italian: "ITAL",
    japanese: "JAPN",
    korean: "KORN",
    mandarin: "MAND",
    polish: "PLSH",
    portuguese: "PORT",
    punjabi: "PJBI",
    romanian: "ROMN",
    russian: "RUSN",
    serbian: "SRBN",
    sinhalese: "SINL",
    spanish: "SPAN",
    swedish: "SWED",
    turkish: "TURK",
    ukrainian: "UKRN",
    urdu: "URDU",
    vietnamese: "VTMN",
    yiddish: "YDSH",
    other: REF_OTHER,
  },
  leaseStatus: {
    active: "ACTV",
    completed: "CMPL",
    pending: "PEND",
    terminated: "TERM",
  },
  matchDislikeReason: {
    availabilityIssue: "AVLB",
    tooExpensive: "EXPS",
    tooFar: "FARA",
    tooSmall: "SMAL",
    other: REF_OTHER,
  },
  medicalCondition: {
    adhd: "ADHD",
    anxiety: "ANXY",
    asthma: "ASMA",
    bphenlargedProstate: "BPHP",
    breastfeeding: "BRFD",
    cancer: "CANC",
    coldSores: "CSRS",
    depression: "DEPR",
    diabetes: "DIAB",
    eatingDisorder: "EDBA",
    emphysema: "EMPH",
    genitalHerpes: "GHER",
    glaucoma: "GLAU",
    heartburnOrGerd: "HBRN",
    heartDiseaseOrHeartAttack: "HRTD",
    heartStent: "HRST",
    highBloodPressure: "HBPR",
    highCholesterol: "HCHL",
    hiv: "HIVX",
    hypothyroidismlowThyroid: "HYTH",
    kidneyDisease: "KIDN",
    immuneSystemProblems: "IMMN",
    infertilityTreatment: "INFR",
    liverDisease: "LIVR",
    migraineHeadaches: "MIGR",
    multipleSclerosis: "MULS",
    panicDisorder: "PANC",
    parkinsonsDisease: "PARK",
    pregnant: "PREG",
    psychosis: "PSYC",
    ptsd: "PTSD",
    socialAnxietyDisorder: "SOCD",
    schizophrenia: "SCHZ",
    seizuresOrEpilepsy: "SZRS",
    seritoninSyndrome: "SERO",
    stroke: "STRK",
    weightLossMedicines: "WTLM",
    other: REF_OTHER,
  },
  notificationCategory: {
    listings: "LIST",
    propertyChats: "PCHT",
    propertyMatches: "PMTH",
  },
  notificationMethod: { email: "EMAL", phone: "PHNE" },
  notificationType: {
    newChatMessage: "CHAT",
    introductionAccepted: "INTA",
    introductionDeclined: "INTD",
    matchedPropertyUpdated: "MPUP",
    newPropertyMatch: "NPMH",
    newProviderIntroduction: "NPIN",
    unlistedDraft: "UDRT",
  },
  officeStaff: {
    medicalAssistant: "MDAS",
    receptionist: "RECP",
    other: REF_OTHER,
  },
  plan: { none: "NONE", legacy: "LGCY", standard: "STRD" },
  pronouns: {
    heHim: "HHIM",
    sheHer: "SHEH",
    theyThem: "THEY",
    other: REF_OTHER,
  },
  propertyStatus: {
    deleted: "DLTD",
    drafted: "DRFT",
    listed: "LIST",
    unlisted: "UNLT",
  },
  providerRole: {
    healcoPro: "HPRO",
    pharmacist: "PHAR",
    standard: "STAN",
  },
  ratePeriod: { day: "DAYX", month: "MNTH", session: "SESN" },
  race: {
    asian: "ASAN",
    blackOrAfricanamerican: "BLCK",
    hispanicOrLatino: "HLTN",
    middleEastern: "MESN",
    nativeAmericanOrAlaskaNative: "NAAN",
    nativeHawaiianOrOtherPacificIslander: "NHPI",
    southAsian: "SASN",
    whiteOrEuropeanamerican: "WEUR",
    other: REF_OTHER,
  },
  rating: { good: "GOOD", fair: "FAIR", poor: "POOR" },
  recreationalDrug: {
    alcohol: "ALCH",
    ayahuasca: "AYAH",
    centralNervousSystemDepressants: "CNSD",
    cocaine: "CCNE",
    dxm: "DXMX",
    dmt: "DMTX",
    ghb: "GHBX",
    hallucinogens: "HLLC",
    heroin: "HRNE",
    inhalants: "INHL",
    ketamine: "KETA",
    khat: "KHAT",
    kratom: "KRAT",
    loperamide: "LPRM",
    lsd: "LSDX",
    marijuanaCannabis: "MARI",
    mdma: "MDMA",
    mescalinePeyote: "MSCL",
    methamphetamine: "METH",
    pcp: "PCPX",
    prescriptionOpioids: "PROP",
    prescriptionStimulants: "PRST",
    psilocybin: "PSIL",
    salvia: "SALV",
    steroidsAnabolic: "STER",
    syntheticCannabinoids: "SCAN",
    syntheticCathinonesBathSalts: "SCAT",
    other: REF_OTHER,
  },
  relationshipStatus: {
    domesticPartnership: "DOMP",
    divorced: "DIVR",
    inARelationship: "INAR",
    married: "MARR",
    separated: "SEPR",
    single: "SNGL",
    widowed: "WIDW",
    other: REF_OTHER,
  },
  roomType: {
    breakRoom: "BREK",
    conferenceRoom: "CONF",
    consultingRoom: "CONS",
    examRoom: "EXAM",
    phlebotomy: "PHLB",
    physicianOffice: "OFFC",
    procedureRoom: "PROC",
    storageRoom: "STOR",
    surgeryRoom: "SURG",
    waitingReceptionArea: "WAIT",
    wellnessRoom: "WELL",
    other: REF_OTHER,
  },
  prescriptionMedicine: {
    celexa: "CELX",
    cymbalta: "CYMB",
    effexor: "EFFX",
    lexapro: "LEXA",
    paxil: "PAXL",
    prozac: "PROZ",
    truvada: "TRUV",
    wellbutrin: "WELL",
    zoloft: "ZLFT",
    other: REF_OTHER,
  },
  providerSpecialty: {
    acupuncture: "ACUP",
    audiology: "AUDI",
    cardiology: "CARD",
    cardiothoracicSurgery: "CART",
    chiropractic: "CHIR",
    colorectalSurgery: "COLR",
    dental: "DENT",
    dermatology: "DERM",
    diagnosticRadiology: "DRAD",
    dietetics: "DIET",
    emergencyMedicine: "EMED",
    endocrinology: "ENDO",
    endodontics: "ENDD",
    familyMedicine: "FMED",
    gastroenterology: "GAST",
    generalSurgery: "GSUR",
    geriatrics: "GERI",
    hematology: "HEMA",
    holisticWellness: "HOLW",
    immunologyAllergies: "IMMN",
    infectiousDiseases: "INFD",
    infertility: "INFR",
    internalMedicine: "IMED",
    midwifery: "MIDW",
    naturopathy: "NATR",
    nephrology: "NEPH",
    nutrition: "NUTR",
    neurology: "NEUR",
    neurosurgery: "NEUS",
    obstetricsGynecology: "OBGY",
    oncology: "ONCL",
    ophthalmology: "OPTH",
    optometry: "OPTO",
    oralSurgeon: "ORAL",
    orthodontics: "ORTH",
    orthopaedics: "ORTP",
    otolaryngology: "OTOL",
    painManagement: "PAIN",
    pediatrics: "PEDI",
    pharmacy: "PHAR",
    physicalTherapy: "PHYT",
    plasticSurgery: "PLAS",
    podiatry: "PODI",
    primaryCare: "PRCP",
    prosthodontics: "PROS",
    psychiatry: "PSYC",
    pulmonology: "PULM",
    rheumatology: "REUM",
    sleepMedicine: "SLPM",
    sportsMedicine: "SPRT",
    therapyBehavioral: "THPY",
    urgentCare: "URGC",
    urology: "UROL",
    other: REF_OTHER,
  },
  questionType: {
    input: "INPT",
    multi: "MULT",
    range: "RNGE",
    single: "SING",
    upload: "UPLD",
  },
  sexualOrientation: {
    asexual: "ASXL",
    bisexual: "BSXL",
    gay: "GAYY",
    lesbian: "LSBN",
    pansexual: "PANS",
    queer: "QUER",
    questioning: "QUES",
    straight: "STRT",
    other: REF_OTHER,
  },
  signupReason: {
    acquireNewPatientsClients: "ACQR",
    find: "FIND",
    list: "LIST",
    other: REF_OTHER,
  },
  therapistType: {
    artTherapist: "ARTT",
    associateMarriageAndFamilyTherapist: "AMFT",
    associateProfessionalClinicalCounselor: "APCC",
    associateProfessionalCounselor: "APFC",
    associateSocialWorker: "ASWR",
    behaviorAnalyst: "BVAN",
    clinicalSocialWorkertherapist: "CSWT",
    counselor: "CNSL",
    creativeArtsTherapist: "CRAT",
    drugAndAlcoholCounselortherapist: "DACT",
    licensedMarriageAndFamilyTherapistAssociate: "LMFA",
    licensedMasterSocialWorker: "LMSW",
    licensedMentalHealthCounselor: "LMHC",
    licensedPsychoanalyst: "LPSY",
    limitedLicensePsychology: "LLPS",
    marriageAndFamilyTherapist: "MRFT",
    marriageAndFamilyTherapistIntern: "MFTI",
    nationalCertifiedCounselor: "NCCR",
    occupationalTherapist: "OCCT",
    pastoralCounselortherapist: "PASC",
    prelicensedProfessional: "PREP",
    psychiatricNursetherapist: "PSNT",
    psychologist: "PSCH",
    psychologistAssociate: "PSCA",
    psychotherapist: "PSYT",
    provisionallyLicensedCounselor: "PVLC",
    schoolPsychologist: "SPSY",
  },
  therapyIssue: {
    attentionDeficitadhd: "ADHD",
    addiction: "ADDC",
    adoptionFosterCareIssues: "ADOP",
    agoraphobia: "AGOR",
    alcoholUse: "ALCH",
    alzheimers: "ALZH",
    angerManagement: "ANGR",
    antisocialPersonality: "ASOC",
    anxiety: "ANXI",
    aspergersSyndrome: "ASPR",
    athletes: "ATHL",
    autism: "AUTI",
    behavioralIssues: "BEHA",
    bipolarDisorder: "BIPO",
    bodyImageAndBodyDysmorphia: "BODY",
    borderlinePersonality: "BORD",
    bullying: "BULY",
    cancer: "CANC",
    careerCounseling: "CARR",
    caregiverIssuesOrStress: "CARE",
    childOrAdolescent: "CHAD",
    chronicIllness: "CRIL",
    chronicImpulsivity: "CIMP",
    chronicPain: "CPAN",
    chronicRelapse: "CREL",
    codependency: "CODE",
    collegeAgedStudentConcerns: "COLL",
    commitmentIssues: "COMT",
    communicationProblems: "COMM",
    compulsiveSpendingAndShopping: "CMPS",
    controlIssues: "CONT",
    copingSkills: "COPS",
    creativeBlocks: "CRTV",
    cyclothymia: "CYCL",
    depression: "DEPR",
    developmentalDisorders: "DEVD",
    divorceAndDivorceAdjustment: "DIVR",
    dissociativeDisorders: "DISS",
    domesticAbuse: "DMAB",
    domesticViolence: "DMVL",
    drugAbuse: "DRAB",
    dualDiagnosis: "DUDI",
    eatingDisorders: "EATD",
    elderlyPersonsDisorders: "ELDY",
    emotionalIntelligence: "EMOI",
    emotionalAbuse: "EMOA",
    emptiness: "EMPT",
    entitlement: "ENTT",
    expats: "EXPT",
    familyConflict: "FCON",
    familyOfOriginIssues: "FAMO",
    fatherlessChildren: "FATH",
    fear: "FEAR",
    forgiveness: "FORG",
    gambling: "GMBL",
    guilt: "GULT",
    healthIllnessAndMedicalIssues: "HLTH",
    hivAids: "HIVA",
    hoarding: "HRDG",
    helplessnessAndVictimhood: "HLPV",
    identityIssues: "IDTY",
    inadequacy: "INAD",
    individuation: "INDV",
    infertility: "INFR",
    infidelityAndAffairRecovery: "INFD",
    intellectualDisability: "INTD",
    internetAddiction: "INTA",
    irritability: "IRRT",
    isolation: "ISOL",
    jealousy: "JEAL",
    learningDisabilities: "LRND",
    lgbtqa: "LGBT",
    lifeCoaching: "LFCN",
    lifeTransitions: "LFTR",
    lossOrGrief: "LSGF",
    maritalAndPremarital: "MARP",
    medicalDetox: "MDTX",
    medicationManagement: "MMNG",
    mensIssues: "MENI",
    midlifeCrisis: "MIDL",
    militaryIssues: "MILT",
    multiculturalConcerns: "MCUL",
    narcissisticPersonality: "NCSC",
    obesity: "OBES",
    obsessivecompulsiveocd: "OBCD",
    oppositionalDefiance: "OPDF",
    paranoia: "PARA",
    parenting: "PRNT",
    peerRelationships: "PEER",
    perfectionism: "PERF",
    phobias: "PHOB",
    polyamory: "POLY",
    pregnancyPrenatalPostpartum: "PPPD",
    racialIdentity: "RCID",
    relationshipIssues: "RSIS",
    schoolIssues: "SCHL",
    selfEsteem: "SLFE",
    selfharming: "SLFH",
    sexTherapy: "SXTY",
    sexualAbuse: "SXAB",
    sexualAddiction: "SXAD",
    singleParentingIssues: "SNGP",
    sleepOrInsomnia: "SLIN",
    spirituality: "SPRT",
    sportsPerformance: "SPPR",
    stress: "STRS",
    substanceUse: "SUBU",
    suicidalIdeation: "SUIC",
    teens: "TEEN",
    teenViolence: "TNVL",
    testingAndEvaluation: "TSEV",
    transgender: "TRNS",
    traumaAndPtsd: "PTSD",
    traumaticBrainInjury: "TRBI",
    veteranIssues: "VTRN",
    videoGameAddiction: "VGAD",
    weightLoss: "WTLS",
    womensIssues: "WMIS",
  },
  therapyLicenseStatus: {
    associateinternLicense: "ASSC",
    fullyLicensed: "FULL",
    prelicensed: "PREL",
  },
  therapyModality: {
    children: "CHIL",
    couples: "COUP",
    family: "FMLY",
    group: "GRUP",
    individual: "INDV",
  },
  therapyOrientation: {
    aedp: "AEDP",
    acceleratedResolutionTherapyart: "ACRT",
    acceptanceAndCommitmentact: "AACT",
    adlerianTheory: "ADLN",
    animalassistedTherapy: "ANIM",
    appliedBehavioralAnalysis: "APBA",
    artTherapy: "ATRY",
    attachmentbased: "ATCH",
    biofeedback: "BIOF",
    brainspotting: "BRNS",
    christianCounseling: "CHRS",
    clinicalSupervisionAndLicensedSupervisors: "CSLS",
    coaching: "CCHG",
    cognitiveBehavioralcbt: "CGBT",
    cognitiveProcessingcpt: "CGPT",
    compassionFocused: "CMPF",
    contemplative: "CPLV",
    culturallySensitive: "CLTS",
    dancemovementTherapy: "DMVT",
    developmental: "DEVP",
    dialecticaldbt: "DLBT",
    dramaTherapy: "DRMA",
    emdr: "EMDR",
    eclectic: "ECLC",
    ecotherapynaturebased: "ECON",
    emotionalFreedomTechnique: "EMFT",
    emotionallyTransformationTherapy: "EMTT",
    energyPsychology: "ENPS",
    equineTherapy: "EQIN",
    existential: "EXIS",
    experientialTherapy: "EXPT",
    exposureResponsePrevention: "EXRP",
    expressiveArts: "EXAT",
    familyMarital: "FMMT",
    familySystems: "FSYS",
    feminist: "FMNT",
    forensicPsychology: "FRPY",
    gestalt: "GSTT",
    gottmanMethod: "GTMN",
    holisticHealth: "HLHT",
    humanistic: "HMST",
    hypnotherapy: "HPNT",
    image: "IMGE",
    integrative: "INTG",
    internalFamilySystems: "INFS",
    interpersonal: "INPS",
    intervention: "INTV",
    jungian: "JNGN",
    lifespanIntegration: "LFSN",
    meditation: "MDTN",
    metacognitiveTherapy: "MCGN",
    mindfulnessbased: "MBCT",
    motivationalInterviewing: "MINT",
    multiculturalTheory: "MTCL",
    musicTherapy: "MSTY",
    narrative: "NRTV",
    neurolinguistic: "NRLG",
    neurofeedback: "NRFD",
    parentchildInteraction: "PCIT",
    personCentered: "PRCD",
    photography: "PHTO",
    playTherapy: "PLTY",
    positivePsychology: "PSTP",
    prolongedExposureTherapy: "PRET",
    psychoanalytic: "PSAC",
    psychobiologicalApproachCoupleTherapy: "PACT",
    psychodynamic: "PSDM",
    psychologicalTestingAndEvaluation: "PTAE",
    qigong: "QGNG",
    rapidResolutionTherapy: "RPDR",
    rationalEmotiveBehavior: "REBT",
    realityTherapy: "RTTY",
    relationalTherapy: "RLTL",
    restorationTherapy: "RSTR",
    rogerian: "RGRN",
    sandplay: "SNDP",
    schemaTherapy: "SCMT",
    sensorimotorPsychotherapy: "SPSY",
    solutionFocusedBrief: "SFBT",
    somatic: "SMTC",
    strengthbased: "SGBD",
    structuralFamilyTherapy: "SCFT",
    telehealth: "TELE",
    therapeuticYoga: "TYGA",
    transpersonal: "TRNP",
    virtualReality: "VRLR",
    writing: "WRTG",
  },
  treatmentCategory: {
    acne: { id: "ACNE", name: "Acne" },
    axdp: { id: "AXDP", name: "Anxiety / Depression" },
    edys: { id: "EDYS", name: "Erectile Dysfunction" },
    hrps: { id: "HRPS", name: "Herpes" },
    migr: { id: "MIGR", name: "Migraine" },
    prep: { id: "PREP", name: "PrEP" },
    sccm: { id: "SCCM", name: "Scream Cream" },
  },
  userRole: {
    healcoAdmin: "HADN",
    patient: "PTNT",
    provider: "PROV",
  },
  userType: {
    patientClient: "PTNT",
    independentProvider: "PROV",
    hospitalHealthSystem: "HOSP",
    employer: "EMPL",
    broker: "BRKR",
    other: REF_OTHER,
  },
  usState: {
    alabama: "AL",
    alaska: "AK",
    arizona: "AZ",
    arkansas: "AR",
    california: "CA",
    colorado: "CO",
    connecticut: "CT",
    delaware: "DE",
    florida: "FL",
    georgia: "GA",
    hawaii: "HI",
    idaho: "ID",
    illinois: "IL",
    indiana: "IN",
    iowa: "IA",
    kansas: "KS",
    kentucky: "KY",
    louisiana: "LA",
    maine: "ME",
    maryland: "MD",
    massachusetts: "MA",
    michigan: "MI",
    minnesota: "MN",
    mississippi: "MS",
    missouri: "MO",
    montana: "MT",
    nebraska: "NE",
    nevada: "NV",
    newHampshire: "NH",
    newJersey: "NJ",
    newMexico: "NM",
    newYork: "NY",
    northCarolina: "NC",
    northDakota: "ND",
    ohio: "OH",
    oklahoma: "OK",
    oregon: "OR",
    pennsylvania: "PA",
    puertoRico: "PR",
    rhodeIsland: "RI",
    southCarolina: "SC",
    southDakota: "SD",
    tennessee: "TN",
    texas: "TX",
    utah: "UT",
    vermont: "VT",
    virginia: "VA",
    washington: "WA",
    westVirginia: "WV",
    wisconsin: "WI",
    wyoming: "WY",
  },
};

interface ReferenceIdConstant {
  accountStatus: {
    active: string;
    deactivated: string;
    locked: string;
  };
  ageGroup: {
    toddlersPreschoolers0To6: string;
    children6To10: string;
    preteensTweens11To13: string;
    adolescentsTeenagers14To19: string;
    adults: string;
    elders65: string;
  };
  auditAccountStatusEvent: {
    created: string;
    deactivated: string;
    deleted: string;
    reactivated: string;
    locked: string;
    unlocked: string;
  };
  auditAuthenticationEvent: {
    failedLogIn: string;
    twoFactorFailed: string;
    twoFactorRequested: string;
    loggedIn: string;
    loggedOut: string;
    requestedPasswordReset: string;
    resetPassword: string;
    securitySuccess: string;
    securityFailed: string;
    updateEmailRequested: string;
    updateEmailSuccess: string;
    updateEmailFailed: string;
    updatePasswordSuccess: string;
    updatePasswordFailed: string;
  };
  auditEphiEvent: {
    accessEphi: string;
    continueEphi: string;
    logoutEphi: string;
  };
  auditLeaseEvent: {
    created: string;
    edited: string;
    terminated: string;
  };
  auditPropertyEvent: {
    completed: string;
    created: string;
    deleted: string;
    edited: string;
    listed: string;
    unlisted: string;
  };
  auditUserEvent: {
    updatePhoneSuccess: string;
    updatePhoneFailed: string;
  };
  developmentType: {
    officeWithinAMedicalOfficeBuilding: string;
    officeInAMixedUseBuilding: string;
    officeInAMallOrStripMall: string;
    standaloneMedicalOffice: string;
  };
  downPaymentType: {
    firstMonthOnly: string;
    firstMonthLastMonth: string;
    firstMonthLastMonthSecurityDeposit: string;
  };
  faith: {
    atheistAgnostic: string;
    buddhist: string;
    christian: string;
    hindu: string;
    islam: string;
    jewish: string;
    lds: string;
    sikh: string;
    other: string;
  };
  fileBucket: {
    feedback: string;
    leases: string;
    logos: string;
    properties: string;
  };
  gender: {
    female: number;
    genderfluid: number;
    male: number;
    nonbinary: number;
    femaleToMale: number;
    maleToFemale: number;
    other: number;
  };
  hearAboutUs: {
    facebook: string;
    google: string;
    linkedin: string;
    reddit: string;
    wordOfMouth: string;
    other: string;
  };
  includedService: {
    handicapAccess: string;
    highspeedInternet: string;
    janitorialServices: string;
    mailbox: string;
    onsiteParking: string;
    redBag: string;
    storage: string;
    utilitieswaterElectric: string;
    other: string;
  };
  insurance: {
    apsHealthcare: string;
    aetna: string;
    allwaysHealthPartners: string;
    anthem: string;
    bmcHealthNet: string;
    beacon: string;
    behavioralHealthSystems: string;
    blueCross: string;
    blueShield: string;
    bluecrossAndBlueshield: string;
    chipa: string;
    careInTheCommunity: string;
    ceridian: string;
    cigna: string;
    claremontEap: string;
    compsych: string;
    concern: string;
    coventry: string;
    e4health: string;
    fallonHealth: string;
    firstHealth: string;
    geha: string;
    greatwestLife: string;
    guardian: string;
    hmcHealthworks: string;
    harvardPilgrim: string;
    healthNet: string;
    holmanGroup: string;
    humana: string;
    interplan: string;
    kaiseroutofnetwork: string;
    mhn: string;
    mhnetBehavioralHealth: string;
    magellan: string;
    medical: string;
    medicaid: string;
    medicare: string;
    militaryOnesource: string;
    multiplan: string;
    networkHealth: string;
    optima: string;
    optum: string;
    phcs: string;
    sutter: string;
    tricare: string;
    triwest: string;
    tufts: string;
    umr: string;
    unitedhealthcare: string;
    other: string;
  };
  introductionRejectionReason: {
    levelOfExperience: string;
    specialty: string;
    notAvailable: string;
    timePeriod: string;
    other: string;
  };
  language: {
    asl: string;
    arabic: string;
    armenian: string;
    bosnian: string;
    cantonese: string;
    creole: string;
    croatian: string;
    dutch: string;
    farsi: string;
    filipino: string;
    french: string;
    german: string;
    greek: string;
    gujarati: string;
    hebrew: string;
    hindi: string;
    hungarian: string;
    italian: string;
    japanese: string;
    korean: string;
    mandarin: string;
    polish: string;
    portuguese: string;
    punjabi: string;
    romanian: string;
    russian: string;
    serbian: string;
    sinhalese: string;
    spanish: string;
    swedish: string;
    turkish: string;
    ukrainian: string;
    urdu: string;
    vietnamese: string;
    yiddish: string;
    other: string;
  };
  leaseStatus: {
    active: string;
    completed: string;
    pending: string;
    terminated: string;
  };
  matchDislikeReason: {
    availabilityIssue: string;
    tooExpensive: string;
    tooFar: string;
    tooSmall: string;
    other: string;
  };
  medicalCondition: {
    adhd: string;
    anxiety: string;
    asthma: string;
    bphenlargedProstate: string;
    breastfeeding: string;
    cancer: string;
    coldSores: string;
    depression: string;
    diabetes: string;
    eatingDisorder: string;
    emphysema: string;
    genitalHerpes: string;
    glaucoma: string;
    heartburnOrGerd: string;
    heartDiseaseOrHeartAttack: string;
    heartStent: string;
    highBloodPressure: string;
    highCholesterol: string;
    hiv: string;
    hypothyroidismlowThyroid: string;
    kidneyDisease: string;
    immuneSystemProblems: string;
    infertilityTreatment: string;
    liverDisease: string;
    migraineHeadaches: string;
    multipleSclerosis: string;
    panicDisorder: string;
    parkinsonsDisease: string;
    pregnant: string;
    psychosis: string;
    ptsd: string;
    socialAnxietyDisorder: string;
    schizophrenia: string;
    seizuresOrEpilepsy: string;
    seritoninSyndrome: string;
    stroke: string;
    weightLossMedicines: string;
    other: string;
  };
  notificationCategory: {
    listings: string;
    propertyChats: string;
    propertyMatches: string;
  };
  notificationMethod: {
    email: string;
    phone: string;
  };
  notificationType: {
    newChatMessage: string;
    introductionAccepted: string;
    introductionDeclined: string;
    matchedPropertyUpdated: string;
    newPropertyMatch: string;
    newProviderIntroduction: string;
    unlistedDraft: string;
  };
  officeStaff: {
    medicalAssistant: string;
    receptionist: string;
    other: string;
  };
  plan: { none: string; legacy: string; standard: string };
  pronouns: {
    heHim: string;
    sheHer: string;
    theyThem: string;
    other: string;
  };
  propertyStatus: {
    deleted: string;
    drafted: string;
    listed: string;
    unlisted: string;
  };
  ratePeriod: { day: string; month: string; session: string };
  race: {
    asian: string;
    blackOrAfricanamerican: string;
    hispanicOrLatino: string;
    middleEastern: string;
    nativeAmericanOrAlaskaNative: string;
    nativeHawaiianOrOtherPacificIslander: string;
    southAsian: string;
    whiteOrEuropeanamerican: string;
    other: string;
  };
  rating: { good: string; fair: string; poor: string };
  recreationalDrug: {
    alcohol: string;
    ayahuasca: string;
    centralNervousSystemDepressants: string;
    cocaine: string;
    dxm: string;
    dmt: string;
    ghb: string;
    hallucinogens: string;
    heroin: string;
    inhalants: string;
    ketamine: string;
    khat: string;
    kratom: string;
    loperamide: string;
    lsd: string;
    marijuanaCannabis: string;
    mdma: string;
    mescalinePeyote: string;
    methamphetamine: string;
    pcp: string;
    prescriptionOpioids: string;
    prescriptionStimulants: string;
    psilocybin: string;
    salvia: string;
    steroidsAnabolic: string;
    syntheticCannabinoids: string;
    syntheticCathinonesBathSalts: string;
    other: string;
  };
  relationshipStatus: {
    domesticPartnership: string;
    divorced: string;
    inARelationship: string;
    married: string;
    separated: string;
    single: string;
    widowed: string;
    other: string;
  };
  roomType: {
    breakRoom: string;
    conferenceRoom: string;
    consultingRoom: string;
    examRoom: string;
    phlebotomy: string;
    physicianOffice: string;
    procedureRoom: string;
    storageRoom: string;
    surgeryRoom: string;
    waitingReceptionArea: string;
    wellnessRoom: string;
    other: string;
  };
  prescriptionMedicine: {
    celexa: string;
    cymbalta: string;
    effexor: string;
    lexapro: string;
    paxil: string;
    prozac: string;
    truvada: string;
    wellbutrin: string;
    zoloft: string;
    other: string;
  };
  providerRole: {
    healcoPro: string;
    pharmacist: string;
    standard: string;
  };
  providerSpecialty: {
    acupuncture: string;
    audiology: string;
    cardiology: string;
    cardiothoracicSurgery: string;
    chiropractic: string;
    colorectalSurgery: string;
    dental: string;
    dermatology: string;
    diagnosticRadiology: string;
    dietetics: string;
    emergencyMedicine: string;
    endocrinology: string;
    endodontics: string;
    familyMedicine: string;
    gastroenterology: string;
    generalSurgery: string;
    geriatrics: string;
    hematology: string;
    holisticWellness: string;
    immunologyAllergies: string;
    infectiousDiseases: string;
    infertility: string;
    internalMedicine: string;
    midwifery: string;
    naturopathy: string;
    nephrology: string;
    nutrition: string;
    neurology: string;
    neurosurgery: string;
    obstetricsGynecology: string;
    oncology: string;
    ophthalmology: string;
    optometry: string;
    oralSurgeon: string;
    orthodontics: string;
    orthopaedics: string;
    otolaryngology: string;
    painManagement: string;
    pediatrics: string;
    pharmacy: string;
    physicalTherapy: string;
    plasticSurgery: string;
    podiatry: string;
    primaryCare: string;
    prosthodontics: string;
    psychiatry: string;
    pulmonology: string;
    rheumatology: string;
    sleepMedicine: string;
    sportsMedicine: string;
    therapyBehavioral: string;
    urgentCare: string;
    urology: string;
    other: string;
  };
  questionType: {
    input: string;
    multi: string;
    range: string;
    single: string;
    upload: string;
  };
  sexualOrientation: {
    asexual: string;
    bisexual: string;
    gay: string;
    lesbian: string;
    pansexual: string;
    queer: string;
    questioning: string;
    straight: string;
    other: string;
  };
  signupReason: {
    acquireNewPatientsClients: string;
    find: string;
    list: string;
    other: string;
  };
  therapistType: {
    artTherapist: string;
    associateMarriageAndFamilyTherapist: string;
    associateProfessionalClinicalCounselor: string;
    associateProfessionalCounselor: string;
    associateSocialWorker: string;
    behaviorAnalyst: string;
    clinicalSocialWorkertherapist: string;
    counselor: string;
    creativeArtsTherapist: string;
    drugAndAlcoholCounselortherapist: string;
    licensedMarriageAndFamilyTherapistAssociate: string;
    licensedMasterSocialWorker: string;
    licensedMentalHealthCounselor: string;
    licensedPsychoanalyst: string;
    limitedLicensePsychology: string;
    marriageAndFamilyTherapist: string;
    marriageAndFamilyTherapistIntern: string;
    nationalCertifiedCounselor: string;
    occupationalTherapist: string;
    pastoralCounselortherapist: string;
    prelicensedProfessional: string;
    psychiatricNursetherapist: string;
    psychologist: string;
    psychologistAssociate: string;
    psychotherapist: string;
    provisionallyLicensedCounselor: string;
    schoolPsychologist: string;
  };
  therapyIssue: {
    attentionDeficitadhd: string;
    addiction: string;
    adoptionFosterCareIssues: string;
    agoraphobia: string;
    alcoholUse: string;
    alzheimers: string;
    angerManagement: string;
    antisocialPersonality: string;
    anxiety: string;
    aspergersSyndrome: string;
    athletes: string;
    autism: string;
    behavioralIssues: string;
    bipolarDisorder: string;
    bodyImageAndBodyDysmorphia: string;
    borderlinePersonality: string;
    bullying: string;
    cancer: string;
    careerCounseling: string;
    caregiverIssuesOrStress: string;
    childOrAdolescent: string;
    chronicIllness: string;
    chronicImpulsivity: string;
    chronicPain: string;
    chronicRelapse: string;
    codependency: string;
    collegeAgedStudentConcerns: string;
    commitmentIssues: string;
    communicationProblems: string;
    compulsiveSpendingAndShopping: string;
    controlIssues: string;
    copingSkills: string;
    creativeBlocks: string;
    cyclothymia: string;
    depression: string;
    developmentalDisorders: string;
    divorceAndDivorceAdjustment: string;
    dissociativeDisorders: string;
    domesticAbuse: string;
    domesticViolence: string;
    drugAbuse: string;
    dualDiagnosis: string;
    eatingDisorders: string;
    elderlyPersonsDisorders: string;
    emotionalIntelligence: string;
    emotionalAbuse: string;
    emptiness: string;
    entitlement: string;
    expats: string;
    familyConflict: string;
    familyOfOriginIssues: string;
    fatherlessChildren: string;
    fear: string;
    forgiveness: string;
    gambling: string;
    guilt: string;
    healthIllnessAndMedicalIssues: string;
    hivAids: string;
    hoarding: string;
    helplessnessAndVictimhood: string;
    identityIssues: string;
    inadequacy: string;
    individuation: string;
    infertility: string;
    infidelityAndAffairRecovery: string;
    intellectualDisability: string;
    internetAddiction: string;
    irritability: string;
    isolation: string;
    jealousy: string;
    learningDisabilities: string;
    lgbtqa: string;
    lifeCoaching: string;
    lifeTransitions: string;
    lossOrGrief: string;
    maritalAndPremarital: string;
    medicalDetox: string;
    medicationManagement: string;
    mensIssues: string;
    midlifeCrisis: string;
    militaryIssues: string;
    multiculturalConcerns: string;
    narcissisticPersonality: string;
    obesity: string;
    obsessivecompulsiveocd: string;
    oppositionalDefiance: string;
    paranoia: string;
    parenting: string;
    peerRelationships: string;
    perfectionism: string;
    phobias: string;
    polyamory: string;
    pregnancyPrenatalPostpartum: string;
    racialIdentity: string;
    relationshipIssues: string;
    schoolIssues: string;
    selfEsteem: string;
    selfharming: string;
    sexTherapy: string;
    sexualAbuse: string;
    sexualAddiction: string;
    singleParentingIssues: string;
    sleepOrInsomnia: string;
    spirituality: string;
    sportsPerformance: string;
    stress: string;
    substanceUse: string;
    suicidalIdeation: string;
    teens: string;
    teenViolence: string;
    testingAndEvaluation: string;
    transgender: string;
    traumaAndPtsd: string;
    traumaticBrainInjury: string;
    veteranIssues: string;
    videoGameAddiction: string;
    weightLoss: string;
    womensIssues: string;
  };
  therapyLicenseStatus: {
    associateinternLicense: string;
    fullyLicensed: string;
    prelicensed: string;
  };
  therapyModality: {
    children: string;
    couples: string;
    family: string;
    group: string;
    individual: string;
  };
  therapyOrientation: {
    aedp: string;
    acceleratedResolutionTherapyart: string;
    acceptanceAndCommitmentact: string;
    adlerianTheory: string;
    animalassistedTherapy: string;
    appliedBehavioralAnalysis: string;
    artTherapy: string;
    attachmentbased: string;
    biofeedback: string;
    brainspotting: string;
    christianCounseling: string;
    clinicalSupervisionAndLicensedSupervisors: string;
    coaching: string;
    cognitiveBehavioralcbt: string;
    cognitiveProcessingcpt: string;
    compassionFocused: string;
    contemplative: string;
    culturallySensitive: string;
    dancemovementTherapy: string;
    developmental: string;
    dialecticaldbt: string;
    dramaTherapy: string;
    emdr: string;
    eclectic: string;
    ecotherapynaturebased: string;
    emotionalFreedomTechnique: string;
    emotionallyTransformationTherapy: string;
    energyPsychology: string;
    equineTherapy: string;
    existential: string;
    experientialTherapy: string;
    exposureResponsePrevention: string;
    expressiveArts: string;
    familyMarital: string;
    familySystems: string;
    feminist: string;
    forensicPsychology: string;
    gestalt: string;
    gottmanMethod: string;
    holisticHealth: string;
    humanistic: string;
    hypnotherapy: string;
    image: string;
    integrative: string;
    internalFamilySystems: string;
    interpersonal: string;
    intervention: string;
    jungian: string;
    lifespanIntegration: string;
    meditation: string;
    metacognitiveTherapy: string;
    mindfulnessbased: string;
    motivationalInterviewing: string;
    multiculturalTheory: string;
    musicTherapy: string;
    narrative: string;
    neurolinguistic: string;
    neurofeedback: string;
    parentchildInteraction: string;
    personCentered: string;
    photography: string;
    playTherapy: string;
    positivePsychology: string;
    prolongedExposureTherapy: string;
    psychoanalytic: string;
    psychobiologicalApproachCoupleTherapy: string;
    psychodynamic: string;
    psychologicalTestingAndEvaluation: string;
    qigong: string;
    rapidResolutionTherapy: string;
    rationalEmotiveBehavior: string;
    realityTherapy: string;
    relationalTherapy: string;
    restorationTherapy: string;
    rogerian: string;
    sandplay: string;
    schemaTherapy: string;
    sensorimotorPsychotherapy: string;
    solutionFocusedBrief: string;
    somatic: string;
    strengthbased: string;
    structuralFamilyTherapy: string;
    telehealth: string;
    therapeuticYoga: string;
    transpersonal: string;
    virtualReality: string;
    writing: string;
  };
  treatmentCategory: {
    acne: Omit<HealcoReference, "description" | "category">;
    axdp: Omit<HealcoReference, "description" | "category">;
    edys: Omit<HealcoReference, "description" | "category">;
    hrps: Omit<HealcoReference, "description" | "category">;
    migr: Omit<HealcoReference, "description" | "category">;
    prep: Omit<HealcoReference, "description" | "category">;
    sccm: Omit<HealcoReference, "description" | "category">;
  };
  userRole: {
    healcoAdmin: string;
    patient: string;
    provider: string;
  };
  userType: {
    patientClient: string;
    independentProvider: string;
    hospitalHealthSystem: string;
    employer: string;
    broker: string;
    other: string;
  };
  usState: {
    alabama: string;
    alaska: string;
    arizona: string;
    arkansas: string;
    california: string;
    colorado: string;
    connecticut: string;
    delaware: string;
    florida: string;
    georgia: string;
    hawaii: string;
    idaho: string;
    illinois: string;
    indiana: string;
    iowa: string;
    kansas: string;
    kentucky: string;
    louisiana: string;
    maine: string;
    maryland: string;
    massachusetts: string;
    michigan: string;
    minnesota: string;
    mississippi: string;
    missouri: string;
    montana: string;
    nebraska: string;
    nevada: string;
    newHampshire: string;
    newJersey: string;
    newMexico: string;
    newYork: string;
    northCarolina: string;
    northDakota: string;
    ohio: string;
    oklahoma: string;
    oregon: string;
    pennsylvania: string;
    puertoRico: string;
    rhodeIsland: string;
    southCarolina: string;
    southDakota: string;
    tennessee: string;
    texas: string;
    utah: string;
    vermont: string;
    virginia: string;
    washington: string;
    westVirginia: string;
    wisconsin: string;
    wyoming: string;
  };
}
