import { useEffect, useState } from "react";
import { REF_OTHER } from "../../constants";
import { getRefTables } from "../../util";

/*
 * Use Healco Reference
 - - - - - - - - - - - -
 Given an array of HealCo Reference table names, returns an object containing
 lists
 @param refTables: array of reference table names
 @param omitOther: filters out the "OTHR" options
 @use const [[REF_TABLE.providerSpecialty]: specialties, [REF_TABLE.hearAboutUs]: hearAboutUs, ...] = useHealcoReference([REF_TABLE.specialties, REF_TABLE.hearAboutUs])
 */
export const useHReference = (
  refTables: string[],
  omitOther?: boolean,
): HealcoRefTables => {
  const [refValues, setRefValues] = useState<HealcoRefTables>({});

  // fetch the ref tables
  useEffect(() => {
    (async () => {
      setRefValues(await getRefTables([...refTables]));
    })();
    // eslint-disable-next-line
  }, []);

  if (omitOther) {
    for (const ref in refValues) {
      refValues[ref] = refValues[ref]?.filter((r) => r.id !== REF_OTHER);
    }
  }

  return refValues;
};
