import { Col, Row, Tabs } from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { HealcoTabs } from "../../../../components";
import {
  getFetchResponse,
  IdentityContext,
  isHealcoRole,
  getInitials,
  updateQs,
} from "../../../../util";
import GuestProperties from "../Properties/GuestProperties";
// import SearchSetting from "./SearchSetting";
import HostProperties from "../Properties/HostProperties";
import Organization from "./Organization";
import Membership from "./Membership";
import Profile from "./Profile";
import Notification from "./Notification";
import AccountPhotos, { HealcoAvatar } from "./AccountPhotos";
import styles from "./account.module.scss";
import Modal from "antd/lib/modal/Modal";
import cx from "classnames";
import TwoFactorAuth from "./TwoFactorAuth";
import { Settings } from "./Settings";

const NonAdmin = () => {
  return <></>;
};

export const getProfile = async () => {
  const params = queryString.parse(window.location.search);
  const queryingParam = {
    userId: params.userId,
  };
  try {
    const { result } = await getFetchResponse<HealcoResponse<HealcoProfile>>(
      fetch(`/api/profile?${queryString.stringify(queryingParam)}`),
    );
    return { success: true, result };
  } catch (e) {
    return { success: false, message: e.message };
  }
};

const Account = () => {
  const identity = useContext(IdentityContext);
  const qs = queryString.parse(window.location.search.substring(1));
  const selectedTab = (qs.tab as string) || "profile";
  const [profile, setProfile] = useState<HealcoProfile>();
  const userIsAdmin = isHealcoRole(identity, "healcoAdmin");
  const [modalContent, setModalContent] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const isOrgNameSame =
    `${profile?.firstName} ${profile?.lastName}`.toLowerCase() ===
    `${profile?.organization.name}`.toLowerCase();

  const tabRender = {
    // * User Components
    profile: Profile,
    organization: Organization,
    billing: Membership,
    settings: Settings,
    // search: SearchSetting,
    // * Admin Components
    properties: userIsAdmin ? HostProperties : NonAdmin,
    matches: userIsAdmin ? GuestProperties : NonAdmin,
  } as Record<string, any>;
  const Component = tabRender[selectedTab];

  const modalRender = {
    // * User Components
    notification: Notification,
    profilePic: AccountPhotos,
    twoFA: TwoFactorAuth,
  } as Record<string, any>;
  const ModalComponent = modalRender[modalContent];

  const updateProfile = () => {
    (async () => {
      const { result } = await getProfile();
      setProfile(result);
    })();
  };

  useEffect(() => {
    updateProfile();
    // eslint-disable-next-line
  }, [window.location.search]);

  const handleModal = (component: string) => {
    setModalContent(component);
    setShowModal(!showModal);
  };

  return (
    <div>
      <Modal visible={showModal} footer={null} onCancel={() => handleModal("")}>
        {ModalComponent && (
          <ModalComponent
            profile={profile}
            modalContent={modalContent}
            handleModal={handleModal}
            updateProfile={updateProfile}
          />
        )}
      </Modal>
      <Row gutter={16}>
        <Col xs={24} lg={6}>
          <div className={styles.profileContainer}>
            <div
              className={styles.picContainer}
              title="Change Profile Picture"
              onClick={() => {
                updateQs("type", "profile");
                handleModal("profilePic");
              }}
            >
              {profile?.profilePic && profile?.profilePic !== "initials" ? (
                <img
                  className={styles.profilePic}
                  src={
                    HealcoAvatar(profile?.profilePic) ||
                    `/api/no-auth/download-file/${profile.profilePic}/logos`
                  }
                  alt="Profile"
                />
              ) : (
                <p
                  className={cx(styles.profile, styles.initials)}
                  data-letters={getInitials(
                    `${profile?.firstName} ${profile?.lastName}`,
                  )}
                />
              )}
            </div>
            <p className={styles.profileName}>
              {profile?.firstName} {profile?.lastName}
            </p>
            <p className={styles.accent}>{profile?.email}</p>
            {profile?.createdOn && (
              <p className={styles.accent}>
                Joined {moment(profile.createdOn).format("MMMM YYYY")}
              </p>
            )}
          </div>

          {!isOrgNameSame && (
            <div className={styles.organizationContainer}>
              <div className={styles.orgInfo}>
                <p className={styles.orgName}>{profile?.organization.name}</p>
              </div>
            </div>
          )}

          <div
            className={styles.notificationContainer}
            onClick={() => handleModal("notification")}
          >
            <div>Update Notification Preferences</div>
          </div>

          <div
            className={styles.notificationContainer}
            onClick={() => handleModal("twoFA")}
          >
            <div>Two-Factor Authentication</div>
          </div>
        </Col>
        <Col
          xs={24}
          lg={{ offset: 1, span: 17 }}
          xl={{ offset: 1, span: 16 }}
          xxl={{ offset: 1, span: 15 }}
          className={styles.tabContainer}
        >
          <HealcoTabs defaultTab="profile" toRemove={["isEdit"]}>
            <Tabs.TabPane tab="Profile" key="profile" />
            <Tabs.TabPane tab="Practice" key="organization" />
            {(userIsAdmin || identity.id === profile?.id) && (
              <Tabs.TabPane tab="Billing & Membership" key="billing" />
            )}
            {(userIsAdmin || identity.id === profile?.id) && (
              <Tabs.TabPane tab="Settings" key="settings" />
            )}
            {/* <Tabs.TabPane tab="Search Settings" key="search" /> */}
            {userIsAdmin && <Tabs.TabPane tab="Properties" key="properties" />}
            {userIsAdmin && <Tabs.TabPane tab="Matches" key="matches" />}
          </HealcoTabs>
          <div>
            {Component && <Component profile={profile} identity={identity} />}
          </div>
          <div className={styles.topCorner} />
        </Col>
      </Row>
    </div>
  );
};

export default Account;
