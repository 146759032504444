import React, { useState } from "react";
import { antdFormValidator } from "../../../../../../util";
import { Input, Form, Switch } from "antd";

const PhoneBody = ({ currentPhone }: PhoneBodyProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(true);
  return (
    <>
      <Form.Item
        label="New Phone Number"
        name="phone"
        rules={[
          () => ({
            validator: (_, value) =>
              antdFormValidator<string>(
                value,
                (value) => currentPhone !== value,
              ),
            message: "This is current phone number.",
          }),
          { required: true },
        ]}
      >
        <Input placeholder="Phone" maxLength={10} />
      </Form.Item>
      <Form.Item
        name="confirmPhone"
        rules={[
          ({ getFieldValue }) => ({
            validator: (_, value) =>
              antdFormValidator<string>(
                value,
                (value) => !value || getFieldValue("phone") === value,
              ),
            message: "Your phone number does not match.",
          }),
          { required: true },
        ]}
      >
        <Input placeholder="Confirm Phone" maxLength={10} />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues !== currentValues
        }
      >
        {({ getFieldValue }: { getFieldValue: any }) => {
          const phoneForm = getFieldValue("phone");
          const confirmPhoneForm = getFieldValue("confirmPhone");
          return (
            phoneForm === confirmPhoneForm &&
            phoneForm &&
            confirmPhoneForm && (
              <Form.Item
                initialValue={isChecked}
                name="twoFactor"
                label="Enable Two-factor authentication?"
                tooltip="Two-factor authentication (2FA) refers to a security method used to help
      protect your account from unauthorized access by requiring an extra
      verification of their identity."
              >
                <Switch
                  checked={isChecked}
                  checkedChildren={"Enabled"}
                  unCheckedChildren={"Disabled"}
                  onChange={(e) => setIsChecked(e)}
                />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
    </>
  );
};

export { PhoneBody };

interface PhoneBodyProps {
  currentPhone?: string;
}
