import { Badge } from "antd";
import React from "react";
import styles from "./tagGroup.module.scss";

/*
 * Tag Group
 - - - - - - -
 TODO: Remake
 */
export const TagGroup = ({
  captions,
  selectedIndex,
  selectedIndexChanged,
  className,
  badges = [],
  customBadges = [],
}: TagGroup) => {
  return (
    <div className={`${styles.tagGroupWrapper} ${className ? className : ""}`}>
      {captions.map((caption, index) => {
        const auxClassName = index === selectedIndex ? styles.selectedTag : "";
        const tag = (
          <div
            key={index}
            className={
              badges[index] ? undefined : `${styles.tag} ${auxClassName}`
            }
            onClick={() => selectedIndexChanged(index)}
          >
            <span>{caption.toUpperCase()}</span>
          </div>
        );
        if (badges[index] === true)
          return (
            <Badge key={index} dot className={`${styles.tag} ${auxClassName}`}>
              {tag}
            </Badge>
          );
        if (badges[index])
          return (
            <Badge
              key={index}
              count={customBadges[index] ? customBadges[index] : badges[index]}
              className={`${styles.tag} ${auxClassName}`}
            >
              {tag}
            </Badge>
          );
        return tag;
      })}
    </div>
  );
};

interface TagGroup {
  captions: string[];
  selectedIndex: number;
  selectedIndexChanged: (index: number) => void;
  className?: string;
  badges?: Array<boolean | number>;
  customBadges?: Array<number | JSX.Element>;
}
