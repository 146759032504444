/*
 * Format Money
 - - - - - - - -
 Formats a number to a money format.
 @param value - the amount to be formatted
 @param prependWithDollar - whether the dollar sign is added or not
 */
export const formatMoney = (
  value?: number | null,
  prependWithDollar = true,
) => {
  return `${prependWithDollar ? "$" : ""}${
    !value
      ? "-"
      : value.toLocaleString("en", {
          maximumFractionDigits: 2,
        })
  }`;
};

// TODO-JEST
