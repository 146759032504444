import React, { useState } from "react";
import { Col, Row, notification, Modal } from "antd";
import styles from "./settings.module.scss";
import { HText } from "../../../../../components";
import { handleFetch } from "../../../../../util";
import { API_ROUTE, FETCH } from "../../../../../constants";
import { ChangeFormBody } from "./ChangeForm";
import { PasswordBody, PhoneBody, EmailBody } from "./FormBody";
import validateNumberModalContent from "../Notification/VerifyModal";
import { useClientInformation } from "../../../../../hooks/useClientInformation";

const Settings = ({ profile }: SettingsProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const clientInformation = useClientInformation();

  const handleUpdateEmail = async (values: any) => {
    setLoading(true);
    await handleFetch({
      route: API_ROUTE.account.changeEmail,
      method: FETCH.post,
      body: {
        email: values.email,
        userId: profile.id,
        currentEmail: values.currentEmail,
      },
      onSuccess: () => {
        notification.success({
          duration: 6.5,
          message: "Email",
          description: `A verification email has been sent to ${values.currentEmail}. Please check your inbox and verify to update your email.`,
        });
      },
    });
    setLoading(false);
  };

  const handleUpdatePassword = async (values: any) => {
    setLoading(true);
    await handleFetch({
      route: API_ROUTE.account.changePassword,
      method: FETCH.put,
      body: {
        currentPassword: values.currentPassword,
        password: values.password,
        userId: profile.id,
        clientInformation,
      },
      onSuccess: () => {
        notification.success({
          message: "Password",
          description: "Successfully Updated",
        });
      },
    });
    setLoading(false);
  };

  const handleUpdatePhone = async (values: any) => {
    Modal.confirm(
      validateNumberModalContent(
        values.phone as string,
        profile.id,
        async () => {
          await handleFetch({
            route: API_ROUTE.account.changePhone,
            method: FETCH.put,
            body: {
              phone: values.phone,
              twoFactor: values.twoFactor,
              userId: profile.id,
            },
            onSuccess: () => {
              notification.success({
                message: "Phone Number",
                description: "Successfully Updated",
              });
            },
          });
        },
      ),
    );
  };

  return (
    <Row>
      <Col span={24} className={styles.divider}>
        <HText>
          <b>Email</b> <span className={styles.small}>({profile.email})</span>
        </HText>
        <ChangeFormBody
          isLoading={isLoading}
          submitText="Change Email"
          handleUpdate={handleUpdateEmail}
        >
          <EmailBody currentEmail={profile.email} />
        </ChangeFormBody>
      </Col>
      <Col span={24} className={styles.divider}>
        <HText>
          <b>Password</b>
        </HText>
        <ChangeFormBody
          isLoading={isLoading}
          submitText="Change Password"
          handleUpdate={handleUpdatePassword}
        >
          <PasswordBody />
        </ChangeFormBody>
      </Col>
      <Col span={24} className={styles.divider}>
        <HText>
          <b>Phone Number</b>{" "}
          <span className={styles.small}>({profile.phone})</span>
        </HText>
        <ChangeFormBody
          isLoading={isLoading}
          submitText="Change Phone Number"
          handleUpdate={handleUpdatePhone}
        >
          <PhoneBody currentPhone={profile.phone} />
        </ChangeFormBody>
      </Col>
    </Row>
  );
};

export { Settings };

interface SettingsProps {
  profile: HealcoProfile;
}
