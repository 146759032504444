import { PATH, REF_ID } from "../../../../../../constants";
import { Signup } from "../types";
import { gtagWrapper } from "../../../../../../util";

/*
 Signup Constant
 - - - - - - - - -
 Provides the state information to conditionally direct users through sign up
 */
export const SIGNUP: HealcoSimpleConstant<Signup.Choice> = {
  providerSeeking: {
    name: "Find medical space",
    link: PATH.landing.providerSignup,
    onClick: () =>
      gtagWrapper({
        category: "Provider Signup",
        action: "Find medical space",
      }),
    state: {
      key: "signupReason",
      value: { input: [REF_ID.signupReason.find] },
    },
  },
  providerListing: {
    name: "List my space",
    link: PATH.landing.providerSignup,
    onClick: () =>
      gtagWrapper({ category: "Provider Signup", action: "List my space" }),
    state: {
      key: "signupReason",
      value: { input: [REF_ID.signupReason.list] },
    },
  },
  // integrateFreeMentalHealthcare: {
  //   name: "Integrate free mental healthcare",
  //   link: PATH.landing.providerSignup,
  //   onClick: () =>
  //     gtagWrapper({
  //       category: "Provider Signup",
  //       action: "Integrate free mental healthcare",
  //     }),
  //   state: {
  //     key: "signupReason",
  //     value: { input: [REF_ID.signupReason.acquireNewPatientsClients] },
  //   },
  // },
  integrateFreeMentalHealthcare: {
    name: "Integrate Tele-mental Therapy Into My Practice",
    link: PATH.landing.providerSignup,
    onClick: () =>
      gtagWrapper({
        category: "Provider Signup",
        action: "Integrate Tele-mental Therapy Into My Practice",
      }),
    state: {
      key: "signupReason",
      value: { input: [REF_ID.signupReason.acquireNewPatientsClients] },
    },
  },
  shareOfficeSpaces: {
    name: "Share Office Space",
    link: PATH.landing.providerHome,
  },
  // acne: {
  //   name: "Acne",
  //   link: PATH.landing.acne,
  // },
  erectileDysfunction: {
    name: "Erectile dysfunction",
    link: PATH.landing.edys,
  },
  // prep: {
  //   name: "PReP",
  //   link: PATH.landing.prep,
  // },
  // femaleLibido: {
  //   name: "Female Libido",
  //   link: PATH.landing.sccm,
  // },
  // anotherRxNeeded: {
  //   name: "AnotherRx needed?",
  //   link: 'https://www.pinewoodfamily.com/contact-the-fam',
  // },
  joinPrimaryCareNj: {
    name: "Join Direct Primary Care in NJ",
    link: 'https://www.pinewoodfamily.com/direct-primary-care',
  },
  meetNewTherapyClients: {
    name: "Meet new therapy clients",
    link: PATH.landing.contact,
    state: {
      key: "userType",
      value: { input: [REF_ID.userType.independentProvider] },
    },
  },
  offerHealcoRxBenefits: {
    name: "Offer HealCoRx to your employees",
    link: PATH.landing.providerSignup,
  },
  patientTalkTherapy: {
    name: "Talk therapy",
    link: PATH.auth.therapyNavigation,
    onClick: () =>
      gtagWrapper({
        category: "Patient Signup",
        action: "Therapy navigation call",
      }),
    subText: "Free 15-Minute call",
  },
  patientMedicinalMarijuana: {
    name: "Medicinal marijuana",
    // link: PATH.auth.therapyNavigation,
    // onClick: () => gtagWrapper({ category: "Patient Signup", action: "Medicinal Marijuana License"}),
    subText: "Coming soon: Request a medical marijuana license",
  },
  // PARTNER
  pharmacies: {
    name: "Pharmacies",
    link: PATH.landing.pharmacies,
    state: {
      key: "userType",
      value: { input: [REF_ID.userType.independentProvider] },
    },
  },
  // healthSystems: {
  //   name: "Health Systems",
  //   link: PATH.landing.contact,
  //   state: {
  //     key: "userType",
  //     value: { input: [REF_ID.userType.hospitalHealthSystem] },
  //   },
  // },
  sharedMedicalOffices: {
    name: "Shared Medical Offices",
    link: "https://www.healco.us/medical-office-brokers",
  },
  // brokers: {
  //   name: "Brokers",
  //   link: PATH.landing.contact,
  //   state: {
  //     key: "userType",
  //     value: { input: [REF_ID.userType.broker] },
  //   },
  // },
  medicalOfficesRealEstate: {
    name: "Medical Office Real Estate",
    link: PATH.landing.contact,
    state: {
      key: "userType",
      value: { input: [REF_ID.userType.broker] },
    },
  },
  employers: {
    name: "Employers and Benefits Advisors",
    link: "https://www.pinewoodfamily.com/about",
    // link: PATH.landing.contact,
    state: {
      key: "userType",
      value: { input: [REF_ID.userType.employer] },
    },
  },
  // PATIENT
  primaryCare: {
    name: "Direct Primary Care",
    link: "https://www.pinewoodfamily.com/direct-primary-care",
  },
  acne: {
    name: "Acne",
    link: PATH.landing.acne,
    onClick: () =>
      gtagWrapper({
        category: "Patient Signup",
        action: "Acne",
      }),
    state: {
      key: "treatmentCategories",
      value: [REF_ID.treatmentCategory.acne.id],
    },
  },
  womenHealth: {
    name: "Concierge Women's Health",
    link: "https://www.pinewoodfamily.com/concierge-womens-health",
  },
  telemedicine: {
    name: "Urgent Care and Telemedicine",
    link: "https://www.pinewoodfamily.com/on-demand-urgent-immediate-care",
  },
  nutrition: {
    name: "Nutrition",
    link: "https://www.pinewoodfamily.com/anti-aging-hormone-therapy-regenerative-medicine-and-weight-management",
  },
  onlinePharmacy: {
    name: "Online Pharmacy",
    link: "/online-pharmacy",
  },
  psychedelics: {
    name: "Cannabis and Psychedelics",
    link: "https://www.pinewoodfamily.com/telehealth-pharmacy-cannabis-and-alternative-medicine",
  },
  anotherRx: {
    name: "Another Rx needed?",
    link: "https://www.pinewoodfamily.com/contact-the-fam",
  },

  axdp: {
    name: "Anxiety / Depression",
    link: PATH.landing.axdp,
    onClick: () =>
      gtagWrapper({
        category: "Patient Signup",
        action: "Anxiety / Depression",
      }),
    state: {
      key: "treatmentCategories",
      value: [REF_ID.treatmentCategory.axdp.id],
    },
  },
  edys: {
    name: "Erectile dysfunction",
    link: PATH.landing.edys,
    onClick: () =>
      gtagWrapper({
        category: "Patient Signup",
        action: "Erectile Dysfunction",
      }),
    state: {
      key: "treatmentCategories",
      value: [REF_ID.treatmentCategory.edys.id],
    },
  },
  hrps: {
    name: "Herpes",
    link: PATH.landing.hrps,
    onClick: () =>
      gtagWrapper({
        category: "Patient Signup",
        action: "Herpes",
      }),
    state: {
      key: "treatmentCategories",
      value: [REF_ID.treatmentCategory.hrps.id],
    },
  },
  migr: {
    name: "Migraine",
    link: PATH.landing.migr,
    onClick: () =>
      gtagWrapper({
        category: "Patient Signup",
        action: "Migraine",
      }),
    state: {
      key: "treatmentCategories",
      value: [REF_ID.treatmentCategory.migr.id],
    },
  },
  prep: {
    name: "PrEP",
    link: PATH.landing.prep,
    onClick: () =>
      gtagWrapper({
        category: "Patient Signup",
        action: "PrEP",
      }),
    state: {
      key: "treatmentCategories",
      value: [REF_ID.treatmentCategory.prep.id],
    },
  },
  sccm: {
    name: "Female libido",
    link: PATH.landing.sccm,
    onClick: () =>
      gtagWrapper({
        category: "Patient Signup",
        action: "Female Libido",
      }),
    state: {
      key: "treatmentCategories",
      value: [REF_ID.treatmentCategory.sccm.id],
    },
  },
};
