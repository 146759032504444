import React from "react";
import styles from "./pageTitle.module.scss";

export const PageTitle = ({ title, subtitle, content }: PageTitleProps) => {
  return (
    <div className={styles.pageTitle}>
      <h2>{title}</h2>
      <p className={styles.subtitle}>{subtitle}</p>
      {content}
    </div>
  );
};

interface PageTitleProps {
  title: string;
  subtitle: string;
  content: string | React.ReactNode;
}
