import { Button, Col, message, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../../../components/";
import { useScrollToPosition } from "../../../../../hooks";
import { getFetchResponse } from "../../../../../util";
import DateRange from "./DateRange";
import HeatMap from "./HeatMap";
import StatisticsData from "./StatisticsData";
import styles from "./lesseeMap.module.scss";

const centerCoordinates: HealcoGeometry = { x: 38.2097343, y: -98.5556199 };

export default ({ showAllData }: HeatMapProps) => {
  const [lesseeResults, setLesseeResults] = useState<WeightedLocation[]>([]);
  const [filterData, setFilterData] = useState<LocationResp[]>();
  const [filteredResults, setFilteredResults] = useState<LocationResp[]>([]);
  // Center on US by default
  const [mapGeometry, setMapGeometry] = useState<HealcoGeometry>(
    centerCoordinates,
  );
  const today = parseInt(moment().format("x"));
  const minRangeMonth = parseInt(
    moment()
      .subtract(3, "month")
      .format("x"),
  );
  const [rangeLimits, setRangeLimits] = useState<[number, number]>([
    minRangeMonth,
    today,
  ]);
  const [showAll, setShowAll] = useState<boolean>(true);
  const [cityStats, setCityStats] = useState<LocationProp>({});

  const getLessees = async () => {
    const resp = await getFetchResponse<HealcoResponse<any>>(
      fetch(`/api/search/lessee`),
    );
    if (resp && resp.result) {
      setFilterData(resp.result.uncompressed);
      return setLesseeResults(resp.result.result);
    }

    return message.error("Error: Unable to fetch lessees.");
  };

  useScrollToPosition();

  useEffect(() => {
    getLessees();
  }, []);

  useEffect(() => {
    filterStats(showAll ? lesseeResults : filteredResults);
  }, [showAll, lesseeResults, filteredResults]);

  const resetRange = () => {
    setRangeLimits([minRangeMonth, today]);
    filterDates([minRangeMonth, today]);
  };

  const filterDates = (value: [number, number]) => {
    // If date selection is larger than slider range
    // Set slider ranges
    if (value[0] < rangeLimits[0] && value[1] > rangeLimits[1])
      setRangeLimits([value[0], value[1]]);
    else if (value[0] < rangeLimits[0])
      setRangeLimits([value[0], rangeLimits[1]]);
    else if (value[1] > rangeLimits[1])
      setRangeLimits([rangeLimits[0], value[1]]);

    // Filter data and set results
    if (filterData) {
      const filtered = filterData.filter(
        (e) => e.createdOn >= value[0] && e.createdOn <= value[1],
      );
      setFilteredResults(filtered);
    }
  };
  const filterStats = (results: LocationResp[]) => {
    // Amount of lessees interested in x city
    const cityStats: LocationProp = {};
    results?.forEach((e) => {
      const formatedLocation = e?.isoLocation?.split(", ");
      const city = formatedLocation ? formatedLocation[0] : "";
      const state =
        formatedLocation && formatedLocation[1]
          ? formatedLocation[1].split(" ")[0]
          : "";
      if (cityStats[`${city}`]) cityStats[`${city}`].amount++;
      else {
        cityStats[`${city}`] = {
          location: city,
          amount: 1,
          state,
          geometry: e.geometry,
        };
      }
    });
    setCityStats(cityStats);
  };

  return (
    <>
      <PageTitle
        title="HealCo Activity"
        subtitle="HealCo has providers seeking to expand their practice all over the
    United States!"
        content={
          <p>
            This is a dynamic map that displays the locations that HealCo
            providers are seeking to lease.
          </p>
        }
      />
      <div>
        <Button
          className={styles.reloadBtn}
          type="primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh Map
        </Button>
      </div>
      {showAllData && (
        <DateRange
          showAll={showAll}
          setShowAll={setShowAll}
          rangeLimits={rangeLimits}
          filterDates={filterDates}
          resetRange={resetRange}
        />
      )}
      <Row id={styles.heatMapRow} gutter={[8, 8]}>
        <Col xs={24} md={24} lg={showAllData ? 16 : 24}>
          <div className={styles.googleMap}>
            <HeatMap
              showAll={showAll}
              userGeometry={centerCoordinates}
              mapGeometry={mapGeometry}
              results={lesseeResults ? lesseeResults : []}
              filteredResults={filteredResults}
              interactive={showAllData}
            />
          </div>
          <div>
            <b>Note:</b> some data may not show on first visit. If the map is
            not visible or empty, please refresh once to ensure all data is
            present.
          </div>
        </Col>
        {showAllData && (
          <Col xs={24} md={24} lg={8} className={styles.statistics}>
            <StatisticsData
              setMapGeometry={setMapGeometry}
              results={cityStats}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

interface LocationResp {
  geometry: HealcoGeometry;
  isoLocation: string;
  createdOn: number;
}

interface WeightedLocation extends LocationResp {
  weight: number;
}

interface LocationProp {
  [key: string]: {
    location: string;
    amount: number;
    state: string;
    geometry: HealcoGeometry;
  };
}

interface HeatMapProps {
  showAllData: boolean;
}
