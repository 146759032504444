import queryString from "query-string";
import { history } from "../../";

/*
 * Update Qs List
 - - - - - - - - - -
 ?
 */
export const updateQsList = (
  param: string[],
  value: Array<string | number | undefined | null>,
) => {
  const newQs = queryString.parse(window.location.search);
  for (let i = 0; i < param.length; i++) {
    const v = value[i];
    if (v) {
      newQs[param[i]] = v.toString();
    } else {
      delete newQs[param[i]];
    }
  }
  history.replace({
    pathname: window.location.pathname,
    search: queryString.stringify(newQs),
  });
};
