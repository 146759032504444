import React from "react";
import { Input, Form } from "antd";
import { antdFormValidator } from "../../../../../../util";

const EmailBody = ({ currentEmail }: EmailBodyProps) => {
  return (
    <>
      <Form.Item
        label="Current Email"
        name="currentEmail"
        rules={[
          () => ({
            validator: (_, value) =>
              antdFormValidator<string>(
                value,
                (value) => currentEmail.toLowerCase() === value.toLowerCase(),
              ),
            message: "Your account email does not match the record entered.",
          }),
          { required: true },
        ]}
      >
        <Input placeholder="email@example.com" type="email" />
      </Form.Item>
      <Form.Item
        label="New Email"
        name="email"
        rules={[
          () => ({
            validator: (_, value) =>
              antdFormValidator<string>(
                value,
                (value) => currentEmail.toLowerCase() !== value.toLowerCase(),
              ),
            message:
              "Your new account email cannot be changed to your current email.",
          }),
          { required: true },
        ]}
      >
        <Input placeholder="new-mail@example.com" type="email" />
      </Form.Item>
    </>
  );
};

export { EmailBody };

interface EmailBodyProps {
  currentEmail: string;
}
