import React from "react";
import { Link } from "react-router-dom";
/*
 * HealCo Link
 - - - - - - - - -
 Opens an external link in a new tab.
 @param children - the text or element to be wrapped in the link
 @param link - the link (just the PATH if internal, the full link if external)
 @param newTab - true if the link should open in a new tab
 @param params - optionally set router state
 @param search - passed in an optional query string
 @param onClick - if passed in, performs callback before redirection. Useful for tracking links through LocalStorage, for example
 */
export const HLink = ({
  children,
  to,
  hash,
  newTab,
  params,
  search,
  onClick,
}: HLink) => {
  // adds new tab attributes if necessary
  const newTabProps = newTab
    ? {
        target: "_blank",
        rel: "noopener noreferrer",
      }
    : {};
  // All external links with start with HTTP
  if (to.startsWith("http") || to.startsWith("#") || to.startsWith("mailto:")) {
    return (
      <a href={to} onClick={onClick} {...newTabProps}>
        {children}
      </a>
    );
  } else {
    return (
      <Link
        // add optional url parameters / search queries

        to={{
          pathname: to,
          state: { ...params },
          search,
          hash,
        }}
        onClick={onClick}
        {...newTabProps}
      >
        {children}
      </Link>
    );
  }
};
interface HLink {
  children: React.ReactNode;
  to: string;
  hash?: string;
  newTab?: boolean;
  params?: {
    [param: string]: string | string[] | { [param: string]: string | string[] };
  };
  search?: string;
  onClick?: () => void;
}
