import cx from "classnames";
import React from "react";
import { HContent } from "../../types";
import styles from "./hText.module.scss";

/*
 * HText
 - - - - -
 Mainly used on Pillar pages, provides consistent template
 for styling elements.

  @param children: any content to be rendered
  @param centered: if true, content is centered
  @param textStyle: optional color, background-color and <a> theming
  @param padding: if true, padding is added around content inside HText
  @param biggerText: if true, font-size is 1.25rem for consistent theming
 */

export const HText = ({
  children,
  centered,
  textStyle,
  padding = false,
  biggerText,
  noMargin,
  addMarginTop = false,
}: HTextProps) => (
  <div
    className={cx(styles.hText, {
      [styles.centered]: centered,
      [styles.whitePrimary]: textStyle === "whitePrimary",
      [styles.whiteSecondary]: textStyle === "whiteSecondary",
      [styles.sectionPadding]: padding,
      [styles.biggerText]: biggerText,
      [styles.noMargin]: noMargin,
      [styles.addMarginTop]: addMarginTop,
    })}
  >
    {children}
  </div>
);

interface HTextProps {
  children: React.ReactNode;
  centered?: boolean;
  textStyle?: HContent.TTextStyle;
  padding?: boolean;
  biggerText?: boolean;
  noMargin?: boolean;
  addMarginTop?: boolean;
}
