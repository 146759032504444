import { OptionData, OptionGroupData } from "rc-select/lib/interface";

/*
 * Antd Number Filter Option
 - - - - - - - - - - -
 Used as the "filterOption" function on Ant Design AutoComplete
 when values are numbers
 @param value: value being validated
 @param option:
 @use filterOption: antdNumberFilterOption
 */
export const antdNumberFilterOption = (
  input: string,
  option: OptionData | OptionGroupData | undefined,
) => {
  return option?.value?.toString().includes(input);
};
