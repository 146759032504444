import React, { useState, useContext } from "react";
import { IconButton, HLink } from "../../../../components";
import { Drawer, Row, Col, Tag, Badge } from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import {
  isMobile,
  IdentityContext,
  isHealcoRole,
  LocalStorage,
} from "../../../../util";
import changelogStyles from "./changelog.module.scss";
import currentLogs from "./currentLogs";
import { logCategory, VersionHistory } from "./logs";
import { LOCAL_STORAGE } from "../../../../constants";

const categoryColor = (category: logCategory) => {
  switch (category) {
    case "General":
      return "cyan";
    case "Patient":
      return "purple";
    case "Provider":
      return "red";
    default:
      return "black";
  }
};

const conditionalLink = (
  log: VersionHistory,
  userRoles: Array<string | false>,
) => {
  if (log.link && log.allowedRoles?.some((role) => userRoles.includes(role)))
    return <HLink to={log.link}>{log.linkText}</HLink>;
  if (log.link && !log.allowedRoles)
    return <HLink to={log.link}>{log.linkText}</HLink>;
  else return <></>;
};

const Changelog = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const identity = useContext(IdentityContext);
  const userIsAdmin = isHealcoRole(identity, "healcoAdmin");
  const userIsProvider = isHealcoRole(identity, "provider");
  const userIsPatient = isHealcoRole(identity, "patient");
  const userRoles = [
    userIsAdmin && "healcoAdmin",
    userIsProvider && "provider",
    userIsPatient && "patient",
  ];
  const userVersion = LocalStorage.getItem(LOCAL_STORAGE.version);
  const currentVersion = currentLogs[0].id;
  return (
    <>
      <div
        className={changelogStyles.changelogContainer}
        onClick={() => {
          setIsVisible(!isVisible);
          LocalStorage.setItem(LOCAL_STORAGE.version, currentLogs[0].id);
        }}
      >
        <Badge dot={userVersion !== currentVersion}>
          <IconButton title="View Changelog" icon={<HistoryOutlined />} />
        </Badge>
      </div>
      <Drawer
        title="HealCo Changelog"
        placement="right"
        width={isMobile() ? "70vw" : "60vw"}
        onClose={() => setIsVisible(!isVisible)}
        visible={isVisible}
      >
        {currentLogs.map((log, i) => (
          <Row gutter={[0, 16]} key={i}>
            {i === 0 && (
              <Col span={24}>
                <p>
                  {log.releaseMonth} {log.releaseYear}
                </p>
              </Col>
            )}
            <Col span={4}>
              <Tag color={categoryColor(log.category)}>{log.category}</Tag>
            </Col>
            <Col span={20}>
              <p>{log.content}</p>
              {conditionalLink(log, userRoles)}
            </Col>
            {currentLogs[i + 1] &&
              log.releaseMonth !== currentLogs[i + 1].releaseMonth && (
                <Col span={24}>
                  <p>
                    {currentLogs[i + 1].releaseMonth}{" "}
                    {currentLogs[i + 1].releaseYear}
                  </p>
                </Col>
              )}
          </Row>
        ))}
      </Drawer>
    </>
  );
};

export default Changelog;
