import React from "react";
import { Form, Input } from "antd";
import { HReferenceSelect } from "../../../components";
import { EducationForm } from "../EducationForm";

const FORM_NAME = "provider";

/*
 * Provider Form
 - - - - - - - - - - -
 Form to update a user's Provider information
 */
export const ProviderForm = ({
  full,
  providerSpecialty,
  insurance,
  usState,
}: ProviderFormProps) => {
  return (
    <>
      <HReferenceSelect
        refValues={providerSpecialty}
        name={[FORM_NAME, "providerSpecialty"]}
        label="What is your specialty?"
        multiple
        multipleOther
        required
      />
      {full && (
        <HReferenceSelect
          refValues={insurance}
          name={[FORM_NAME, "insurance"]}
          label="Which insurances do you take?"
          multiple
        />
      )}
      {full && (
        <Form.Item name={[FORM_NAME, "npi"]} label="NPI">
          <Input type="number" />
        </Form.Item>
      )}

      {full && <EducationForm />}

      {full && (
        <HReferenceSelect
          refValues={usState}
          name={[FORM_NAME, "licensedState"]}
          label="Which states are you licensed in?"
          multiple
        />
      )}
      {full && (
        <Form.Item
          name={[FORM_NAME, "yearsPracticed"]}
          label="How many years have you practiced?"
        >
          <Input type="number" />
        </Form.Item>
      )}
    </>
  );
};

interface ProviderFormProps {
  providerSpecialty?: HealcoReference[];
  insurance?: HealcoReference[];
  usState?: HealcoReference[];
  full?: boolean;
}
