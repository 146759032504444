import React, { Fragment } from "react";
import { Switch } from "react-router-dom";

/*
 * Deep Switch
 - - - - - - - -
 Allows for React fragments in a Switch component.

 See: https://github.com/ReactTraining/react-router/issues/5785
 */

export const DeepSwitch = ({ children }) => {
  const flattenedChildren = [];
  flatten(flattenedChildren, children);
  return React.createElement.call(
    React,
    ...[Switch, null].concat(flattenedChildren),
  );
};

const flatten = (target, children) => {
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === Fragment) {
        flatten(target, child.props.children);
      } else {
        target.push(child);
      }
    }
  });
};
