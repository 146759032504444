/*
 * Session Storage
 - - - - - - - - - -
 Type-safe session storage utils
 */

const getItem = (key: string): string | null =>
  window.sessionStorage ? window.sessionStorage.getItem(key) : null;
const setItem = (key: string, value: string) =>
  window.sessionStorage ? window.sessionStorage.setItem(key, value) : null;
const removeItem = (key: string) =>
  window.sessionStorage ? window.sessionStorage.removeItem(key) : null;
const clear = () =>
  window.sessionStorage ? window.sessionStorage.clear() : null;

export const SessionStorage = {
  getItem: <T extends unknown>(
    key: string,
    reviver?: (this: unknown, key: string, value: unknown) => unknown,
  ): T | null => {
    const strItem = getItem(key);
    if (!strItem) {
      return null;
    }
    return JSON.parse(strItem, reviver) as T;
  },

  setItem: <T extends unknown>(
    key: string,
    value: T,
    replacer?: (this: unknown, key: string, value: unknown) => unknown,
  ): void => {
    const strItem = JSON.stringify(value, replacer);
    try {
      setItem(key, strItem);
    } catch {
      clear();
      console.error(
        "Session storage has been cleared due to exceeding its quota",
      );
      try {
        setItem(key, strItem);
      } catch {
        // Despite clearing sessionStorage, this item is too big to cache. Give up...
        console.error("Failed to cache " + key);
      }
    }
  },

  removeItem: (key: string) => {
    return removeItem(key);
  },

  clear,

  keys: () => Object.keys(sessionStorage || {}),
};
