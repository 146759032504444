import React, { useState, useEffect } from "react";
import TwoFactorAuth from "../../Provider/Account/TwoFactorAuth";
import { getProfile } from "../../Provider/Account";
import { LoadingPage, HealcoTabs } from "../../../../components";
import { Row, Col, Tabs } from "antd";
import accountStyles from "../../Provider/Account/account.module.scss";
import queryString from "query-string";
import { Settings } from "../../Provider/Account/Settings";

const Account = () => {
  const [profile, setProfile] = useState<HealcoProfile>();
  const qs = queryString.parse(window.location.search.substring(1));
  const selectedTab = (qs.tab as string) || "settings";
  const tabRender = {
    settings: Settings,
    twoFA: TwoFactorAuth,
  } as Record<string, any>;
  const Component = tabRender[selectedTab];

  useEffect(() => {
    updateProfile();
  }, []);

  const updateProfile = () => {
    (async () => {
      const { result } = await getProfile();
      setProfile(result);
    })();
  };

  if (!profile) return <LoadingPage />;
  return (
    <Row>
      <Col
        xs={24}
        lg={{ offset: 1, span: 17 }}
        xl={{ offset: 1, span: 16 }}
        xxl={{ offset: 1, span: 15 }}
        className={accountStyles.tabContainer}
      >
        <HealcoTabs defaultTab="settings">
          <Tabs.TabPane tab="Settings" key="settings" />
          <Tabs.TabPane tab="Two-Factor Authentication" key="twoFA" />
        </HealcoTabs>
        <div>
          {Component && (
            <Component profile={profile} updateProfile={updateProfile} />
          )}
        </div>
        <div className={accountStyles.topCorner} />
      </Col>
    </Row>
  );
};

export default Account;
