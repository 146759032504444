import React, { useState } from "react";
import { HHeading, HLink } from "../../../components";
import { LandingTemplate } from "../components";
import variables from "../../../styles/variables.module.scss";
import { useScrollToPosition } from "../../../hooks";
import {
  healcoBeanGreen,
  healcorxBeanGreen,
  healcoBeanDark,
} from "../../../constants/cdn";
import { Button, Col, Drawer, Row } from "antd";
import styles from "./home.module.scss";
import { PATH } from "../../../constants";
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MediumOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

const LOGOS: { [key: string]: string } = {
  "For Providers": healcoBeanGreen,
  "For Patients": healcorxBeanGreen,
  "For Partners": healcoBeanDark,
};

const Home = () => {
  useScrollToPosition();
  const [isVisible, setIsVisible] = useState(true);
  const [displayLogo, setDisplayLogo] = useState<string>("For Providers");

  return (
    <LandingTemplate metaTitle="HealCo | Online pharmacy & wellness collective.">
      <Drawer
        bodyStyle={{ padding: 0, margin: 0 }}
        width={"100vw"}
        height={"100vh"}
        placement="top"
        closable={false}
        destroyOnClose
        onClose={() => setIsVisible(false)}
        visible={isVisible}
      >
        <div className={styles.splashDrawer}>
          <img
            src={LOGOS[displayLogo]}
            style={{ height: "200px", marginBottom: 20, transition: "1s" }}
            alt="HealCoRx"
          />
          <HHeading
            color={variables.tertiary}
            centered
            size={3}
            text={<>A Collective of Benefits</>}
          />
          <div className={styles.splashButtons}>

            <HLink to={PATH.landing.patientHome}>
              <Button
                onClick={() => setIsVisible(false)}
                onMouseEnter={() => setDisplayLogo("For Patients")}
                size="large"
              >
                For Patients
              </Button>
            </HLink>
             <HLink to={PATH.landing.extraProviderHome}>
              <Button
                onClick={() => setIsVisible(false)}
                onMouseEnter={() => setDisplayLogo("For Providers")}
                size="large"
              >
                For Providers
              </Button>
            </HLink>

            <HLink to={PATH.landing.partnerHome}>
              <Button
                onClick={() => setIsVisible(false)}
                onMouseEnter={() => setDisplayLogo("For Partners")}
                size="large"
              >
                For Partners
              </Button>
            </HLink>
          </div>
          {/* Copyright and Social */}
          <Row style={{ marginTop: 100 }}>
            <HLink to={PATH.landing.about}>Who We Are</HLink>
          </Row>
          <Row className={styles.bottomRow}>
            <Col xs={24} md={12} className={styles.copyright}>
              <p>Copyright © 2021 HealCo All Rights Reserved.</p>
            </Col>
            <Col xs={24} md={12} className={styles.socialLinks}>
              <HLink newTab to="https://www.HLinkedin.com/company/healco/">
                <LinkedinOutlined />
              </HLink>
              <HLink
                newTab
                to="https://www.facebook.com/groups/2824956500855824/"
              >
                <FacebookOutlined />
              </HLink>
              <HLink newTab to="https://medium.com/@HealCo">
                <MediumOutlined />
              </HLink>
              <HLink newTab to="https://twitter.com/WeAreHealCo">
                <TwitterOutlined />
              </HLink>
              <HLink newTab to="https://www.instagram.com/wearehealco/">
                <InstagramOutlined />
              </HLink>
              <HLink
                newTab
                to="https://www.youtube.com/channel/UCIBSAbOOdC5MDjRrSm19agw"
              >
                <YoutubeOutlined />
              </HLink>
            </Col>
          </Row>
        </div>
      </Drawer>
      <div style={{ height: 2000, backgroundColor: "white" }}></div>
    </LandingTemplate>
  );
};

export default Home;

interface HomeProps {
  isTabletView?: boolean;
  isCellView?: boolean;
}
