import { Form, AutoComplete } from "antd";
import { SelectValue } from "antd/lib/select";
import React from "react";

const { Option } = AutoComplete;

/*
 * HealCo Auto Complete
 - - - - - - - - - - - - -
 Antd wrapper for Auto Complete, which populates with data from an array of HealCo references.
 @param refValues: array of HealCo References
 @param name: form item names ('Other' is appended on other fields)
 @param label: form section's label
 @param placeholder: main input's placeholder
 @param useIds: uses Reference IDs as values instead of names
 @param required: main field required or not
 @param tooltip: string that appears when user hovers over the info tooltip
 @param onChange: onChange Event Handler
 */
export const HAutoComplete = ({
  refValues,
  name,
  label,
  placeholder,
  useIds,
  required = false,
  tooltip,
  onChange,
}: HAutoCompleteProps) => {
  return (
    <>
      {/* Main input field */}
      <Form.Item
        name={name}
        label={label}
        labelCol={{ span: 24 }}
        rules={[{ required }]}
        tooltip={tooltip}
      >
        <AutoComplete
          onChange={onChange ? (v) => onChange(v) : undefined}
          placeholder={placeholder || "Choose one"}
          filterOption={(searchValue, option) =>
            option?.value.toUpperCase().indexOf(searchValue.toUpperCase()) !==
              -1 ||
            option?.children
              .toUpperCase()
              .indexOf(searchValue.toUpperCase()) !== -1
          }
        >
          {refValues?.map((ref) => (
            <Option key={ref.id} value={ref.id}>
              {useIds ? ref.id : ref.name}
            </Option>
          ))}
        </AutoComplete>
      </Form.Item>
    </>
  );
};

interface HAutoCompleteProps {
  refValues?: HealcoReference[];
  name: string | string[];
  label?: string;
  placeholder?: string;
  useIds?: boolean;
  required?: boolean;
  tooltip?: string;
  onChange?: (v: SelectValue) => void;
}
