import React from "react";
import { Route, Switch } from "react-router-dom";
import { PATH } from "../../../constants";
import Settings from "./Account";
import Dashboard from "./Dashboard";
import LoadableChunks from "../../../components/Loadable";

const ProviderRoutes = () => (
  <Switch>
    <Route exact path={PATH.patient.dashboard} component={Dashboard} />
    <Route exact path={PATH.patient.account} component={Settings} />
    <Route component={LoadableChunks.landing.PageNotFound} />
  </Switch>
);

export default ProviderRoutes;
