import cx from "classnames";
import React from "react";
import styles from "./sectionContent.module.scss";

/*
 * Section Content
 - - - - - - - - - -
 All content within landing pages should be wrapped in this component that
 sets a max-width for the content.
 */
export const SectionContent = ({
  className,
  children,
  padding = false,
  style,
  height,
}: SectionContentProps) => {
  return (
    <div
      className={cx(styles.sectionContent, className, {
        [styles.sectionPadding]: padding,
      })}
      style={style ? style : { height: `${height}px` }}
    >
      {children}
    </div>
  );
};

export interface SectionContentProps {
  children: React.ReactNode;
  className?: string;
  padding?: boolean;
  style?: React.CSSProperties;
  height?: number;
}
