import { Tabs } from "antd";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { updateQsList } from "../../../util";
import styles from "./healcotab.module.scss";
// TODO0 Force React Node to be types of antd tabpanes
// import { TabsProps as RcTabsProps, TabPaneProps } from "rc-tabs";

/*
 * HealcoTabs
 - - - - - - - - - - - - - - -
 @param children - {AntdPane} - Ant design pane component wrapping a React Node of what to render
 @param defaultTab - {string} - Default Tab for proper tab selection/highlighting
 @param toRemove - {string[]} - Any QueryString values you want to remove such as isEdit/connectionId
 */
export const HealcoTabs = ({ children, defaultTab, toRemove }: TabsProps) => {
  const qs = queryString.parse(window.location.search);
  const [tab, setTab] = useState<string>("");

  const handleTab = useCallback(
    (key: string) => {
      if (toRemove)
        updateQsList(toRemove, Array(toRemove.length).fill(null) as string[]);

      setTab(key);
      updateQsList(["tab"], [key]);
    },
    [toRemove],
  );

  useEffect(() => {
    if (qs.tab) setTab(qs.tab as string);
    else if (defaultTab) handleTab(defaultTab);
  }, [qs.tab, defaultTab, handleTab]);

  return (
    <Tabs
      className={styles.healcoTabs}
      activeKey={tab}
      onChange={(tab: string) => handleTab(tab)}
      type="card"
    >
      {children}
    </Tabs>
  );
};

interface TabsProps {
  children: React.ReactNode | React.ReactNode[];
  defaultTab?: string;
  toRemove?: string[];
}
