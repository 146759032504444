import React from "react";
import cx from "classnames";
import styles from "./hHeading.module.scss";
import variables from "../../styles/variables.module.scss";

/*
 * HealCo Heading
 - - - - - - - - -
 A simple heading component for consistent styling.
 @param text: main heading text
 @param subText: optional subheading text
 @param size: h size (h2, h3... h5)
 @param centered: text-align: center if true
 @para
 */
export const HHeading = ({
  text,
  subText,
  size = 2,
  centered,
  color,
  subTextColor,
  subTextSize = 6,
  white,
  noMargin,
  addMarginTop,
  gagalin,
  padding = false,
  noPaddingY = false,
}: HHeadingProps) => {
  const Hx = `h${size}` as keyof JSX.IntrinsicElements;
  const Sx = `h${subTextSize}` as keyof JSX.IntrinsicElements;
  return (
    <>
      <Hx
        className={cx(styles.hHeading, {
          [styles.sectionPadding]: padding,
          [styles.noPaddingY]: noPaddingY,
        })}
        style={{
          color: white ? variables.white : color,
          textAlign: centered ? "center" : "left",
          marginBottom: noMargin ? 0 : "20px",
          marginTop: addMarginTop ? "30px" : 0,
          fontFamily: gagalin ? "Gagalin" : "'Now', sans-serif",
        }}
      >
        {text}
      </Hx>
      {subText && (
        <Sx
          className={cx(styles.hHeading, {
            [styles.sectionPadding]: padding,
            [styles.noPaddingY]: noPaddingY,
          })}
          style={{
            color: subTextColor,
          }}
        >
          {subText}
        </Sx>
      )}
    </>
  );
};

interface HHeadingProps {
  text: React.ReactNode;
  subText?: string;
  size?: 2 | 3 | 4 | 5 | 6;
  centered?: boolean;
  color?: string;
  subTextColor?: string;
  subTextSize?: 2 | 3 | 4 | 5 | 6;
  white?: boolean;
  noMargin?: boolean;
  addMarginTop?: boolean;
  gagalin?: boolean;
  padding?: boolean;
  noPaddingY?: boolean;
}
