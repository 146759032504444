import React, { useMemo, useState } from "react";
import { Modal, Button, Steps, Row, Col } from "antd";
import { LocalStorage, isMobile } from "../../../../util";
import { LOCAL_STORAGE, PATH, UI } from "../../../../constants";
import { Dashboard } from "../../../../types";
import { SIGNUP } from "../../../Landing/Home/Welcome/Signup/constants";
import {
  HCard,
  HHeading,
  HIconSVG,
  HLink,
  HList,
  HSpace,
  HStrong,
} from "../../../../components";
import variables from "../../../../styles/variables.module.scss";
import { ReactComponent as iconSuccessCheck } from "../../../../assets/icons/icon-success-check.svg";
import { ReactComponent as iconSearchRealEstate } from "../../../../assets/icons/icon-search-real-estate.svg";
import { ReactComponent as iconRealEstate } from "../../../../assets/icons/icon-real-estate.svg";
import { ReactComponent as iconCaringPatients } from "../../../../assets/icons/icon-caring-patients.svg";

/*
 * Welcome Modal
 - - - - - - - - -
 Displays welcome information to a user
 */

export const WelcomeModal = () => {
  const [current, setCurrent] = useState<number>(0);
  const signupReason = useMemo(
    () =>
      LocalStorage.getItem<Dashboard.signupReason>(LOCAL_STORAGE.signupReason),
    [],
  ) as string[];
  const [visible, setVisible] = useState<boolean>(
    signupReason?.length > 0 ? true : false,
  );

  const integratingTherapyContent = (
    <HSpace>
      <HStrong>
        Are you interested in integrating therapy within your practice?
      </HStrong>
      <p>Here is how:</p>
      <HList
        left
        numbered
        listItems={[
          {
            text: (
              <p>
                Fill out this simple form to notify HealCo of a patient who
                wants to schedule a{" "}
                <HLink to={PATH.auth.therapyNavigation}>
                  complimentary therapy navigation call
                </HLink>
                .
              </p>
            ),
          },
          {
            text: (
              <p>
                Be sure to let the patient know they will be receiving a call
                from HealCo within a few hours.
              </p>
            ),
          },
          {
            text: (
              <p>
                That’s it! The patient will learn about their therapy options
                and proceed with a treatment plan if they choose to.
              </p>
            ),
          },
        ]}
      />
    </HSpace>
  );

  const stepMap = [
    signupReason.includes("FIND") && {
      id: "FIND",
      icon: <HIconSVG customIcon={iconSearchRealEstate} />,
      content: (
        <HCard
          noMargin
          title={
            <>
              <HHeading
                color={variables.white}
                noMargin
                centered
                text={
                  <>
                    <HIconSVG customIcon={iconSearchRealEstate} />{" "}
                    {SIGNUP.providerSeeking.name}
                  </>
                }
              />
            </>
          }
          cornerBean={[{ color: variables.primary, position: "bottomRight" }]}
          headingStyle="primary"
        >
          <HSpace>
            <p>
              We’re excited to help you find a space that perfectly fits your
              needs.
            </p>
            <HList
              noMargin
              left
              listItems={[
                {
                  text: (
                    <p>
                      Keep an eye out for alerts indicating a new property is
                      ready for you to view.
                    </p>
                  ),
                },
                {
                  text: (
                    <p>
                      When you've found one that interests you, you can
                      introduce yourself to the property owner directly from the
                      property's page.
                    </p>
                  ),
                },
              ]}
            />
          </HSpace>
          {integratingTherapyContent}
        </HCard>
      ),
    },
    signupReason.includes("LIST") && {
      id: "LIST",
      icon: <HIconSVG customIcon={iconRealEstate} />,
      content: (
        <HCard
          noMargin
          title={
            <>
              <HHeading
                color={variables.white}
                noMargin
                centered
                text={
                  <>
                    <HIconSVG customIcon={iconRealEstate} />{" "}
                    {SIGNUP.providerListing.name}
                  </>
                }
              />
            </>
          }
          cornerBean={[{ color: variables.tertiary, position: "bottomRight" }]}
          headingStyle="tertiary"
        >
          <HSpace>
            <p>
              We’re excited to help you fill your space with a provider from our
              network.
            </p>
            <HList
              listItems={[
                {
                  text: (
                    <p>
                      As a first step, please make sure your HealCo properties
                      are uploaded to your dashboard so we can find you the
                      perfect match.
                    </p>
                  ),
                },
                {
                  text: (
                    <p>
                      You won't be able to accept property applications until
                      you've signed up for our HealCo Membership, but you'll be
                      able to list your space for everyone to see. If you need
                      help processing the payment, please reply directly and let
                      us know!
                    </p>
                  ),
                },
                {
                  text: (
                    <p>
                      Don't forget to add photos of your space - property
                      listings with high-quality pictures get 94% more views.
                    </p>
                  ),
                },
              ]}
            />
          </HSpace>
          {integratingTherapyContent}
        </HCard>
      ),
    },
    signupReason.includes("ACQR") && {
      id: "ACQR",
      icon: <HIconSVG customIcon={iconCaringPatients} />,
      content: (
        <HCard
          noMargin
          title={
            <>
              <HHeading
                color={variables.white}
                noMargin
                centered
                text={
                  <>
                    <HIconSVG customIcon={iconCaringPatients} />{" "}
                    {SIGNUP.integrateFreeMentalHealthcare.name}
                  </>
                }
              />
            </>
          }
          cornerBean={[{ color: variables.secondary, position: "bottomRight" }]}
          headingStyle="secondary"
        >
          <HSpace>
            <p>Through HealCo, you can:</p>
            <HList
              left
              noMargin
              listItems={[
                {
                  text: "list your medical space",
                },
                {
                  text: "find new space",
                },
                {
                  text: "integrate therapy within your practice",
                },
                {
                  text: "Or become a HealCo therapist.",
                },
              ]}
            />

            <p>
              Join us as we continue our mission of bringing care to communities
              that need it the most!
            </p>
          </HSpace>
          {integratingTherapyContent}
        </HCard>
      ),
    },
    signupReason.includes("PATIENT") && {
      id: "PATIENT",
      icon: <HIconSVG customIcon={iconSuccessCheck} />,
      content: (
        <HCard
          noMargin
          title={
            <>
              <HHeading
                color={variables.white}
                noMargin
                centered
                text={
                  <>
                    <HIconSVG customIcon={iconSuccessCheck} /> You’ve joined
                    HealCoRx!
                  </>
                }
              />
            </>
          }
          cornerBean={[{ color: variables.secondary, position: "bottomRight" }]}
        >
          <HSpace>
            <p>
              We’re an online pharmacy and wellness collective bringing health
              care directly to your doorstep - and we’re so glad you’re here.
            </p>
            <p>How does it work?</p>
            <HList
              noMargin
              left
              numbered
              listItems={[
                {
                  text:
                    "Request a prescription by completing your online consultation.",
                },
                {
                  text:
                    "Your request will be reviewed by our team of licensed providers.",
                },
                {
                  text:
                    "If approved, your order will ship automatically. If your request is denied, we will inform you via email.",
                },
                {
                  text: (
                    <p>
                      We know how connected your physical health is to your
                      mental health.{" "}
                      <HLink to={PATH.landing.talkTherapy}>
                        Schedule a complimentary therapy consultation with a
                        licensed psychologist
                      </HLink>
                      .
                    </p>
                  ),
                },
                {
                  text: (
                    <p>
                      We’ll deliver your order in discreet packaging to protect
                      your privacy
                    </p>
                  ),
                },
              ]}
            />

            <p>
              That’s it! Questions? Visit us online at{" "}
              <HLink to="/">www.healcorx.com</HLink> or{" "}
              <HLink to={UI.support.email}>contact support</HLink>.
            </p>
            <p>
              We’re here to help. We look forward to being your home base for
              all things health and wellness.
            </p>
          </HSpace>
        </HCard>
      ),
    },
  ].filter((step) => step);

  const handleClose = () => {
    setVisible(false);
    LocalStorage.removeItem(LOCAL_STORAGE.signupReason);
  };

  return (
    <Modal
      width={isMobile() ? "90vw" : "50vw"}
      visible={visible}
      onCancel={handleClose}
      footer={[
        current > 0 && (
          <Button key="prev" onClick={() => setCurrent(current - 1)}>
            Previous
          </Button>
        ),
        current < stepMap.length && (
          <Button
            key="next"
            type="primary"
            onClick={() => {
              if (current + 1 < stepMap.length) setCurrent(current + 1);
              else handleClose();
            }}
          >
            Next
          </Button>
        ),
      ]}
      title={
        <HHeading
          text="Welcome to your HealCo Dashboard!"
          size={4}
          centered
          noMargin
          color={variables.text}
        />
      }
    >
      <Steps current={current}>
        {stepMap.length > 1 &&
          stepMap?.map(
            (signupReason) =>
              signupReason && (
                <Steps.Step icon={signupReason.icon} key={signupReason.id} />
              ),
          )}
      </Steps>
      <Row style={{ marginTop: 20 }}>
        {stepMap[current] && (
          <Col>{(stepMap[current] as StepMapType).content}</Col>
        )}
      </Row>
    </Modal>
  );
};

interface StepMapType {
  id: string;
  icon: JSX.Element;
  content: React.ReactNode;
  image?: JSX.Element;
}
