import { useEffect, useState } from "react";

/*
 * Use Client Information
 - - - - - - - - - - - - -
 Returns the IP, Device and Approximate Location of the client
 */
export const useClientInformation = (): HealcoClientInformation | undefined => {
  const [clientInformation, setClientInformation] = useState<
    HealcoClientInformation
  >();

  useEffect(() => {
    (async () => {
      setClientInformation(await getClientInformation());
    })();
  }, []);

  return clientInformation;
};

/*
 Get Client Information
 - - - - - - - - - - - - -
 Returns an object containing information about the client's location and operating system.
 # Author: @Kevin Zheng, Modified by @Rohan Deshpande, @Brandon Huelga
*/
const getClientInformation = async (): Promise<HealcoClientInformation> => {
  const cacheLocation = localStorage.getItem("ip-geoLocation");
  // Stored location check if 1 minute has elapsed. If it has, do API call
  // If it has not, use data from Stored location.
  if (
    !cacheLocation ||
    JSON.parse(cacheLocation).timestamp + 60000 < Date.now()
  ) {
    try {
      const apiResponse: any = await fetch(`https://ipapi.co/json/`);
      const data = await apiResponse.json();

      const clientInformation = {
        ip: data.ip,
        approximateLocation: `${data.city}, ${data.region}`,
        os: navigator.platform,
        geometry: {
          x: data.latitude,
          y: data.longitude,
          zipcode: data.postal,
          state: data.region_code,
        },
      };

      // caches the client info object
      localStorage.setItem(
        "ip-geoLocation",
        JSON.stringify({
          timestamp: Date.now(),
          location: clientInformation,
        }),
      );

      return clientInformation;
    } catch (err) {
      // Ignore
    }
  } else {
    return JSON.parse(cacheLocation).location;
  }

  return {} as HealcoClientInformation;
};
