import React from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { HLink } from "../../components";
import { Sitemap } from "../../types";
import { useToggle } from "../../hooks";
import styles from "./hCollapseLink.module.scss";

const { Panel } = Collapse;

export const HCollapseLink = ({ text, expandItems }: HCollapseLinkProps) => {
  const [isToggled, toggle] = useToggle();

  return (
    <Collapse onChange={toggle} ghost>
      <Panel
        key={"HCollapseLink"}
        showArrow={false}
        header={
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {text}
            <CaretDownOutlined
              className={styles.caret}
              rotate={isToggled ? 180 : 0}
            />
          </span>
        }
      >
        <ul>
          {expandItems.map((item, i) => (
            <li key={i}>
              <HLink to={item.link} hash={item.hash}>
                {item.text}
              </HLink>
            </li>
          ))}
        </ul>
      </Panel>
    </Collapse>
  );
};

interface HCollapseLinkProps {
  text: string;
  expandItems: Sitemap.Link[];
}
