/*
 * Make Full Address
 - - - - - - - - - - - - - -
 Takes in a HealCo address object and returns it in a readable string
 @param address - HealCo Address object
 */

const ifExists = (string?: string, extraString = "") => {
  return string ? string + extraString : "";
};

export const makeFullAddress = (a: HealcoAddress): string => {
  return `${ifExists(a?.addressLineOne)}${` ${ifExists(
    a?.addressLineTwo,
    ",",
  )}`} ${ifExists(a?.city, ",")} ${ifExists(a?.usState)} ${ifExists(
    a?.zipcode,
  )}`;
};

// TODO: Write Jest Unit Test
