import React from "react";
import variables from "../../../styles/variables.module.scss";
import { MenuOutlined } from "@ant-design/icons";
import styles from "./headerHamburger.module.scss";

/*
* Header Hamburger
- - - - - - - - - -
Hamburger Manu for Landing page
*/
const HeaderHamburger = ({
  isScrolled,
  isHamburgerActive,
  toggleHamburgerActive,
}: HeaderHamburger) => {
  return (
    <MenuOutlined
      className={styles.menuIcon}
      onClick={toggleHamburgerActive}
      style={{
        color:
          isScrolled || isHamburgerActive ? variables.primary : variables.white,
      }}
    />
  );
};

interface HeaderHamburger {
  isScrolled: boolean;
  isHamburgerActive: boolean;
  toggleHamburgerActive: () => void;
}

export default HeaderHamburger;
