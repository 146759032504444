// ! Going to be deprecated

import { history, triggerIdentityRefresh } from "../..";
import { PATH } from "../../../constants";

/*
 * Get Fetch Response
 - - - - - - - - - - -
 Fetch wrapper.
 */
export async function getFetchResponse<
  T extends HealcoResponse<any> | HealcoResponsePage<any>
>(response: Response | Promise<Response>): Promise<T> {
  if (response instanceof Promise) response = await response;
  if (response.status === 401) {
    triggerIdentityRefresh({} as HealcoUserIdentity);
    const e = new Error("Session expired") as HealcoJSONError;
    e.httpStatus = 401;
    history.push(PATH.auth.login);
    throw e;
  }
  if (response.status === 404) throw Error("404: Page or object not found");
  if (response.status === 504) throw Error("504: Connection error");

  let json;
  try {
    json = await response.json();
  } catch (e) {
    /* ignore */
  }

  if (!json) throw Error("Response does not seem to be a valid JSON");

  if (json.success) return json;

  const message = json.message || (json.error && json.error.message);
  if (message) {
    const e = new Error(message) as HealcoJSONError;
    e.json = json;
    e.httpStatus = response.status;
    throw e;
  }

  if (response.status !== 200) {
    const e = new Error(
      `HTTP ${response.status}: Server reported an error`,
    ) as HealcoJSONError;
    e.json = json;
    e.httpStatus = response.status;
    throw e;
  } else {
    const e = new Error(
      "Server report unsuccessful operation",
    ) as HealcoJSONError;
    e.json = json;
    e.httpStatus = response.status;
    throw e;
  }
}
