/*
 * Local Storage
 - - - - - - - - - -
 Type-safe local storage utils
 */

const getItem = (key: string): string | null =>
  window.localStorage ? window.localStorage.getItem(key) : null;
const setItem = (key: string, value: string) =>
  window.localStorage ? window.localStorage.setItem(key, value) : undefined;
const removeItem = (key: string) =>
  window.localStorage ? window.localStorage.removeItem(key) : undefined;
const clear = () => (window.localStorage ? window.localStorage.clear() : null);

export const LocalStorage = {
  getItem: <T>(
    key: string,
    reviver?: (this: unknown, key: string, value: unknown) => unknown,
  ): T | null => {
    const strItem = getItem(key);
    if (!strItem) {
      return null;
    }
    return JSON.parse(strItem, reviver) as T;
  },
  setItem: <T>(
    key: string,
    value: T,
    replacer?: (this: unknown, key: string, value: unknown) => unknown,
  ): void => {
    const strItem = JSON.stringify(value, replacer);
    setItem(key, strItem);
  },
  removeItem: (key: string) => {
    removeItem(key);
  },

  clear,

  keys: () => Object.keys(localStorage || {}),
};
