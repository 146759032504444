import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Row, Select } from "antd";
import cx from "classnames";
import React, { useReducer } from "react";
import { LoadingPage } from "../../../../../components";
import { REF_TABLE } from "../../../../../constants";
import { useHReference } from "../../../../../hooks";
import {
  actionCreators,
  getFetchResponse,
  objectMetaReducer,
} from "../../../../../util";
import { InputField } from "../Profile/EditInfo";
import styles from "../Profile/profile.module.scss";
import { HealcoOrganizationTemp } from ".";

const EditInfo = ({
  organization,
  dispatchOrgInfo,
  getOrganization,
  setIsEdit,
}: EditInfo) => {
  const { [REF_TABLE.usState]: usStates } = useHReference([REF_TABLE.usState]);

  const [address, dispatchAddress] = useReducer<
    TObjectReducer<HealcoAddress | undefined>
  >(objectMetaReducer, organization?.address);

  const handleSave = async () => {
    try {
      if (organization) {
        const data = organization as HealcoOrganization;
        // * Delete data not updating
        delete data.createdOn;
        delete data.isEmailVerified;
        delete data.isPhoneVerified;
        delete data.profilePic;
        delete data.propertySearchSettings;

        const { success } = await getFetchResponse<any>(
          fetch(`/api/organization`, {
            method: "PUT",
            body: JSON.stringify({ ...data, address }),
            headers: { "Content-Type": "application/json" },
          }),
        );
        if (success) {
          getOrganization();
          setIsEdit(false);
        }
      }
    } catch (err) {
      message.error("Unable to update organization. Please try again later.");
    }
  };

  if (!organization) return <LoadingPage />;

  return (
    <Form layout="vertical" onFinish={handleSave}>
      <div className={styles.editContainer}>
        <InputField
          label="Practice name"
          isRequired={true}
          value={organization?.name}
          onChange={(e) =>
            dispatchOrgInfo(
              actionCreators.metaUpdateObject({
                name: e.target.value,
              }),
            )
          }
        />
      </div>
      <div className={cx(styles.flexInfo, styles.infoContainer)}>
        <InputField
          label="Website"
          value={organization?.website}
          onChange={(e) =>
            dispatchOrgInfo(
              actionCreators.metaUpdateObject({
                website: e.target.value,
              }),
            )
          }
        />
        <InputField
          label="Phone"
          value={organization?.phone}
          onChange={(e) =>
            dispatchOrgInfo(
              actionCreators.metaUpdateObject({
                phone: e.target.value,
              }),
            )
          }
        />
        <InputField
          label="Fax"
          value={organization?.fax}
          onChange={(e) =>
            dispatchOrgInfo(
              actionCreators.metaUpdateObject({
                fax: e.target.value,
              }),
            )
          }
        />
        <InputField
          label="EIN"
          value={organization?.ein}
          onChange={(e) =>
            dispatchOrgInfo(
              actionCreators.metaUpdateObject({
                ein: e.target.value,
              }),
            )
          }
        />
      </div>
      <div className={styles.editContainer}>
        <p className={styles.infoLabel}>Billing Address </p>
        <Row gutter={16}>
          <Col span={24}>
            <InputField
              label="Address Line One"
              value={address?.addressLineOne || ""}
              onChange={(e) =>
                dispatchAddress(
                  actionCreators.metaUpdateObject({
                    addressLineOne: e.target.value,
                  }),
                )
              }
            />
          </Col>
          <Col span={24}>
            <InputField
              label="Address Line Two"
              value={address?.addressLineTwo || ""}
              onChange={(e) =>
                dispatchAddress(
                  actionCreators.metaUpdateObject({
                    addressLineTwo: e.target.value,
                  }),
                )
              }
            />
          </Col>
          <Col span={8}>
            <InputField
              label="City"
              value={address?.city || ""}
              onChange={(e) =>
                dispatchAddress(
                  actionCreators.metaUpdateObject({
                    city: e.target.value,
                  }),
                )
              }
            />
          </Col>
          <Col span={8}>
            <Form.Item label="State">
              <Select
                size="large"
                style={{ minWidth: "100%" }}
                value={address?.usState || ""}
                filterOption={(input, option: any) =>
                  option?.children
                    .toUpperCase()
                    .indexOf(input?.toUpperCase()) !== -1
                }
                onChange={(state) => {
                  dispatchAddress(
                    actionCreators.metaUpdateObject({
                      usState: state,
                    }),
                  );
                }}
              >
                {usStates?.map((s) => (
                  <Select.Option key={s.id} value={s.id}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <InputField
              label="Zipcode"
              value={address?.zipcode || ""}
              onChange={(e) =>
                dispatchAddress(
                  actionCreators.metaUpdateObject({
                    zipcode: e.target.value,
                  }),
                )
              }
            />
          </Col>
        </Row>
      </div>
      <div className={styles.actionBtns}>
        <Button
          size="large"
          onClick={() => setIsEdit(false)}
          icon={<CloseCircleOutlined />}
        />
        <Button htmlType="submit" size="large" type="primary">
          Save Settings
        </Button>
      </div>
    </Form>
  );
};

export default EditInfo;

interface EditInfo {
  organization?: HealcoOrganizationTemp;
  getOrganization: () => Promise<void>;
  dispatchOrgInfo: React.Dispatch<IReducerObjectAction<HealcoOrganizationTemp>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

interface InputField {
  label: string;
  value: string | undefined;
  onChange: (e: any) => void;
  isRequired?: boolean;
}
