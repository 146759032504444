import { ValidateMessages } from "rc-field-form/lib/interface";

/*
 Antd Config
- - - - - - -
Global Antd config that is passed into the Antd Config Provider in App
*/
const antdConfig = {
  form: {
    validateMessages: {
      required: "Please fill out this field.",
    } as ValidateMessages,
    requiredMark: false,
  },
};

export default antdConfig;
