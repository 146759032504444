import { REF_OTHER } from "../../constants";
import { findHRef } from "../findHRef";
import { makeArrayIfNot } from "../makeArrayIfNot";

/*
 * Prep Ref For API
 - - - - - - - - - - - - - - - - - -
 Given an array of ref ids (and possible other values) that you would receive from
 a Form submission, returns the array as a list of HealCoReferenceOther objects to
 be passed into an API call.
 @param formValues: array of HRef values submitted by a form
 @param refTables: array of HealCo Reference tables
 */
export const prepRefForApi = (
  formValues: HealcoReferenceFormValue | string[] | string,
  refTables?: HealcoReference[],
): HealcoReferenceOther[] => {
  // for multiple values that have no Other field
  if (Array.isArray(formValues)) {
    return formValues.map((refId) => {
      const name = findHRef(refId, refTables);

      return {
        id: refId,
        name,
      };
    });
  } else if (typeof formValues === "string") {
    const name = findHRef(formValues, refTables);

    return [
      {
        id: formValues,
        name,
      },
    ];

    // for multiple values that have an Other field
  } else if (Array.isArray(formValues.input)) {
    return formValues.input.map((refId) => {
      const name = findHRef(refId, refTables);

      if (refId === REF_OTHER) {
        return {
          id: refId,
          name,
          other: makeArrayIfNot(formValues.other),
        };
      } else return { id: refId, name };
    });
    // singular values with an other
  } else {
    return [
      {
        id: formValues.input,
        name: findHRef(formValues.input, refTables),
        other: makeArrayIfNot(formValues.other),
      },
    ];
  }
};
