import React from "react";
import { PATH /*, REF_ID */ /* UI */ } from "../../constants";
import { Sitemap } from "../../types";

/*
 * Map Sitemap to Component
 - - - - - - - - -
 Collection of links that appear on the landing pages.
 Maps Sitemap Constant to different displays depending on the component that is using them.
 Individual links can be hidden through a filter depending on the component.
 TODO1: Modularize more into a hook?
 @param Component: Container component function of specific type to contain sitemap links
 @param props: Any additional props that are needed for the component
 @param filter: Any header(s) that should be skipped when creating the map
 */
export const mapSitemapToComponent = ({
  sitemapComponentCreator,
  props,
  filter,
}: MapSitemapToComponent): JSX.Element => {
  const [Container, SubContainer] = sitemapComponentCreator();

  return (
    <>
      {SITEMAP.filter((cat) => !filter?.includes(cat.header)).map((cat) => (
        <Container key={cat.header} category={cat} {...props}>
          {cat.links.map(
            (
              { sectionHeader, text, link, expandItems, params, hash },
              index,
            ) => {
              return (
                <SubContainer
                  sectionHeader={sectionHeader}
                  text={text}
                  link={link}
                  key={index}
                  hash={hash}
                  expandItems={expandItems}
                  params={params}
                />
              );
            },
          )}
        </Container>
      ))}
    </>
  );
};

interface MapSitemapToComponent {
  sitemapComponentCreator: Sitemap.ComponentCreator;
  props?: { [prop: string]: any };
  filter?: Sitemap.Header[] | Sitemap.Header;
}

/*
 Site Map Constant
 - - - - - - - - -
 Collection of links that appear on the landing pages.
 */
const SITEMAP: Sitemap.Category[] = [
  {
    header: "Patients",
    links: [
      {
        sectionHeader: { text: "Get care" },

        text: "Direct Primary Care",
        link: "https://www.pinewoodfamily.com/direct-primary-care",
      },
      {
        text: "Urgent Care/Telemedicine",
        link: "https://www.pinewoodfamily.com/on-demand-urgent-immediate-care",
      },
      {
        text: "Concierge Women's Health",
        link: "https://www.pinewoodfamily.com/concierge-womens-health",
      },
      {
        text: "Cannabis and Psychedlics",
        link: "https://www.pinewoodfamily.com/telehealth-pharmacy-cannabis-and-alternative-medicine",
      },
      {
        text: "Nutrition",
        link: "https://www.pinewoodfamily.com/anti-aging-hormone-therapy-regenerative-medicine-and-weight-management",
      },
      {
        text: "Talk Therapy",
        link: "https://www.healco.us/schedule-a-complimentary-therapy-navigation",
      },
      {
        text: "Online Pharmacy",
        link: PATH.landing.whatWeTreat,

        expandItems: [
          {
            text: "ED",
            link: PATH.landing.edys,
          },
          {
            text: "Female libido",
            link: PATH.landing.sccm,
          },
          {
            text: "PrEP",
            link: PATH.landing.prep,
          },
          {
            text: "Acne",
            link: PATH.landing.acne,
          },
          {
            text: "Another Rx needed?",
            link: 'https://www.pinewoodfamily.com/contact-the-fam',
          },
        ],
      },
      {
        text: "Mental health",
        link: PATH.landing.talkTherapy,
      },
      // {
      //   text: "Medicinal marijuana",
      //   link: PATH.landing.getMedicinalCannabisLicense,
      // },
    ],
  },
  {
    header: "Providers",
    links: [
      {
        sectionHeader: { text: "Provide care" },
        text: "Share space",
        link: PATH.landing.providerFindListSpace,
      },
      {
        text: "Direct Primary Care",
        link: "https://www.pinewoodfamily.com/direct-primary-care",
      },
      // {
      //   text: "Membership",
      //   link: PATH.landing.providerMemberBenefits,
      // },
      {
        text: "Integrate Mental Therapy",
        link: PATH.landing.providerResources,
        expandItems: [
          {
            text: "Pricing",
            link: PATH.landing.pricing,
          },
          {
            text: "Tips for listing your space",
            link: PATH.landing.getYourMedicalSpaceRented,
          },
          {
            text: "Negotiate a higher wRVU",
            link: PATH.landing.wrvuCalculator,
          },
        ],
      },
    ],
  },
  {
    header: "Partners",
    links: [
      {
        sectionHeader: {
          text: "Partner with HealCo",
        },
        text: "Pharmacies",
        link: PATH.landing.pharmacies,
      },
      {
        text: "Medical Real Estate",
        link: PATH.landing.physicianOrganizations,
      },
      // {
      //   text: "Health systems",
      //   link: PATH.landing.physicianOrganizations,
      // },

      {
        text: "Employers",
        link: PATH.landing.employeeHealthBenefitPlans,
      },

      // {
      //   text: "Brokers",
      //   link: PATH.landing.medicalOfficeBrokers,
      // },
    ],
  },
  {
    header: "About us",
    links: [
      {
        sectionHeader: { text: "Our mission" },
        text: "About us",
        link: PATH.landing.about,
      },
      {
        text: "FAQ",
        link: PATH.landing.faq,
      },
      // {
      //   text: "Help Center",
      //   link: PATH.landing.helpCenter,
      // },
      // {
      //   text: "Pricing",
      //   link: PATH.landing.pricing,
      // },
      {
        text: "Contact us",
        link: PATH.landing.contact,
      },
    ],
  },
];
