/*
 * Convert HRef to HRef Form Value
 - - - - - - - - - - - - - - - - - -
 Converts a HealcoReference object to a HealcoReferenceFormValue
 @param refs: the HealcoReferenceArray
 */
export const combinePropertyPhotos = (
  propertyImages: HealcoFile[],
  room?: HealcoRoom[],
) => {
  const roomImages: { [key: number]: HealcoFile[] } = {};
  room?.forEach((rm, i) => {
    const imgList = rm.filesInfo.files || [];
    return (roomImages[i] = imgList);
  });

  const roomImgArray = [] as HealcoFile[];
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(roomImages)) {
    roomImgArray.push(...(value as HealcoFile[]));
  }
  return propertyImages.concat(roomImgArray);
};
