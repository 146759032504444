import React from "react";
import { Modal, Button, Image } from "antd";
import styles from "./prescriptionFileModal.module.scss";
import { useToggle } from "../../../../../../hooks";

const PrescriptionFileModal = ({
  uploadedFiles,
}: ApprovePrescriptionModalProps) => {
  const [isModalVisible, toggleModal] = useToggle();
  return (
    <div className={styles.margin}>
      <Button type="primary" onClick={toggleModal}>
        View Prescription
      </Button>
      <Modal
        title="Prescription"
        visible={isModalVisible}
        onCancel={toggleModal}
        footer={[
          <Button key="Close" onClick={toggleModal}>
            Close
          </Button>,
        ]}
      >
        <div className={styles.imageContainer}>
          <Image.PreviewGroup>
            {uploadedFiles &&
              uploadedFiles?.map((file) => (
                <Image
                  key={file}
                  height={250}
                  width={250}
                  src={`/api/no-auth/download-file/${file}/patientPrescriptions`}
                />
              ))}
          </Image.PreviewGroup>
        </div>
      </Modal>
    </div>
  );
};

export { PrescriptionFileModal };

interface ApprovePrescriptionModalProps {
  uploadedFiles?: string[];
}
