import { Col, Row } from "antd";
import React from "react";
import { Divider, HHeading } from "..";
import { SIGNUP } from "../../pages/Landing/Home/Welcome/Signup/constants";
import { SignupChoice } from "../../pages/Landing/Home/Welcome/Signup/SignupChoice";
import { ReactComponent as iconTalkTherapy } from "../../assets/icons/icon-talk-therapy.svg";
import { ReactComponent as iconCaringPatients } from "../../assets/icons/icon-caring-patients.svg";
import { ReactComponent as iconBottlePills } from "../../assets/icons/icon-bottle-pills.svg";
import { HIconSVG } from "../HIconSVG";
import { medicationIcons } from "./medicationIcons";
// import { ReactComponent as iconFirstAidGreen } from "../../assets/icons/icon-first-aid-green.svg";

export const StartQuestionnaire = () => {
  return (
    <Row gutter={[12, 16]} justify="center">
      <Col span={24}>
        <HHeading size={5} centered text="What can we help with?" />
      </Col>
      <Col xs={12} md={6}>
        <SignupChoice
          choice={SIGNUP.primaryCare}
          icon={<HIconSVG customIcon={medicationIcons.ACNE} />}
        />
      </Col>
      {/* <Col xs={12} md={6}>
        <SignupChoice
          choice={SIGNUP.acne}
          icon={<HIconSVG customIcon={medicationIcons.ACNE} />}
        />
      </Col> */}
      <Col xs={12} md={6}>
        <SignupChoice
          choice={SIGNUP.womenHealth}
          icon={<HIconSVG customIcon={medicationIcons.EDYS} />}
        />
      </Col>
      {/* <Col xs={12} md={6}>
        <SignupChoice
          choice={SIGNUP.edys}
          icon={<HIconSVG customIcon={medicationIcons.EDYS} />}
        />
      </Col> */}
       <Col xs={12} md={6}>
        <SignupChoice
          choice={SIGNUP.telemedicine}
          icon={<HIconSVG customIcon={medicationIcons.PREP} />}
        />
      </Col>
      {/* <Col xs={12} md={6}>
        <SignupChoice
          choice={SIGNUP.prep}
          icon={<HIconSVG customIcon={medicationIcons.PREP} />}
        />
      </Col> */}

      <Col xs={12}  md={6}>
          <SignupChoice
            choice={SIGNUP.patientTalkTherapy}
            icon={<HIconSVG customIcon={iconTalkTherapy} />}
          />
        </Col>
      {/* <Col xs={12} md={6}>
        <SignupChoice
          choice={SIGNUP.sccm}
          icon={<HIconSVG customIcon={medicationIcons.SCCM} />}
        />
      </Col> */}
      <Divider />
      <Row gutter={[12, 16]} justify="center">
      <Col xs={12} md={8}>
        <SignupChoice
          choice={SIGNUP.nutrition}
          icon={<HIconSVG customIcon={iconCaringPatients} />}
        />
      </Col>
      <Col xs={12} md={8}>
        <SignupChoice
          choice={SIGNUP.onlinePharmacy}
          icon={<HIconSVG customIcon={iconBottlePills} />}
        />
      </Col>
      <Col xs={12} md={8}>
        <SignupChoice
          choice={SIGNUP.psychedelics}
          icon={<HIconSVG customIcon={medicationIcons.SCCM} />}
        />
      </Col>
      {/* <Col xs={12} md={6}>
        <SignupChoice
          choice={SIGNUP.anotherRx}
          icon={<HIconSVG customIcon={medicationIcons.SCCM} />}
        />
      </Col>
       <Col
          xs={24}
          md={24}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <SignupChoice
            choice={SIGNUP.patientTalkTherapy}
            icon={<HIconSVG customIcon={iconTalkTherapy} />}
          />
        </Col> */}

        {/* <Col
          xs={24}
          md={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <SignupChoice
            disabled
            choice={SIGNUP.patientMedicinalMarijuana}
            icon={<HIconSVG customIcon={iconFirstAidGreen}/>}
          />
        </Col> */}
      </Row>
    </Row>
  );
};
