/*
 * Ref Tables
 - - - - - - - - -
 Names of all of the HealCo Reference Tables
 */
export const REF_TABLE: RefTable = {
  accountStatus: "ref_accountStatus",
  ageGroup: "ref_ageGroup",
  auditAccountStatusEvent: "ref_auditAccountStatusEvent",
  auditAuthenticationEvent: "ref_auditAuthenticationEvent",
  auditLeaseEvent: "ref_auditLeaseEvent",
  auditPropertyEvent: "ref_auditPropertyEvent",
  dayOfTheWeek: "ref_dayOfTheWeek",
  developmentType: "ref_developmentType",
  downPaymentType: "ref_downPaymentType",
  faith: "ref_faith",
  fileBucket: "ref_fileBucket",
  gender: "ref_gender",
  hearAboutUs: "ref_hearAboutUs",
  includedService: "ref_includedService",
  insurance: "ref_insurance",
  introductionRejectionReason: "ref_introductionRejectionReason",
  jobTitle: "ref_jobTitle",
  language: "ref_language",
  leaseStatus: "ref_leaseStatus",
  matchDislikeReason: "ref_matchDislikeReason",
  medicalCondition: "ref_medicalCondition",
  notificationCategory: "ref_notificationCategory",
  notificationMethod: "ref_notificationMethod",
  notificationType: "ref_notificationType",
  officeStaff: "ref_officeStaff",
  plan: "ref_plan",
  pronouns: "ref_pronouns",
  propertyStatus: "ref_propertyStatus",
  ratePeriod: "ref_ratePeriod",
  race: "ref_race",
  rating: "ref_rating",
  recreationalDrug: "ref_recreationalDrug",
  relationshipStatus: "ref_relationshipStatus",
  role: "ref_role",
  roomType: "ref_roomType",
  painLevel: "ref_painLevel",
  prescriptionMedicine: "ref_prescriptionMedicine",
  prescriptionRejectionReason: "ref_prescriptionRejectionReason",
  providerRole: "ref_providerRole",
  providerSpecialty: "ref_providerSpecialty",
  sexualOrientation: "ref_sexualOrientation",
  signupReason: "ref_signupReason",
  treatmentCategory: "ref_treatmentCategory",
  therapistType: "ref_therapistType",
  therapyIssue: "ref_therapyIssue",
  therapyLicenseStatus: "ref_therapyLicenseStatus",
  therapyModality: "ref_therapyModality",
  therapyOrientation: "ref_therapyOrientation",
  timeline: "ref_timeline",
  timeslot: "ref_timeslot",
  userRole: "ref_userRole",
  userType: "ref_userType",
  usState: "ref_usState",
};

interface RefTable {
  accountStatus: string;
  ageGroup: string;
  auditAccountStatusEvent: string;
  auditAuthenticationEvent: string;
  auditLeaseEvent: string;
  auditPropertyEvent: string;
  dayOfTheWeek: string;
  developmentType: string;
  downPaymentType: string;
  faith: string;
  fileBucket: string;
  gender: string;
  hearAboutUs: string;
  includedService: string;
  insurance: string;
  introductionRejectionReason: string;
  jobTitle: string;
  language: string;
  leaseStatus: string;
  matchDislikeReason: string;
  medicalCondition: string;
  notificationCategory: string;
  notificationMethod: string;
  notificationType: string;
  officeStaff: string;
  plan: string;
  pronouns: string;
  propertyStatus: string;
  ratePeriod: string;
  race: string;
  rating: string;
  recreationalDrug: string;
  relationshipStatus: string;
  role: string;
  roomType: string;
  painLevel: string;
  prescriptionMedicine: string;
  prescriptionRejectionReason: string;
  providerRole: string;
  providerSpecialty: string;
  sexualOrientation: string;
  signupReason: string;
  treatmentCategory: string;
  therapistType: string;
  therapyIssue: string;
  therapyLicenseStatus: string;
  therapyModality: string;
  therapyOrientation: string;
  timeline: string;
  timeslot: string;
  userRole: string;
  userType: string;
  usState: string;
}
