import React, { Fragment } from "react";
import { HHeading, HSpace } from "..";
import { HContent } from "../../types/hContent";
import variables from "../../styles/variables.module.scss";

export const HTextBlocks = ({
  contentDisplayBlock,
  headingAddMarginTop,
  headingCentered = false,
  headingColor = variables.secondary,
  headingNoMargin = true,
  headingSize = 4,
  headingWhite,
  spaceAlign,
  spaceDirection,
  spaceAddPadding = false,
  spaceSize,
  spaceTextStyle,
  textBlocksArr,
}: HTextBlocksProps) => {
  const blockStyle = {
    display: "block",
  };

  const centeredStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const textArr = textBlocksArr.map((textBlock) => ({
    heading: textBlock.heading && (
      <HHeading
        text={textBlock.heading}
        size={headingSize}
        centered={headingCentered}
        color={headingColor}
        white={headingWhite}
        noMargin={headingNoMargin}
        addMarginTop={headingAddMarginTop}
      />
    ),
    text: textBlock.text,
  }));
  return (
    <div style={!contentDisplayBlock ? centeredStyle : blockStyle}>
      <HSpace
        addPadding={spaceAddPadding}
        size={spaceSize}
        align={spaceAlign}
        direction={spaceDirection}
        textStyle={spaceTextStyle}
      >
        {textArr.map((item, i) => (
          <Fragment key={i}>
            {item.heading}
            {item.text}
          </Fragment>
        ))}
      </HSpace>
    </div>
  );
};

type THTextBlocksItems = {
  heading?: string;
  text?: JSX.Element;
};

interface HTextBlocksProps {
  contentDisplayBlock?: boolean;
  headingAddMarginTop?: boolean;
  headingCentered?: boolean;
  headingColor?: string;
  headingNoMargin?: boolean;
  headingSize?: 2 | 3 | 4 | 5;
  headingWhite?: boolean;
  spaceAlign?: HContent.TSpaceAlign;
  spaceDirection?: HContent.TSpaceDirection;
  spaceAddPadding?: boolean;
  spaceSize?: HContent.TSpaceSize;
  spaceTextStyle?: HContent.TTextStyle;
  textBlocksArr: THTextBlocksItems[];
}
