import { Spin } from "antd";
import queryString from "query-string";
import React, { useCallback, useEffect, useRef } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { HLink } from "../../../components";
import { API_ROUTE, FETCH, PATH } from "../../../constants";
import { useToggle } from "../../../hooks";
import { handleFetch, history } from "../../../util";
import { AuthTemplate, InformationSection } from "../components";

/*
 * Verify Email
 - - - - - - - - -
 This page is where a user will be taken to when clicking the link in the Verify Email
 email.
*/

const VerifyEmail = withRouter(({ location, isUpdate }: VerifyEmailProps) => {
  const [isLoading, toggleLoading] = useToggle();
  const [isSuccess, toggleSuccess] = useToggle();
  const [isFailure, toggleFailure] = useToggle();

  const { verificationKey } = queryString.parse(location.search);

  /*
   Checks that the verification key is correctly formatted.
   */
  const checkVerificationKey = useCallback(() => {
    if (!verificationKey) {
      throw Error(
        "Missing validation key. Please check your email for the correct link.",
      );
    } else if (verificationKey.length < 64) {
      throw Error(
        "Invalid validation key. It appears part of the key is missing. Please check your email for the correct link.",
      );
    }
  }, [verificationKey]);

  const redirect = useRef<any>();

  /*
   Checks that the verification key passed in currently in a record in the database.
   */
  useEffect(() => {
    (async () => {
      toggleLoading();

      checkVerificationKey();
      await handleFetch<HealcoVerifyBody>({
        route: isUpdate
          ? API_ROUTE.auth.verifyUpdateEmail
          : API_ROUTE.auth.verifyEmailUser,
        method: FETCH.post,
        body: {
          verificationKey,
        },
        onSuccess: () => {
          toggleSuccess();
          redirect.current = setTimeout(() => {
            // triggerIdentityRefresh();
            history.push(PATH.auth.login);
          }, 5000);
        },
        onFailure: () => {
          toggleFailure();
        },
      });

      toggleLoading();
    })();
  }, [
    verificationKey,
    checkVerificationKey,
    isUpdate,
    toggleSuccess,
    toggleLoading,
    toggleFailure,
  ]);

  return (
    <AuthTemplate
      metaTitle="HealCo | Verify Email"
      title="Email verification"
      links={[
        <React.Fragment key="1">
          {!isFailure && (
            <HLink
              to={PATH.auth.login}
              onClick={() => clearTimeout(redirect.current)}
            >
              Continue to Dashboard
            </HLink>
          )}
        </React.Fragment>,
      ]}
    >
      <Spin spinning={isLoading}>
        {isSuccess && (
          <InformationSection title="Your email has been verified!">
            You'll be redirected automatically to your dashboard.
          </InformationSection>
        )}
        {isFailure && (
          <InformationSection title="We're unable to verify your email.">
            Please try again later, or contact HealCo Support of this problem
            persists.
          </InformationSection>
        )}
      </Spin>
    </AuthTemplate>
  );
});

export default VerifyEmail;

interface VerifyEmailProps extends RouteComponentProps {
  isUpdate?: boolean;
}
