import React from "react";
import { HealcoTabs } from "../../../../../../components";
import { Tabs } from "antd";
import PrescriptionTable from "../../../Prescriptions/PrescriptionTable";
import queryString from "query-string";

const HProPharmDashboard = ({
  isSecurityChecked,
  userIsAdmin,
  userIsPatient,
  userIsPharmacist,
  userIsHPro,
}: HProPharmDashboardProps) => {
  const qs = queryString.parse(window.location.search);

  return (
    <>
      <HealcoTabs defaultTab={userIsPharmacist ? "approved" : "pending"}>
        {!userIsPharmacist && <Tabs.TabPane tab="Pending" key="pending" />}
        <Tabs.TabPane tab="Approved" key="approved" />
        {!userIsPharmacist && <Tabs.TabPane tab="Rejected" key="rejected" />}
        {userIsAdmin && <Tabs.TabPane tab="Incomplete" key="incomplete" />}
      </HealcoTabs>
      <PrescriptionTable
        userIsPatient={userIsPatient}
        userIsHPro={userIsHPro}
        userIsPharmacist={userIsPharmacist}
        isSecurityChecked={isSecurityChecked}
        trackingDataCondition={
          (userIsPatient || userIsPharmacist) && qs.tab === "approved"
        }
        rejectionDataCondition={qs.tab === "rejected"}
      />
    </>
  );
};

export { HProPharmDashboard };

interface HProPharmDashboardProps {
  isSecurityChecked: boolean;
  userIsAdmin: boolean;
  userIsPatient: boolean;
  userIsPharmacist: boolean;
  userIsHPro: boolean;
}
