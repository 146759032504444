/*
 * HTTP Status
 - - - - - - - - - - - - - -
 This object contains all HTTP statuses.
 */
export const HTTP_STATUS: HttpStatusConstant = {
  success: {
    ok: 200,
    created: 201,
    accepted: 202,
  },
  redirection: {
    movedPermanently: 301,
  },
  clientError: {
    badRequest: 400,
    unauthorized: 401,
    paymentRequired: 402,
    forbidden: 403,
    notFound: 404,
  },
  serverError: {
    internal: 500,
    notImplemented: 501,
    gatewayTimeout: 504,
  },
};

interface HttpStatusConstant {
  success: {
    ok: number;
    created: number;
    accepted: number;
  };
  redirection: {
    movedPermanently: number;
  };
  clientError: {
    badRequest: number;
    unauthorized: number;
    paymentRequired: number;
    forbidden: number;
    notFound: number;
  };
  serverError: {
    internal: number;
    notImplemented: number;
    gatewayTimeout: number;
  };
}
