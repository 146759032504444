import { Form, Checkbox } from "antd";
import { FormItemProps } from "antd/lib/form";
import { CheckboxProps } from "antd/lib/checkbox";
import React from "react";
import { antdFormValidator } from "../../../../util";

/*
 * Required Checkbox
 - - - - - - - - - -
 Simple wrapper for a checkbox that is required to be checked
 to complete a form.
 @param children: checkbox content (ReactNode because we often include links)
 @param validationMessage: message that appears if the user tries to continue without accepting
 @param formItemProps: Antd Form Item Props
 */
export const RequiredCheckbox = ({
  children,
  name = "requiredAgree",
  validationMessage = "Please accept before continuing.",
  formItemProps,
  checkboxProps,
}: RequiredCheckboxProps) => {
  return (
    <Form.Item
      name={name}
      valuePropName="checked"
      rules={[
        {
          required: true,
          validator: (_, value) => antdFormValidator(value, (value) => value),
          message: validationMessage,
        },
      ]}
      {...formItemProps}
    >
      <Checkbox {...checkboxProps}>{children}</Checkbox>
    </Form.Item>
  );
};

interface RequiredCheckboxProps {
  children: React.ReactNode;
  name?: string;
  formItemProps?: FormItemProps;
  checkboxProps?: CheckboxProps;
  validationMessage?: string;
}
