import cx from "classnames";
import React from "react";
import { HLink, HCollapseLink } from "../../../components";
import { Sitemap } from "../../../types";
import { mapSitemapToComponent } from "../../../util";
import styles from "./headerLinks.module.scss";

/*
* Header Links
- - - - - - - -
Header Links that hold an abridged Sitemap
*/
const HeaderLinks = ({ isScrolled, doNotStick }: HeaderLinks) => {
  /* Sitemap Component Creator */
  const sitemapComponentCreator: Sitemap.ComponentCreator = () => {
    const MainMenu: Sitemap.ContainerComponent = ({ children, category }) => {
      const { header } = category;

      return (
        <div key={header} className={styles.headerLinksDropdown}>
          <div
            className={cx(styles.headerLinksDropdownHeader, {
              [styles.doNotStick]: doNotStick,
            })}
          >
            {header}
          </div>
          <div className={styles.headerLinksDropdownContainer}>{children}</div>
        </div>
      );
    };

    const SubMenu: Sitemap.SubContainerComponent = ({
      sectionHeader,
      text,
      link,
      params,
      hash,
      expandItems,
    }) => {
      return (
        <>
          {sectionHeader && (
            <div className={styles.headerLinksDropdownSubHeader}>
              {sectionHeader.link ? (
                <HLink to={sectionHeader.link} hash={hash}>
                  {sectionHeader.text}{" "}
                </HLink>
              ) : (
                sectionHeader.text
              )}
            </div>
          )}
          <div className={styles.headerLinksDropdownLink}>
            {expandItems ? (
              <HCollapseLink text={text} expandItems={expandItems} />
            ) : (
              <HLink to={link} params={params} hash={hash}>
                {text}
              </HLink>
            )}
          </div>
        </>
      );
    };

    return [MainMenu, SubMenu];
  };

  return (
    <div
      className={cx(styles.headerLinkSection, {
        [styles.isScrolled]: isScrolled,
      })}
    >
      {mapSitemapToComponent({
        sitemapComponentCreator,
        filter: ["About us", "Contact us"],
      })}
    </div>
  );
};

export default HeaderLinks;

interface HeaderLinks {
  isScrolled: boolean;
  doNotStick?: boolean;
}
