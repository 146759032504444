import cx from "classnames";
import React from "react";
import variables from "../../styles/variables.module.scss";
import { HContent } from "../../types";
import styles from "./hCard.module.scss";
import { ReactComponent as CornerBean } from "../../assets/textures/bean_current.svg";

/*
 * HCard
 - - - - -
 Raised content in with rounded borders and box shadow styling.  Can take
 a title with styling options.

 Used throughout Pillar pages, can used anywhere in the application.

  @param children: anything content to be rendered on HCard
  @param title: optional main heading text
  @param headingStyle: optional styling
  @param noMargin: removes margin
  @param centered: content center if true
  @param backgroundColor: optional background color style
  @param beanColor: optional rotated bean image in 1 - 4 corners of HCard, provide object for each corner {color: string, position: HContent.TCornerBeanPosition}
 */

export const HCard = ({
  children,
  title,
  headingStyle = "secondary",
  centered = false,
  noMargin = false,
  backgroundColor = variables.white,
  className,
  cornerBean,
  onClick,
}: HCardProps) => {
  return (
    <div
      style={{ backgroundColor }}
      className={cx(styles.hCard, className, {
        [styles.noMargin]: noMargin,
      })}
      onClick={onClick}
    >
      {cornerBean &&
        cornerBean.map((bean, i) => (
          <CornerBean
            key={i}
            className={cx({
              [styles.topRightCornerBean]: bean.position === "topRight",
              [styles.bottomRightCornerBean]: bean.position === "bottomRight",
              [styles.bottomLeftCornerBean]: bean.position === "bottomLeft",
              [styles.topLeftCornerBean]: bean.position === "topLeft",
            })}
            fill={bean.color}
          />
        ))}
      <div className={styles.hCardContent}>
        {title && (
          <div
            className={cx(styles.hCardTitle, {
              [styles.primary]: headingStyle === "primary",
              [styles.secondary]: headingStyle === "secondary",
              [styles.tertiary]: headingStyle === "tertiary",
            })}
          >
            {title}
          </div>
        )}
        <div
          className={styles.hCardBody}
          style={{
            textAlign: centered ? "center" : "left",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

interface HCardProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  backgroundColor?: string;
  centered?: boolean;
  noMargin?: boolean;
  headingStyle?: HContent.TStyle;
  className?: string;
  cornerBean?: Array<{
    color: string;
    position: HContent.TCornerBeanPosition;
  }>;
  onClick?: () => void;
}
