import ReactGa from "react-ga";

export const gtagWrapper = ({
  category,
  action,
}: {
  category: string;
  action: string;
}) => {
  if (process.env.NODE_ENV === "production")
    ReactGa.event({
      category,
      action,
    });
};
