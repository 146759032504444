import { LocalStorage } from "../../localStorage";

export const objectMetaReducer = <T>(
  state: T | undefined,
  action: IReducerObjectAction<T>,
) => {
  switch (action.type) {
    case "init":
      return Object.assign({}, action.payload, state);
    case "update":
      return Object.assign({}, state, action.payload);
    case "reset":
      return Object.assign({}, action.payload);
    default:
      return state;
  }
};

export const persistedObjectMetaReducer = <T>(stateKey: string) => (
  state: T,
  action: IReducerObjectAction<T>,
) => {
  switch (action.type) {
    case "init": {
      const newState = Object.assign({}, action.payload, state);
      LocalStorage.setItem(stateKey, newState);
      return newState;
    }
    case "update": {
      const newState = Object.assign({}, state, action.payload);
      LocalStorage.setItem(stateKey, newState);
      return newState;
    }
    case "reset": {
      const newState = Object.assign({}, action.payload);
      LocalStorage.setItem(stateKey, newState);
      return newState;
    }
    default:
      return state;
  }
};

export const listMetaReducer = <T>(
  state: T[],
  action: IReducerListAction<T>,
) => {
  if (!state) return state;

  switch (action.type) {
    case "list-init": {
      if (action.index === undefined)
        throw Error(`Action ${action.type} requires index`);
      const newItems = [...state];
      const newItem = Object.assign({}, action.payload, state[action.index]);
      newItems.splice(action.index, 1, newItem);
      return newItems;
    }
    case "list-add":
      return [...state, action.payload];
    case "list-update": {
      if (action.index === undefined)
        throw Error(`Action ${action.type} requires index`);
      const newItems = [...state];
      const newItem = Object.assign({}, state[action.index], action.payload);
      newItems.splice(action.index, 1, newItem);
      return newItems;
    }
    case "list-remove": {
      if (action.index === undefined)
        throw Error(`Action ${action.type} requires index`);
      const newItems = [...state];
      newItems.splice(action.index, 1);
      return newItems;
    }
    default:
      return state;
  }
};

export const objectWithItemsMetaReducer = <T>(
  state: ObjectWithItems & T,
  action: IReducerAction<T>,
): T => {
  if (
    action.type === "init" ||
    action.type === "update" ||
    action.type === "reset"
  ) {
    const newObj = objectMetaReducer(state, action);
    if (newObj !== state) return newObj;
  } else {
    const newItems = listMetaReducer(state.items, action);
    if (newItems !== state.items)
      return Object.assign({}, state, { items: newItems });
  }
  return state;
};

interface ObjectWithItems {
  items: any[];
}
