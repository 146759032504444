/*
 * Error Codes
 - - - - - - - - - - - - - -
 This object contains all custom HealCo error codes.
 */

export const ERROR_CODE: ErrorCodeConstant = {
  auth: {
    unverifiedEmail: "UNVERIFIED_EMAIL",
    incorrectPassword: "INCORRECT_PASSWORD",
    deactivatedAccount: "DEACTIVATED_ACCOUNT",
    enforceAdmin: "NO_ADMIN_PRIVILEGES",
    unableToSendEmailVerificationEmail: "UNABLE_TO_SEND_EMAIL_VERIFICATION",
    unableToSendResetPasswordEmail: "UNABLE_TO_SEND_RESET_PASSWORD_EMAIL",
    unableToResetPassword: "UNABLE_TO_RESET_PASSWORD",
    unableToLogIn: "UNABLE_TO_LOG_IN",
    unableToVerifyEmail: "UNABLE_TO_VERIFY_EMAIL",
    invalidResetPasswordLink: "RESET_LINK_INVALID",
    resetPasswordLinkExpired: "RESET_LINK_EXPIRED",
    unableToUpdateLastLogin: "UNABLE_TO_UPDATE_LAST_LOGIN",
    emailVerificationLinkExpired: "VERIFICATION_LINK_EXPIRED",
    failedToVerifyTwoFactorAuth: "INVALID_2FA_AUTH",
    failedToVerifySecurityAuth: "INVALID_USER_AUTH",
  },
  account: {
    unableToCreateAccount: "UNABLE_TO_CREATE_ACCOUNT",
    unableToUpdateProvider: "UNABLE_TO_UPDATE_PROVIDER",
    duplicateEmail: "DUPLICATE_EMAIL",
  },
  questionnaire: {
    unableToGetQuestionnaire: "UNABLE_TO_GET_QUESTIONNAIRE",
    unableToSubmitQuestionnaire: "UNABLE_TO_SUBMIT_QUESTIONNAIRE",
  },
  prescription: {
    unableToGetPrescription: "UNABLE_TO_GET_PRESCRIPTION",
    unableToPostPrescription: "FAILED_TO_POST_PRESCRIPTION",
  },
  sendgrid: {
    unableToCreateContact: "UNABLE_TO_CREATE_CONTACT",
    unableToCreateContactMailingList: "UNABLE_TO_CREATE_CONTACT_MAILING_LIST",
    unableToSendEmail: "UNABLE_TO_SEND_EMAIL",
    invalidTransactionalId: "INVALID_SENDGRID_TRANSACTIONAL_TEMPLATE_ID",
  },
  twilio: {
    unableToSendText: "UNABLE_TO_SEND_TEXT",
  },
  stripe: {
    unableToCreateCustomer: "UNABLE_TO_CREATE_CUSTOMER",
    unableToFindCustomer: "UNABLE_TO_FIND_CUSTOMER",
    unableToCheckout: "UNABLE_TO_CHECKOUT",
    unableToFindSession: "UNABLE_TO_FIND_SESSION",
  },
  db: {
    transactionFailed: "TRANSACTION_FAILED",
    auditEntryFailed: "AUDIT_ENTRY_FAILED",
    getReferencesFailed: "GET_REFERENCES_FAILED",
    insertIntoFailed: "INSERT_INTO_FAILED",
  },
  careCoordination: {
    unableToScheduleNavigation: "UNABLE_TO_SCHEDULE_THERAPY_NAVIGATION",
  },
  misc: {
    gatewayTimeout: "GATEWAY_TIMEOUT",
    unableToAddFeedback: "UNABLE_TO_ADD_FEEDBACK",
    unableToGetFeedback: "UNABLE_TO_GET_FEEDBACK",
    contactUsFailed: "UNABLE_TO_RECEIVE_CONTACT",
    wrvuCalculatorFailed: "UNABLE_TO_SEND_WRVU_RESULTS",
  },
};

interface ErrorCodeConstant {
  auth: {
    unverifiedEmail: string;
    incorrectPassword: string;
    deactivatedAccount: string;
    enforceAdmin: string;
    unableToSendEmailVerificationEmail: string;
    unableToSendResetPasswordEmail: string;
    unableToResetPassword: string;
    unableToLogIn: string;
    unableToVerifyEmail: string;
    invalidResetPasswordLink: string;
    resetPasswordLinkExpired: string;
    unableToUpdateLastLogin: string;
    emailVerificationLinkExpired: string;
    failedToVerifyTwoFactorAuth: string;
    failedToVerifySecurityAuth: string;
  };
  account: {
    unableToCreateAccount: string;
    unableToUpdateProvider: string;
    duplicateEmail: string;
  };
  questionnaire: {
    unableToGetQuestionnaire: string;
    unableToSubmitQuestionnaire: string;
  };
  prescription: {
    unableToGetPrescription: string;
    unableToPostPrescription: string;
  };
  sendgrid: {
    unableToCreateContact: string;
    unableToCreateContactMailingList: string;
    unableToSendEmail: string;
    invalidTransactionalId: string;
  };
  twilio: {
    unableToSendText: string;
  };
  stripe: {
    unableToCreateCustomer: string;
    unableToFindCustomer: string;
    unableToCheckout: string;
    unableToFindSession: string;
  };
  db: {
    transactionFailed: string;
    auditEntryFailed: string;
    getReferencesFailed: string;
    insertIntoFailed: string;
  };
  careCoordination: {
    unableToScheduleNavigation: string;
  };
  misc: {
    gatewayTimeout: string;
    unableToAddFeedback: string;
    unableToGetFeedback: string;
    contactUsFailed: string;
    wrvuCalculatorFailed: string;
  };
}
