import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  notification,
  Popconfirm,
  Select,
  Tag,
} from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { PATH, REF_OTHER, UI } from "../../../../../constants";
import {
  getFetchResponse,
  history,
  IdentityContext,
} from "../../../../../util";
import styles from "./applicationCard.module.scss";

const ApplicationButtons = ({
  btnText,
  popconfirmHelper,
  handleConfirm,
  isLoading,
  btnStyles,
}: ApplicationButtons) => {
  return (
    <Popconfirm
      title={popconfirmHelper}
      onConfirm={() => handleConfirm()}
      okText="Yes"
      cancelText="No"
    >
      <Button
        type="primary"
        disabled={isLoading}
        className={btnStyles}
        loading={isLoading}
      >
        {btnText}
      </Button>
    </Popconfirm>
  );
};

const ApplicationCard = ({
  userIsAdmin,
  application,
  isLoading,
  toggleLoading,
  organizationId,
  getApplications,
  introductionRejectionReasons,
}: ApplicationCardProps) => {
  const identity = useContext(IdentityContext);
  const isFreePlan = identity?.billing?.healcoPlan !== "standard";
  const isFreeCondition =
    isFreePlan &&
    !userIsAdmin &&
    identity.organization.id === application.organization_id;
  const [reason, setReason] = useState<string>("");
  const [reasonOther, setReasonOther] = useState<string>("");

  const address = `${application.address.addressLineOne} ${
    application.address.addressLineTwo ? application.address.addressLineTwo : ""
  }`;
  const partialAddress = `${application.address.city}, ${application.address.usState} ${application.address.zipcode}`;
  const addressInfo =
    application.organization_id === organizationId || userIsAdmin
      ? address + partialAddress
      : partialAddress;

  const handleApplication = async (isApproved: boolean) => {
    toggleLoading(true);
    if (!isApproved) {
      if (!reason) message.error("Please provide a reason.");
      else if (reason === REF_OTHER && !reasonOther)
        message.error("Please provide a reason other.");
    }

    try {
      const applicationBody = {
        introductionRejectionReason: reason,
        introductionRejectionReasonOther: reasonOther,
      };

      const { result, success } = await getFetchResponse<HealcoResponse<any>>(
        fetch(`/api/propertyIntroduction/${isApproved}/${application?.id}`, {
          method: "POST",
          body: JSON.stringify(applicationBody),
          headers: { "Content-Type": "application/json" },
        }),
      );
      if (success) {
        notification.success({
          message: "Success",
          description: isApproved
            ? "Application approved, you can now start your communication with your potential guest."
            : "Application Rejected.",
        });
        if (isApproved && result)
          history.push(
            `${PATH.provider.propertyChat}?propertyChat_id=${result}`,
          );
      }
    } catch (err) {
      message.error("Unable to load applications - ", err);
      toggleLoading(false);
    } finally {
      if (!isApproved) {
        toggleLoading(false);
        getApplications();
      }
    }
  };

  return (
    <Card
      id={application.id}
      className={styles.applicationCard}
      hoverable
      bodyStyle={{ padding: "12px" }}
      style={{ width: 240 }}
      cover={
        <div className={styles.coverPhoto}>
          <img
            alt={`application-`}
            src={`/api/no-auth/download-file/${application.propertyPhoto[0]?.fileName}/properties`}
          />
          <Tag className={styles.tag}>#{application.property_id}</Tag>
        </div>
      }
    >
      <Card.Meta
        title={
          <div className={styles.propertyInfo}>
            <p className={styles.title}>{application.title}</p>
            <p>{addressInfo}</p>
          </div>
        }
        description={
          <>
            <div className={styles.applicationMeta}>
              <div className={styles.contactInfo}>
                {isFreeCondition ? (
                  <p>
                    <b>Contact Info: </b> Upgrade your membership to see more
                  </p>
                ) : (
                  <>
                    <p>
                      <b>Applicant:</b> {application.applicantInfo.name}
                    </p>
                    <p>
                      <b>Email:</b> {application.applicantInfo.email}
                    </p>
                    <p>
                      <b>Phone:</b> {application.applicantInfo.phone}
                    </p>
                  </>
                )}
              </div>
              <div>
                <p>
                  <b>Sent on:</b>{" "}
                  {moment(application.sentOn).format(
                    UI.datetimeFormat.fullDate,
                  )}
                </p>
                <p>
                  <b>Message:</b>{" "}
                  {isFreeCondition ? "Hidden" : application.messageToHost}
                </p>
                <p>
                  <b>How soon:</b> {application.howSoon}
                </p>
              </div>
            </div>
            {application?.organization_id === organizationId &&
              !application?.responded &&
              (isFreePlan ? (
                <Link to={`${PATH.provider.account}?tab=billing`}>
                  <Button>Upgrade Membership</Button>
                </Link>
              ) : (
                <div className={styles.responseBtn}>
                  <ApplicationButtons
                    btnStyles={styles.reject}
                    key="reject"
                    btnText={<DislikeOutlined />}
                    popconfirmHelper={
                      <div>
                        <p>Are you sure? Please provide a reason</p>
                        <Form layout="vertical">
                          <Form.Item label="Reason">
                            <Select
                              onChange={(reason) => setReason(reason as string)}
                            >
                              {introductionRejectionReasons?.map((reason) => (
                                <Select.Option
                                  key={reason.id}
                                  value={reason.id}
                                >
                                  {reason.name}
                                </Select.Option>
                              ))}
                            </Select>
                            {reason === REF_OTHER && (
                              <Form.Item label="Reason Other">
                                <Input
                                  value={reasonOther}
                                  onChange={(e) =>
                                    setReasonOther(e.currentTarget.value)
                                  }
                                />
                              </Form.Item>
                            )}
                          </Form.Item>
                        </Form>
                      </div>
                    }
                    handleConfirm={() => handleApplication(false)}
                    isLoading={isLoading}
                  />
                  <ApplicationButtons
                    btnStyles={styles.approve}
                    key="approve"
                    btnText={<LikeOutlined />}
                    popconfirmHelper="Everything looks good? Great! Approving the application would open a chat where you can discuss details to ensure your lease!"
                    handleConfirm={() => handleApplication(true)}
                    isLoading={isLoading}
                  />
                </div>
              ))}
          </>
        }
      />
    </Card>
  );
};

export default ApplicationCard;

interface ApplicationCardProps {
  userIsAdmin?: boolean;
  application: HealcoApplication;
  isLoading: boolean;
  toggleLoading: React.Dispatch<React.SetStateAction<boolean>>;
  organizationId: string;
  getApplications: () => Promise<void>;
  introductionRejectionReasons?: HealcoReference[];
}

interface ApplicationButtons {
  btnText: string | React.ReactNode;
  popconfirmHelper: string | React.ReactNode;
  handleConfirm: () => Promise<void>;
  isLoading: boolean;
  btnStyles?: string;
}
