/*
 * Open In New Tab
 - - - - - - - - - -
 Opens url in a new tab
 @param url - link to send
 */
export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
  return;
};
