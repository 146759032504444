import React from "react";
import { dateSort, alphabeticalSort } from "../../../../../util/sort";
import moment from "moment";
import { UI } from "../../../../../constants";
import providerStyles from "../../Providers/providers.module.scss";
import { ColumnsType } from "antd/lib/table";

const onlineStatusConf = [
  { className: providerStyles.neverIndicator, title: "Never logged in" },
  { className: providerStyles.offlineIndicator, title: "Offline" },
  {
    className: providerStyles.offlineRecentIndicator,
    title: "Online in the last 24 hours",
  },
  { className: providerStyles.onlineIndicator, title: "Online" },
];

const now = moment();

export const patients = ({
  FilterProps,
  currentPage,
  searchParam,
  stateFilter,
}: {
  FilterProps: (
    filterField: string,
    filteredData: HealcoPatientList[] | undefined,
  ) => {
    filterDropdown: () => JSX.Element;
    filterIcon: (filtered: boolean) => JSX.Element;
  };
  currentPage?: HealcoPatientList[];
  searchParam?: { [key: string]: string };
  stateFilter:
    | Array<{
        text: string;
        value: string;
      }>
    | undefined;
}): ColumnsType<HealcoPatientList> => [
  {
    key: "name",
    title: "Patient Name",
    fixed: "left",
    sorter: (a, b) => alphabeticalSort(a.name, b.name),
    render: (item: HealcoPatientList) => {
      const lastOnlineMoment = moment.utc(item.lastLogin);
      const onlineStatus = item.lastLogin
        ? lastOnlineMoment.add(2, "minutes") > now
          ? 3
          : lastOnlineMoment.add(24, "hours") > now
          ? 2
          : 1
        : 0;
      const conf = onlineStatusConf[onlineStatus];
      return (
        <>
          <span title={conf.title} className={conf.className}>
            ⬤
          </span>
          &nbsp;
          <span>{item.name}</span>
        </>
      );
    },
    ...FilterProps(
      "name",
      currentPage?.filter(
        (patients) =>
          searchParam?.["name"] &&
          patients?.name
            ?.toLowerCase()
            ?.includes(searchParam?.["name"].toLowerCase()),
      ),
    ),
  },
  {
    key: "email",
    title: "Email",
    sorter: (a, b) => alphabeticalSort(a.email, b.email),
    render: (item: HealcoPatientList) => item.email,
    ...FilterProps(
      "email",
      currentPage?.filter(
        (patients) =>
          searchParam?.["email"] &&
          patients?.email
            ?.toLowerCase()
            ?.includes(searchParam?.["email"].toLowerCase()),
      ),
    ),
  },
  {
    key: "phone",
    title: "Phone",
    render: (item: HealcoPatientList) => item.phone,
    ...FilterProps(
      "phone",
      currentPage?.filter(
        (provider) =>
          searchParam?.["phone"] &&
          provider?.phone?.includes(searchParam?.["phone"]),
      ),
    ),
  },
  {
    key: "address",
    title: "Address",
    sorter: false,
    render: (item: HealcoPatientList) => item.address,
    filters: stateFilter,
    filterMultiple: false,
    onFilter: (value, record) =>
      record?.usState?.indexOf(value as string) === 0,
  },

  {
    key: "signup",
    title: "Signup Date",
    sorter: (a, b) => dateSort(a.createdOn, b.createdOn),
    render: (item: HealcoPatientList) =>
      moment(item.createdOn).format(UI.datetimeFormat.date),
  },
  {
    key: "login",
    title: "Last Active",
    sorter: (a, b) => dateSort(a.lastLogin || "0", b.createdOn || "0"),
    render: (item: HealcoPatientList) =>
      moment(item.lastLogin).isValid()
        ? moment(item.lastLogin).format(UI.datetimeFormat.date)
        : "never",
  },
];
