import React from "react";
import { Button, ButtonProps, Form } from "antd";

/*
 * Submit Button
 - - - - - - - -
 Basic wrapper for Form Submit Button
 @param text: submit text
 @param isLoading: turns on loading animation and disables button if passed along
 */
export const SubmitButton = ({
  text = "Submit",
  isLoading,
  ...props
}: SubmitButtonProps) => {
  return (
    <Form.Item noStyle>
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        loading={isLoading}
        disabled={isLoading}
        {...props}
      >
        {text}
      </Button>
    </Form.Item>
  );
};

interface SubmitButtonProps extends ButtonProps {
  text?: string;
  isLoading?: boolean;
}
