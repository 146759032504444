import { Col, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { DateForm } from "..";
import { antdFormValidator, isOnlyLetters, isOnlyNumbers } from "../../../util";
import { HHeading } from "../../HHeading";
import { HReferenceSelect } from "../General";

const FORM_NAME = "user";
const PERSONAL_QUESTION_REASONING =
  "We ask this question so we can best match our patients' specific needs.";

/*
 * User Form
 - - - - - - - - - -
 Form input for a user's first name, last name, and email.
 TODO2: Incorporate gender / race / orientiation, etc (only show on ShowAll)
 @param showEmail: shows email form (default true)
 @param showPhone: shows phone form (default false)
 @param showAll: shows all fields
 */
export const UserForm = ({
  showTitle = false,
  showName = true,
  showEmail = true,
  showPhone,
  showDateOfBirth,
  showLanguage,
  showBio,
  showGender,
  showAll,
  placeholderOnly,
  language,
  gender,
  faith,
  race,
  pronouns,
  sexualOrientation,
}: UserFormProps) => {
  return (
    <>
      {showTitle && <HHeading size={4} text="Personal Information" />}
      {showAll ||
        (showName && (
          <Row gutter={8}>
            <Col xs={12}>
              <Form.Item
                name={[FORM_NAME, "firstName"]}
                label={placeholderOnly ? "" : "First name"}
                rules={[
                  {
                    required: true,
                    validator: (_, value) =>
                      antdFormValidator(value, (v) => isOnlyLetters(v)),
                    message: "Please only enter alphabetical characters.",
                  },
                ]}
              >
                <Input
                  placeholder={placeholderOnly ? "First name" : ""}
                  required
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name={[FORM_NAME, "lastName"]}
                label={placeholderOnly ? "" : "Last name"}
                rules={[
                  {
                    required: true,
                    validator: (_, value) =>
                      antdFormValidator(value, (v) => isOnlyLetters(v)),
                    message: "Please only enter alphabetical characters.",
                  },
                ]}
              >
                <Input
                  placeholder={placeholderOnly ? "Last name" : ""}
                  required
                />
              </Form.Item>
            </Col>
          </Row>
        ))}

      {showAll ||
        (showEmail && (
          <Form.Item
            name={[FORM_NAME, "email"]}
            label={placeholderOnly ? "" : "Email"}
            rules={[{ required: true }]}
          >
            <Input placeholder={placeholderOnly ? "Email" : ""} type="email" />
          </Form.Item>
        ))}

      {showAll ||
        (showPhone && (
          <Form.Item
            name={[FORM_NAME, "phone"]}
            label={placeholderOnly ? "" : "Phone number"}
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  antdFormValidator(
                    value,
                    (v) => isOnlyNumbers(v) && v.length === 10,
                  ),
              },
            ]}
          >
            <Input
              placeholder={placeholderOnly ? "Phone number" : ""}
              maxLength={10}
            />
          </Form.Item>
        ))}

      {showAll ||
        (showBio && (
          <Form.Item
            name={[FORM_NAME, "bio"]}
            label="Personal biography"
            tooltip="Tell other HealCo providers about yourself!"
          >
            <TextArea />
          </Form.Item>
        ))}
      {showAll ||
        (showDateOfBirth && (
          <DateForm name={[FORM_NAME, "dateOfBirth"]} label="Date of Birth" />
        ))}
      {showAll ||
        (showLanguage && (
          <HReferenceSelect
            label="Which languages do you speak?"
            refValues={language}
            name={[FORM_NAME, "language"]}
            multiple
            multipleOther
          />
        ))}
      {showAll ||
        (showGender && (
          <HReferenceSelect
            label="Gender"
            refValues={gender}
            name={[FORM_NAME, "gender"]}
            multiple
            multipleOther
          />
        ))}
      {showAll && (
        <HReferenceSelect
          label="Faith"
          refValues={faith}
          name={[FORM_NAME, "faith"]}
          tooltip={PERSONAL_QUESTION_REASONING}
          multiple
          multipleOther
        />
      )}
      {showAll && (
        <HReferenceSelect
          label="Race"
          refValues={race}
          name={[FORM_NAME, "race"]}
          tooltip={PERSONAL_QUESTION_REASONING}
          multiple
          noOther
        />
      )}
      {showAll && (
        <HReferenceSelect
          label="Sexual Orientation"
          refValues={sexualOrientation}
          name={[FORM_NAME, "gender"]}
          tooltip={PERSONAL_QUESTION_REASONING}
          multiple
          multipleOther
        />
      )}
      {showAll && (
        <HReferenceSelect
          label="Pronouns"
          refValues={pronouns}
          name={[FORM_NAME, "pronouns"]}
          tooltip={PERSONAL_QUESTION_REASONING}
          multiple
          multipleOther
        />
      )}
    </>
  );
};

interface UserFormProps {
  showTitle?: boolean;
  showName?: boolean;
  showEmail?: boolean;
  showPhone?: boolean;
  showBio?: boolean;
  showLanguage?: boolean;
  showDateOfBirth?: boolean;
  showGender?: boolean;
  showAll?: boolean;
  placeholderOnly?: boolean;
  language?: HealcoReference[];
  gender?: HealcoReference[];
  faith?: HealcoReference[];
  race?: HealcoReference[];
  sexualOrientation?: HealcoReference[];
  pronouns?: HealcoReference[];
}
