import { ConfigProvider } from "antd";
import "antd/dist/antd.min.css"; // Antd Styles
import React, { useEffect, useState } from "react";
import { Route, Router, Switch } from "react-router-dom";
import antdConfig from "./antd.config";
import "./assets/fonts/fonts.css"; // Fonts
import LoadableChunks from "./components/Loadable";
import config from "./config";
import { API_ROUTE, FETCH, PATH } from "./constants";
import "./styles/global.module.scss"; // Global App Styles
import "./styles/reset.scss"; // CSS Reset
import {
  handleFetch,
  history,
  IdentityContext,
  setCheckFunction,
  unsetCheckFunction,
} from "./util";
import Dashboard from "./pages/Dashboard/index";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import ReactGa from "react-ga";
import Home from "./pages/Landing/Home";

/*
 * App
 - - - - -
 Main HealCo App
 This file defines the the routes, sets up the User Identity, sets up Google tracking,
 and sets up the global styles.
*/
const App = () => {
  const [identity, setIdentity] = useState({} as HealcoUserIdentity);
  const userId = identity && identity.id;

  const preventDefaultHandler = (e: Event) => e.preventDefault();

  useEffect(() => {
    window.addEventListener("dragover", preventDefaultHandler, false);
    window.addEventListener("drop", preventDefaultHandler, false);

    if (process.env.NODE_ENV === "production") {
      ReactGa.initialize("UA-174006588-1");
      ReactGa.pageview(window.location.pathname + window.location.search);
    }

    /* Google Tracking */
    window.gtag("config", config.gaTrackingId, {
      page_path: window.location.pathname,
    });
    history.listen((location) =>
      window.gtag("config", config.gaTrackingId, {
        page_path: location.pathname,
      }),
    );

    // checks session and redirects to login screen if not successful
    const checkIdentity = async (receivedIdentity?: HealcoUserIdentity) => {
      if (receivedIdentity) {
        // some subscribers, like the login function, receive the identity as a
        // result of their previous operation
        setIdentity(receivedIdentity);
        return;
      }

      // If the user identity exists, set the identity
      await handleFetch<void, HealcoUserIdentity>({
        route: API_ROUTE.auth.getIdentity,
        method: FETCH.get,
        onSuccess: (res) => setIdentity(res),
        ignoreError: true,
      });

      // TODO2: implement on the server side, add to failureCallback
      // if (json.deactive) {
      //   fetch("/api/logout").then(() => {
      //     triggerIdentityRefresh({} as HealcoUserIdentity);
      //     history.push("/");
      //   });
      // }
    };

    setCheckFunction(checkIdentity);
    return () => unsetCheckFunction(checkIdentity);
  }, []);

  /* Updates Google Tracking when user logs in / out */
  useEffect(() => {
    if (userId) window.gtag("config", config.gaTrackingId, { user_id: userId });
  }, [userId]);

  return (
    <ConfigProvider form={{ ...antdConfig.form }}>
      <IdentityContext.Provider value={identity}>
        <Router history={history}>
          <Switch>
            {/* Landing Routes */}
            <Route exact path={PATH.landing.home} render={() => <Home />} />
            <Route
              path={PATH.landing.patientHome}
              component={LoadableChunks.landing.PatientHome}
            />
            <Route
              path={PATH.landing.onlinePharmacyHome}
              component={LoadableChunks.landing.OnlinePharmacyHome}
            />
            <Route
              path={PATH.landing.providerHome}
              component={LoadableChunks.landing.ProviderHome}
            />
            <Route
              path={PATH.landing.extraProviderHome}
              component={LoadableChunks.landing.ExtraProviderHome}
            />
            <Route
              path={PATH.landing.partnerHome}
              component={LoadableChunks.landing.PartnerHome}
            />
            <Route
              path={PATH.landing.offerVirtualCare}
              component={LoadableChunks.landing.OfferVirtualCare}
            />

            <Route
              path={PATH.landing.physicianOrganizations}
              component={LoadableChunks.landing.PhysicianOrganizations}
            />
            <Route
              path={PATH.landing.faq}
              component={LoadableChunks.landing.FAQ}
            />
            <Route
              path={PATH.landing.glossary}
              component={LoadableChunks.landing.Glossary}
            />
            <Route
              path={PATH.landing.pricing}
              component={LoadableChunks.landing.Pricing}
            />
            <Route
              path={PATH.landing.about}
              component={LoadableChunks.landing.About}
            />
            <Route
              path={PATH.landing.contact}
              component={LoadableChunks.landing.Contact}
            />
            <Route
              path={PATH.landing.wrvuCalculator}
              component={LoadableChunks.landing.WrvuCalculator}
            />
            <Route
              path={PATH.landing.providerSignup}
              component={LoadableChunks.landing.ProviderSignup}
            />
            <Route
              path={PATH.landing.patientSignup}
              component={LoadableChunks.landing.PatientSignup}
            />
            {/* <Route
                path={PATH.landing.blog}
                component={LoadableChunks.landing.Blog}
              /> */}
            <Route
              path={PATH.landing.termsAndConditions}
              component={LoadableChunks.landing.TermsAndConditions}
            />
            <Route
              path={PATH.landing.privacyPolicy}
              component={LoadableChunks.landing.PrivacyPolicy}
            />
            <Route
              path={PATH.landing.hipaaAuthorization}
              component={LoadableChunks.landing.HipaaAuthorization}
            />
            <Route
              path={PATH.landing.consentToTelehealth}
              component={LoadableChunks.landing.ConsentToTelehealth}
            />

            <Route
              path={PATH.landing.getMedicinalCannabisLicense}
              component={LoadableChunks.landing.GetMedicinalCannabisLicense}
            />

            <Route
              path={PATH.landing.employeeHealthBenefitPlans}
              component={LoadableChunks.landing.EmployeeHealthBenefitPlans}
            />
            <Route
              path={PATH.landing.medicalOfficeBrokers}
              component={LoadableChunks.landing.MedicalOfficeBrokers}
            />

            <Route
              path={PATH.landing.providerMemberBenefits}
              component={LoadableChunks.landing.ProviderMemberBenefits}
            />

            <Route
              path={PATH.landing.helpCenter}
              component={LoadableChunks.landing.HelpCenter}
            />

            <Route
              path={PATH.landing.providerFindListSpace}
              component={LoadableChunks.landing.ProviderFindListSpace}
            />

            <Route
              path={PATH.landing.talkTherapy}
              component={LoadableChunks.landing.TalkTherapy}
            />

            <Route
              path={PATH.landing.getYourMedicalSpaceRented}
              component={LoadableChunks.landing.GetYourMedicalSpaceRented}
            />

            <Route
              path={PATH.landing.providerResources}
              component={LoadableChunks.landing.ProviderResources}
            />

            <Route
              exact
              path={PATH.landing.whatWeTreat}
              component={LoadableChunks.landing.WhatWeTreat}
            />

            <Route
              exact
              path={PATH.landing.pharmacies}
              component={LoadableChunks.landing.Pharmacies}
            />

            <Route
              path={PATH.landing.edys}
              component={LoadableChunks.landing.ErectileDysfunction}
            />

            <Route
              path={PATH.landing.sccm}
              component={LoadableChunks.landing.FemaleLibido}
            />

            <Route
              path={PATH.landing.prep}
              component={LoadableChunks.landing.Prep}
            />

            <Route
              path={PATH.landing.acne}
              component={LoadableChunks.landing.Acne}
            />

            {/* Auth Pages */}
            <Route
              path={PATH.auth.login}
              component={LoadableChunks.auth.Login}
            />
            <Route
              path={PATH.auth.verifyEmail}
              component={LoadableChunks.auth.VerifyEmail}
            />
            <Route
              path={PATH.auth.verifyUpdateEmail}
              render={() => <VerifyEmail isUpdate={true} />}
            />
            <Route
              path={PATH.auth.forgotPassword}
              component={LoadableChunks.auth.ForgotPassword}
            />
            <Route
              path={PATH.auth.resetPassword}
              component={LoadableChunks.auth.ResetPassword}
            />
            <Route
              path={PATH.auth.questionnaire}
              component={LoadableChunks.landing.Questionnaire}
            />
            <Route
              path={PATH.auth.therapyNavigation}
              component={LoadableChunks.auth.TherapyNavigation}
            />

            {/* Dashboard Pages */}
            <Dashboard />
          </Switch>
        </Router>
      </IdentityContext.Provider>
    </ConfigProvider>
  );
};

export default App;
