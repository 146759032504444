import React from "react";
import { Col, Radio, Row } from "antd";
import cx from "classnames";
import styles from "./medicationOptions.module.scss";
import variables from "../../../styles/variables.module.scss";
import { history } from "../../../util";
import { PATH } from "../../../constants";

const MedicationOptions = ({
  med,
  chosenMedicationId,
  onChoose,
  treatmentCategory,
}: MedicationOptionsProps) => {
  return (
    <Col xs={12} md={8} lg={6} style={{ margin: "0 auto 20px auto" }}>
      <div className={styles.labelSpacer}>
        {Boolean(med.isMostPopular) && (
          <b style={{ color: variables.warning }}>Most popular!</b>
        )}
      </div>
      <Radio.Button
        id={med.id}
        value={{ id: med.id, priceId: med.stripeProductId }}
        className={cx(styles.priceChoice, {
          [styles.active]: chosenMedicationId === med.id,
          [styles.isMostPopular]: med.isMostPopular,
          [styles.displayOnly]: !onChoose,
        })}
        checked={chosenMedicationId === med.id}
        onChange={(e) => {
          if (onChoose) onChoose(e.target.value);
          else {
            history.push({
              pathname: PATH.auth.questionnaire,
              state: { treatmentCategories: [treatmentCategory.id] },
            });
          }
        }}
        onClick={(e) => {
          if (chosenMedicationId === e.currentTarget.id && onChoose)
            onChoose({ id: "", priceId: "" });
        }}
      >
        <Row gutter={[8, 0]} className={styles.individualPrice}>
          <Col span={24} className={cx(styles.medName, styles.centered)}>
            {med.name.split(" ")[0]}
          </Col>
          <Col xs={24} md={med.dosage ? 12 : 24} className={styles.centered}>
            <b>${med.price}</b>
          </Col>

          {med.dosage && (
            <Col className={styles.dosage} xs={24} md={12}>
              <b style={{ fontSize: ".75rem" }}>{med.dosage}</b>
            </Col>
          )}

          <Col span={24} className={styles.centered}>
            <div style={{ fontSize: ".6rem" }}>
              {med.daySupply && `(${med.daySupply} supply)`}
            </div>
          </Col>
        </Row>
      </Radio.Button>
    </Col>
  );
};

export default MedicationOptions;

interface MedicationOptionsProps {
  med: HealcoMedication;
  chosenMedicationId?: string;
  onChoose?: ({ id, priceId }: { id: string; priceId: string }) => void;
  treatmentCategory: HealcoReference;
}
