import cx from "classnames";
import React from "react";
import variables from "../../styles/variables.module.scss";
import styles from "./hList.module.scss";

/*
 * HList
 - - - - -
 Can be a ul or ol with consistent styling, mainly used on Pillar pages.

  @param children: optional to pass a title (HHeading)
  @param listItems: items to render as <li>'s
  @param left: left orientation on page
  @param numbered: if true, <ol> with numbers markers render
  @param biggerText: if true, font-size increased for consistent 1.25rem styling
  @param color: optional color styling for HList
  @param secondary: if true, HList will render in $secondary color
 */

export const HList = ({
  children,
  listItems,
  left,
  numbered,
  noMargin,
  biggerText,
  color,
  secondary,
  noMarker,
  noPadding,
  pointer,
}: HListProps) => {
  const listContent = listItems.map((li, i) => (
    <li key={i} className={cx({ [styles.pointer]: pointer })}>
      {li.text}
      {li.subList && (
        <>
          <br />
          <br />
          <ul>
            {li.subList.map((subListItem, i) => (
              <li key={i}>{subListItem} </li>
            ))}
          </ul>
        </>
      )}
    </li>
  ));

  return (
    <div
      className={cx(styles.hList, {
        [styles.left]: left,
        [styles.noMargin]: noMargin,
        [styles.biggerText]: biggerText,
        [styles.noMarker]: noMarker,
        [styles.noPadding]: noPadding,
      })}
    >
      {children && children}
      {numbered ? (
        <ol
          className={styles.numbered}
          style={{ color: secondary ? variables.secondary : color }}
        >
          {listContent}
        </ol>
      ) : (
        <ul>{listContent}</ul>
      )}

      {/* // TODO add "View Plan" button */}
    </div>
  );
};

interface HListProps {
  children?: React.ReactNode;
  left?: boolean;
  numbered?: boolean;
  listItems: ListItem[];
  noMargin?: boolean;
  biggerText?: boolean;
  secondary?: boolean;
  color?: string;
  noMarker?: boolean;
  noPadding?: boolean;
  pointer?: boolean;
}

interface ListItem {
  text: React.ReactNode;
  subList?: React.ReactNode[];
}
