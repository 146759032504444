import moment from "moment";
/*
 * Date Sort
 - - - - - - - - - -
 Sort string date unix
 @param a - first date reference
 @param b - second date reference
 */
export const dateSort = (a: string, b: string) => {
  return moment(a).unix() - moment(b).unix();
};
