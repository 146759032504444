import { message, notification } from "antd";
import styles from "./handleHealcoError.module.scss";

// message duration (seconds)
const DURATION = 5;

/*
 * Handle HealCo Error
 - - - - - - - - - - - -
 Handles a custom HealCo Error
 @param error: the HealCo Error
 @param showThrow: true if an actual Error should be thrown to the console (and exit the function)\
 @param onClose: optional callback function to execute after the Error message closes
 */
export const handleHealcoError = (
  error?: HealcoError,
  shouldThrow = false,
  onClose?: Function,
) => {
  // throws a message (and logs the message), or logs the unique HealCo Error code
  if (shouldThrow) throw new Error(error?.message);
  else console.error(error?.code);
  // shows the error message through Ant Design
  // content cannot be empty string
  if (error?.isInformation) {
    notification.info({
      message: "",
      description: error?.message,
    });
  } else {
    message.error({
      content: error?.message ? error?.message : "Something went wrong.",
      duration: DURATION,
      className: styles.errorMessage,
      onClose,
    });
  }
};
