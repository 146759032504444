import { REF_OTHER } from "../../constants";

/*
 * Convert HRef to HRef Form Value
 - - - - - - - - - - - - - - - - - -
 Converts a HealcoReference object to a HealcoReferenceFormValue
 @param refs: the HealcoReferenceArray
 */
export const convertHRefToHRefFormValue = (
  refs?: HealcoReferenceOther[],
): HealcoReferenceFormValue => {
  if (!refs) refs = [];

  const input = refs.filter((ref) => ref.id !== REF_OTHER).map((ref) => ref.id);

  const other = refs.filter((ref) => ref.id === REF_OTHER)[0]?.other || [];

  return {
    input: input.length === 1 ? input[0] : input,
    other: other.length === 1 ? other[0] : other,
  };
};
