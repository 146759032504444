import React, { useState } from "react";
import { Button, Steps, Form, Row, Col, StepsProps } from "antd";
import { FormProps, FormInstance } from "antd/lib/form";
import { SubmitButton } from "../";
const { Step } = Steps;

/*
 * Multi-Step Form
 - - - - - - - - - -
 A Form that is spread through many different subforms on different steps.
 All API logic and stepCreation is done on the parent component.
 ! If you have more than 15 steps, you CANNOT allow the steps to be 'switchable'
 @param form: The whole form's instance
 @param steps: Each object of this array maps to the step that the form is on
 @param switchable: If the user can switch between steps of the form on click or not
 @params formProps, stepsProps, progressProps: Any optional Antd props that can be passed along
 @param isLoading: optional isLoading param to be used by the submit button
 @param onSubmit: What to do with the form values once the the form is submitted. Usually an API call
 @param shouldShowNavButtons: Whether or not Next and Previous Buttons should show
 */
export const MultiStepForm = ({
  form,
  steps,
  switchable,
  formProps,
  stepsProps,
  isLoading,
  onSubmit,
  shouldShowNavButtons = true,
}: MultiStepFormProps) => {
  const [currentStep, setCurrentStep] = useState(0);

  const displaySteps = steps.map((item, idx) => (
    <Step
      key={idx}
      title={item.title}
      style={{ width: `${1 / steps.length}%` }}
    />
  ));

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Steps
          progressDot
          labelPlacement="vertical"
          size="small"
          current={currentStep}
          onChange={(current) => {
            if (switchable) setCurrentStep(current);
          }}
          {...stepsProps}
        >
          {displaySteps}
        </Steps>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            onFinish={onSubmit}
            labelCol={{ span: 24 }}
            {...formProps}
          >
            {steps.map((step, idx) => (
              // hiding each step so hidden one's are not destroyed
              <div
                key={idx}
                style={{ display: idx === currentStep ? "initial" : "none" }}
              >
                {step.content}
              </div>
            ))}
          </Form>
        </Col>
      </Row>
      {shouldShowNavButtons && (
        <Row justify="space-between" gutter={12}>
          <Col>
            {currentStep > 0 && (
              <Button
                type="text"
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                Previous
              </Button>
            )}
          </Col>
          {currentStep < steps.length - 1 && (
            <Col>
              <Button onClick={() => setCurrentStep(currentStep + 1)}>
                Next
              </Button>
            </Col>
          )}
          {currentStep === steps.length - 1 && (
            <Col>
              <SubmitButton
                onClick={() => form.submit()}
                isLoading={isLoading}
                size="middle"
              />
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

interface MultiStepFormProps {
  form: FormInstance;
  steps: MultiStepFormStep[];
  onSubmit: (values: any) => void;
  switchable?: boolean;
  formProps?: FormProps;
  stepsProps?: StepsProps;
  isLoading?: boolean;
  shouldShowNavButtons?: boolean;
}

interface MultiStepFormStep {
  title?: string;
  content: React.ReactNode;
  // error handling stuff
}
