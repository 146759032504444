import React, { useState } from "react";
import { Row, Col, Form, Checkbox, Modal } from "antd";
import notificationStyles from "./notification.module.scss";
import { REF_TABLE } from "../../../../../constants";
import { useHReference } from "../../../../../hooks";
import validateNumberModalContent from "./VerifyModal";

const NotificationForms = ({
  userNotification,
  phone,
  isPhoneVerified,
  userId,
  updateProfile,
}: NotificationFormsProps) => {
  const { [REF_TABLE.notificationMethod]: notificationMethod } = useHReference([
    REF_TABLE.notificationMethod,
  ]);
  const [showVerify, setShowVerify] = useState<boolean[]>(
    userNotification?.map((uN) => {
      if (uN.notificationSettings?.method?.includes("PHNE")) return true;
      return false;
    }) as boolean[],
  );

  return (
    <Row gutter={8}>
      {userNotification?.map((notif, i) => (
        <React.Fragment key={i}>
          {(i === 0 ||
            (i > 0 &&
              userNotification?.[i - 1].question.category !==
                notif.question.category)) && (
            <Col span={24} className={notificationStyles.category}>
              {notif.question.category}
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              className={notificationStyles.formContainer}
              label={notif.question.name}
              name={notif.question.id}
              tooltip={notif.question.description}
            >
              <Checkbox.Group
                onChange={(v) => {
                  const verifyList = showVerify;
                  verifyList[i] = v.includes("PHNE") ? true : false;
                  setShowVerify([...verifyList]);
                }}
              >
                {notificationMethod?.map((methods) => (
                  <Checkbox key={methods.id} value={methods.id}>
                    {methods.name}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            {!isPhoneVerified && showVerify && showVerify[i] && (
              <p
                className={notificationStyles.verify}
                onClick={() =>
                  Modal.confirm(
                    validateNumberModalContent(phone, userId, updateProfile),
                  )
                }
              >
                Click here to verify Phone Number
              </p>
            )}
          </Col>
        </React.Fragment>
      ))}
    </Row>
  );
};

export default NotificationForms;

interface NotificationFormsProps {
  userNotification?: HealcoPreferences[];
  phone: string;
  isPhoneVerified: boolean;
  userId: string;
  updateProfile: () => void;
}
