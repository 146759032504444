/*
 * Disable Auto Complete
 - - - - - - - - - - - - - -
 Select the specific query string and sets the autocomplete attribute to
"stopDamAutocomplete". As 'off' and 'false' does not work on chrome for whatever reason
 @param query - can be either a string or an array of string of varying DOMString (ids - classNames - attributes - html)
 */

// # querySelectorAll accepts varying types (DOMString).
// https://developer.mozilla.org/en-US/docs/Web/API/DOMString
// https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelectorAll
export const disableAutocomplete = (query: string | string[]) => {
  document
    .querySelectorAll(Array.isArray(query) ? query.join(",") : query)
    .forEach((e) => {
      e.setAttribute("autocomplete", "stopDamnAutocomplete");
      //you can put any value but NOT "off" or "false" because they DO NOT works
    });
};
