import { handleFetch } from "../..";
import { API_ROUTE, FETCH } from "../../../constants";

export const accessEPHIAudit = () => {
  handleFetch<void, HealcoPatientList[]>({
    route: API_ROUTE.audit.accessEphi,
    method: FETCH.post,
    onSuccess: () => {},
  });
};

export const continueEPHIAudit = () => {
  handleFetch<void, HealcoPatientList[]>({
    route: API_ROUTE.audit.continueEphi,
    method: FETCH.post,
    onSuccess: () => {},
  });
};

export const logoutEPHIAudit = () => {
  handleFetch<void, HealcoPatientList[]>({
    route: API_ROUTE.audit.logoutEphi,
    method: FETCH.post,
    onSuccess: () => {},
  });
};
