import { SettingOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NavIconHome } from "../../../../../assets/icon-home.svg";
import { PATH } from "../../../../../constants";
import { NavWrapper } from "../NavWrapper";
import { history } from "../../../../../util";
import { rxPages } from "../../..";

/*
 * Patient Nav
 - - - - - - - - - - - -
 Shows only the patient side navigation options.
 */
export const PatientNav = () => {
  const prevPage = { prevPage: history.location.pathname };
  const isSecurePath = rxPages.includes(history.location.pathname);
  return (
    <NavWrapper userIsRx={true} isSecurePath={isSecurePath}>
      <Menu.Item key={PATH.patient.dashboard}>
        <Link to={{ pathname: PATH.patient.dashboard, state: prevPage }}>
          <NavIconHome />
          <span>Dashboard</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={PATH.patient.account}>
        <Link to={{ pathname: PATH.patient.account, state: prevPage }}>
          <SettingOutlined />
          <span>Account</span>
        </Link>
      </Menu.Item>
    </NavWrapper>
  );
};
