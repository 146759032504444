import { Button } from "antd";
import React, { useEffect, useReducer } from "react";
import { getProfile } from "..";
import { LoadingPage } from "../../../../../components";
import {
  isHealcoRole,
  objectMetaReducer,
  updateQsList,
} from "../../../../../util";
import EditInfo from "./EditInfo";
import ShowInfo from "./ShowInfo";
import styles from "./profile.module.scss";
import { useToggle } from "../../../../../hooks";

const Profile = ({ profile, identity }: ProfileProps) => {
  const [isLoading, toggleLoading] = useToggle();
  const [isEdit, toggleEdit] = useToggle();
  const userIsAdmin = isHealcoRole(identity, "healcoAdmin");
  const allowedUser = profile?.id === identity?.id || userIsAdmin;
  const [updProfile, setUpdProfile] = useReducer<
    TObjectReducer<HealcoProfileTemp | undefined>
  >(objectMetaReducer, undefined);

  useEffect(() => {
    updateQsList(["isEdit"], [isEdit ? "true" : null]);
  }, [isEdit]);

  useEffect(() => {
    const fetchProfile = async () => {
      toggleLoading();
      try {
        const { result } = await getProfile();
        if (result) {
          if (!updProfile) {
            setUpdProfile({
              type: "init",
              payload: { ...result },
            });
          } else {
            setUpdProfile({
              type: "update",
              payload: { ...result },
            });
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        toggleLoading();
      }
    };

    if (isEdit) fetchProfile();
    // eslint-disable-next-line
  }, [isEdit, toggleLoading]);

  if (!profile || isLoading) return <LoadingPage />;
  return (
    <div className={styles.accountContainer}>
      {isEdit && updProfile && allowedUser ? (
        <EditInfo
          profile={updProfile}
          dispatchEntityInfo={setUpdProfile}
          toggleEdit={toggleEdit}
        />
      ) : (
        <div>
          {allowedUser && (
            <div className={styles.editBtn}>
              <Button size="large" onClick={toggleEdit}>
                Edit Profile
              </Button>
            </div>
          )}
          <ShowInfo profile={profile} loading={isLoading} />
        </div>
      )}
    </div>
  );
};

export default Profile;

interface ProfileProps {
  profile: HealcoProfile;
  identity: HealcoUserIdentity;
}
