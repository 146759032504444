import { Button, Col, message, Row, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PageHeader, IconButton, HLink } from "../../../../components/";
import { PATH, REF_TABLE, LOCAL_STORAGE } from "../../../../constants";
import { useHReference, useMobile, useToggle } from "../../../../hooks";
import { getFetchResponse, IdentityContext } from "../../../../util";
import ApplicationCard from "../Application/ApplicationCard";
import { NearbyAndRecent } from "./GuestComponents";
import styles from "./userDashboard.module.scss";
import Modal from "antd/lib/modal/Modal";
import LesseeMap from "../Map/LesseeMap";
import {
  SearchOutlined,
  BookOutlined,
  PlusOutlined,
  CrownOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import Changelog from "../../components/Changelog";
import { WelcomeModal } from "../../components/WelcomeModal";
import variables from "../../../../styles/variables.module.scss";

const UserDashboard = () => {
  const identity = useContext(IdentityContext);
  const isMobile = useMobile();
  const [isLoading, toggleLoading] = useToggle();
  const [hostApplications, setHostApplications] = useState<HealcoApplication[]>(
    [],
  );
  const [isActivityModalVisible, toggleActivityModal] = useToggle();
  const organizationId = identity?.organization?.id;
  const planStatus = identity?.billing?.healcoPlan;
  // const propertySearchSettings = identity?.organization.propertySearchSettings;
  const {
    [REF_TABLE.introductionRejectionReason]: introductionRejectionReasons,
  } = useHReference([REF_TABLE.introductionRejectionReason]);

  const getHostApplications = async () => {
    toggleLoading();
    try {
      const { result } = await getFetchResponse<
        HealcoResponse<HealcoApplication[]>
      >(
        fetch(
          `/api/propertyIntroduction/host/${organizationId}?viewCondition=simple`,
        ),
      );
      setHostApplications(result);
    } catch (err) {
      message.error("Unable to load applications - ", err);
    } finally {
      toggleLoading();
    }
  };
  // eslint-disable-next-line
  useEffect(() => void getHostApplications(), []);

  if (!identity) return null;
  // const newLessor = 0;
  // !identity.owned_property_count;

  return (
    <>
      {localStorage.getItem(LOCAL_STORAGE.signupReason) && <WelcomeModal />}
      <Changelog />
      <PageHeader label="Dashboard" />
      <Row gutter={8} justify={"start"}>
        <Col>
          <Link to={`${PATH.provider.account}?tab=billing`}>
            <IconButton
              title={`HealCo Membership: ${
                planStatus === "none" ? "Free" : planStatus
              }`}
              icon={<CrownOutlined />}
              buttonProps={{ style: { backgroundColor: variables.primary } }}
            />
          </Link>
        </Col>
        <Col>
          <Link to={PATH.provider.newProperty}>
            <IconButton title="Create a listing" icon={<PlusOutlined />} />
          </Link>
        </Col>
        <Col>
          <Link to={`${PATH.provider.properties}?tab=property-search`}>
            <Tooltip title="Search properties">
              <Button icon={<SearchOutlined />} />
            </Tooltip>
          </Link>
        </Col>
        <Col>
          <HLink
            newTab
            to={`https://hipaa.jotform.com/203556350690152?referringProvider=Dr.%20${identity.firstName}%20${identity.lastName}&providerEmail=${identity.email}&providerPhone=${identity.phone}`}
          >
            <Tooltip title="Refer patients to talk therapy">
              <Button icon={<BookOutlined />} />
            </Tooltip>
          </HLink>
        </Col>
        <Col>
          <HLink newTab to={PATH.landing.home}>
            <Tooltip title="Refer patients through HealCoRx">
              <Button icon={<AuditOutlined />} />
            </Tooltip>
          </HLink>
        </Col>
      </Row>

      <Modal
        visible={isActivityModalVisible}
        closable
        onCancel={toggleActivityModal}
        footer={null}
        width={isMobile ? "90vw" : "70vw"}
      >
        <LesseeMap showAllData={false} />
      </Modal>

      {hostApplications?.length > 0 && (
        <>
          <h2 style={{ marginTop: 40 }}>
            Property Connections{" "}
            {hostApplications.length > 0 && (
              <Link to={PATH.provider.connections}>- View all connections</Link>
            )}
          </h2>
          <div className={styles.applicationContainer}>
            {hostApplications.map((app) => (
              <div key={app.id}>
                <ApplicationCard
                  application={app}
                  organizationId={organizationId}
                  isLoading={isLoading}
                  toggleLoading={toggleLoading}
                  introductionRejectionReasons={introductionRejectionReasons}
                  getApplications={getHostApplications}
                />
              </div>
            ))}
          </div>
        </>
      )}

      <NearbyAndRecent organizationId={identity?.organization.id} />
    </>
  );
};

export default UserDashboard;
