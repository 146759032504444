import { Button, Tooltip } from "antd";
import React from "react";
import { HLink } from "../../../../../../components";
import { Signup } from "../types";
import styles from "./signupChoice.module.scss";
import cx from "classnames";
import variables from "../../../../../../styles/variables.module.scss";

/*
 * Sign Up Option
 - - - - - - - - - -
 Individual Sign Up Choice Button
 @param choice: Current signup choice
 @param icon: The button's icon
 @param setChoice: Set Choice callback
 */
export const SignupChoice = ({
  choice,
  icon,
  onClick,
  selected,
  disabled,
  text,
  width = "100%",
}: SignupChoiceProps) => {
  const ChoiceButton = ({ selected }: ChoiceButtonProps) => {
    return (
      <>
        {disabled ? (
          <Tooltip
            title="Coming soon"
            placement="bottom"
            color={variables.secondary}
          >
            <span>
              <Button
                disabled={disabled}
                style={{ pointerEvents: "none" }}
                className={styles.signUpChoice}
              >
                {icon}
                {choice?.name}
                <div className={styles.subText}>
                  {choice?.subText && choice.subText}
                </div>
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            className={cx(styles.signUpChoice, {
              [styles.active]: selected,
            })}
            onClick={() => {
              if (onClick) onClick();
            }}
          >
            {icon}
            {text || choice?.name}
            <div className={styles.subText}>
              {choice?.subText && choice.subText}
            </div>
          </Button>
        )}
      </>
    );
  };

  return (
    <div style={{ width }}>
      {choice?.link ? (
        <HLink
          to={choice?.link}
          onClick={choice?.onClick}
          params={
            choice?.state ? { [choice.state.key]: choice?.state.value } : {}
          }
        >
          <ChoiceButton />
        </HLink>
      ) : (
        <ChoiceButton selected={selected} />
      )}
    </div>
  );
};

interface ChoiceButtonProps {
  selected?: boolean;
  disabled?: boolean;
}

interface SignupChoiceProps extends ChoiceButtonProps {
  choice: Signup.Choice;
  icon: JSX.Element;
  onClick?: () => void;
  text?: string;
  width?: string;
}
