import cx from "classnames";
import moment from "moment";
import React from "react";
import { HealcoOrganizationTemp } from ".";
import { LoadingPage } from "../../../../../components";
import { isHealcoRole } from "../../../../../util";
import { makeFullAddress } from "../../../../../util/string";
import styles from "../Profile/profile.module.scss";
import { UI } from "../../../../../constants";

const ShowOrganization = ({
  identity,
  profile,
  organization,
  loading,
}: ShowOrganizationProps) => {
  const userIsAdmin = isHealcoRole(identity, "healcoAdmin");
  const billingInfo = profile.billing;
  const organizationAddress = {
    addressLineOne: organization?.address?.addressLineOne || "",
    addressLineTwo: organization?.address?.addressLineTwo || "",
    city: organization?.address?.city || "",
    usState: organization?.address?.usState || "",
    zipcode: organization?.address?.zipcode || "",
  };

  if (loading) return <LoadingPage />;
  return (
    <>
      <div className={cx(styles.flexInfo, styles.infoContainer)}>
        <div>
          <p>Practice name</p>
          <p>{organization?.name}</p>
        </div>
        <div>
          <p>Created On</p>
          <p>
            {moment(organization?.createdOn).format(UI.datetimeFormat.date)}
          </p>
        </div>
      </div>
      <div className={cx(styles.flexInfo, styles.infoContainer)}>
        <div>
          <p className={styles.label}>Business email</p>
          <p>{organization?.email}</p>
        </div>
        <div>
          <p className={styles.label}>Business phone</p>
          <p>{organization?.phone ? organization.phone : "-"}</p>
        </div>
        <div>
          <p className={styles.label}>Website</p>
          <p>{organization?.website ? organization.website : "-"}</p>
        </div>
        <div>
          <p className={styles.label}>Fax</p>
          <p>{organization?.fax ? organization.fax : "-"}</p>
        </div>
        <div>
          <p className={styles.label}>EIN</p>
          <p>{organization?.ein ? organization.ein : "-"}</p>
        </div>
      </div>
      <div className={cx(styles.infoContainer)}>
        <div>
          <p>Address</p>
          <p>{makeFullAddress(organizationAddress)}</p>
        </div>
      </div>
      {userIsAdmin && (
        <div className={cx(styles.flexInfo, styles.infoContainer)}>
          <div>
            <p>HealCo Plan</p>
            <p>{billingInfo.healcoPlan}</p>
          </div>
          <div>
            <p>Stripe Customer Id</p>
            <p>{billingInfo.stripeCustomerId}</p>
          </div>
          <div>
            <p>Stripe Connected Customer Id</p>
            <p>{billingInfo.stripeConnectedAccountId}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowOrganization;

interface ShowOrganizationProps {
  identity: HealcoUserIdentity;
  profile: HealcoProfile;
  organization?: HealcoOrganizationTemp;
  loading: boolean;
}
