import { useCallback, useState } from "react";

/*
 * Use Toggle
 - - - - - - - -
 Optimizes Set State function to use when you are toggling
 between a boolean state.
 @param initialState: true / false initial toggle

 @ref From: https://dev.to/alexkhismatulin/update-boolean-state-right-with-react-hooks-3k2i
 */
export const useToggle = (initialState = false): [boolean, () => void] => {
  const [isToggled, setIsToggled] = useState(initialState);

  // put [setIsToggled] into the useCallback's dependencies array
  // this value never changes so the callback is not going to be ever re-created
  const toggle = useCallback(() => setIsToggled((state) => !state), [
    setIsToggled,
  ]);

  return [isToggled, toggle];
};
