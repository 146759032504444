import React, { useState } from "react";
import { Button, Modal, Steps, Form, Row, Col } from "antd";
import { ModalProps } from "antd/lib/modal";
import { FormProps, FormInstance } from "antd/lib/form";
import { useMobile } from "../../hooks";
import variables from "../../styles/variables.module.scss";
import { SubmitButton } from "../Form";

const { Step } = Steps;

/*
 * Multi-Step Form Modal
 - - - - - - - - - - - - -
 Given different steps (see type below), creates a modal
 that leads the user through the form items, with a final submit once the
 last step has been reached.
 @param form: the Antd form object (from const [form] = Form.useForm())
 @param steps: array of step titles and content
 @param isLoading: optional Loading boolean
 @param onSubmit: callback to fire on form submit
 @param modalProps: any other props
 */
export const MultiStepFormModal = ({
  form,
  steps,
  isLoading,
  onSubmit,
  modalProps,
  formProps,
}: MultiStepModalProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const isMobileXxl = useMobile(variables.antdXxl);
  const isMobileXl = useMobile(variables.antdXl);
  const isMobileSm = useMobile(variables.antdSm);

  const onFinishFailed = () => {
    console.log("FORM ERRORS:");
    console.log(form.getFieldsError());
  };

  return (
    <Modal
      width={isMobileXl ? "95vw" : "60vw"}
      {...modalProps}
      footer={[
        <React.Fragment key={1}>
          {currentStep > 0 && (
            <Button type="text" onClick={() => setCurrentStep(currentStep - 1)}>
              Previous
            </Button>
          )}
        </React.Fragment>,
        <React.Fragment key={2}>
          {currentStep < steps.length - 1 && (
            <Button onClick={() => setCurrentStep(currentStep + 1)}>
              Next
            </Button>
          )}
        </React.Fragment>,
        <React.Fragment key={3}>
          {currentStep === steps.length - 1 && (
            <SubmitButton onClick={() => form.submit()} isLoading={isLoading}>
              Submit
            </SubmitButton>
          )}
        </React.Fragment>,
      ]}
    >
      <Row align="middle">
        {!isMobileSm && (
          <Col xs={4} sm={8} lg={4} xxl={24}>
            <Steps
              progressDot
              size="small"
              current={currentStep}
              direction={isMobileXxl ? "vertical" : "horizontal"}
              onChange={(current) => setCurrentStep(current)}
            >
              {steps.map((item, idx) => (
                <Step key={idx} title={item.title} />
              ))}
            </Steps>
          </Col>
        )}
        <Col
          xs={24}
          sm={16}
          lg={{ offset: 2, span: 16 }}
          xxl={{ offset: 4, span: 16 }}
        >
          <Form
            form={form}
            onFinish={onSubmit}
            onFinishFailed={onFinishFailed}
            labelCol={{ span: 24 }}
            style={{ marginTop: isMobileXxl ? 0 : 50 }}
            {...formProps}
          >
            {steps.map((step, idx) => (
              // hiding each step so hidden one's are not destroyed
              <div
                key={idx}
                style={{ display: idx === currentStep ? "initial" : "none" }}
              >
                {step.content}
              </div>
            ))}
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

interface MultiStepModalProps {
  form: FormInstance;
  formProps?: FormProps;
  modalProps?: ModalProps;
  steps: CheckupSteps[];
  isLoading?: boolean;
  onSubmit: (values: any) => void;
}

interface CheckupSteps {
  title: TCheckupSteps;
  content: React.ReactNode;
}

type TCheckupSteps =
  | "Personal"
  | "Practice"
  | "Provider"
  | "Mental Health Provider"
  | "Property Search Settings"
  | "Property Listing";
