/*
 * Alphabetical Sort
 - - - - - - - - - -
 Sort string alphabetically
 @param a - first reference
 @param b - second reference
 */
export const alphabeticalSort = (a: string, b: string) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};
