import { useEffect, useState } from "react";

/*
 * Use Is Scrolled
 - - - - - - - -
 On scroll, returns if the page has scrolled past a certain amount.
 @param scrollLimit: the scroll limit
 */
export const useScrolled = (scrollLimit: number) => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      setScrollTop(window.scrollY);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return scrollTop > scrollLimit;
};
