import React from "react";
import { Alert } from "antd";
import styles from "./hBanner.module.scss";

/* ! Change */
const HBANNER_CONTENT = (
  <span>
    Limited time! Get one <b>FREE MONTH</b> of any medication subscription.
  </span>
);

const HBANNER_UNSUPPORTED_BROWSER = (
  <span>
    YOUR CURRENT BROWSER MAY NOT BE SUPPORTED. WE RECOMMEND USING THE LATEST
    VERSION OF CHROME.
  </span>
);

/*
 * HBanner
 - - - - -
 Banner that stretches across the screen with centered, simple content.
 Used on the Landing Template for promotions / announcements, but can also be
 used in other places.
 */
export const HBanner = ({
  onClose,
  isBannerClosed,
  isUnsupportedBrowser,
}: HBannerProps) => {
  return (
    <>
      {!isBannerClosed && (
        <Alert
          className={styles.hBanner}
          message={HBANNER_CONTENT}
          onClose={onClose}
          closable
        />
      )}
      {isUnsupportedBrowser && (
        <Alert
          style={{ top: !isBannerClosed ? "35px" : "0px" }}
          className={styles.hBanner}
          message={HBANNER_UNSUPPORTED_BROWSER}
        />
      )}
    </>
  );
};

interface HBannerProps {
  onClose?: () => void;
  isBannerClosed?: boolean | null;
  isUnsupportedBrowser?: boolean;
}
