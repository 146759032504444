import { loadStripe } from "@stripe/stripe-js";

/*
 * Stripe Promise
 - - - - - - - - - - -
 */
export const stripePromise = loadStripe(
  window.location.host.includes("localhost") ||
    window.location.host.includes("dev")
    ? "pk_test_JgiBWxy6Mi9WENJFCbcr5AB4" // Test Publishable Key
    : "pk_live_lW1fUY3oz0wYZ2co96XhUotE", // Live Publishable Key
);
