import React from "react";
import { Form, Select, Input, Row, Button } from "antd";
import { SubmitButton } from "../../../../../../components";
import { useHReference } from "../../../../../../hooks";
import { REF_TABLE, REF_OTHER } from "../../../../../../constants";
import styles from "../../PrescriptionModal/PrescriptionInfoModal/prescriptionsInfoModal.module.scss";

const RejectWindow = ({
  handleReject,
  loading,
  handleBack,
}: RejectWindowProps) => {
  const {
    [REF_TABLE.prescriptionRejectionReason]: prescriptionRejectionReason,
  } = useHReference([REF_TABLE.prescriptionRejectionReason]);
  const [form] = Form.useForm();
  const alertWindow = () => {
    const resp = window.confirm(
      "Are you sure you want to exit? You have not accepted or rejected this prescription yet.",
    );
    if (resp === true) handleBack();
  };

  return (
    <Form layout="vertical" onFinish={handleReject} form={form}>
      <Form.Item
        label="Reason"
        name="rejectionReason"
        rules={[{ required: true }]}
      >
        <Select>
          {prescriptionRejectionReason?.map((reason) => (
            <Select.Option key={reason.id} value={reason.id}>
              {reason.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.rejectionReason !== currentValues.rejectionReason
        }
      >
        {({ getFieldValue }) =>
          getFieldValue("rejectionReason") === REF_OTHER ? (
            <Form.Item
              name="rejectionReasonOther"
              label="Please Explain"
              rules={[{ required: true }]}
            >
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
                showCount
                maxLength={255}
              />
            </Form.Item>
          ) : null
        }
      </Form.Item>
      <Row justify="space-between">
        <Button type="primary" size="large" onClick={() => alertWindow()}>
          Back
        </Button>
        <SubmitButton
          text="Confirm"
          className={styles.reject}
          isLoading={loading}
        />
      </Row>
    </Form>
  );
};

export { RejectWindow };

interface RejectWindowProps {
  handleReject: (values: any) => Promise<void>;
  loading: boolean;
  handleBack: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
