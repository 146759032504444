import { message } from "antd";

/*
 * Get Zipcode
 - - - - - - - -
 */
export const getZipcode = async (
  location: string,
  specialty: string,
  range: number,
) => {
  if (specialty?.length > 0 || (location && location?.length === 5)) {
    try {
      if (location) {
        const fetchZip = await fetch(
          `/api/search/GeolocationFromZipcode/?zipcode=${location}`,
        );
        const data = await fetchZip.json();
        if (data?.success) {
          return { geometry: data?.location, range };
        }
      }
    } catch (e) {
      /* IGNORE */
    }
  } else {
    message.error("Please enter a valid Zipcode.");
  }
};
