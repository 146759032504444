import React, { useRef } from "react";
import { Form, Row, Col, Button, Input, FormItemProps, Tooltip } from "antd";
import { FormListProps } from "antd/lib/form";
import {
  MinusCircleOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { NamePath } from "rc-field-form/lib/interface";

/*
 * Input List
 - - - - - - - - - - - - -
 Expandable list of Inputs.
 @param name: required on Form List
 @param customInput: optional, more complex Form Item than just a regular input
 @param maxInputs: maximum number of additional input fields
 @param formList: Form List props
 @param formItem: Form Item props
 */
export const InputList = ({
  name,
  customInput,
  maxInputs = 5,
  formItemProps,
  formListProps,
  required = false,
}: InputListProps) => {
  const numInputs = useRef(1);

  return (
    <Form.Item {...formItemProps}>
      <Form.List name={name} {...formListProps} initialValue={[""]}>
        {(fields, { add, remove }) => (
          <>
            <Row wrap={false}>
              <Col flex="none">
                <Tooltip
                  title={
                    numInputs.current >= maxInputs
                      ? "Maximum inputs reached"
                      : "Add field"
                  }
                >
                  <Button
                    type="text"
                    onClick={() => {
                      numInputs.current += 1;
                      add();
                    }}
                    disabled={numInputs.current >= maxInputs}
                    icon={
                      numInputs.current >= maxInputs ? (
                        <MinusOutlined />
                      ) : (
                        <PlusOutlined />
                      )
                    }
                  />
                </Tooltip>
              </Col>
              <Col flex="auto">
                {fields.map((field) => (
                  <Row align="middle" key={field.key}>
                    <Col span={23}>
                      <Form.Item
                        {...field}
                        rules={[
                          {
                            required,
                            whitespace: true,
                            message: "Please specify or delete this field.",
                          },
                        ]}
                        noStyle
                      >
                        {customInput ? (
                          customInput
                        ) : (
                          <Input placeholder={"Please specify"} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          onClick={() => {
                            numInputs.current -= 1;
                            remove(field.name);
                          }}
                          style={{ marginLeft: "5px", color: "#2d8dfa" }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};

interface InputListProps {
  name: NamePath;
  customInput?: React.ReactNode;
  formItemProps?: FormItemProps;
  formListProps?: Omit<FormListProps, "children">;
  maxInputs?: number;
  required?: boolean;
}
