/*
 * Is Only Numbers
 - - - - - - - - -
 Returns if a string is only numbers.
 */
export const isOnlyNumbers = (str: string) => {
  const numberRegexp = /^[0-9]+$/;

  return numberRegexp.test(str);
};
