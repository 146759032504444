import React from "react";
import { Modal } from "antd";
import { HHeading, HLink, HButton, HSpace } from "../../components";
import variables from "../../styles/variables.module.scss";
import { useSessionState } from "../../hooks/useSessionState";

/*
 * HConfirmModal
 - - - - -
  Modal that offers an optional question or title text and a confirm or cancel button option.
  Used on Pillar page "Get Medicinal Cannabis License" to confirm age 21+, but can also be used in other places.

  @param storageKey: short custom text variation to accommodate reuse without duplicating Session Storage keys if multiple HConfirmModals are implemented in the application
  @param cancelText: text displayed in cancel option
  @param cancelPATH: url from PATH constant to be applied on handleCancel()
  @param confirmText: text displayed in confirm option
  @param confirmPATH: url from PATH constant to be applied on handleConfirm()
  @param headingText: optional text displayed above the cancel confirm options, likely a question but can be any text
 */

export const HConfirmModal = ({
  storageKey,
  cancelText,
  cancelPATH,
  confirmText,
  confirmPATH,
  headingText,
}: HConfirmModalProps) => {
  const IS_MODAL_OPEN_KEY = `is${storageKey}ModalClosed`;

  const [isModalClosed, setModalClosed] = useSessionState<boolean>(
    IS_MODAL_OPEN_KEY,
  );

  const handleConfirm = () => {
    setModalClosed(true);
  };

  const handleCancel = () => {
    setModalClosed(false);
  };

  return (
    <>
      <Modal
        title={null}
        footer={null}
        visible={!isModalClosed}
        bodyStyle={{ textAlign: "center" }}
        centered
        closable={false}
        maskClosable={false}
        maskStyle={{ backgroundColor: variables.black, opacity: ".9" }}
      >
        <HSpace>
          {headingText && (
            <HHeading
              size={4}
              color={variables.text}
              text={headingText}
              centered
              noMargin
            />
          )}

          <HLink to={confirmPATH} onClick={handleConfirm}>
            <HButton text={confirmText} buttonStyle="primary" />
          </HLink>
          <HLink to={cancelPATH} onClick={handleCancel}>
            <HButton text={cancelText} buttonStyle="secondary" />
          </HLink>
        </HSpace>
      </Modal>
    </>
  );
};

interface HConfirmModalProps {
  storageKey: string;
  cancelText: string;
  cancelPATH: string;
  confirmText: string;
  confirmPATH: string;
  headingText?: string;
}
